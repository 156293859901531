import React, { Component } from "react";
import { connect } from "react-redux";
import AdminAppBar from "./AdminAppBar";
import AdminCommunityList from "./AdminCommunityList";

class AdminInactiveCommunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: "",
    };
  }

  render() {
    return (
      <AdminAppBar selected="Inactive Communities" {...this.props}>
        <AdminCommunityList type="inactive" />
      </AdminAppBar>
    );
  }
}

AdminInactiveCommunities.propTypes = {};

const mapStateToProps = (state) => {
  return {
    deviceMonitor: state.deviceStatus,
  };
};

export default connect(mapStateToProps)(AdminInactiveCommunities);
