import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteAccountFromVendors = createAsyncAction(
  "DELETE_ACCOUNT_FROM_VENDORS_DATA",
  (vendor_uuid, account_id) => {
    return call(nimbioServer.admin.deleteAccountFromVendors, [vendor_uuid, account_id], "admin-get-vendors-by-account");
  },
);

export const getVendorsByAccount = createAsyncAction("ADMIN_GET_VENDORS_BY_ACCOUNT", (account_id) => {
  return call(nimbioServer.admin.getVendorsByAccount, [account_id], "admin-get-vendors-by-account");
});

export default handleActions(
  {
    ADMIN_GET_VENDORS_BY_ACCOUNT_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_VENDORS_BY_ACCOUNT_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_VENDORS_BY_ACCOUNT_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState,
);
