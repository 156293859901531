// One of these has to be commented out!

// NodeJS
// const wampyCra = require('wampy-cra');
// export const challengeResponse = (secret, challenge) => wampyCra.sign(secret, challenge);

// NOTE: ReactNative
// const rnCrypt = require('react-native-crypto');
// export const challengeResponse = (secret, challenge) =>
// rnCrypt.createHmac("sha256", secret).update(challenge).digest("base64");

// NOTE: ReactNative
import rnCrypt from "crypto-js";
// export const challengeResponse = (secret, challenge) =>
// rnCrypt.createHmac("sha256", secret).update(challenge).digest("base64");
export const challengeResponse = (secret, challenge) =>
  rnCrypt.enc.Base64.stringify(rnCrypt.HmacSHA256(challenge, secret));
