import React, { useRef, useEffect, useState } from "react";
import "../../App.css";
import { connect, useSelector } from "react-redux";
import { Box, Button, Grid, FormControlLabel, Switch, TextField } from "@mui/material";
import { setShareDepth, setShareDepthEnabled } from "../../reducers/admin-community-list";

const styles = {
  maxDepthContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
};

const isPositiveNumeric = (input) => {
  return /^[1-9][0-9]*$/.test(input); // /^[0-9][0-9]*$/ if >=0
};

const ShareDepthItem = (props) => {
  const community = useSelector((state) => state.adminCommunityList.community);
  const [maxShareDepth, setMaxShareDepth] = useState(props.maxShareDepth);
  const [maxShareDepthEnabled, setMaxShareDepthEnabled] = useState(props.maxShareDepthEnabled);
  const [prevMaxDepth, setPrevMaxDepth] = useState(props.maxShareDepth ? props.maxShareDepth : "No Limit");
  const [error, setError] = useState(false);
  const maxDepthRef = useRef(props.maxShareDepth);

  useEffect(() => {
    if (community) {
      const currentKey = community.keys.find((key) => {
        if (key.id === props.keyID) {
          return key;
        }
        return null;
      });

      if (currentKey) {
        setPrevMaxDepth(currentKey.max_share_depth);
        setMaxShareDepth(currentKey.max_share_depth);
        setMaxShareDepthEnabled(currentKey.max_share_depth_enabled);
      } else {
        console.error("Failed to fetch key for ShareDepthItem");
      }
    }
  }, [community]);

  const handleSwitchChange = (newState) => {
    if (newState === undefined) return;
    props.dispatch(setShareDepthEnabled(newState, props.keyID));
  };

  const handleTextFieldChange = (input) => {
    if (isPositiveNumeric(input)) {
      maxDepthRef.current = input;
      setError(false);
    } else setError(true);
  };

  const handleButtonClick = () => {
    if (isPositiveNumeric(maxDepthRef.current)) {
      setPrevMaxDepth(maxDepthRef.current);
      props.dispatch(setShareDepth(Number(maxDepthRef.current), props.keyID));
    } else setError(true);
  };

  return (
    <Box data-testid={"share-depth-item"} style={{ display: "flex", flexDirection: "column" }}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          <FormControlLabel
            value="top"
            control={
              <Switch
                data-testid={"share-depth-enabled-switch"}
                color="primary"
                checked={maxShareDepthEnabled}
                onClick={(elem) => handleSwitchChange(elem.target.checked)}
              />
            }
            label={"Max Depth Enabled"}
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12} md={6} mb={2}>
          <Box sx={styles.maxDepthContainer}>
            <Box
              component="form"
              sx={{
                mb: 1,
                mr: 2,
              }}
              noValidate
              autoComplete="off">
              <TextField
                error={error}
                data-testid={"share-depth-text-field"}
                disabled={!maxShareDepthEnabled}
                id="outlined-helperText"
                label={"Enter Max Depth"}
                type="number"
                defaultValue={maxShareDepth ? maxShareDepth : "no limit"}
                helperText={error ? "Only Positive Integers" : `Current Max Depth: ${prevMaxDepth}`}
                onChange={(elem) => handleTextFieldChange(elem.target.value)}
              />
            </Box>
            <Button
              variant="contained"
              disabled={!maxShareDepthEnabled || error}
              style={{ padding: "10px", borderRadius: "5px", maxWidth: "240px" }}
              onClick={() => handleButtonClick()}>
              Set Max Depth
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    communityState: state.communityState,
  };
};

export default connect(mapStateToProps)(ShareDepthItem);
