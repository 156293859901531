import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Paper,
  IconButton,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, connect } from "react-redux";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AddCircle from "@mui/icons-material/AddCircle";
import CopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import useConfirm from "./useConfirm";
import { getColor } from "../../../utilities/materialAvatar";
import { getLatchesVendors } from "../../../reducers/admin-get-latches-vendors";
import { deleteVendorFromLatch } from "../../../reducers/admin-get-latches-vendors";
import AdminSelectVendorDialog from "../../Admin/components/AdminSelectVendorDialog";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";

const styles = () => ({
  buttonContainer: {
    width: 72,
    height: 72,
    background: "#FF0000",
    borderRadius: "50%",
    borderColor: "#FF000066",
  },
  latchContainer: {
    padding: 1,
    margin: 1,
    userSelect: "none",
  },
  latchName: {
    marginBottom: "5px",
    textAlign: "center",
  },
  latchId: {
    marginTop: "5px",
    fontSize: 12,
    color: "#888",
    textAlign: "center",
  },
  centerText: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  gridCenter: {
    textAlign: "center",
    color: "white",
    fontFamily: "GothamBook",
    fontWeight: "bold",
    textShadow: "rgba(0, 0, 0, 0.4) 1px 1px 3px",
    userSelect: "none",
  },
});

function ExpandIcon({ expanded }) {
  if (expanded) {
    return (
      <IconButton>
        <ExpandLess />
      </IconButton>
    );
  }
  return (
    <IconButton>
      <ExpandMore />
    </IconButton>
  );
}

function VendorItem({ vendor, latchId }) {
  const dispatch = useDispatch();
  const { ConfirmDialog, openDialog } = useConfirm();
  return (
    <>
      <Grid container key={vendor.vendor_uuid + latchId} alignItems="center" justifyContent="flex-start">
        <Grid item xs={11}>
          <Typography variant="body2" color="textSecondary">
            {vendor.vendor_name}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            style={{ padding: 4 }}
            onClick={() => {
              openDialog({
                heading: "Remove Vendor",
                message: "Are you sure you want to remove this vendor from this latch?",
                okButton: "Remove",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteVendorFromLatch(latchId, vendor.vendor_uuid));
                },
              });
            }}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ConfirmDialog />
    </>
  );
}

const DeviceLatch = (props) => {
  const dispatch = useDispatch();

  const [vendorsOpen, setVendorsOpen] = useState(false);
  const [vendorsDialogOpen, setVendorsDialogOpen] = useState(false);

  const buttonContainer = {
    width: 72,
    height: 72,
    background: getColor(props.latch.id),
    borderRadius: "50%",
    borderColor: getColor(props.latch.id) + "66",
  };

  useSubscription(nimbioServer.vendor.vendorToLatch, () =>
    dispatch(getLatchesVendors(props.latch.box_id, props.latch.id))
  );

  useEffect(() => {
    dispatch(getLatchesVendors(props.latch.box_id, props.latch.id));
  }, [props.latch]);

  const _handleVendorsOpen = () => {
    setVendorsOpen((oldValue) => !oldValue);
  };

  const _selectVendorsOpen = () => {
    setVendorsDialogOpen(true);
  };

  return (
    <Paper sx={styles().latchContainer}>
      <Container sx={styles().latchName}>{props.latch.name}</Container>

      <Container sx={buttonContainer}>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={styles().centerText}>
          <Grid item sx={styles().gridCenter}>
            {props.latch.offline ? "Offline" : "OPEN"}
          </Grid>
        </Grid>
      </Container>
      <Container sx={styles().latchId}>
        {props.latch.id}
        <IconButton
          color="inherit"
          size="large"
          aria-label="Copy"
          onClick={() => {
            navigator.clipboard.writeText(props.latch.id);
          }}>
          <CopyIcon sx={{ fontSize: 12, marginTop: "-3px" }} />
        </IconButton>
      </Container>
      <Container>
        {(props.getLatchesVendors?.length > 0 && (
          <>
            <ListItem button onClick={_handleVendorsOpen}>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText secondary={`Vendors (${props.getLatchesVendors?.length || 0})`} />
              <ExpandIcon expanded={vendorsOpen} />
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  _selectVendorsOpen();
                }}>
                <AddCircle />
              </IconButton>
            </ListItem>

            <Collapse in={vendorsOpen} timeout="auto" unmountOnExit>
              {props.getLatchesVendors?.map((vendor) => {
                return (
                  <VendorItem vendor={vendor} latchId={vendor.latch_id} key={vendor.vendor_uuid + vendor.latch_id} />
                );
              })}
            </Collapse>
          </>
        )) || (
          <ListItem button onClick={_selectVendorsOpen}>
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText secondary="Vendors (0)" />
            <IconButton onClick={_selectVendorsOpen}>
              <AddCircle />
            </IconButton>
          </ListItem>
        )}
      </Container>
      {vendorsDialogOpen && (
        <AdminSelectVendorDialog
          open={vendorsDialogOpen}
          latch_id={props.latch.id}
          onClose={() => {
            setVendorsDialogOpen(false);
          }}
        />
      )}
    </Paper>
  );
};

DeviceLatch.propTypes = {
  latch: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    getLatchesVendors: state.getLatchesVendorsReducer.results.result,
  };
};

export default connect(mapStateToProps)(DeviceLatch);
