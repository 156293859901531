import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { connect, useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import {
  IconButton,
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  Divider,
  DialogContent,
  ListItemText,
  DialogTitle,
  ListItemAvatar,
  ListItem,
  Typography,
} from "@mui/material";
import EditMemberKeysDialog from "./EditMemberKeysDialog";
import MemberDetailKeys from "./MemberDetailKeys";
import HomeDetail from "./HomeDetail";
import ChildrenDialog from "./ChildrenDialog";
import { closeMemberDetail, removeMember, setMemberHome } from "../../../reducers/memberdetail-reducer";
import { adminGetMemberChildren, getMemberChildren } from "../../../reducers/member-children-reducer";
import { closeHomeDetail } from "../../../reducers/homedetail-reducer";
import { getColor, getShortName } from "../../../utilities/materialAvatar";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";
import { formatDatetime } from "../../../utilities/formatDatetime";
import EventDateTimePicker from "../../common/Components/EventDateTimePicker";

const styles = (theme) => ({
  divider: {
    margin: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

const createMomentInstanceFromDate = (moveOutDate) => {
  return moment().set({
    year: parseInt(formatDatetime(moveOutDate).split("-")[0]),
    month: parseInt(formatDatetime(moveOutDate).split("-")[1]) - 1,
    date: parseInt(formatDatetime(moveOutDate).split("-")[2]),
  });
};

const DialogBody = (props) => {
  const {
    selectedKeys,
    setSelectedKeys,
    communityKeys,
    community_id,
    detailState,
    handleReasonChange,
    revokeKeys,
    showChildren,
  } = props;
  const dispatch = useDispatch();
  const [revokeOpen, setRevokeOpen] = useState(false);
  const [editKeysOpen, setEditKeysOpen] = useState(false);

  const [editMoveOutDateOpen, setEditMoveOutDateOpen] = useState(false);
  const details = detailState.details;
  const [moveOutDate, setMoveOutDate] = useState(
    details.move_out_date ? createMomentInstanceFromDate(details.move_out_date) : null
  );
  const [moveOutDateDeleted, setMoveOutDateDeleted] = useState(false);
  const theme = useTheme();

  const submitMoveOutDateandClose = (isCancel) => {
    if (!isCancel) {
      updateMoveOutDate();
      setMoveOutDateDeleted(false);
    } else {
      setMoveOutDate(
        details.move_out_date && !moveOutDateDeleted ? createMomentInstanceFromDate(details.move_out_date) : null
      );
    }
    setEditMoveOutDateOpen(false);
  };

  const editKeys = async () => {
    const keysIdsToSubmit = [];
    Object.entries(selectedKeys).forEach(([key]) => {
      if (selectedKeys[key]) {
        keysIdsToSubmit.push(key);
      }
    });
    await call(
      nimbioServer.community.manager.setMemberKeys,
      [community_id, details.account_community_id, keysIdsToSubmit],
      "MemberDetail"
    );

    setSelectedKeys({});
    setEditKeysOpen(false);
    dispatch(closeMemberDetail());
  };

  const handleOpenEditKeys = (value) => {
    setEditKeysOpen(value);
  };

  const handleCheckKey = (key_id, checked) => {
    setSelectedKeys({ ...selectedKeys, [key_id]: checked });
  };

  const handleOpenEditMoveOutDate = () => {
    setEditMoveOutDateOpen(true);
    if (!moveOutDate) {
      setMoveOutDate(
        details.move_out_date && !moveOutDateDeleted
          ? createMomentInstanceFromDate(details.move_out_date)
          : moment().add(12, "months")
      );
    }
  };

  const handleDateChange = (date) => {
    setMoveOutDate(date);
  };

  const removeMoveOutDate = async () => {
    await call(
      nimbioServer.community.manager.setMemberMoveOutDate,
      [community_id, details.account_community_id, null],
      "MemberDetail"
    );
    setMoveOutDate(null);
    setMoveOutDateDeleted(true);
  };

  const updateMoveOutDate = async () => {
    const formattedDate = moveOutDate ? formatDatetime(moveOutDate) : null;
    await call(
      nimbioServer.community.manager.setMemberMoveOutDate,
      [community_id, details.account_community_id, formattedDate],
      "MemberDetail"
    );
  };

  useEffect(() => {
    checkKeysForDefaultCheckedState();
  }, [editKeysOpen]);

  const checkKeysForDefaultCheckedState = () => {
    const memberKeys = {};
    details.keys.forEach((member_key) => {
      memberKeys[member_key.parent_key_id] = member_key;
    });

    const keysForSelect = {};

    communityKeys.forEach((community_key) => {
      if (memberKeys[community_key.id] && memberKeys[community_key.id].disabled !== 1) {
        keysForSelect[community_key.id] = true;
      }
      if (memberKeys[community_key.id] && memberKeys[community_key.id].disabled === 1) {
        keysForSelect[community_key.id] = false;
      }
      if (!memberKeys[community_key.id]) {
        keysForSelect[community_key.id] = false;
      }
    });

    setSelectedKeys(keysForSelect);
  };

  return (
    <div>
      <ListItem>
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: getColor(details.account_community_id) }}>
            {getShortName(details.first_name, details.last_name)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText>
          {details.first_name} {details.last_name}
        </ListItemText>
      </ListItem>
      <Divider sx={styles(theme).divider} />
      <MemberDetailKeys
        handleOpenEditKeys={() => handleOpenEditKeys(true)}
        keys={details.keys}
        showChildren={showChildren}
      />
      <EditMemberKeysDialog
        editKeysOpen={editKeysOpen}
        selectedKeys={selectedKeys}
        handleOpenEditKeys={handleOpenEditKeys}
        communityKeys={communityKeys}
        handleCheckKey={handleCheckKey}
        editKeys={editKeys}
      />
      <Divider sx={styles(theme).divider} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Button variant="contained" onClick={() => setRevokeOpen(true)}>
          Remove Member
          <DeleteIcon sx={styles(theme).rightIcon} />
        </Button>
      </Grid>
      <Divider sx={styles(theme).divider} />
      <HomeDetail
        community_id={community_id}
        home_id={detailState.home_id}
        account_community_id={details.account_community_id}
      />
      <Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Move Out Date:{"  "}</Typography>
        </Grid>
        <Grid item xs={6} style={{ display: "flex", flexDirection: "row", paddingTop: 2 }}>
          <ListItemText
            data-testid={"member-detail-move-out-date"}
            style={{ marginLeft: "8px", maxWidth: "50%", paddingTop: "8px" }}>
            {moveOutDate ? formatDatetime(moveOutDate) : "None"}
          </ListItemText>
          <IconButton
            data-testid={"member-detail-handle-open-edit-move-out"}
            sx={{ ml: 2 }}
            aria-label="settings"
            size="large"
            onClick={handleOpenEditMoveOutDate}>
            <EditIcon color="primary" />
          </IconButton>
          {moveOutDate ? (
            <IconButton
              data-testid={"member-detail-delete-move-out-button"}
              sx={{ ml: 1 }}
              aria-label="settings"
              size="large"
              onClick={removeMoveOutDate}>
              <DeleteIcon color="primary" />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>

      <ChildrenDialog communityID={community_id} accountCommunityID={details.account_community_id} />
      <Dialog open={revokeOpen}>
        <DialogTitle>Remove Member From Community</DialogTitle>
        <DialogContent>
          <p>
            {"Are you sure you want to remove this community member? " +
              "The member's keys and any shared keys will be revoked."}
          </p>
          <TextField
            data-testid={"removal-reason-input"}
            onChange={handleReasonChange}
            sx={{ width: "50%" }}
            placeholder="Reason"
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => setRevokeOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              revokeKeys(
                details.account_community_id,
                details.keys?.map((item) => item.key_id),
                community_id
              );
              setRevokeOpen(false);
            }}
            variant={"contained"}
            autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <EventDateTimePicker
        open={editMoveOutDateOpen}
        setDateTimeCallback={(moveOutDate) => handleDateChange(moveOutDate)}
        firstPageTitle="Set Move Out Date"
        datetime={moveOutDate}
        dateOnly={true}
        closeCallback={submitMoveOutDateandClose}
        startingPage="date"
      />
    </div>
  );
};

const MemberDetail = (props) => {
  const { type } = props;
  const [reason, setReason] = useState("");
  const [selectedKeys, setSelectedKeys] = useState({});

  const handleOk = () => {
    props.dispatch(closeMemberDetail());
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const getChildren = () => {
    if (type === "admin") {
      props.dispatch(adminGetMemberChildren(props.community_id, props.detailState.details.account_community_id));
    } else {
      props.dispatch(getMemberChildren(props.community_id, props.detailState.details.account_community_id));
    }
  };

  const revokeKeys = (account_community_id, keys_to_remove, community_id) => {
    props.dispatch(setMemberHome(community_id, account_community_id, null));
    props.dispatch(removeMember(account_community_id, keys_to_remove, community_id, reason));
    props.dispatch(closeMemberDetail());
    props.dispatch(closeHomeDetail());
  };

  const showChildren = () => {
    if ((props.permissions && props.permissions.view_children) || type === "admin") {
      return (
        <Button onClick={getChildren} variant="contained" autoFocus>
          View Children
        </Button>
      );
    }
  };

  if (!props.detailState.open) {
    return <Dialog open={false} />;
  }
  if (props.detailState.open && props.detailState.error) {
    return (
      <Dialog open={props.detailState.open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>Could not load member details.</DialogContent>
        <DialogActions>
          <Button onClick={handleOk} variant="outlined" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (props.detailState.open && props.detailState.loading) {
    return (
      <Dialog open={props.detailState.open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>Loading</DialogTitle>
        <DialogContent>Loading details.</DialogContent>
        <DialogActions>
          <Button onClick={handleOk} variant="outlined" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      data-testid={"member-detail"}
      open={props.detailState.open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleOk}>
      <DialogTitle>Member Details</DialogTitle>
      <DialogContent>
        <DialogBody
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          detailState={props.detailState}
          community_id={props.community_id}
          handleReasonChange={handleReasonChange}
          revokeKeys={revokeKeys}
          communityKeys={props.communityKeys}
          showChildren={showChildren}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk} variant="contained" autoFocus data-testid={"accepted-item-confirm"}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    homesState: state.homesState,
    detailState: state.memberDetailState,
    permissions: state.communityState.currentCommunity?.permissions,
    communityKeys: state.keysState.keys,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(MemberDetail);
