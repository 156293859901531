import "core-js/es/map";
import "core-js/es/set";
import "raf/polyfill";

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "typeface-roboto";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import store from "./store";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import theme from "./theme";

// if (localStorage.getItem('auth_id') !== null && localStorage.getItem('secret') !== null){
//     ReactDOM.render(<PickCommunity/>, document.getElementById('root'));
// }
// else {
//     ReactDOM.render(<Login/>, document.getElementById('root'));
// }

// ReactDOM.render(<App/>, document.getElementById('root'));
// registerServiceWorker();

unregister();

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
