import store from "../store";

import { accountGetKeys } from "../reducers/account-get-keys";

export function latchUpdateSubscription(message) {
  message = message.argsList[0];
  if ("a" in message) {
    switch (message.a) {
      case "refresh":
        if (store.getState().accountState.account.id) {
          store.dispatch(accountGetKeys(store.getState().accountState.account.id));
        }
        break;
      default:
        console.log("<B>Message received and not handled", message);
    }
  }
}
