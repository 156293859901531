import React from "react";
import DistributorAppBar from "./DistributorAppBar";

const DistributorBoard = () => {
  return (
    <div>
      <DistributorAppBar />
    </div>
  );
};

DistributorBoard.propTypes = {};

export default DistributorBoard;
