import React from "react";
import PropTypes from "prop-types";
import { Button, Switch, FormControlLabel, Typography, Box } from "@mui/material";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { adminCommunitySetAllowAccessLogs } from "../../../reducers/admin-community-list";

const CommunityAllowAccessLogs = (props) => {
  const dispatch = useDispatch();

  return (
    <Box sx={{ m: 1 }}>
      <Box direction="row" alignItems="center">
        <Typography variant="body2" color="textSecondary" component="p">
          Access Logs
        </Typography>
        <FormControlLabel
          control={<Switch checked={props.is_open_log_history_enabled} />}
          label={<Typography>Access Logs Viewable</Typography>}
          labelPlacement="start"
          onClick={async () => {
            dispatch(adminCommunitySetAllowAccessLogs(props.community_id, !props.is_open_log_history_enabled));
          }}
        />
      </Box>
      <Box sx={{ m: 2 }} direction="row" alignItems="center">
        <Button variant="contained" href={`/admin/access-logs/${props.community_id}`}>
          View Access Logs
        </Button>
      </Box>
    </Box>
  );
};

CommunityAllowAccessLogs.propTypes = {
  is_open_log_history_enabled: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunityAllowAccessLogs);
