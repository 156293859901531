import React, { useEffect } from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sendAccountToAccountGroupData, sendBoxToAccountGroupData } from "../../../reducers/admin-get-account-groups";
import { getAccountGroups } from "../../../reducers/admin-get-account-groups";

const AdminSelectAccountGroupDialog = ({ account_id, box_id, open, onClose }) => {
  const dispatch = useDispatch();

  const accountGroups = useSelector((state) => {
    return state.getAccountGroupsReducer.results;
  });

  function handleSave(account_group_id) {
    if (box_id) {
      dispatch(sendBoxToAccountGroupData(account_group_id, box_id));
      return;
    }
    if (account_id) {
      dispatch(sendAccountToAccountGroupData(account_group_id, account_id));
    }
  }

  useEffect(() => {
    dispatch(getAccountGroups());
  }, [dispatch, open]);

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid={"add-account-group"}>
      <DialogTitle>Select an Account Group</DialogTitle>
      <DialogContent>
        <Grid container>
          {accountGroups.map((accountGroup) => {
            return (
              <Grid item xs={12} key={accountGroup.account_group_id}>
                <Button
                  onClick={() => {
                    handleSave(accountGroup.account_group_id);
                    onClose();
                  }}>
                  {accountGroup.name}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminSelectAccountGroupDialog;
