import React from "react";
import PropTypes from "prop-types";
import { Box, Switch, FormControlLabel, Typography } from "@mui/material";
import { connect } from "react-redux";
import { adminCommunitySetAllowSubkeys } from "../../../reducers/admin-community-list";

const styles = {
  text: {
    fontFamily: "Filson",
  },
};
const CommunityAllowSubkeys = (props) => {
  return (
    <Box sx={{ m: 1 }}>
      <Box direction="row" alignItems="center">
        <Typography sx={styles.text} variant="body2" color="textSecondary" component="p">
          Subkeys
        </Typography>
      </Box>
      <Box direction="row" alignItems="center">
        <FormControlLabel
          control={<Switch checked={Boolean(props.allow_subkeys)} />}
          label={<Typography sx={styles.text}>Allow Subkeys</Typography>}
          labelPlacement="start"
          onClick={() => props.dispatch(adminCommunitySetAllowSubkeys(props.community_id, !props.allow_subkeys))}
        />
      </Box>
    </Box>
  );
};

CommunityAllowSubkeys.propTypes = {
  allow_subkeys: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunityAllowSubkeys);
