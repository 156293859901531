import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import AdminAppBar from "./AdminAppBar";
import AdminDeviceLatches from "./AdminDeviceLatches";
import { adminGetDevice } from "../../../reducers/device-status-reducer";
import { adminDeviceGetConnectionHistory } from "../../../server/server";
import DeviceItem from "../../common/Components/DeviceItem";

import DataTable from "../../common/Components/DataTable";
import { getBoxesAccountGroups } from "../../../reducers/admin-get-boxes-account-groups";
import { getBoxesVendors } from "../../../reducers/admin-get-boxes-vendors";

const NoDeviceError = () => {
  return <div>A device with that ID was not found.</div>;
};

const DeviceHistory = ({ box_id }) => {
  const pageSize = 100;

  const connectionHistory = async () => {
    return await adminDeviceGetConnectionHistory(box_id, 0, pageSize);
  };

  const handlePaginate = async (page) => {
    return await adminDeviceGetConnectionHistory(box_id, page, pageSize);
  };

  return (
    <Card sx={{ margin: 1 }}>
      <CardContent>
        <DataTable
          dataTableTitle={"Box Connection History"}
          getDataCallBack={connectionHistory}
          paginateCallback={handlePaginate}
          pageSize={pageSize}
        />
      </CardContent>
    </Card>
  );
};

const AdminDeviceView = (props) => {
  const { deviceId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (deviceId !== undefined) {
      dispatch(adminGetDevice(deviceId));
      dispatch(getBoxesAccountGroups());
      dispatch(getBoxesVendors());
    }
  }, [deviceId, dispatch]);

  if (props.deviceLoading || !props.deviceLoaded || !props.device) {
    return (
      <AdminAppBar selected="Device" {...props}>
        <div>Loading...</div>
      </AdminAppBar>
    );
  }

  return (
    <AdminAppBar selected="Device" {...props}>
      {props.device && !props.deviceLoading && props.deviceLoaded && !props.deviceError && (
        <>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
            <DeviceItem
              device={props.device}
              fullScreen
              is_admin
              type="revealed"
              accountGroups={props.accountGroups}
              vendors={props.vendors.result}
            />
            <AdminDeviceLatches box_id={props.device.box} />
          </Grid>
          <Box sx={styles.deviceViewSection}>
            <DeviceHistory box_id={props.device.box} />
          </Box>
        </>
      )}
      {props.communityError && <NoDeviceError />}
    </AdminAppBar>
  );
};

const styles = {
  deviceViewSection: {
    margin: 4,
  },
};

const mapStateToProps = (state) => {
  return {
    device: state.deviceStatus.device,
    deviceLoading: state.deviceStatus.deviceLoading,
    deviceLoaded: state.deviceStatus.deviceLoaded,
    deviceError: state.deviceStatus.deviceError,
    accountGroups: state.getBoxesAccountGroupsReducer.results,
    vendors: state.getBoxesVendorsReducer.results,
  };
};

export default connect(mapStateToProps)(AdminDeviceView);
