import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommunityManagerAppBar from "./CommunityManagerAppBar";
import LoadingScreen from "./LoadingScreen";
import AdminAppBar from "../../Admin/components/AdminAppBar";

function SelectedAppBar(props) {
  const [loaded, setLoaded] = useState(false);
  const profileState = useSelector((state) => state.profileState);
  const currentCommunityID = useSelector((state) => state.communityState.currentCommunityID);

  useEffect(() => {
    setLoaded(profileState.loaded && currentCommunityID);
  }, [profileState.loaded, currentCommunityID]);

  if (!loaded) <LoadingScreen />;

  if (profileState?.details.is_admin && !currentCommunityID) {
    return (
      <AdminAppBar selected={props.selected} {...props}>
        {props.children}
      </AdminAppBar>
    );
  }

  return (
    <CommunityManagerAppBar selected={props.selected} {...props}>
      {props.children}
    </CommunityManagerAppBar>
  );
}

export default SelectedAppBar;
