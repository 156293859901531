/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { getHomes } from "../../../reducers/homes-reducer";
import { setMemberHome } from "../../../reducers/memberdetail-reducer";
import AddHome from "../../common/Components/AddHome";
import HomeSelect from "../../common/Components/HomeSelect";

const HomeDetail = ({ community_id, account_community_id, home_id }) => {
  const dispatch = useDispatch();
  const homesState = useSelector((state) => {
    return state.homesState;
  });
  const lastHomeAdded = useRef(homesState.home_added_id);

  const setHome = (homeID) => {
    if (homeID === "") {
      homeID = null;
    }
    dispatch(setMemberHome(community_id, account_community_id, homeID));
  };

  useEffect(() => {
    dispatch(getHomes(community_id));
    if (lastHomeAdded.current === homesState.home_added_id) {
      return;
    }
    lastHomeAdded.current = homesState.home_added_id;
    setHome(homesState.home_added_id);
  }, [homesState.home_added_id]);

  return (
    <FormControl fullWidth={true}>
      <Typography variant="h6">Home</Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box sx={styles().root}>
            <HomeSelect homeID={home_id} onChange={(home_id) => setHome(home_id)} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <AddHome />
        </Grid>
      </Grid>
    </FormControl>
  );
};

const styles = () => ({
  root: {
    padding: 1,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
});

export default HomeDetail;
