import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  phone_number: "",
  loading: false,
  error: false,
  result: false,
  loaded: false,
  universalSearchResult: [],
  universalSearchTotalResults: 0,
  universalSearchQuery: "",
};

export const findUser = createAsyncAction("ADMIN_FIND_USER", (first_name, last_name, phone) => {
  return call(nimbioServer.admin.findUser, [first_name, last_name, phone], "admin-find-user");
});

export const setUniversalSearchQuery = createAction("ADMIN_SET_UNIVERSAL_SEARCH_QUERY", (query) => {
  return query;
});

export const searchUniversal = createAsyncAction("ADMIN_UNIVERSAL_SEARCH", (search, page, size) => {
  return call(nimbioServer.admin.universalSearch, [search, page, size], "admin-find-user");
});

export const clearUniversalSearch = createAction("ADMIN_CLEAR_UNIVERSAL_SEARCH", () => {
  return true;
});

export default handleActions(
  {
    ADMIN_FIND_USER_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: false,
      };
    },
    ADMIN_FIND_USER_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        result: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_FIND_USER_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
    ADMIN_UNIVERSAL_SEARCH_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_UNIVERSAL_SEARCH_SUCCESS: (state, { payload }) => {
      if (!payload["result"]) {
        return {
          ...state,
          loading: false,
          universalSearchResult: [],
          error: true,
        };
      }
      return {
        ...state,
        loading: false,
        universalSearchResult: payload.data["results"],
        universalSearchTotalResults: payload.data["total_length"],
      };
    },
    ADMIN_UNIVERSAL_SEARCH_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
      };
    },
    ADMIN_CLEAR_UNIVERSAL_SEARCH: (state) => {
      return {
        ...state,
        universalSearchResult: [],
        universalSearchTotalResults: 0,
        universalSearchQuery: "",
      };
    },
    ADMIN_SET_UNIVERSAL_SEARCH_QUERY: (state, { payload }) => {
      return {
        ...state,
        universalSearchQuery: payload,
      };
    },
  },
  initialState
);
