import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog/Dialog";
import Button from "@mui/material/Button/Button";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import TextField from "@mui/material/TextField/TextField";
import { IconButton, Box, FormControlLabel, Switch } from "@mui/material";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogContentText from "@mui/material/DialogContentText";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneNumberCustom from "../../common/Components/PhoneNumberCustom";
import { closeDetails, removeHome, setHomeDetails } from "../../../reducers/homes-reducer";
import { setOwnerOccupied } from "../../../reducers/homedetail-reducer";

const styles = (theme) => ({
  dialog_divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const HomeListItemDetails = (props) => {
  const theme = useTheme();
  const [removeHomeOpen, setRemoveHomeOpen] = React.useState(false);
  const [homeName, setHomeName] = React.useState("");
  const [homeOwnerName, setHomeOwnerName] = React.useState("");
  const [homePhone, setHomePhone] = React.useState("");
  const [homeEmail, setHomeEmail] = React.useState("");
  const [homeAddress, setHomeAddress] = React.useState("");
  const [ownerOccupiedState, setOwnerOccupiedState] = React.useState(false);
  const [error, setError] = React.useState("");

  useEffect(() => {
    if (props.homesState.details_open) {
      setHomeName(props.homesState.details_home_name ? props.homesState.details_home_name : "");
      setHomeOwnerName(props.homesState.details_home_owner_name ? props.homesState.details_home_owner_name : "");
      setHomePhone(props.homesState.details_home_phone ? props.homesState.details_home_phone : "");
      setHomeEmail(props.homesState.details_home_email ? props.homesState.details_home_email : "");
      setHomeAddress(props.homesState.details_home_address) ? props.homesState.details_home_address : "";
      setOwnerOccupiedState(props.homesState.details_owner_occupied);
    }
  }, [props.homesState.details_open]);

  const handleChangeHomeAddress = (event) => {
    setHomeAddress(event.target.value);
  };

  const handleChangeHomeName = (event) => {
    setHomeName(event.target.value);
  };

  const handleChangeHomeOwnerName = (event) => {
    setHomeOwnerName(event.target.value);
  };

  const handleChangeHomePhone = (phone_input) => {
    setError("");
    setHomePhone(phone_input ? phone_input : "");
  };

  const handleChangeHomeEmail = (event) => {
    setHomeEmail(event.target.value);
  };

  const handleAccept = () => {
    if (homePhone !== "" && !isPossiblePhoneNumber(homePhone)) {
      setError("Please enter a valid phone number.");
      return;
    }
    props.dispatch(
      setHomeDetails(props.communityState.currentCommunityID, props.homesState.details_home_id, {
        home_name: homeName,
        home_owner_name: homeOwnerName,
        home_phone: homePhone,
        home_email: homeEmail,
        home_address: homeAddress,
      })
    );
    props.dispatch(
      setOwnerOccupied(props.communityState.currentCommunityID, props.homesState.details_home_id, ownerOccupiedState)
    );

    handleClose();
  };

  const handleClose = () => {
    props.dispatch(closeDetails());
  };

  const openRemoveHome = () => {
    setRemoveHomeOpen(true);
  };

  const closeRemoveHome = () => {
    setRemoveHomeOpen(false);
  };

  const deleteHome = () => {
    props.dispatch(
      removeHome(
        props.communityState.currentCommunityID,
        props.homesState.details_home_id,
        props.homesState.details_home_address
      )
    );
    closeRemoveHome();
    handleClose();
  };

  const handleOwnerOccupiedChange = (value) => {
    setOwnerOccupiedState(value.target.checked);
  };

  return (
    <div>
      <Dialog
        fullWidth
        open={props.homesState.details_open}
        onClose={handleClose}
        data-testid={"homes-list-item-detail"}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "12px 8px 8px 12px",
          }}>
          <DialogTitle>Home Details</DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <FormControlLabel
              control={<Switch checked={ownerOccupiedState} onChange={handleOwnerOccupiedChange} />}
              label="Owner Occupied"
            />
            <IconButton
              aria-label="settings"
              onClick={openRemoveHome}
              data-testid={"homes-list-item-detail-delete"}
              sx={{ width: "64px" }}>
              <DeleteIcon color="primary" />
            </IconButton>
          </Box>
        </Box>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            label="Home Address"
            sx={styles(theme).textField}
            value={homeAddress}
            onChange={handleChangeHomeAddress}
            margin="normal"
            data-testid={"homes-list-item-detail-address"}
          />
          <TextField
            label="Home Name"
            sx={styles(theme).textField}
            value={homeName}
            onChange={handleChangeHomeName}
            margin="normal"
            data-testid={"homes-list-item-detail-name"}
          />
          <TextField
            label="Home Owner Name"
            sx={styles(theme).textField}
            value={homeOwnerName}
            onChange={handleChangeHomeOwnerName}
            margin="normal"
            data-testid={"homes-list-item-detail-owner-name"}
          />

          <TextField
            label="Home Email"
            sx={styles(theme).textField}
            value={homeEmail}
            onChange={handleChangeHomeEmail}
            margin="normal"
            data-testid={"homes-list-item-detail-email"}
          />
          <Box sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 2 }}>
            {error ? (
              <DialogContentText sx={{ marginBottom: 2 }} color="error">
                {error}
              </DialogContentText>
            ) : null}
            <PhoneInput
              value={homePhone}
              onChange={(phone_input) => handleChangeHomePhone(phone_input)}
              defaultCountry="US"
              numberInputProps={{ autoFocus: true, label: "Home Phone Number" }}
              inputComponent={PhoneNumberCustom}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAccept} autoFocus data-testid={"homes-list-item-detail-save"}>
            Accept
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={removeHomeOpen} onClose={closeRemoveHome}>
        <DialogTitle>
          Are you sure you want to delete this home? Residents will still be members of the community.
        </DialogTitle>

        <DialogActions>
          <Button variant="outlined" onClick={closeRemoveHome}>
            Cancel
          </Button>
          <Button variant="contained" onClick={deleteHome} autoFocus data-testid={"homes-list-item-detail-confirm"}>
            Delete Home
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    communityState: state.communityState,
    homesState: state.homesState,
  };
};

export default connect(mapStateToProps)(HomeListItemDetails);
