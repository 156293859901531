import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Box, DialogTitle, DialogContentText, Typography } from "@mui/material";
import { connect } from "react-redux";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";

type AdminRemoveCommunityKeyDialogProps = {
  open: boolean;
  closeCallback: () => void;
  communityId: number;
  keyId: string;
};

const AdminRemoveCommunityKeyDialog = (props: AdminRemoveCommunityKeyDialogProps) => {
  const { communityId, closeCallback, keyId } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCancel = () => {
    closeCallback();
  };
  const handleConfirm = async () => {
    setLoading(true);
    const response = await call(nimbioServer.admin.key.removeCommunityKey, [communityId, keyId]);
    if (!response["result"]) {
      setError(response["message"]);
      return;
    }
    setLoading(false);
    closeCallback();
  };

  return (
    <Dialog open={props.open} onClose={closeCallback} aria-labelledby="form-dialog-title">
      <Box sx={{ padding: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
        <DialogTitle p={1}>Remove Community Key</DialogTitle>
        <DialogContentText sx={{ alignItems: "center", paddingLeft: 3 }}>
          Removing a key will cause all child keys to be removed from user accounts.
        </DialogContentText>
        <DialogContent>{error !== "" ? <Typography color={"error"}>{error}</Typography> : null}</DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button disabled={loading} variant={"contained"} onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

AdminRemoveCommunityKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  communityId: PropTypes.number.isRequired,
  keyId: PropTypes.string.isRequired,
};

export default connect()(AdminRemoveCommunityKeyDialog);
