import React, { useState } from "react";
import moment from "moment";
import { Dialog, DialogActions, IconButton, MenuItem, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DataTable from "./DataTable";
import { adminDeviceGetConnectionHistory } from "../../../server/server";

const DeviceConnectionHistory = ({ open, close, box, device }) => {
  const pageSize = 100;
  const [timeZone, setTimeZone] = useState(device?.timezone ? device.timezone : "UTC");
  const [timeZoneChanged, setTimeZoneChanged] = useState(undefined);

  const getHistory = async () => {
    const result = await adminDeviceGetConnectionHistory(box, 0, pageSize);
    result["data"]["results"] = mapAndFormatDate(result["data"]["results"]);
    return result;
  };

  const handlePaginate = async (page) => {
    const result = await adminDeviceGetConnectionHistory(box, page, pageSize);
    result["data"]["results"] = mapAndFormatDate(result["data"]["results"]);
    return result;
  };

  const mapAndFormatDate = (data) => {
    return data.map((row) => {
      const mDate = moment(row.log_datetime).tz(timeZone);
      return {
        ...row,
        log_datetime: mDate.format("MM/DD/YYYY hh:mm:ss A z"),
      };
    });
  };

  const handleTimezoneChange = (value) => {
    setTimeZone(value);
    setTimeZoneChanged(!timeZoneChanged);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="lg" fullWidth data-testid={"show-connection-history"}>
      <DialogActions>
        <Select
          labelId="timezone-select"
          id="timezone-select"
          value={timeZone}
          onChange={(event) => handleTimezoneChange(event.target.value)}>
          <MenuItem value="UTC">UTC</MenuItem>
          <MenuItem value="America/New_York">America/New York</MenuItem>
          <MenuItem value="America/Chicago">America/Chicago</MenuItem>
          <MenuItem value="America/Denver">America/Denver</MenuItem>
          <MenuItem value="America/Los_Angeles">America/Los Angeles</MenuItem>
          <MenuItem value="Pacific/Honolulu">Pacific/Honolulu</MenuItem>
          <MenuItem value="Europe/Paris">Europe/Paris (CET)</MenuItem>
          <MenuItem value="Europe/Sofia">Europe/Sofia (EET)</MenuItem>
          <MenuItem value="Asia/Shanghai">Asia/Shanghai (CST)</MenuItem>
        </Select>
        <IconButton aria-label="close" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <DataTable
          getDataCallBack={getHistory}
          paginateCallback={handlePaginate}
          pageSize={pageSize}
          dataTableTitle={"Device Connection History"}
          parentDataRefresh={timeZoneChanged}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DeviceConnectionHistory;
