import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  READ_ONLY_MODE: false,
  error: false,
  loading: false,
  show_read_only_error_modal: false,
};

export const getReadOnlyStatusOfServer = createAsyncAction("ADMIN_GET_READY_ONLY_STATUS", () => {
  return call(nimbioServer.admin.getServerReadOnlyStatus, [], "admin-maintenance-mode-reducer");
});

export const setReadOnlyStatusOfServer = createAsyncAction("ADMIN_SET_READY_ONLY_STATUS", (read_only) => {
  return call(nimbioServer.admin.setServerReadOnlyStatus, [read_only], "admin-maintenance-mode-reducer");
});

export default handleActions(
  {
    ADMIN_GET_READY_ONLY_STATUS_LOADING: (state) => {
      return {
        ...state,
        READ_ONLY_MODE: false,
        error: false,
        loading: true,
      };
    },
    ADMIN_GET_READY_ONLY_STATUS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        READ_ONLY_MODE: payload["read_only"],
        error: payload["result"] === false,
        loading: false,
      };
    },
    ADMIN_GET_READY_ONLY_STATUS_ERROR: (state) => {
      return {
        ...state,
        READ_ONLY_MODE: null,
        error: true,
        loading: false,
      };
    },
    ADMIN_SET_READY_ONLY_STATUS_LOADING: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    ADMIN_SET_READY_ONLY_STATUS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        READ_ONLY_MODE: payload["read_only"],
        error: payload["result"] === false,
        loading: false,
      };
    },
    ADMIN_SET_READY_ONLY_STATUS_ERROR: (state) => {
      return {
        ...state,
        READ_ONLY_MODE: null,
        error: true,
        loading: false,
      };
    },
    SHOW_READ_ONLY_ERROR_MODAL: (state) => {
      return {
        ...state,
        show_read_only_error_modal: true,
      };
    },
    HIDE_READ_ONLY_ERROR_MODAL: (state) => {
      return {
        ...state,
        show_read_only_error_modal: false,
      };
    },
  },
  initialState,
);
