import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CommunityManagerAppBar from "../../common/Components/CommunityManagerAppBar";
import NimbioDataGrid from "../../common/Components/NimbioDataGrid";
import { communityGetComercialKeyUsageHistory, communityGetResidentialKeyUsageHistory } from "../../../server/server";
import DateTwoWeekMaxPicker from "../../common/Components/DateTwoWeekMaxPicker";
import PickerPagination from "../../common/Components/PickerPagination";
import endpoints from "../../../routes/endpoints";
import { APP_BAR_ITEMS } from "../../common/Components/CommunityManagerAppBar";

const MIN_COLUMN_WIDTH = 100;
const COLUMNS = [
  { field: "id", headerName: "ID", width: 0, hide: true },
  {
    field: "datetime",
    headerName: "Date",
    flex: 1,
    minWidth: MIN_COLUMN_WIDTH * 2,
    renderCell: (params) => {
      return (
        <strong>
          <Typography sx={{ fontSize: "inherit" }}>
            {moment(params.row.datetime).calendar(null, { sameElse: "MM/DD/YYYY hh:mm:ss A" })}
          </Typography>
        </strong>
      );
    },
  },
  { field: "user", headerName: "User", flex: 1, minWidth: MIN_COLUMN_WIDTH * 1.5, height: "auto" },
  { field: "key_name", headerName: "Key", flex: 1, minWidth: MIN_COLUMN_WIDTH * 1.75 },
  { field: "latch_name", headerName: "Latch", flex: 1, minWidth: MIN_COLUMN_WIDTH * 1.25 },
  { field: "open_desc", headerName: "Description", flex: 1, minWidth: MIN_COLUMN_WIDTH },
  { field: "open_result", headerName: "Result", flex: 1, minWidth: MIN_COLUMN_WIDTH },
];

const PROPERTY_TYPES = {
  none: 0,
  residential: 1,
  commercial: 2,
};

const DATE_FORMAT = "YYYY-MM-DD";
const MAX_LOG_LENGTH = 1000;

const getPropetyType = (communityState) => {
  const communities = communityState.details;
  const community = communities.filter((com) => com.id === communityState.currentCommunityID);
  return community[0]?.community_type;
};

const AccessLogs = (props) => {
  const [redirect, setRedirect] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [eDate, setEDate] = useState(moment().subtract(14, "days"));
  const [lDate, setLDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const communityType = getPropetyType(props?.comm_state);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (props?.comm_state.communitiesLoaded && eDate && lDate) {
      fetchData(eDate, lDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.comm_state.communitiesLoaded]);

  const fetchFullCommercialData = async (page = 0) => {
    const result = await communityGetComercialKeyUsageHistory(
      props.comm_state.currentCommunityID,
      lDate.format(DATE_FORMAT),
      eDate.format(DATE_FORMAT),
      page
    );
    if (result.logs) {
      let appendedData = [];
      if (result.logs.length === MAX_LOG_LENGTH) {
        appendedData = await fetchFullCommercialData(page + 1);
      }
      return result.logs.concat(appendedData);
    }
    return null;
  };

  const fetchFullResidentialData = async (page = 0) => {
    const result = await communityGetResidentialKeyUsageHistory(
      props.comm_state.currentCommunityID,
      lDate.format(DATE_FORMAT),
      eDate.format(DATE_FORMAT),
      page
    );
    if (result.logs) {
      let appendedData = [];
      if (result.logs.length === MAX_LOG_LENGTH) {
        appendedData = await fetchFullResidentialData(page + 1);
      }
      return result.logs.concat(appendedData);
    }
    return null;
  };

  const fetchCommercialData = async (earlierDate, laterDate, page = 0) => {
    const result = await communityGetComercialKeyUsageHistory(
      props.comm_state.currentCommunityID,
      laterDate.format(DATE_FORMAT),
      earlierDate.format(DATE_FORMAT),
      page
    );
    if (result.logs) {
      return result.logs;
    }
    return null;
  };

  const fetchResidentialData = async (earlierDate, laterDate, page = 0) => {
    const result = await communityGetResidentialKeyUsageHistory(
      props.comm_state.currentCommunityID,
      laterDate.format(DATE_FORMAT),
      earlierDate.format(DATE_FORMAT),
      page
    );
    if (result.logs) {
      return result.logs;
    }
    return null;
  };

  const fetchData = async (earlierDate, laterDate, page = 0) => {
    setEDate(earlierDate);
    setLDate(laterDate);
    if (props.comm_state.currentCommunityID) {
      setLoading(true);
      const data =
        communityType === PROPERTY_TYPES.commercial
          ? await fetchCommercialData(earlierDate, laterDate, page)
          : await fetchResidentialData(earlierDate, laterDate, page);
      if (!data) setRedirect(true);
      setData(data);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const setData = (logs) => {
    try {
      if (!logs) throw new Error("failed to get key usage history");

      const newRows = [];
      for (const [index, log] of logs.entries()) {
        newRows.push({
          id: index,
          key_name: log.key_name,
          latch_name: log.latch_name,
          datetime: log.datetime,
          open_desc: log.open_desc,
          open_result: log.open_result,
          user: log.user,
        });
      }

      const orderedRows = newRows.sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime));
      setRowData(orderedRows);
    } catch (err) {
      setRedirect(true);
      console.error("err: " + JSON.stringify(err));
    }
  };

  if (redirect) {
    navigate("/pick-community");
    return;
  }

  return (
    <CommunityManagerAppBar selected={APP_BAR_ITEMS.ACCESS_LOGS} {...props}>
      <Box>
        <DateTwoWeekMaxPicker
          startLabel="Logs Starting On"
          endLabel="Logs Ending On"
          handlePickerChanged={fetchData}
          loading={loading}
        />
        <NimbioDataGrid
          rows={rows}
          columns={COLUMNS}
          rowData={rowData}
          loading={loading}
          fetchAllData={
            communityType === PROPERTY_TYPES.commercial ? fetchFullCommercialData : fetchFullResidentialData
          }
        />
        {rows && (
          <PickerPagination
            rowData={rowData}
            setRows={setRows}
            fetchData={fetchData}
            maxLogLength={MAX_LOG_LENGTH}
            earlierDate={eDate}
            laterDate={lDate}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            mb: 1,
          }}>
          <Typography sx={{ fontSize: ".8em" }}>
            If you need Access Logs prior to August 27, 2023, please contact support.
          </Typography>
          <Button
            sx={{ fontSize: ".9em" }}
            onClick={() => {
              window.open(
                endpoints.EXTERNAL.nimbioHelpPage,
                "_blank" // <- This is what makes it open in a new window.
              );
            }}>
            Support
          </Button>
        </Box>
      </Box>
    </CommunityManagerAppBar>
  );
};

AccessLogs.propTypes = {};

const mapStateToProps = (state) => {
  return {
    comm_state: state.communityState,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(AccessLogs);
