import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Box, Grid, Button, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import AdminAppBar from "./AdminAppBar";
import AdminAccountGroupDialog from "./AdminAccountGroupDialog";
import { getAccountGroups } from "../../../reducers/admin-get-account-groups";
import { deleteAccountGroupData } from "../../../reducers/admin-get-account-groups";
import useConfirm from "../../common/Components/useConfirm";
import endpoints from "../../../routes/endpoints";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import LoadingScreen from "../../common/Components/LoadingScreen";

const AccountGroupTable = ({ accountGroups, handleEditModalOpen }) => {
  const dispatch = useDispatch();
  const { ConfirmDialog, openDialog } = useConfirm();

  const columns = [
    { field: "name", flex: 1, headerName: "Name", sortable: false, filterable: false },
    {
      field: "actions",
      width: 100,
      headerName: "",
      sortable: false,
      filterable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(event) => {
              event.ignore = true;
              event.stopPropagation();
              openDialog({
                heading: "Delete Account Group",
                message: "Are you sure you want to delete this account group?",
                okButton: "Yes",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteAccountGroupData(params.row.account_group_id));
                },
                closeCallback: () => {},
              });
            }}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  if (!accountGroups) {
    return null;
  }
  return (
    <Box id={"nimbio-testing" + endpoints.ADMIN_ACCOUNT_GROUPS + "-account-group-list"}>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: red[500],
          },
          ".MuiDataGrid-filterIcon": {
            color: red[500],
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-menuIconButton": {
            color: red[500],
          },
        }}
        getRowId={(row) => row.account_group_id}
        rows={accountGroups}
        columns={columns}
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        disableColumnFilter={true}
        disableColumnReorder={true}
        disableColumnResize={true}
        disableDensitySelector={true}
        disableExtendRowFullWidth={true}
        disableMultipleColumnsFiltering={true}
        disableMultipleSelection={true}
        disableSelectionOnRowClick={true}
        disableSelectionOnRowDrag={true}
        disableSortBy={true}
        onRowClick={(params) => {
          handleEditModalOpen(params.row);
        }}
      />
      <ConfirmDialog />
    </Box>
  );
};

const AdminAccountGroups = (props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [accountGroup, setAccountGroup] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useSubscription(nimbioServer.internal.accountGroups, () => dispatch(getAccountGroups()));

  useEffect(() => {
    dispatch(getAccountGroups());
  }, [dispatch]);

  React.useEffect(() => {
    const loadDelay = setTimeout(() => {
      setLoading(props.accountGroups.loading);
    }, 350);
    return () => {
      clearTimeout(loadDelay);
    };
  }, [props.accountGroups]);

  const handleEditModalOpen = (accountGroup) => {
    setOpen(true);
    setAccountGroup(accountGroup);
  };

  if (props.profileState.details.is_admin === false) {
    navigate("/");
    return;
  }

  return (
    <AdminAppBar selected="Account Groups" {...props}>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box data-testid={"account-group-list"}>
          <Grid
            sx={{ paddingTop: 0, width: "100%" }}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            <Grid item>
              <Button
                data-testid={"account-group-list-add-account-group"}
                id={"nimbio-testing-admin-vendors-add-vendor-button"}
                onClick={() => {
                  setOpen(true);
                  setAccountGroup(null);
                }}>
                Add Account Group
              </Button>
            </Grid>
          </Grid>

          <AccountGroupTable accountGroups={props.accountGroups?.results} handleEditModalOpen={handleEditModalOpen} />
        </Box>
      )}
      {open && (
        <AdminAccountGroupDialog
          open={open}
          accountGroup={accountGroup}
          onClose={() => {
            setOpen(false);
            setAccountGroup(null);
          }}
        />
      )}
    </AdminAppBar>
  );
};

AdminAccountGroups.propTypes = {};

const mapStateToProps = (state) => {
  return {
    accountGroups: state.getAccountGroupsReducer,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(AdminAccountGroups);
