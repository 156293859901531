import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Hidden from "@mui/material/Hidden";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListItemText from "@mui/material/ListItemText";
import { connect } from "react-redux";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../reducers/connection-reducer";
import { getLoginDetails } from "../../../reducers/profile-reducer";
import NimbioAppBar from "../../common/Components/NimbioAppBar";
import { nimbioPalette } from "../../../theme";

const drawerWidth = 265;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: 2.5,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: nimbioPalette.white,
  },
  commHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.mixins.toolbar.minHeight,
  },
  nimbioFont: {
    font: "Filson",
  },
  versionText: {
    cursor: "pointer",
  },
});

const DistributorAppBarDrawer = ({ props }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box>
      <Box sx={styles(theme).toolbar}>
        <Box sx={styles(theme).commHeader}>{props.selected}</Box>
      </Box>
      <Divider />
      <List>
        <ListItem
          button
          selected={props.selected === "Add Installer"}
          key="Add Installer"
          onClick={() => navigate("/distributor/add-installer")}>
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Add Installer" />
        </ListItem>
        <ListItem
          button
          selected={props.selected === "Device Monitor"}
          key="Device Monitor"
          onClick={() => navigate("/distributor/device-monitor")}>
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          <ListItemText primary="Device Monitor" />
        </ListItem>
      </List>
      {props.is_community_manager && (
        <>
          <Divider />
          <List>
            <ListItem
              button
              selected={props.selected === "Community Manager"}
              key="Community Manager"
              onClick={() => navigate("/pick-community")}>
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Comm Manager" />
            </ListItem>
          </List>
        </>
      )}
      <Divider />
      <List>
        <ListItem
          button
          key="Logout"
          onClick={() => {
            localStorage.removeItem("auth_id");
            localStorage.removeItem("secret");
            window.location.href = "/";
            props.dispatch(logout);
            navigate("/login");
          }}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
      <List style={styles(theme).versionContainer}>
        <ListItem>
          <Typography variant="caption" style={styles(theme).versionText}>
            Build Version: {process.env.REACT_APP_BUILD_VERSION ? process.env.REACT_APP_BUILD_VERSION : "Dev"}
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

const DistributorAppBar = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen((oldOpen) => !oldOpen);
  };

  useEffect(() => {
    if (!props.profile.loaded) {
      props.dispatch(getLoginDetails());
    }
  }, []);

  return (
    <>
      {!props.profile.loaded ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box sx={styles(theme).root}>
          <NimbioAppBar title="Distributor Dashboard" handleDrawerToggle={handleDrawerToggle} />
          <Box sx={styles(theme).drawer}>
            {/* The implementation can be swap with js to avoid SEO duplication of links. */}
            <Hidden mdUp>
              <Drawer
                container={props.container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                  sx: styles(theme).drawerPaper,
                }}
                sx={{ display: { xs: "block", sm: "none" } }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}>
                <DistributorAppBarDrawer props={props} />
              </Drawer>
            </Hidden>
            {/* Desktop View */}
            <Hidden xsDown>
              <Drawer
                PaperProps={{ sx: styles(theme).drawerPaper }}
                sx={{ display: { xs: "none", sm: "block" } }}
                variant="permanent"
                open>
                <DistributorAppBarDrawer props={props} />
              </Drawer>
            </Hidden>
          </Box>
          <Box sx={styles(theme).content}>
            <Box sx={styles(theme).toolbar} />
            {props.children}
          </Box>
        </Box>
      )}
    </>
  );
};

DistributorAppBar.propTypes = {
  container: PropTypes.object,
  selected: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    communities: state.communityState,
    profile: state.profileState,
    is_community_manager: state.profileState.details.is_community_manager,
  };
};

export default connect(mapStateToProps)(DistributorAppBar);
