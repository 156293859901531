import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  InputLabel,
  FormControl,
  FormControlLabel,
  TextField,
  MenuItem,
  Select,
  FormGroup,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Typography } from "@mui/material";
import { useParams } from "react-router";
import { SenseLineRecordsTable, SenseLineTable, SenseLineStatusTable } from "./SenseLineTables";
import {
  communitySenseLineUpdateSenseLineStatus,
  communitySenseLineCreateSenseLineStatus,
  communitySenseLineCreateSenseLine,
} from "../../reducers/sense-line-reducer";

const SenseLineConfigure = () => {
  const dispatch = useDispatch();
  const { boxId, communityId, latchId } = useParams();
  const {
    box_sense_lines: sense_lines,
    box_sense_line_statuses: sense_line_statuses,
    latch_messages,
    button_messages,
    sense_line_records,
    loading,
  } = useSelector((state) => state.senseLine);
  const community_id = parseInt(communityId);

  const [openUpdateSenseLineStatus, setOpenUpdateSenseLineStatus] = React.useState(false);
  const handleOpenUpdateSenseLineStatus = () => setOpenUpdateSenseLineStatus(true);
  const handleCloseUpdateSenseLineStatus = () => setOpenUpdateSenseLineStatus(false);

  const [transientEnabled, setTransientEnabled] = useState(false);

  const handleTransientToggle = (e) => {
    if (e.target.checked) {
      setTransientEnabled(e.target.checked);
    } else {
      setTransientEnabled(e.target.checked);
      setSenseLineStatusUpdateData({ ...senseLineStatusUpdateData, transient: 0 });
    }
  };

  const [senseLineStatusUpdateData, setSenseLineStatusUpdateData] = useState({
    box_id: boxId,
    latch_id: latchId,
    sense_line_id: null,
    sense_line_state: null,
    transient: 0,
    latch_message_id: null,
    button_message_id: 1,
  });

  const handleUpdateSenseLineStatus = (sense_line_status_data) => {
    setTransientEnabled(sense_line_status_data.transient > 0);
    setSenseLineStatusUpdateData({
      ...senseLineStatusUpdateData,
      ...sense_line_status_data,
    });
    handleOpenUpdateSenseLineStatus();
  };

  const handleSubmitUpdateSenseLineStatus = () => {
    dispatch(
      communitySenseLineUpdateSenseLineStatus(
        community_id,
        senseLineStatusUpdateData.box_id,
        senseLineStatusUpdateData.sense_line_state,
        senseLineStatusUpdateData.sense_line_id,
        senseLineStatusUpdateData.latch_id,
        senseLineStatusUpdateData.transient,
        senseLineStatusUpdateData.latch_message_id,
        senseLineStatusUpdateData.button_message_id,
      ),
    );
    handleCloseUpdateSenseLineStatus();
  };

  const createInitialSenseLinesandStatuses = () => {
    dispatch(communitySenseLineCreateSenseLine(community_id, boxId, 1, false, false));
    dispatch(communitySenseLineCreateSenseLine(community_id, boxId, 2, false, false));
    dispatch(communitySenseLineCreateSenseLineStatus(community_id, boxId, latchId, 1, 0, 0, 5, 1));
    dispatch(communitySenseLineCreateSenseLineStatus(community_id, boxId, latchId, 1, 1, 0, 6, 1));
    dispatch(communitySenseLineCreateSenseLineStatus(community_id, boxId, latchId, 2, 0, 0, 3, 1));
    dispatch(communitySenseLineCreateSenseLineStatus(community_id, boxId, latchId, 2, 1, 0, 1, 1));
  };

  return (
    <Box style={styles.container} data-testid={"sense-line-configure"}>
      {sense_lines &&
      sense_line_statuses &&
      !loading &&
      (sense_lines.length === 0 || sense_line_statuses.length === 0) ? (
        <Card data-testid="create-sense-lines-card" style={styles.createSenseLineCard}>
          <CardContent>
            <Typography variant="h6">Activate Sense Lines</Typography>
            <Typography variant="body1" style={styles.createSenseLineText}>
              Press the start button to create a default sense line configuration. When a sense line is activated users
              will see the status of gates and doors in operation.
            </Typography>
          </CardContent>
          <Box style={styles.createSenseLineButtonBox}>
            <Button
              style={styles.createSenseLinesButton}
              variant="outlined"
              onClick={createInitialSenseLinesandStatuses}>
              Start
            </Button>
          </Box>
        </Card>
      ) : (
        <Box style={styles.sense_line_create_and_update_tables}>
          <Box style={styles.create_sense_line_table}>
            <SenseLineTable community_id={community_id} box_id={boxId} />
          </Box>
          <Box style={styles.create_sense_line_status_table}>
            <SenseLineStatusTable
              handleUpdateSenseLineStatus={handleUpdateSenseLineStatus}
              button_messages={button_messages}
              latch_messages={latch_messages}
            />
          </Box>
          {/* TODO(rick) we should break this out into another component */}
          <Dialog
            open={openUpdateSenseLineStatus}
            onClose={handleCloseUpdateSenseLineStatus}
            data-testid={"sense-line-config-update"}>
            <DialogTitle>Update Line Status Config</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Line # {senseLineStatusUpdateData.sense_line_id}:{" "}
                {senseLineStatusUpdateData.sense_line_state === 1 ? "Triggered" : "Untriggered"}
              </DialogContentText>
              <FormGroup style={styles.dialog_form}>
                <FormControl margin="dense">
                  <InputLabel id="latch-message-label">Result</InputLabel>
                  <Select
                    labelId="latch_message_id"
                    id="latch_message_id"
                    value={senseLineStatusUpdateData.latch_message_id}
                    label="Latch Message"
                    onChange={(e) =>
                      setSenseLineStatusUpdateData({ ...senseLineStatusUpdateData, latch_message_id: e.target.value })
                    }
                    data-testid={"sense-line-latch-message"}>
                    {latch_messages.map((latch_message_object) => {
                      if (latch_message_object.hidden) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={latch_message_object.latch_message_id}
                          value={latch_message_object.latch_message_id}>
                          {latch_message_object.latch_message_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl margin="dense">
                  <InputLabel id="latch-message-label">Button Message</InputLabel>
                  <Select
                    labelId="button_message_id"
                    id="button_message_id"
                    value={senseLineStatusUpdateData.button_message_id}
                    label="Button Message"
                    onChange={(e) =>
                      setSenseLineStatusUpdateData({ ...senseLineStatusUpdateData, button_message_id: e.target.value })
                    }>
                    {button_messages.map((button_message_obj) => {
                      if (button_message_obj.hidden) {
                        return null;
                      }
                      return (
                        <MenuItem
                          key={button_message_obj.button_message_id}
                          value={button_message_obj.button_message_id}>
                          {button_message_obj.button_message_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    control={<Switch onChange={(e) => handleTransientToggle(e)} checked={transientEnabled} />}
                    label={<p>Max Display Time Activated</p>}
                  />
                </FormControl>
                {transientEnabled ? (
                  <FormControl margin="dense">
                    <TextField
                      type="number"
                      onChange={(e) =>
                        setSenseLineStatusUpdateData({
                          ...senseLineStatusUpdateData,
                          transient: parseInt(e.target.value) * 1000,
                        })
                      }
                      value={parseInt(senseLineStatusUpdateData.transient) / 1000}
                      id="outlined-basic"
                      label="Max Display Time in Seconds"
                      variant="outlined"
                    />
                  </FormControl>
                ) : null}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCloseUpdateSenseLineStatus()} variant="contained">
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmitUpdateSenseLineStatus()}
                variant="contained"
                data-testid={"sense-line-config-submit"}>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
          <Box style={styles.records_table}>
            <SenseLineRecordsTable
              box_sense_line_statuses={sense_line_statuses}
              box_id={boxId}
              sense_line_records={sense_line_records}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100vw",
  },
  createSenseLineCard: {
    display: "flex",
    flexDirection: "column",
    padding: "24px",
    maxWidth: "600px",
  },
  createSenseLineButtonBox: {
    display: "flex",
    padding: "8px",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  sense_line_create_and_update_tables: {
    width: "100%",
  },
  create_sense_line_status_table: {
    maxWidth: "900px",
  },
  createSenseLineText: {
    paddingTop: "16px",
  },
  create_sense_line_table: {
    maxWidth: "300px",
  },
  createSenseLinesButton: {
    width: "10px",
  },
  records_table: {
    maxWidth: "900px",
  },
  dialog_form: {
    padding: "8px",
  },
  modal_style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modal_button_container: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "8px",
    paddingTop: "16px",
  },
  sense_line_table_container: {
    maxWidth: "400px",
  },
};

export default SenseLineConfigure;
