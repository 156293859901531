import React, { useEffect } from "react";
import "../../App.css";
import { connect, useDispatch } from "react-redux";
import { Grid, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import LatchDetail from "./LatchDetail";
import CommunityManagerAppBar from "../common/Components/CommunityManagerAppBar";
import { loadHoldOpens } from "../../reducers/hold-open-reducers";
import endpoints from "../../routes/endpoints";

function HoldOpenMain(props) {
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const communityIdValue = parseInt(communityId);
  const navigate = useNavigate();

  useEffect(() => {
    if (communityIdValue) {
      dispatch(loadHoldOpens(communityIdValue));
    }
  }, [dispatch, communityIdValue]);

  if (!communityIdValue) {
    navigate("/pick-community");
    return;
  }
  return (
    <div>
      <CommunityManagerAppBar selected="Hold Opens" {...props}>
        <Card sx={{ padding: 4 }}>
          <Grid
            data-testid={"hold-open-grid"}
            id={"nimbio-testing" + endpoints.COMMUNITY_HOLD_OPENS + "-container"}
            container
            spacing={3}>
            {Object.values(props.hold_opens).map((latch) => (
              <LatchDetail
                latch={latch}
                key={latch.latch_id}
                keysState={props.keys_state}
                community_id={communityIdValue}
              />
            ))}
          </Grid>
        </Card>
      </CommunityManagerAppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    hold_opens: state.holdOpens.hold_opens,
    keys_state: state.keysState,
  };
};

export default connect(mapStateToProps)(HoldOpenMain);
