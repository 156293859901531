import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Avatar,
  Box,
  Badge,
  Button,
  CardHeader,
  Divider,
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";
import { InsertInvitation } from "@mui/icons-material";
import moment from "moment";
import { connect } from "react-redux";
import { useTheme } from "@mui/material";
import OneTimeEvent from "./components/OneTimeEvent";
import AddEvent from "./components/AddEvent";
import AddRecurring from "./components/AddRecurring";
import RecurringEvent from "./components/RecurringEvent";
import CancelFutureHoldOpens from "./components/CancelFutureHoldOpens";
import { enableHoldOpens, addHoldOpenEvent, removeHoldOpenEvent } from "../../reducers/hold-open-reducers";
import { getColor, getShortName } from "../../utilities/materialAvatar";

const styles = (theme) => ({
  eventNote: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(3),
  },
  text: {
    fontFamily: "Filson",
    marginBottom: 2,
  },
});

const RenderReEnableLatchUI = (props) => {
  const theme = useTheme();
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={9}>
          <Typography data-testid={"postpone-all-hold-opens"} color={"secondary"} sx={styles(theme).text}>
            Hold Opens Disabled until{" "}
            {moment(moment(props.latch.disabled_until, "YYYY-MM-DD HH:mm:ss").toDate()).format("MMM Do h:mm a")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            sx={styles(theme).text}
            color="primary"
            onClick={props.handleEnableHoldOpens}>
            Re-Enable
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

// const RenderDisableFutureHolds = (props) => {
//   const theme = useTheme();
//   return (
//     <>
//       <Grid item style={{ marginTop: 5, marginBottom: 40 }} xs={12}>
//         <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <Button
//               fullWidth
//               variant="outlined"
//               size="small"
//               color="primary"
//               onClick={props.openCancelHoldOpens}
//               sx={styles(theme).text}
//             >
//               Cancel Hold Opens
//             </Button>
//           </Grid>
//           {/*<Grid item xs={12}>*/}
//           {/*<Button
//                fullWidth
//                color="primary"
//                size="small"
//                variant="contained"
//                onClick={openAddEvent}
//                sx={styles(theme).text}>*/}
//           {/*      New One Time Event*/}
//           {/*    </Button>*/}
//           {/*</Grid>*/}
//           {/*<Grid item xs={12}>*/}
//           {/*<Button
//                fullWidth
//                color="primary"
//                size="small"
//                variant="contained"
//                onClick={openAddRecurringEvent}
//                sx={styles(theme).text}>*/}
//           {/*    New Recurring Event*/}
//           {/*  </Button>*/}
//           {/*</Grid>*/}
//         </Grid>
//         <CancelFutureHoldOpens
//           latch_id={props.latch.latch_id}
//           community_id={props.community_id}
//           open={props.cancelHoldOpensOpen}
//           closeCallback={props.closeCancelHoldOpens}
//         />
//       </Grid>
//     </>
//   );
// };

const LatchDetail = (props) => {
  const [addEventOpen, setAddEventOpen] = useState(false);
  const [addRecurringEventOpen, setAddRecurringEventOpen] = useState(false);
  const [cancelHoldOpensOpen, setCancelHoldOpensOpen] = useState(false);
  const [openIndefiniteDialog, setOpenIndefiniteDialog] = useState(false);
  const theme = useTheme();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const closeAddEvent = () => {
    setAddEventOpen(false);
  };

  const openAddEvent = () => {
    setAddEventOpen(true);
  };

  const closeAddRecurringEvent = () => {
    setAddRecurringEventOpen(false);
  };

  const openAddRecurringEvent = () => {
    setAddRecurringEventOpen(true);
  };

  const closeCancelHoldOpens = () => {
    setCancelHoldOpensOpen(false);
  };

  const openCancelHoldOpens = () => {
    setCancelHoldOpensOpen(true);
  };

  const handleEnableHoldOpens = () => {
    props.dispatch(enableHoldOpens(props.community_id, props.latch.latch_id));
  };

  const handleOpenIndefiniteDialog = () => {
    setOpenIndefiniteDialog(true);
  };

  const handleCloseIndefiniteDialog = () => {
    setOpenIndefiniteDialog(false);
  };

  const onCancelIndefinite = () => {
    setOpenIndefiniteDialog(false);
  };

  const _formatDatetime = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  const endOfTime = moment("9990-12-31").format("YYYY-MM-DD HH:mm");
  const _onSelectIndefinite = () => {
    const holdOpens = props.holdOpens.hold_opens[props.latch.latch_id];
    const timezone = holdOpens.timezone;

    props.dispatch(
      addHoldOpenEvent(
        props.community_id,
        props.latch.latch_id,
        _formatDatetime(moment.tz(moment(), timezone)),
        endOfTime
      )
    );
    is_indefinite_ref.current = true;
    handleCloseIndefiniteDialog();
  };

  const _remove_event = (event_datetime_id) => {
    props.dispatch(removeHoldOpenEvent(props.community_id, props.latch.latch_id, event_datetime_id));
    is_indefinite_ref.current = false;
  };

  const is_indefinite_ref = React.useRef(false);
  is_indefinite_ref.current = props.latch.events
    .map((event) => {
      if (event.end.split("-")[0] === "9990") {
        return true;
      } else {
        return false;
      }
    })
    .includes(true);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ m: 4 }}>
        <Grid item xs={9}>
          <CardHeader
            avatar={
              <Badge
                color="secondary"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                badgeContent="Active"
                invisible={!props.latch.held_open}>
                <Avatar aria-label="recipe" style={{ marginRight: 2, backgroundColor: getColor(props.latch.latch_id) }}>
                  {getShortName(props.latch.latch_name, "")}
                </Avatar>
              </Badge>
            }
            title={props.latch.latch_name}
            subheader={!props.latch.held_open ? "" : "Hold Open Active"}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8} sx={{ mb: 4 }}>
        {/* Postpone Hold Opens */}
        <Box sx={{ mt: 0 }}>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={8} md={9}>
              <Grid container direction="row" justify="left" alignItems="center" spacing={2}>
                <Grid item>
                  <InsertInvitation />
                </Grid>
                <Grid item>Postpone Hold Opens</Grid>
              </Grid>
            </Grid>

            {!props.latch.disabled_until ? (
              <Grid item xs={4} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={styles(theme).text}
                  onClick={openCancelHoldOpens}
                  data-testid={"postpone-all-hold-opens"}>
                  <Typography variant="p">{windowWidth < 740 ? "Postpone All" : "Postpone All Hold Opens"}</Typography>
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <Divider sx={{ mb: 2 }} />

        {props.latch.disabled_until ? (
          <Box container direction="row" justifyContent="space-between" alignItems="center">
            <RenderReEnableLatchUI latch={props.latch} handleEnableHoldOpens={handleEnableHoldOpens} />
          </Box>
        ) : (
          <Box>
            <Typography color="textSecondary">Hold opens not postponed</Typography>
          </Box>
        )}

        <CancelFutureHoldOpens
          latch_id={props.latch.latch_id}
          community_id={props.community_id}
          cancelHoldOpensOpen={cancelHoldOpensOpen}
          closeCallback={closeCancelHoldOpens}
        />

        {/* Permanent Hold Opens */}
        <Box sx={{ mt: 10 }}>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={9}>
              <Grid container direction="row" justify="left" alignItems="center" spacing={2}>
                <Grid item>
                  <InsertInvitation />
                </Grid>
                <Grid item>Hold Open</Grid>
              </Grid>
            </Grid>

            {!is_indefinite_ref.current ? (
              <Grid item xs={3}>
                <Button
                  onClick={handleOpenIndefiniteDialog}
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={styles(theme).text}
                  fullWidth
                  data-testid="latch-detail-hold-open-indefinite">
                  Hold Open
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Box>
        <Divider sx={{ mb: 2 }} />

        <Dialog fullWidth maxWidth="lg" open={openIndefiniteDialog} sx={{ textAlign: "center" }}>
          <DialogTitle>Start a Permanent Hold Open</DialogTitle>
          <DialogContent>
            <Grid
              style={{
                paddingTop: 2,
                paddingBottom: 2,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}>
              <Grid sx={{ mb: 2, flexDirection: "row" }} item xs={12}>
                <Typography>Gate will remain open until the permanent hold open is removed.</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancelIndefinite} variant="outlined">
              Cancel
            </Button>
            <Button data-testid="confirm-indefinite-hold-open" onClick={_onSelectIndefinite} variant="contained">
              Hold Open
            </Button>
          </DialogActions>
        </Dialog>

        <Grid container spacing={3}>
          {props.latch.events.map((event) => {
            if (event.end.split("-")[0] === "9990") {
              return (
                <Grid data-testid="latch-detail-indefinite-hold-open" key={event.event_datetime_id} item xs={12}>
                  <CardHeader
                    sx={{ mb: 2 }}
                    avatar={
                      <Badge
                        color="secondary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        badgeContent={event.active ? "Active" : null}
                        invisible={!is_indefinite_ref.current}>
                        <Avatar
                          aria-label="recipe"
                          style={{ marginRight: 2, backgroundColor: getColor(props.latch.latch_id) }}>
                          {getShortName(props.latch.latch_name, "")}
                        </Avatar>
                      </Badge>
                    }
                    title={props.latch.latch_name}
                    subheader={!props.latch.held_open ? "Disabled" : "Hold Open Active"}
                  />
                  <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                    <Paper elevation={2} sx={{ p: 1 }}>
                      <Typography>{props.latch.latch_name} will stay open until this is ended</Typography>
                    </Paper>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
                    <Button size="small" variant="outlined" onClick={() => _remove_event(event.event_datetime_id)}>
                      End Hold Open
                    </Button>
                  </Box>
                </Grid>
              );
            }
          })}
          {!is_indefinite_ref.current ? (
            <Typography sx={[styles(theme).eventNote, styles(theme).text]}>No hold open.</Typography>
          ) : null}
        </Grid>

        {/* One Time Hold Opens */}
        <Box sx={{ mt: 10 }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Grid item xs={9}>
              <Grid container direction="row" justify="left" alignItems="center" spacing={2}>
                <Grid item>
                  <InsertInvitation />
                </Grid>
                <Grid item>Scheduled Hold Opens</Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button
                data-testid={"add-one-time-event"}
                fullWidth
                color="primary"
                size="small"
                variant="contained"
                sx={styles(theme).text}
                onClick={openAddEvent}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          {props.latch.events.map((event) => (
            <OneTimeEvent
              event_datetime_id={event.event_datetime_id}
              start={event.start}
              end={event.end}
              active={event.active}
              key={event.event_datetime_id}
              expired={event.expired}
              community_id={props.community_id}
              latch_id={props.latch.latch_id}
              disabled_active={
                props.latch.disabled_until
                  ? moment(props.latch.disabled_until).isAfter(moment.tz(moment(), props.latch.timezone))
                  : false
              }
            />
          ))}
          {props.latch.events.length === 1 && is_indefinite_ref.current ? (
            <Typography sx={[styles(theme).eventNote, styles(theme).text]}>No one time hold opens.</Typography>
          ) : props.latch.events.length === 0 ? (
            <Typography sx={[styles(theme).eventNote, styles(theme).text]}>No one time hold opens.</Typography>
          ) : null}
        </Grid>

        {/* Recurring Hold Opens */}
        <Box sx={{ mt: 10 }}>
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
            <Grid item xs={9}>
              <Grid container direction="row" justify="left" alignItems="center" spacing={2}>
                <Grid item>
                  <InsertInvitation />
                </Grid>
                <Grid item>Recurring Hold Opens</Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                color="primary"
                size="small"
                variant="contained"
                sx={styles(theme).text}
                onClick={openAddRecurringEvent}
                data-testid={"latch-detail-add-recurring-event"}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3}>
          {props.latch.recurring.map((event) => (
            <RecurringEvent
              temporal_date_id={event.temporal_date_id}
              start_date={event.start_date}
              end_date={event.end_date}
              active={event.active}
              key={event.temporal_date_id}
              community_id={props.community_id}
              latch_id={props.latch.latch_id}
              days_of_the_week={event.days_of_the_week}
              recurring_week={event.recurring_week}
              temporal_times={event.temporal_times}
              disabled_active={
                props.latch.disabled_until
                  ? moment(props.latch.disabled_until).isAfter(moment.tz(moment(), props.latch.timezone))
                  : false
              }
            />
          ))}
          {props.latch.recurring.length === 0 && (
            <Typography sx={[styles(theme).eventNote, styles(theme).text]}>No recurring hold opens.</Typography>
          )}
        </Grid>
        <AddEvent
          open={addEventOpen}
          closeCallback={closeAddEvent}
          community_id={props.community_id}
          latch_id={props.latch.latch_id}
        />
        <AddRecurring
          open={addRecurringEventOpen}
          closeCallback={closeAddRecurringEvent}
          community_id={props.community_id}
          latch_id={props.latch.latch_id}
        />
      </Grid>
    </>
  );
};

LatchDetail.propTypes = {
  latch: PropTypes.shape({
    held_open: PropTypes.bool.isRequired,
    latch_name: PropTypes.string.isRequired,
    latch_id: PropTypes.string.isRequired,
    disabled_until: PropTypes.string,
    events: PropTypes.array.isRequired,
    recurring: PropTypes.array.isRequired,
  }),
  community_id: PropTypes.number.isRequired,
  keysState: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    holdOpens: state.holdOpens,
  };
};

export default connect(mapStateToProps)(LatchDetail);
