import React from "react";
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { sendAccountGroupData } from "../../../reducers/admin-get-account-groups";

const AdminAccountGroupDialog = ({ accountGroup, open, onClose }) => {
  const [name, setName] = React.useState(accountGroup ? accountGroup.name : "");
  const dispatch = useDispatch();

  function handleSave() {
    // Handle the save of the account.
    dispatch(sendAccountGroupData(accountGroup ? accountGroup.account_group_id : null, name));
  }

  return (
    <Dialog
      id="nimbio-testing-admin-account-group-dialog"
      data-testid="admin-account-group-dialog"
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>{accountGroup ? "Edit Account Group" : "Add Account Group"}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>Name</Typography>
          <TextField
            data-testid="admin-account-group-dialog-name"
            id="nimbio-testing-admin-account-group-dialog-name"
            variant="outlined"
            fullWidth
            defaultValue={name}
            autoFocus
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          id="nimbio-testing-admin-account-group-dialog-cancel-button"
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          data-testid="admin-account-group-dialog-save-button"
          id="nimbio-testing-admin-account-group-dialog-save-button"
          onClick={() => {
            handleSave();
            onClose();
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminAccountGroupDialog;
