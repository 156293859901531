import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import moment from "moment";

const styles = {
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 12,
  },
};

const DeviceSession = (props) => {
  const _device_temp = (temp) => {
    if (!temp) {
      return "Unknown";
    } else {
      return (
        <>
          {props.session_details.temp}C/{(temp * (9 / 5) + 32).toFixed(1)}F
        </>
      );
    }
  };
  return (
    <Card>
      <CardContent>
        <Typography sx={styles.title} color="textSecondary" gutterBottom>
          Session ID: {props.session_id}
        </Typography>
        <List dense>
          <ListItem>
            <ListItemText primary="Board" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.session_details.board}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Firmware" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.session_details.firmware}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Serial" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.session_details.serial}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Signal" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.session_details.signal}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Temp" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {_device_temp(props.session_details.temp)}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="CCID" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {props.session_details.ccid}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Uptime" />
            <ListItemSecondaryAction>
              <Typography variant="body2" color="textSecondary" component="p">
                {moment.duration(props.session_details.uptime, "seconds").humanize()}
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

DeviceSession.propTypes = {
  session_id: PropTypes.string.isRequired,
  session_details: PropTypes.object.isRequired,
};

export default DeviceSession;
