import React from "react";
import { Box, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import nimbioPalette from "../../../theme";
import { logout } from "../../../reducers/connection-reducer";
import store from "../../../store";
import { ReactComponent as NimbioLogo } from "../../../assets/image/nimbio-logo-vector.svg";

const PlaceHolderPage = ({ msg, withLogout = false }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        height: "100vh",
        backgroundColor: nimbioPalette.white,
      }}>
      <Box
        sx={{
          width: { xs: 200, sm: 300, md: 400 },
        }}>
        <NimbioLogo />
      </Box>
      <Typography sx={{ color: nimbioPalette.black, fontSize: "1.7rem", maxWidth: "60%", textAlign: "center" }}>
        {msg}
      </Typography>
      {withLogout && (
        <Stack spacing={5} direction="row" sx={{ mt: 3 }}>
          <Button
            variant="contained"
            sx={{ color: nimbioPalette.black }}
            onClick={() => {
              store.dispatch(logout());
            }}>
            Log Out
          </Button>
        </Stack>
      )}
    </Box>
  );
};

export default PlaceHolderPage;
