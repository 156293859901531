import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import { Box, Card, CardContent, Typography, Divider } from "@mui/material";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import KeyIcon from "@mui/icons-material/Key";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import CommunityLatch from "../../common/Components/CommunityLatch";
import useSubscription from "../../../utilities/hooks/useSubscription";
import nimbioServer from "../../../server/endpoints";

const styles = () => ({
  accordian: {
    boxShadow: "none",
  },
  communityName: {
    fontWeight: "500",
  },
  hidden: {
    backgroundColor: grey[200],
    padding: 3,
    width: "100%",
    borderRadius: 4,
  },
  nonHidden: {
    backgroundColor: "white",
  },
});

const AdminAccountKey = (props) => {
  const theme = useTheme();

  useSubscription(nimbioServer.community.community_key + props.account_key.id, () => {
    props.refresh_account();
  });

  return (
    <Card sx={{ marginBottom: 3, marginTop: 2 }}>
      <CardContent>
        <Box sx={props.account_key.hidden ? styles(theme).hidden : styles(theme).nonHidden}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {props.account_key.disabled === 1 ? (
                  <Box mt={1} ml={2} mr={2}>
                    <KeyOffIcon color="error" />
                  </Box>
                ) : (
                  <Box mt={1} ml={2} mr={2}>
                    <KeyIcon color="success" />
                  </Box>
                )}
                <Typography sx={styles(theme).communityName}>{props.account_key.name}</Typography>
              </Box>
              <>
                {props.account_key.hidden === true && (
                  <Typography color="textSecondary" component="p">
                    Hidden
                  </Typography>
                )}

                {props.account_key.parent_name && (
                  <Typography variant="body2" color="textSecondary" component="p">
                    {props.account_key.parent_name}
                  </Typography>
                )}
              </>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Grid container>
            {props.account_key.latches.map((latch, index) => (
              <CommunityLatch
                key={`${props.account_key.id}_${index}`}
                latch={latch}
                key_id={props.account_key.id}
                type={props.type}
              />
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

AdminAccountKey.propTypes = {
  account_key: PropTypes.object.isRequired,
  refresh_account: PropTypes.func.isRequired,
};

export default connect()(AdminAccountKey);
