const materialColors = [
  "#e57373",
  "#f06292",
  "#ba68c8",
  "#9575cd",
  "#7986cb",
  "#64b5f6",
  "#4fc3f7",
  "#4dd0e1",
  "#4db6ac",
  "#81c784",
  "#aed581",
  "#ff8a65",
  "#d4e157",
  "#ffd54f",
  "#ffb74d",
  "#a1887f",
  "#90a4ae",
];

export function javaHashCode(str) {
  return Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
}

export function getColor(str) {
  if (str === undefined) {
    str = "";
  }
  return materialColors[Math.abs(javaHashCode(str)) % materialColors.length];
}

export function getShortName(first, last) {
  return first?.charAt(0).toUpperCase() + last?.charAt(0).toUpperCase();
}
