import React from "react";
import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import BoxLatchSelect from "./BoxLatchSelect";
import { addLatchToKey } from "../../../reducers/admin-community-list";

const BoxLatchSelectDialog = ({ key_id, onClose }) => {
  const dispatch = useDispatch();
  const [selectedLatches, setSelectedLatches] = React.useState([]);

  const handleAddLatches = async () => {
    const promises = Object.keys(selectedLatches).map((latch_id) => {
      return dispatch(addLatchToKey(key_id, latch_id));
    });
    await Promise.all(promises);
    onClose();
  };

  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <DialogContent style={{ height: 600 }}>
        <Typography>Select Latches</Typography>
        <BoxLatchSelect selectedLatches={selectedLatches} setSelectedLatches={setSelectedLatches} />
      </DialogContent>
      <DialogActions style={{ marginTop: 20 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleAddLatches}>
          Add Latches to Key
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoxLatchSelectDialog;
