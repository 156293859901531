import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  DialogContentText,
  Typography,
  FormControl,
  Fab,
  IconButton,
  Button,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment-timezone";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import EventDateTimePicker from "./EventDateTimePicker";
import PhoneNumberCustom from "./PhoneNumberCustom";
import useConfirm from "./useConfirm";
import { formatDatetime } from "../../../utilities/formatDatetime";
import nimbioServer from "../../../server/endpoints";
import { call } from "../../../utilities/connection";
import { clearKeys, getKeys } from "../../../reducers/keys-reducer";

const CreateAccountPreApprovedKey = (props) => {
  const { ConfirmDialog, openDialog } = useConfirm();
  const dispatch = useDispatch();
  const keysState = useSelector((state) => state.keysState);
  const is_admin = useSelector((state) => state.profileState.details.is_admin);
  const { open, is_dialog, community_id, closeCallback } = props;
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const [showAccountCreateSuccess, setShowAccountCreateSuccess] = useState(false);
  const [moveOutDateOpen, setMoveOutDateOpen] = useState(false);
  const [moveOutDate, setMoveOutDate] = useState(null);
  const [checkedKeys, setCheckedKeys] = useState({});

  useEffect(() => {
    if (keysState.keys.length > 0) {
      const keys = {};
      keysState.keys.forEach((key) => {
        keys[key.id] = true;
      });
      setCheckedKeys(keys);
    }
  }, [keysState.keys]);

  useEffect(() => {
    return () => {
      setShowAccountCreateSuccess(false);
      dispatch(clearKeys());
    };
  }, []);

  useEffect(() => {
    if (!is_dialog) {
      dispatch(getKeys(community_id));
    }
  }, [is_dialog]);

  useEffect(() => {
    if (is_dialog && open) {
      dispatch(getKeys(community_id));
    }

    if (is_dialog && !open) {
      dispatch(clearKeys());
    }
  }, [open, is_dialog]);

  const handleCheckBoxChange = (keyId, event) => {
    setCheckedKeys({
      ...checkedKeys,
      [keyId]: event.target.checked,
    });
  };

  const handleDeselectAllCheckBoxes = () => {
    const deselected = { ...checkedKeys };
    Object.keys(deselected).forEach((key) => {
      deselected[key] = false;
    });
    setCheckedKeys(deselected);
  };

  const checkKeysPhoneAndCreate = async () => {
    if (!isPossiblePhoneNumber(phone)) {
      setError("Please enter a valid phone number.");
      return;
    }

    const isKeyChecked = Object.keys(checkedKeys).some((key) => checkedKeys[key]);

    if (!isKeyChecked) {
      openDialog({
        heading: "Create Member with No Keys",
        message: "Are you sure you want to create a member with no keys?",
        okButton: "Confirm",
        cancelButton: "Cancel",
        confirmCallback: async () => {
          await _createAccountWithPreApprovedKeys();
        },
      });
    } else {
      await _createAccountWithPreApprovedKeys();
    }
  };

  const _createAccountWithPreApprovedKeys = async () => {
    let url = nimbioServer.community.manager.addAndConfirmCommunityMember;

    if (is_admin) {
      url = nimbioServer.admin.addAndConfirmCommunityMember;
    }
    const formattedMoveOutDate = moveOutDate ? formatDatetime(moveOutDate) : null;

    const checkedKeyIds = Object.keys(checkedKeys).filter((key) => checkedKeys[key]);
    const data = await call(
      url,
      [phone, checkedKeyIds, community_id, formattedMoveOutDate],
      "CreateAccountPreApprovedKey"
    );
    if (!data["result"]) {
      setError(data["message"]);
      return;
    }
    setCheckedKeys([]);
    if (is_dialog) {
      closeCallback();
    } else {
      setShowAccountCreateSuccess(true);
    }
  };

  const handlePhoneChange = (phone_input) => {
    setError(false);
    setPhone(phone_input);
  };

  const resetAccountState = () => {
    setShowAccountCreateSuccess(false);
    setPhone("");
    const keys = {};
    keysState.keys.forEach((key) => {
      keys[key.id] = true;
    });
    setCheckedKeys(keys);
  };

  const handleOpenMoveOutDate = () => {
    setMoveOutDateOpen(true);
    if (!moveOutDate) {
      setMoveOutDate(moment().add(12, "months"));
    }
  };
  const handleDateChange = (date) => {
    setMoveOutDate(date);
  };
  const removeMoveOutDate = () => {
    setMoveOutDate(null);
  };
  const handleCloseEventDateTimePicker = (isCancel) => {
    if (isCancel) {
      setMoveOutDate(null);
    }
    setMoveOutDateOpen(false);
  };

  return (
    <Box data-testid={"add-member-container"}>
      {showAccountCreateSuccess ? (
        <Box>
          <DialogContentText sx={{ marginBottom: 2 }} color="green">
            Account created and added as community member successfully!
          </DialogContentText>
          <Button variant="contained" onClick={resetAccountState}>
            Create another Account
          </Button>
        </Box>
      ) : (
        <Box>
          <Box sx={{ marginBottom: 4 }}>
            <FormControl sx={{ flexGrow: 1, display: "flex" }}>
              <PhoneInput
                value={phone}
                onChange={(phone_input) => handlePhoneChange(phone_input)}
                defaultCountry="US"
                numberInputProps={{ autoFocus: true, label: "Member Phone Number" }}
                inputComponent={PhoneNumberCustom}
              />
            </FormControl>
          </Box>
          {error ? (
            <DialogContentText sx={{ marginBottom: 2 }} color="error">
              {error}
            </DialogContentText>
          ) : null}
          {keysState.keys && keysState.keys.length > 0 && (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row", marginBottom: 2 }}>
                <FormLabel component="legend">Keys to assign</FormLabel>
                {keysState.keys.length > 1 ? (
                  <Button
                    data-testid="create-account-pre-approved-key-deselect-keys"
                    variant={"outlined"}
                    onClick={handleDeselectAllCheckBoxes}>
                    Deselect All Keys
                  </Button>
                ) : null}
              </Box>
              {Object.keys(checkedKeys).length > 0
                ? keysState.keys.map((item) => (
                    <FormControlLabel
                      key={item.id}
                      control={
                        <Checkbox
                          data-testid={"key-for-accept-checkbox+" + item.id}
                          checked={checkedKeys[item.id]}
                          sx={{ paddingLeft: 1 }}
                          onChange={(value) => handleCheckBoxChange(item.id, value)}
                          value={checkedKeys[item.id]}
                        />
                      }
                      label={item.name}
                    />
                  ))
                : null}
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: "16px",
              paddingBottom: "32px",
              maxWidth: "300px",
            }}>
            <Typography data-testid="create-account-move-out-date" style={{ lineHeight: "40px" }}>
              Move Out Date: {moveOutDate ? formatDatetime(moveOutDate) : "None"}
              {moveOutDate ? (
                <IconButton sx={{ marginLeft: 2 }} aria-label="settings" size="small" onClick={removeMoveOutDate}>
                  <DeleteIcon color="primary" />
                </IconButton>
              ) : null}
            </Typography>
            {!moveOutDate ? (
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="Add Move Out Date"
                onClick={handleOpenMoveOutDate}
                style={{ marginRight: 16 }}
                data-testid={"add-move-out-date"}>
                <NoMeetingRoomIcon sx={{ mr: 2 }} />
                <h5>Add</h5>
              </Fab>
            ) : null}
          </Box>
          <EventDateTimePicker
            open={moveOutDateOpen}
            setDateTimeCallback={(moveOutDate) => handleDateChange(moveOutDate)}
            firstPageTitle="Set Move Out Date"
            datetime={moveOutDate}
            dateOnly={true}
            closeCallback={handleCloseEventDateTimePicker}
            startingPage="date"
          />
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
            {is_dialog ? (
              <Button sx={{ marginRight: 1 }} variant="outlined" onClick={closeCallback}>
                Cancel
              </Button>
            ) : null}
            <Button variant="contained" data-testid={"create-account-btn"} onClick={checkKeysPhoneAndCreate}>
              Create Account
            </Button>
          </Box>
          <ConfirmDialog />
        </Box>
      )}
    </Box>
  );
};

CreateAccountPreApprovedKey.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  community_id: PropTypes.number.isRequired,
  is_dialog: PropTypes.bool.isRequired,
  open: PropTypes.bool,
};

export default CreateAccountPreApprovedKey;
