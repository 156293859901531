import React from "react";
import { connect } from "react-redux";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Button } from "@mui/material";
import store from "../../../store";

const ReadOnlyErrorModal = ({ show_read_only_error_modal }) => {
  const handleClose = () => {
    store.dispatch({ type: "HIDE_READ_ONLY_ERROR_MODAL" });
  };

  return (
    <div>
      <Dialog
        open={show_read_only_error_modal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle p={2} style={{ fontSize: "0.2em" }} align="center">
          Unavailable due to Maintanence.
        </DialogTitle>
        <DialogContent p={0}>
          <DialogContentText p={0} align="center" id="alert-dialog-description">
            Try again shortly.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    show_read_only_error_modal: state.adminMaintenanceModeState.show_read_only_error_modal,
  };
};

export default connect(mapStateToProps)(ReadOnlyErrorModal);
