import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const styles = (theme) => ({
  circleButton: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    cursor: "pointer",
  },
  buttonActive: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  buttonInactive: {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  text: {
    fontFamily: "Filson",
  },
});

const WeekdayButton = (props) => {
  const theme = useTheme();
  return (
    <Grid item>
      <Box
        onClick={() => props.handleClick(props.serverKey)}
        sx={[
          styles(theme).circleButton,
          props.styles,
          props.isActive ? styles(theme).buttonActive : styles(theme).buttonInactive,
        ]}>
        <Typography sx={styles(theme).text}>{props.dayLabel}</Typography>
      </Box>
    </Grid>
  );
};

WeekdayButton.propTypes = {
  dayLabel: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  serverKey: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default WeekdayButton;
