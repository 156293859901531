import { handleActions } from "redux-actions";
import { createAction } from "redux-promise-middleware-actions";

// Action Creators
export const openRemovePendingMember = createAction("OPEN_REMOVE_PENDING_MEMBER", (option) => option);
export const closeRemovePendingMember = createAction("CLOSE_REMOVE_PENDING_MEMBER", () => false);
export const openAcceptPendingMember = createAction(
  "OPEN_ACCEPT_PENDING_MEMBER",
  (details, isMemberRemoved = false) => {
    return { details, isMemberRemoved };
  }
);
export const closeAcceptPendingMember = createAction("CLOSE_ACCEPT_PENDING_MEMBER", () => false);

// Reducer
export const initialState = {
  details: {},
  loading: false,
  error: false,
  removePendingMemberOpen: false,
  acceptPendingMemberOpen: false,
  isMemberRemoved: false,
};

export default handleActions(
  {
    OPEN_ACCEPT_PENDING_MEMBER: (state, { payload }) => {
      return {
        ...state,
        acceptPendingMemberOpen: true,
        details: payload.details,
        isMemberRemoved: payload.isMemberRemoved,
      };
    },
    OPEN_REMOVE_PENDING_MEMBER: (state, { payload }) => {
      return {
        ...state,
        removePendingMemberOpen: true,
        details: payload,
      };
    },
    CLOSE_REMOVE_PENDING_MEMBER: (state) => {
      return {
        ...state,
        removePendingMemberOpen: false,
      };
    },
    CLOSE_ACCEPT_PENDING_MEMBER: (state) => {
      return {
        ...state,
        acceptPendingMemberOpen: false,
      };
    },
  },
  initialState
);

// Selectors
