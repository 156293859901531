import { createTheme } from "@mui/material";

export const nimbioPalette = {
  white: "#F4F4F4",
  orange: "#E15634",
  //Light to dark
  purple: ["#F4EFFF", "#E5D9F1", "#CDC1FD", "#A493F8", "#7270FC"],
  black: "#1E1E1E",
};

export default createTheme({
  palette: {
    mode: "light",
    primary: {
      main: nimbioPalette.purple[4],
      contrastText: nimbioPalette.white,
    },
  },
  text: {
    primary: {
      main: nimbioPalette.black,
      fontFamily: "Filson",
    },
  },
  link: {
    text: {
      color: nimbioPalette.purple[4],
    },
    highlighted: {
      color: nimbioPalette.purple[2],
    },
    clicked: {
      color: nimbioPalette.purple[4],
    },
    background: {
      color: nimbioPalette.purple[4],
    },
  },
  background: {
    backgroundColor: nimbioPalette.white,
  },
  typography: {
    fontFamily: "Filson, Arial, Roboto",
  },
});
