import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import {
  deviceListSetSearchTerm,
  adminGetDeviceStatusPaginated,
  adminGetDeviceStatusPaginatedHidden,
} from "../../../reducers/device-status-reducer";
import endpoints from "../../../routes/endpoints";

type DeviceListSearchBoxType = {
  type: string;
  paginate: (pageNum: number) => void;
  devicesPerPage: number;
};

const DeviceListSearchBox = ({ type, devicesPerPage }: DeviceListSearchBoxType) => {
  const [value, setValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isTyping) {
      timeoutId = setTimeout(() => {
        setIsTyping(false);
        if (!!value) {
          dispatch(deviceListSetSearchTerm(value.toLowerCase(), 1, devicesPerPage, type));
        }
      }, 600);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, isTyping, dispatch, devicesPerPage, type]);

  const handleChange = (event: any) => {
    if (event.target.value === "") {
      setValue("");
      switch (type) {
        case "revealed":
          // @ts-expect-error: adminGetDeviceStatusPaginated not converted to ts yet
          dispatch(adminGetDeviceStatusPaginated(1, 24));
          break;
        case "hidden":
          // @ts-expect-error: typescript
          dispatch(adminGetDeviceStatusPaginatedHidden(1, 24));
          break;
        case "recent":
          // @ts-expect-error: adminGetDeviceStatusPaginated not converted to ts yet
          dispatch(adminGetDeviceStatusPaginated(1, 24, "recent"));
          break;
        default:
          console.error("No type specified for DeviceListSearchBox");
      }
    }

    if (!!event.target.value) {
      setIsTyping(true);
      setValue(event.target.value);
    }
  };

  return (
    <TextField
      id={"nimbio-testing" + endpoints.ADMIN_DEVICE_MONITOR + "-searchbox"}
      variant="outlined"
      label="Search"
      onChange={(event) => handleChange(event)}
      sx={styles.searchBox}
    />
  );
};

const styles = {
  searchBox: {
    width: "100%",
    mt: 2,
    mb: 2,
  },
};

DeviceListSearchBox.propTypes = {};

const mapStateToProps = (state: any) => {
  return {
    search_term: state.deviceStatus.search_term,
  };
};

export default connect(mapStateToProps)(DeviceListSearchBox);
