import store from "../store";
// import { getHomeDetailForCache } from "../reducers/members-reducer";

export function formatSearchData(is_accepted, is_unaccepted, is_removed, memberData) {
  return {
    searchTerm: memberData.first_name + " " + memberData.last_name,
    is_home: false,
    is_accepted: is_accepted,
    is_unaccepted: is_unaccepted,
    is_removed: is_removed,
    id: memberData.id,
    phone_numbers: memberData.phone_numbers,
    account_community_id: memberData.account_community_id,
    first_name: memberData.first_name,
    last_name: memberData.last_name,
    created_datetime: memberData.created_datetime ? memberData.created_datetime : "",
    removal_date: memberData.removal_date ? memberData.removal_date : "",
    keys: memberData.keys,
  };
}

export function parseAcceptedMembers(payload, action) {
  let searchData = [];

  if (payload && payload.data && payload.data.accepted && payload.data.accepted.length > 0) {
    payload.data.accepted.forEach((item) => {
      if (item.is_home) {
        setTimeout(() => store.dispatch(action(item.id, store.getState().communityState.currentCommunityID)), 0);
      } else {
        searchData.push(formatSearchData(true, false, false, item));
      }
    });
  }
  return searchData;
}

export function parseUnacceptedMembers(payload, action) {
  let searchData = [];

  if (payload && payload.data && payload.data.unaccepted && payload.data.unaccepted.length > 0) {
    payload.data.unaccepted.forEach((item) => {
      if (item.is_home) {
        setTimeout(() => store.dispatch(action(item.id, store.getState().communityState.currentCommunityID)), 0);
      } else {
        searchData.push(formatSearchData(false, true, false, item));
      }
    });
  }

  return searchData;
}

export function parseRemovedMembers(payload, action) {
  let searchData = [];

  if (payload && payload.data.removed && payload.data.removed.length > 0) {
    payload.data.removed.forEach((item) => {
      if (item.is_home) {
        setTimeout(() => store.dispatch(action(item.id, store.getState().communityState.currentCommunityID)), 0);
      } else {
        searchData.push(formatSearchData(false, false, true, item));
      }
    });
  }
  return searchData;
}
