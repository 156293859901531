import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Typography, Box, Grid } from "@mui/material";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { adminSetCommunityLocation } from "../../../reducers/admin-community-list";

const CommunityLocation = (props) => {
  const [newX, setNewX] = useState(props.location?.x || props.location?.x === 0 ? props.location?.x : -115);
  const [newY, setNewY] = useState(props.location?.y || props.location?.y === 0 ? props.location?.y : 36);
  const [newLocation, setNewLocation] = useState(false);
  const [mapOpen, setMapOpen] = useState(false);

  const _setLocation = () => {
    props.dispatch(adminSetCommunityLocation(props.community_id, newX, newY));
    setNewLocation(false);
  };

  const _openMap = () => {
    setMapOpen(true);
  };

  const _hideMap = () => {
    setMapOpen(false);
  };

  useEffect(() => {
    //To do: This doesn't work (didn't before the refactor) ENG-T-171
    //props.location doesn't update.
    if (props.location?.x !== newX && props.location?.y !== newY) {
      if (props.location) {
        setNewX(props.location.x);
        setNewY(props.location.y);
      }
    }
    /* 
    Old implementation for reference. As you can see by the repeating ternary operators, there is a logic issue.
        if (prevProps.location?.x !== this.props.location?.x || prevProps.location?.x !== this.props.location?.x) {
      if (this.props.location) {
        this.setState({ newX: this.props.location.x, newY: this.props.location.y });
      }
    }
    */
  }, [props.location]);

  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 2 }}>
        <Typography variant="body2" color="textSecondary" component="p">
          Location {!props.location && <b>Not Set</b>}
        </Typography>
        <Box style={{ marginBottom: 12 }}>
          {!mapOpen && (
            <Button variant="contained" color="primary" endIcon={<EditLocationIcon />} size="small" onClick={_openMap}>
              Show
            </Button>
          )}
          {mapOpen && (
            <Button
              variant="contained"
              color="primary"
              endIcon={<EditLocationIcon />}
              size="small"
              disabled={!newLocation}
              onClick={_setLocation}>
              Save
            </Button>
          )}
        </Box>
      </Box>
      {mapOpen && (
        <LoadScript googleMapsApiKey="AIzaSyCj3G-abpscr6FVFTYlGTPB9FlqCGOCh8Y">
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: 240,
            }}
            center={{ lng: newX, lat: newY }}
            zoom={15}
            options={{ disableDefaultUI: true }}
            // onLoad={onLoad}
            // onUnmount={onUnmount}
          >
            <Marker
              draggable
              position={{ lng: newX, lat: newY }}
              onDragEnd={(event) => {
                setNewX(event.latLng.lng());
                setNewY(event.latLng.lat());
                setNewLocation(true);
              }}
            />
          </GoogleMap>
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            <Grid item>
              <Box style={{ marginTop: 12, marginBottom: 12 }}>
                <Button variant="contained" color="primary" endIcon={<CloseIcon />} size="small" onClick={_hideMap}>
                  Close
                </Button>
              </Box>
            </Grid>
          </Grid>
        </LoadScript>
      )}
    </>
  );
};

CommunityLocation.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.number.isRequired]),
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunityLocation);
