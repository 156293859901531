import React from "react";
import { Avatar, CardHeader, Box, Typography, FormControlLabel, Switch } from "@mui/material";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { connect } from "react-redux";
import PhoneNumbers from "./PhoneNumbers";
import { getColor } from "../../../utilities/materialAvatar";
import {
  adminCommunityManagerSetActive,
  adminCommunityManagerSetInactive,
  adminCommunityManagerSetAccessLogs,
} from "../../../reducers/admin-community-list";

const CommunityManagerDetail = (props) => {
  const clickCheckBox = () => {
    if (props.manager.active) {
      props.dispatch(adminCommunityManagerSetInactive(props.manager.id));
    } else {
      props.dispatch(adminCommunityManagerSetActive(props.manager.id));
    }
  };

  const handleSetLogHistoryEnabled = async () => {
    await props.dispatch(
      adminCommunityManagerSetAccessLogs(!props.manager.is_open_log_history_enabled, props.manager.id),
    );
  };

  if (!props.manager) {
    return true;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        m: 1,
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
      }}>
      <CardHeader
        sx={{ minWidth: "250px" }}
        avatar={
          <Avatar aria-label="recipe" style={{ backgroundColor: getColor(props.manager.account.id) }}>
            <SupervisedUserCircleIcon />
          </Avatar>
        }
        title={props.manager.account.first_name + " " + props.manager.account.last_name}
        titleTypographyProps={{ fontFamily: "Filson", maxWidth: "164px" }}
        subheader={<PhoneNumbers phone_numbers={props.manager.account.phone_numbers} />}
      />
      <Box sx={{ display: "flex", flex: 1, justifyContent: "space-evenly", flexDirection: "row", ml: 2 }}>
        <FormControlLabel
          control={<Switch checked={Boolean(props.manager.active)} onClick={clickCheckBox} />}
          label={<Typography variant="label">Enabled</Typography>}
          labelPlacement="top"
        />
        <FormControlLabel
          control={<Switch checked={props.manager.is_open_log_history_enabled} onClick={handleSetLogHistoryEnabled} />}
          disabled={!props.historyEnabled}
          label={<Typography variant="label">{"Logs\u00a0Viewable"}</Typography>}
          labelPlacement="top"
        />
      </Box>
    </Box>
  );
};

export default connect()(CommunityManagerDetail);
