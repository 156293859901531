import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Fab,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Switch,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { addHome } from "../../../reducers/homes-reducer";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";

const TITLE = "Add Homes";

const BulkAddHomes = () => {
  const dispatch = useDispatch();
  const { currentCommunityID } = useSelector((state: any) => state.communityState);
  const homeState = useSelector((state: any) => state.homesState);
  const [open, setOpen] = useState(false);
  const [homeAddress, setHomeAddress] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showOwnerOccupiedList, setShowOwnerOccupiedList] = useState<boolean>(false);
  const [homeAddresses, setHomeAddresses] = useState<string[]>([]);
  const [ownerOccupiedState, setOwnerOccupiedState] = useState<any>({});

  const theme = useTheme();

  const handleAddHomes = () => {
    if (homeAddress === "") {
      setError("Please enter at least one home address.");
      return;
    }
    const home_addresses = homeAddress.split("\n").map((address: string) => address.trim());

    home_addresses.forEach((address: string) => {
      if (address !== "") {
        dispatch(addHome(currentCommunityID, address));
      }
    });
    setHomeAddresses(home_addresses);
    setShowOwnerOccupiedList(true);
  };

  const _handleChange = (e: any) => {
    setError("");
    setHomeAddress(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setHomeAddress("");
    setShowOwnerOccupiedList(false);
    setError("");
    setOwnerOccupiedState({});
  };

  const handleUpdateOwnerOccupied = () => {
    Object.keys(ownerOccupiedState).forEach(async (home_id: string) => {
      await call(
        nimbioServer.community.manager.setOwnerOccupied,
        [currentCommunityID, home_id, ownerOccupiedState[home_id]],
        "bulk-add-homes"
      );
    });
    handleClose();
  };

  const handleUpdateOwnerOccupiedState = (home_id: string, owner_occupied: boolean) => {
    setOwnerOccupiedState({ ...ownerOccupiedState, [home_id]: owner_occupied });
  };

  return (
    <Box>
      <Fab
        variant="extended"
        size="medium"
        color="primary"
        aria-label={TITLE}
        sx={styles(theme).margin}
        onClick={() => setOpen(true)}
        data-testid={"bulk-add-home-open-btn"}>
        <HomeIcon sx={styles(theme).marginRight} />
        <h5>{TITLE}</h5>
      </Fab>
      <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth={true} data-testid={"bulk-add-home-dialog"}>
        {!showOwnerOccupiedList ? (
          <Box>
            <DialogTitle>{TITLE}</DialogTitle>
            <DialogContent sx={styles(theme).textContainer}>
              <DialogContentText paddingBottom={2}>
                Add a list of new home addresses with each address separated by a new line.
              </DialogContentText>

              {error ? (
                <DialogContentText paddingBottom={3} color="error">
                  {error}
                </DialogContentText>
              ) : null}

              <TextField
                data-testid={"bulk-add-home-dialog-address"}
                sx={styles(theme).textBox}
                maxRows={50}
                multiline={true}
                type="text"
                label="New Addresses"
                value={homeAddress}
                onChange={_handleChange}
                fullWidth={true}
                autoFocus={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                data-testid={"bulk-add-homes-cancel-button"}
                onClick={handleClose}
                color="primary">
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleAddHomes} data-testid={"bulk-add-homes-save"}>
                Add Homes
              </Button>
            </DialogActions>
          </Box>
        ) : (
          <Box>
            <DialogTitle>Owner Occupied</DialogTitle>
            <DialogContent sx={styles(theme).textContainer}>
              <DialogContentText paddingBottom={2}>Adjust the owner occupied status for each home.</DialogContentText>
              {homeState.homes.map((home: any) => {
                if (homeAddresses.includes(home.address)) {
                  return (
                    <Box
                      key={home.id}
                      sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                      <DialogContentText sx={{ paddingTop: 1, paddingRight: 2 }}>{home.address}</DialogContentText>
                      <FormControlLabel
                        control={
                          <Switch
                            data-testId={`bulk-add-home-owner-occupied-${home.id}`}
                            defaultChecked={home.owner_occupied}
                            onChange={(e) => handleUpdateOwnerOccupiedState(home.id, e.target.checked)}
                          />
                        }
                        label="Owner Occupied"
                      />
                    </Box>
                  );
                }
              })}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                data-testid={"bulk-add-homes-cancel-button"}
                onClick={handleClose}
                color="primary">
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdateOwnerOccupied}
                data-testid={"bulk-add-homes-own-occupied-save-btn"}>
                Confirm
              </Button>
            </DialogActions>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

const styles = (theme: any) => ({
  addButtonContainer: {
    m: 2,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: 1,
  },
  marginRight: {
    marginRight: 1,
  },
  textBox: {
    flex: 1,
  },
  textContainer: { minHeight: 100, display: "flex", alignItems: "flex-start", flexDirection: "column" },
  rightIcon: {},
});

export default connect()(BulkAddHomes);
