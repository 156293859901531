/*
    To run the debugger, set process.env.REACT_APP_DEBUG to true and append to the start script
    e.g.
    REACT_APP_DEBUG=true yarn start_dev
*/

/* eslint-disable no-console */
type DebugLevel = {
  1: "FATAL";
  2: "ERROR";
  3: "INFO";
  4: "DEBUG";
  5: "TRACE";
};

type OptionsType = {
  level?: DebugLevel & number;
  extraMsg?: string;
};

/*
    moduleIdentifier is optional, but highly suggested so this is required to set options.
*/
type DebugType = (msg: string, moduleIdentifier: string, options?: OptionsType, hidden?: boolean) => void;

const TAG_NAME = "NIMBIO_LOG";
const STYLES = "color: #7270FC";
const manualConfig = {
  // Only displays logs that match this identifier e.g. moduleIdentifier="useSubscription" will only print
  // logs from moduleIdentifier "useSubscription"
  moduleIdentifier: null,
};

const debug: DebugType = (msg, moduleIdentifier, options?, hidden?) => {
  if (hidden && hidden === true) return;

  if (!process.env.REACT_APP_DEBUG || process.env.NODE_ENV !== "development") return;

  if (typeof manualConfig.moduleIdentifier === "string" && manualConfig.moduleIdentifier !== moduleIdentifier) return;

  let appendedMsg = "\n" + TAG_NAME + "\n";

  appendedMsg += "Module:" + moduleIdentifier + "\n";

  if (options?.extraMsg) appendedMsg += "ExMsg: " + options.extraMsg + "\n";

  if (options?.level) {
    if (options.level) {
      switch (options?.level) {
        case 1:
          appendedMsg += "Level = Fatal\n\n";
          console.warn(appendedMsg + "\n" + "Msg: " + msg + "\n");
          break;
        case 2:
          appendedMsg += "Level = Error\n\n";
          console.error(appendedMsg + "\n" + "Msg: " + msg + "\n");
          break;
        case 3:
          appendedMsg += "Level = Info\n\n";
          console.info(appendedMsg + "\n" + "Msg: " + msg + "\n");
          break;
        case 4:
          appendedMsg += "Level = Debug\n\n";
          console.debug("Msg :" + appendedMsg + "\n" + "Msg: " + msg + "\n");
          break;
        case 5:
          appendedMsg += "Level = Trace\n\n";
          console.trace(appendedMsg + "\n" + "Msg: " + msg + "\n");
          break;
        default:
          console.debug(`%c "Msg: "+ ${msg}\n${appendedMsg}\n\n`, STYLES);
          break;
      }
    }
  } else {
    console.debug(`%c ${appendedMsg}\nMsg: ${msg}\n\n`, STYLES);
  }
};

// Used for async function return value run time type checking.
/*
Matches types. Returns the boolean result if the moduleIdentifier is included. Returns the .debug function if not.
*/
const matchType = (
  returnValue: any,
  returnType: string,
  moduleIdentifier?: string,
): { debug: DebugType } | boolean | void => {
  if (!process.env.REACT_APP_DEBUG || process.env.NODE_ENV !== "development") return;

  const match = typeof returnValue === returnType;

  if (moduleIdentifier) {
    return match;
  } else {
    if (!match) {
      debug(
        "Return value:" + returnValue + " does not match expected return type:" + returnType,
        "see attached bug log of one exists",
      );
      return { debug };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const emptyFunction: DebugType = (msg: string, moduleIdentifier?: string, options?: OptionsType) => {
        /*Do nothing with the function if the types match*/
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return { debug: emptyFunction };
    }
  }
};

const nimbioDebugger = {
  debug,
  matchType,
};

export default nimbioDebugger;
