import React, { Component } from "react";
import PropTypes from "prop-types";
import { CardHeader, Paper, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import PhoneNumbers from "./PhoneNumbers";
import { getColor } from "../../../utilities/materialAvatar";

class CreatedBy extends Component {
  render() {
    if (!this.props.creator) {
      return true;
    }
    return (
      <>
        <Typography variant="body2" color="textSecondary" component="p">
          Creator
        </Typography>
        <Paper>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" style={{ backgroundColor: getColor(this.props.creator.id) }}>
                <BlurOnIcon />
              </Avatar>
            }
            title={this.props.creator.first_name + " " + this.props.creator.last_name}
            subheader={<PhoneNumbers phone_numbers={this.props.creator.phone_numbers} />}
          />
        </Paper>
      </>
    );
  }
}

CreatedBy.propTypes = {
  creator: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.number.isRequired]),
};

export default CreatedBy;
