import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { connect } from "react-redux";
import Button from "@mui/material/Button";

import AdminDeviceLatches from "../../Admin/components/AdminDeviceLatches";

function DeviceLatchDisplay(props) {
  const _close = () => {
    props.closeCallback();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeCallback}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth
      data-testid={"show-latches"}>
      <DialogTitle id="form-dialog-title">Device Latches</DialogTitle>
      <DialogContent>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={0}>
          <AdminDeviceLatches box_id={props.box_id} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={_close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeviceLatchDisplay.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    latches: state.deviceStatus.device_latches,
  };
};

export default connect(mapStateToProps)(DeviceLatchDisplay);
