import React, { Component } from "react";
import { connect } from "react-redux";
import AdminAppBar from "./AdminAppBar";
import { adminGetDeviceStatusPaginatedHidden } from "../../../reducers/device-status-reducer";
import DeviceList from "../../common/Components/DeviceList";

class AdminHiddenDeviceMonitor extends Component {
  componentDidMount() {
    this.props.dispatch(adminGetDeviceStatusPaginatedHidden(1, 24));
  }

  render() {
    return (
      <AdminAppBar selected="Hidden Devices" {...this.props}>
        <DeviceList type="hidden" />
      </AdminAppBar>
    );
  }
}

AdminHiddenDeviceMonitor.propTypes = {};

const mapStateToProps = (state) => {
  return {
    deviceMonitor: state.deviceStatus,
  };
};

export default connect(mapStateToProps)(AdminHiddenDeviceMonitor);
