import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteAccountFromAccountGroup = createAsyncAction(
  "DELETE_ACCOUNT_FROM_ACCOUNT_GROUP_DATA",
  (account_group_id, account_id) =>
    call(
      nimbioServer.admin.deleteAccountFromAccountGroup,
      [account_group_id, account_id],
      "admin-get-account-account-groups",
    ),
);

export const getAccountGroupsByAccount = createAsyncAction("ADMIN_GET_ACCOUNT_GROUPS_BY_ACCOUNT", (account_id) => {
  return call(nimbioServer.admin.getAccountGroupsByAccount, [account_id], "admin-get-account-account-groups");
});

export default handleActions(
  {
    ADMIN_GET_ACCOUNT_GROUPS_BY_ACCOUNT_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_ACCOUNT_GROUPS_BY_ACCOUNT_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_ACCOUNT_GROUPS_BY_ACCOUNT_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState,
);
