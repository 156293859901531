import React, { useEffect } from "react";
import { connect } from "react-redux";
import ListCommunities from "./Components/ListCommunities";
import { getCommunities } from "../../reducers/communities-reducer";
import ReadOnlyErrorModal from "../common/Components/ReadOnlyErrorModal";

function PickCommunity(props) {
  useEffect(() => {
    if (props.realm.connected) {
      props.dispatch(getCommunities());
    }
  }, [props.realm.connected]);

  return (
    <div data-testid={"pick-community"}>
      <ReadOnlyErrorModal />
      <ListCommunities />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    realm: state.realmState,
    adminMaintenanceModeState: state.adminMaintenanceModeState,
  };
};

export default connect(mapStateToProps)(PickCommunity);
