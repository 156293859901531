import React, { useEffect } from "react";
import "./App.css";
import { connect, useDispatch } from "react-redux";
import { setCredentials } from "./reducers/connection-reducer";
import ReconnectingDialog from "./views/common/Components/ReconnectingDialog";
import Routes from "./routes/Routes";
import { pageVisibleChange } from "./reducers/app-reducer";
import useSubscription from "./utilities/hooks/useSubscription";
import { generalSubscription } from "./subscriptions/generalSubscription";
import nimbioServer from "./server/endpoints";

const App = (props) => {
  const dispatch = useDispatch();
  useSubscription(nimbioServer.internal.general, () => generalSubscription);

  useEffect(() => {
    const handleVisibilityChange = () => dispatch(pageVisibleChange());

    document.addEventListener("visibilitychange", handleVisibilityChange, false);
  }, [dispatch]);

  useEffect(() => {
    if (
      !props.realmState.connecting &&
      !props.realmState.connected &&
      localStorage.getItem("auth_id") &&
      localStorage.getItem("secret")
    ) {
      props.dispatch(setCredentials(localStorage.getItem("auth_id"), localStorage.getItem("secret")));
    }
  }, [props]);

  if (props.realmState.connected) {
    return (
      <div>
        <Routes />
        <ReconnectingDialog
          open={props.realmState.reconnecting && props.realmState.should_connect}
          retryCount={props.realmState.reconnectCount}
        />
      </div>
    );
  } else {
    return <Routes />;
  }
};

const mapStateToProps = (state) => {
  return {
    loginState: state.loginState,
    realmState: state.realmState,
  };
};

export default connect(mapStateToProps)(App);
