import React, { useEffect, useRef, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import moment from "moment";

const LOG_START_DATE = "27/08/2023";

const PastDay = (props) => {
  const { day, ...other } = props;

  const logStartDateParsed = moment(LOG_START_DATE, "DD/MM/YYYY");
  const isAfterStartDate = day.diff(logStartDateParsed, "days", true) < 0;

  return <PickersDay {...other} day={day} disabled={isAfterStartDate} />;
};

const FutureDay = (props) => {
  const { day, earlierDate, ...other } = props;

  const twoWeeksLater = moment(earlierDate).add(14, "days");
  // const isWithinTwoWeeks = day.isBetween(earlierDate, twoWeeksLater, null, '[]');
  const isWithinTwoWeeks = day.isBetween(earlierDate, twoWeeksLater, null) || moment(day).isSame(earlierDate, "day");
  const isFuture = day.diff(moment(), "days", true) > 0;
  const logStartDateParsed = moment(LOG_START_DATE, "DD/MM/YYYY");
  const isBeforeStartDate = day.diff(logStartDateParsed, "days", true) < 0;

  return <PickersDay {...other} day={day} disabled={!isWithinTwoWeeks || isFuture || isBeforeStartDate} />;
};

const DateTwoWeekMaxPicker = ({ startLabel, endLabel, handlePickerChanged, loading }) => {
  const [earlierDate, setEarlierDate] = useState(moment().subtract(14, "days"));
  const [laterDate, setLaterDate] = useState(moment());
  const loadDelayRef = useRef(false);

  useEffect(() => {
    const logStartDateParsed = moment(LOG_START_DATE, "DD/MM/YYYY");
    const isBeforeStartDate = earlierDate.diff(logStartDateParsed, "days", true) < 0;
    if (isBeforeStartDate) {
      setEarlierDate(logStartDateParsed);
      return;
    }

    if (moment().diff(earlierDate, "days") > 14) {
      const newlaterDate = moment(earlierDate).add(14, "days");
      setLaterDate(newlaterDate);
      handlePickerChanged(earlierDate, newlaterDate);
    } else {
      const newlaterDate = moment();
      setLaterDate(newlaterDate);
      handlePickerChanged(earlierDate, newlaterDate);
    }

    loadDelayRef.current = true;
    setTimeout(() => {
      loadDelayRef.current = false;
    }, 1000);
  }, [earlierDate]);

  useEffect(() => {
    if (!loadDelayRef.current) handlePickerChanged(earlierDate, laterDate);
  }, [laterDate]);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 2,
        mb: 2,
      }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          disabled={loading}
          slots={{ day: PastDay }}
          slotProps={{
            textField: {
              fontFamily: "Filson",
            },
          }}
          label={startLabel}
          value={earlierDate}
          onChange={(newDate) => setEarlierDate(newDate)}
          format="LL"
          disableFuture
        />
        <DatePicker
          disabled={loading}
          slots={{ day: FutureDay }}
          slotProps={{
            textField: {
              fontFamily: "Filson",
            },
            day: {
              earlierDate: earlierDate,
            },
          }}
          label={endLabel}
          value={laterDate}
          onChange={(newDate) => setLaterDate(newDate)}
          format="LL"
          disableFuture
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateTwoWeekMaxPicker;
