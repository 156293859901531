import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, IconButton, Paper, Button, Box, Typography } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import ChangeLatchName from "./ChangeLatchName";
import RemoveLatch from "./RemoveLatch";
import LoadingLatchButton from "./LoadingLatchButton";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { latchStatusSubscription } from "../../../subscriptions/latchStatusSubscription";

const styles = () => ({
  offlineText: {
    marginTop: 2,
  },
  statusContainer: {
    marginTop: 1,
    marginBottom: 1,
  },
  removeContainer: {
    marginTop: 2,
  },
  latchContainer: {
    padding: 1,
    margin: 0.5,
    textAlign: "center",
    userSelect: "none",
    maxWidth: 400,
  },
  latchName: {
    marginBottom: 1,
  },
  centerText: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  gridCenter: {
    textAlign: "center",
    color: "white",
    fontFamily: "GothamBook",
    fontWeight: "bold",
    textShadow: "rgba(0, 0, 0, 0.4) 1px 1px 3px",
    userSelect: "none",
  },
});

const CommunityLatch = (props) => {
  const [changeNameDialogOpen, setChangeNameDialogOpen] = React.useState(false);
  const [removeLatchDialogOpen, setRemoveLatchDialogOpen] = React.useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { latch } = props;

  useSubscription(nimbioServer.latch + `.${latch.id}`, latchStatusSubscription);

  const _handle_edit_open = () => {
    if (props.type !== "cm_configure_latches") {
      setChangeNameDialogOpen(true);
    }
  };

  const _handle_open_latch = () => {
    if (props.type === "admin" && props.is_admin) {
      call(
        nimbioServer.admin.openLatch,
        [latch.id, props.key_id, props.communities.currentCommunityID],
        "CommunityLatch"
      );
    }
  };

  const _handle_edit_close = () => {
    setChangeNameDialogOpen(false);
  };

  const _handle_remove_latch_open = () => {
    setRemoveLatchDialogOpen(true);
  };

  const _handle_remove_latch_close = () => {
    setRemoveLatchDialogOpen(false);
  };

  return (
    <Grid data-testid="latch_result" item xs={12} sm={6} md={6} lg={4} xl={2} m={2}>
      <Paper sx={styles(theme).latchContainer}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item>
            {props.type === "admin" ? (
              <Box>
                <IconButton sx={{ padding: 1.5 }} aria-label="settings" size="small" onClick={_handle_edit_open}>
                  <EditIcon color="primary" sx={{ fontSize: 18 }} />
                </IconButton>
                <IconButton aria-label="settings" size="large" onClick={_handle_remove_latch_open}>
                  <RemoveCircleOutlineIcon color="secondary" />
                </IconButton>
              </Box>
            ) : null}
          </Grid>
        </Grid>
        <Container sx={styles(theme).latchName}>
          <span style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{props.latch.name}</span>
        </Container>
        <LoadingLatchButton
          clickable={props.type === "admin" && props.is_admin}
          latch={latch}
          latchMsgID={latch?.latch_status_current_message?.latch_message_id}
          onClick={_handle_open_latch}
        />
        <Container disableGutters sx={styles(theme).statusContainer}>
          {props.latch.offline ? (
            "Status: Offline"
          ) : props.latch.latch_status_current_message &&
            props.latch.latch_status_current_message["latch_message_label"] !== null ? (
            "Status: " + props.latch.latch_status_current_message["latch_message_label"]
          ) : (
            <Typography>&nbsp;</Typography>
          )}
        </Container>
        {props.type === "cm_configure_latches" && (props.latch.latch_type === null || props.latch.latch_type === 0) ? (
          <Button
            variant="outlined"
            onClick={() =>
              navigate(
                `/community-manager/${props.communities.currentCommunityID}` +
                  `/configure-sense-line/${props.latch.box_id}/${props.latch.id}`
              )
            }
            data-testid={"configure-latch"}>
            Configure Latch Status
          </Button>
        ) : null}
      </Paper>
      {props.type === "admin" ? (
        <>
          <ChangeLatchName
            open={changeNameDialogOpen}
            closeCallback={_handle_edit_close}
            latch_id={latch.id}
            latch_name={props.latch.name}
            community_id={props.community_id}
          />
          <RemoveLatch
            open={removeLatchDialogOpen}
            closeCallback={_handle_remove_latch_close}
            latch_id={latch.id}
            key_id={props.key_id}
          />
        </>
      ) : null}
    </Grid>
  );
};

CommunityLatch.propTypes = {
  latch: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    keys: state.keysState.keys,
    communities: state.communityState,
    is_admin: state.profileState.details.is_admin,
    community_id: state.communityState.currentCommunityID,
  };
};

export default connect(mapStateToProps)(CommunityLatch);
