import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { nimbioPalette } from "../../../theme";

const EditMemberKeysDialog = (props) => {
  const { editKeysOpen, handleOpenEditKeys, communityKeys, handleCheckKey, editKeys, selectedKeys } = props;

  const searchDebounceTimer = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const debounceTimeout = 500;
  const [searchValueDebounce, setSearchValueDebounce] = useState("");

  const [highLightedKeys, setHighLightedKeys] = useState([]);

  const handleChangeSearch = (event) => {
    clearTimeout(searchDebounceTimer.current);
    searchDebounceTimer.current = setTimeout(() => {
      setSearchValue(event.target.value);
    }, debounceTimeout);

    setSearchValueDebounce(event.target.value);
  };

  useEffect(() => {
    if (searchValueDebounce !== "") {
      const filteredKeys = communityKeys
        .filter((key) => key.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map((key) => key.id);

      setHighLightedKeys(filteredKeys);
    }
    if (searchValueDebounce === "") {
      setHighLightedKeys([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValueDebounce === "") {
      setHighLightedKeys([]);
    }
  }, [communityKeys]);

  return (
    <Dialog data-testid={"edit-member-keys"} open={editKeysOpen}>
      <DialogTitle>{"Edit Member's Keys"}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <p>Check keys to be associated with account. Unchecked keys and children will be disabled. </p>
          <AnnouncementIcon color="error" />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          {communityKeys.filter((key) => !key.hidden).length > 4 ? (
            <TextField
              size="small"
              id="find-keys-member-detail"
              onChange={handleChangeSearch}
              label="Find Keys"
              value={searchValueDebounce}
              variant="outlined"
              fullWidth
            />
          ) : null}
        </Box>
        {communityKeys?.map((item) => {
          if (item.hidden) return;
          return (
            <Box key={item.id} sx={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-testid={"member-detail-select-key-for-edit"}
                    checked={selectedKeys[item.id] ? true : false}
                    onChange={(e) => handleCheckKey(item.id, e.target.checked)}
                  />
                }
                label={
                  <Typography sx={{ color: highLightedKeys.includes(item.id) ? nimbioPalette.purple[4] : "" }}>
                    {item.name}
                  </Typography>
                }
              />
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions sx={{ paddingBottom: "16px" }}>
        <Button variant={"outlined"} onClick={() => handleOpenEditKeys(false)}>
          Cancel
        </Button>
        <Button onClick={editKeys} variant="contained" autoFocus>
          {"Update Member's Keys"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMemberKeysDialog;
