import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircle from "@mui/icons-material/AddCircle";
import { green, grey, red } from "@mui/material/colors";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useTheme } from "@mui/material/styles";
import AlarmOffIcon from "@mui/icons-material/AlarmOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeviceSession from "./DeviceSession";
import DeviceSetName from "./DeviceSetName";
import DeviceLatchDisplay from "./DeviceLatchDisplay";
import DeviceConnectionHistory from "./DeviceConnectionHistory";
import useConfirm from "./useConfirm";
import {
  adminDeviceCheckUpdate,
  adminDeviceRestartBox,
  adminDeviceUpdateStats,
  adminHideDevice,
  adminRevealDevice,
  distributorSetUpdate,
} from "../../../reducers/device-status-reducer";
import { call } from "../../../utilities/connection";
import store from "../../../store";
import AdminSelectAccountGroupDialog from "../../Admin/components/AdminSelectAccountGroupDialog";
import DeviceSetIgnoreAlertsUntil from "../../common/Components/DeviceSetIgnoreAlertsUntil";
import { deleteBoxFromAccountGroup } from "../../../reducers/admin-get-boxes-account-groups";
import { deleteVendorFromBox } from "../../../reducers/admin-get-boxes-vendors";
import AdminSelectVendorDialog from "../../Admin/components/AdminSelectVendorDialog";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";

const styles = (theme) => ({
  card: {
    flexDirection: "column",
    display: "flex",
    minWidth: 300,
    maxWidth: 550,
    flex: 1,
    m: 1,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatarOffline: {
    backgroundColor: grey[500],
  },
  avatarOnline: {
    backgroundColor: green[400],
  },
  avatarOpen: {
    backgroundColor: green[900],
  },
  avatarDidNotOpen: {
    backgroundColor: red[900],
  },
});

function ExpandIcon({ expanded }) {
  if (expanded) {
    return (
      <IconButton>
        <ExpandLess />
      </IconButton>
    );
  }
  return (
    <IconButton>
      <ExpandMore />
    </IconButton>
  );
}

function VendorItem({ vendor, boxId }) {
  const dispatch = useDispatch();
  const { ConfirmDialog, openDialog } = useConfirm();
  return (
    <Box data-testid={"vendor-item"}>
      <Grid container key={vendor.vendor_uuid + boxId} alignItems="center" justifyContent="center">
        <Grid item xs={11}>
          <Typography variant="body2" color="textSecondary">
            {vendor.vendor_name}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            style={{ padding: 4 }}
            onClick={() => {
              openDialog({
                heading: "Remove Vendor",
                message: "Are you sure you want to remove this vendor from this device?",
                okButton: "Remove",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteVendorFromBox(boxId, vendor.vendor_uuid));
                },
              });
            }}
            data-testid={"delete-vendor"}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ConfirmDialog />
    </Box>
  );
}

function AccountGroupItem({ accountGroup, boxId }) {
  const dispatch = useDispatch();
  const { ConfirmDialog, openDialog } = useConfirm();
  return (
    <Box data-testid={"account-group-item"}>
      <Grid
        container
        key={accountGroup.account_group_id + accountGroup.box_id}
        alignItems="center"
        justifyContent="center">
        <Grid item={true} xs={11}>
          <Typography variant="body2" color="textSecondary">
            {accountGroup.name}
          </Typography>
        </Grid>
        <Grid item={true} xs={1}>
          <IconButton
            style={{ padding: 4 }}
            onClick={() => {
              openDialog({
                heading: "Remove Account Group",
                message: "Are you sure you want to remove this account group from this device?",
                okButton: "Remove",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteBoxFromAccountGroup(accountGroup.account_group_id, boxId));
                },
              });
            }}
            data-testid={"delete-account-group"}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ConfirmDialog />
    </Box>
  );
}

const DeviceItem = (props) => {
  const [accountGroupsOpen, setAccountGroupsOpen] = useState(false);
  const [vendorsOpen, setVendorsOpen] = useState(false);
  const [accountGroupDialogOpen, setAccountGroupDialogOpen] = useState(false);
  const [vendorsDialogOpen, setVendorsDialogOpen] = useState(false);
  const [ignoreAlertsOpen, setIgnoreAlertsOpen] = useState(false);
  const [sessionsOpen, setSessionsOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [latchesOpen, setLatchesOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [updatedBox, setUpdatedBox] = useState(false);

  const theme = useTheme();
  const updateType = false;

  const responseHandler = async (message) => {
    message = message.argsList[0];
    if ("a" in message) {
      switch (message.a) {
        case "box_update":
          updateBox();
          break;
        case "opened":
          store.dispatch(distributorSetUpdate(message.box_id, "opened"));
          break;
        case "did_not_open":
          store.dispatch(distributorSetUpdate(message.box_id, "did_not_open"));
          break;
        default:
          // eslint-disable-next-line no-console
          console.log("<B>Message received and not handled", message);
      }
    }
  };

  useSubscription(`${nimbioServer.internal.device.statusUpdate}.` + props.device.box, responseHandler);

  const updateBox = async () => {
    const result = await call(nimbioServer.distributor.box.getStatus, props.device.box, "DeviceItem");
    setUpdatedBox(result);
  };

  const _last_ping = (last_ping) => {
    if (updateType === "opened") {
      return "Opening";
    }
    if (updateType === "did_not_open") {
      return "Did Not Open";
    }
    if (last_ping === null) {
      return "Waiting To connect";
    }
    return "Last ping " + moment().subtract(last_ping, "seconds").fromNow();
  };

  const _avatar_class = (device) => {
    if (props.device.box in props.box_update) {
      if (props.box_update[props.device.box] === "opened") {
        return styles(theme).avatarOpen;
      }
      if (props.box_update[props.device.box] === "did_not_open") {
        return styles(theme).avatarDidNotOpen;
      }
    }
    if (device.offline) {
      return styles(theme).avatarOffline;
    }
    return styles(theme).avatarOnline;
  };

  const _handleMenuPress = (event) => {
    if (!menuAnchor) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const _handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const _restartBox = () => {
    props.dispatch(adminDeviceRestartBox(props.device.box));
    _handleMenuClose();
  };

  const _checkUpdate = () => {
    props.dispatch(adminDeviceCheckUpdate(props.device.box));
    _handleMenuClose();
  };

  const _hideBox = () => {
    props.dispatch(adminHideDevice(props.device.box));
    _handleMenuClose();
  };

  const _showBox = () => {
    props.dispatch(adminRevealDevice(props.device.box));
    _handleMenuClose();
  };

  const _updateStats = () => {
    props.dispatch(adminDeviceUpdateStats(props.device.box));
    _handleMenuClose();
  };

  const _nameOpen = () => {
    setNameDialogOpen(true);
    setMenuAnchor(null);
  };

  const _nameClose = () => {
    setNameDialogOpen(false);
  };

  const _latchesOpen = () => {
    setLatchesOpen(true);
    setMenuAnchor(null);
  };

  const _latchesClose = () => {
    setLatchesOpen(false);
  };

  const _showLatches = () => {
    _latchesOpen();
  };

  const openHistory = () => {
    setHistoryOpen(true);
    setMenuAnchor(null);
  };

  const closeHistory = () => {
    setHistoryOpen(false);
  };

  const showHistory = () => {
    // props.dispatch(adminDeviceGetConnectionHistory(props.device.box, 0, 10));
    openHistory();
  };

  const _selectAccountGroupOpen = () => {
    setAccountGroupDialogOpen(true);
    setMenuAnchor(false);
  };

  const _selectVendorsOpen = () => {
    setVendorsDialogOpen(true);
    setMenuAnchor(false);
  };

  const _handleAcountGroupsOpen = () => {
    setAccountGroupsOpen((oldValue) => !oldValue);
  };

  const _handleVendorsOpen = () => {
    setVendorsOpen((oldValue) => !oldValue);
  };

  const _handleIgnoreAlertsOpen = () => {
    setIgnoreAlertsOpen(true);
  };
  const _handleIgnoreAlertsClose = () => {
    setIgnoreAlertsOpen(false);
  };
  const _copyBoxId = () => {
    navigator.clipboard.writeText(props.device.box);
  };

  const accountGroupsByBox = props.is_admin
    ? props.accountGroups?.filter((group) => {
        return group.box_id === props.device.box;
      })
    : [];

  const vendorsByBox = props.is_admin
    ? props.vendors?.filter((vendor) => {
        return vendor.box_id === props.device.box;
      })
    : [];

  const _menuList = () => {
    if (props.type === "revealed") {
      return (
        <div data-testid={"device-menu"}>
          <MenuItem onClick={_nameOpen} data-testid={"name"}>
            Set Box Name
          </MenuItem>
          <MenuItem onClick={_selectVendorsOpen} data-testid={"vendor"}>
            Add Vendor
          </MenuItem>
          <MenuItem onClick={_selectAccountGroupOpen} data-testid={"account-group"}>
            Add Account Group
          </MenuItem>
          <MenuItem onClick={_hideBox} data-testid={"hide"}>
            Hide
          </MenuItem>
          <MenuItem onClick={_updateStats} data-testid={"update"}>
            Update Stats
          </MenuItem>
          <MenuItem onClick={showHistory} data-testid={"connection-history"}>
            Show Connection History
          </MenuItem>
          <MenuItem onClick={_showLatches} data-testid={"latches"}>
            Show Latches
          </MenuItem>
          <Divider />
          <MenuItem onClick={_restartBox} data-testid={"restart"}>
            Restart
          </MenuItem>
          <MenuItem onClick={_checkUpdate} data-testid={"check"}>
            Check Update
          </MenuItem>
          <MenuItem onClick={_handleIgnoreAlertsOpen} data-testid={"ignore"}>
            Ignore Alerts Until
          </MenuItem>
          <MenuItem onClick={_copyBoxId} data-testid={"copy"}>
            <ContentCopyIcon sx={{ marginRight: 1 }} />
            Box ID
          </MenuItem>
        </div>
      );
    }
    if (props.type === "hidden") {
      return (
        <div>
          <MenuItem onClick={_nameOpen}>Set Box Name</MenuItem>
          <MenuItem onClick={_selectVendorsOpen}>Add Vendor</MenuItem>
          <MenuItem onClick={_selectAccountGroupOpen}>Add Account Group</MenuItem>
          <MenuItem onClick={_showBox}>Show</MenuItem>
          <MenuItem onClick={_updateStats}>Update Stats</MenuItem>
          <MenuItem onClick={_showLatches}>Show Latches</MenuItem>
          <Divider />
          <MenuItem onClick={_restartBox}>Restart</MenuItem>
          <MenuItem onClick={_checkUpdate}>Check Update</MenuItem>
          <MenuItem onClick={_handleIgnoreAlertsOpen}>Ignore Alerts Until</MenuItem>
          <MenuItem onClick={_copyBoxId}>
            <ContentCopyIcon sx={{ marginRight: 1 }} />
            Box ID
          </MenuItem>
        </div>
      );
    }
    if (props.type === "recent") {
      return (
        <div>
          <MenuItem onClick={_nameOpen}>Set Box Name</MenuItem>
          <MenuItem onClick={_selectVendorsOpen}>Add Vendor</MenuItem>
          <MenuItem onClick={_selectAccountGroupOpen}>Add Account Group</MenuItem>
          <MenuItem onClick={_updateStats}>Update Stats</MenuItem>
          <MenuItem onClick={_showLatches}>Show Latches</MenuItem>
          <Divider />
          <MenuItem onClick={_restartBox}>Restart</MenuItem>
          <MenuItem onClick={_checkUpdate}>Check Update</MenuItem>
          <MenuItem onClick={_handleIgnoreAlertsOpen}>Ignore Alerts Until</MenuItem>
          <MenuItem onClick={_copyBoxId}>
            <ContentCopyIcon sx={{ marginRight: 1 }} />
            Box ID
          </MenuItem>
        </div>
      );
    }
    if (props.type === "offline") {
      return (
        <div>
          <MenuItem onClick={_nameOpen}>Set Box Name</MenuItem>
          <MenuItem onClick={_selectVendorsOpen}>Add Vendor</MenuItem>
          <MenuItem onClick={_selectAccountGroupOpen}>Add Account Group</MenuItem>
          <MenuItem onClick={_updateStats}>Update Stats</MenuItem>
          <MenuItem onClick={_showLatches}>Show Latches</MenuItem>
          <Divider />
          <MenuItem onClick={_restartBox}>Restart</MenuItem>
          <MenuItem onClick={_checkUpdate}>Check Update</MenuItem>
          <MenuItem onClick={_handleIgnoreAlertsOpen}>Ignore Alerts Until</MenuItem>
          <MenuItem onClick={_copyBoxId}>
            <ContentCopyIcon sx={{ marginRight: 1 }} />
            Box ID
          </MenuItem>
        </div>
      );
    }
  };

  const _more_button = () => {
    if (props.is_admin) {
      return (
        <IconButton aria-label="Device More" size="large" onClick={_handleMenuPress} data-testid={"more-button"}>
          <MoreVertIcon />
          <Menu
            id="settings-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={_handleMenuClose}>
            {_menuList()}
          </Menu>
        </IconButton>
      );
    } else {
      return;
    }
  };

  const _avatar_header = (device) => {
    if (!device.name) {
      return (
        <CardHeader
          avatar={
            <Avatar aria-label="device" sx={_avatar_class(device)}>
              ?
            </Avatar>
          }
          action={_more_button()}
          title="Unnamed"
          subheader={_last_ping(device.last_ping_ago)}
        />
      );
    }
    return (
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" sx={_avatar_class(device)}>
            {device.name.substring(0, 1)}
          </Avatar>
        }
        action={_more_button()}
        title={device.name}
        subheader={_last_ping(device.last_ping_ago)}
      />
    );
  };

  const _handleSessionOpenChange = () => {
    setSessionsOpen((oldValue) => !oldValue);
  };

  if (props.type === "hidden" && updatedBox && !updatedBox.hidden) {
    return null;
  }

  if (props.type === "revealed" && updatedBox && updatedBox.hidden) {
    return null;
  }

  return (
    <Grid item xs={12} md={6} lg={4} xl={3} data-testid={"device-item"}>
      <Card sx={styles(theme).card}>
        {_avatar_header(!updatedBox ? props.device : updatedBox)}
        <CardContent>
          {(Object.keys(!updatedBox ? props.device.details : updatedBox.details).length > 0 && (
            <>
              <ListItem button onClick={_handleSessionOpenChange}>
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`Device Sessions (${
                    Object.keys(!updatedBox ? props.device.details : updatedBox.details).length
                  })`}
                />
                {sessionsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={sessionsOpen} timeout="auto" unmountOnExit>
                {/* <Typography variant="body2" color="textSecondary" component="p">
                    {props.device.box}
                  </Typography> */}
                {/*<Typography variant="body2" color="textSecondary" component="p">*/}

                {Object.entries(!updatedBox ? props.device.details : updatedBox.details).map((item) => {
                  return <DeviceSession session_id={item[0]} session_details={item[1]} key={item[0]} />;
                })}
                {props.box_update && props.box_updates}
                {/*</Typography>*/}
              </Collapse>
            </>
          )) || (
            <ListItem>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText secondary="Session Data N/A" />
            </ListItem>
          )}
          {props.is_admin
            ? (vendorsByBox?.length > 0 && (
                <>
                  <ListItem onClick={_handleVendorsOpen} data-testid={"expand-vendors"}>
                    <ListItemIcon>
                      <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText secondary={`Vendors (${vendorsByBox.length})`} />
                    <ExpandIcon expanded={vendorsOpen} />
                  </ListItem>

                  <Collapse in={vendorsOpen} timeout="auto" unmountOnExit>
                    {vendorsByBox.map((vendor) => {
                      return (
                        <VendorItem vendor={vendor} boxId={props.device.box} key={vendor.vendor_uuid + vendor.box_id} />
                      );
                    })}
                  </Collapse>
                </>
              )) || (
                <ListItem onClick={_selectVendorsOpen}>
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText secondary="Vendors (0)" />
                  <IconButton onClick={_selectVendorsOpen}>
                    <AddCircle />
                  </IconButton>
                </ListItem>
              )
            : null}
          {props.is_admin
            ? (accountGroupsByBox.length > 0 && (
                <>
                  <ListItem onClick={_handleAcountGroupsOpen} data-testid={"expand-account-groups"}>
                    <ListItemIcon>
                      <EqualizerIcon />
                    </ListItemIcon>
                    <ListItemText secondary={`Account Groups (${accountGroupsByBox.length})`} />
                    <ExpandIcon expanded={accountGroupsOpen} />
                  </ListItem>

                  <Collapse in={accountGroupsOpen} timeout="auto" unmountOnExit>
                    {accountGroupsByBox.map((accountGroup) => {
                      return (
                        <AccountGroupItem
                          accountGroup={accountGroup}
                          boxId={props.device.box}
                          key={accountGroup.account_group_id + accountGroup.box_id}
                        />
                      );
                    })}
                  </Collapse>
                </>
              )) || (
                <ListItem onClick={_selectAccountGroupOpen}>
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText secondary="Account Groups (0)" />
                  <IconButton onClick={_selectAccountGroupOpen}>
                    <AddCircle />
                  </IconButton>
                </ListItem>
              )
            : null}
          {(updatedBox && updatedBox.ignore_alerts_active) || props.device.ignore_alerts_active ? (
            <>
              <ListItem>
                <AlarmOffIcon color="error" sx={{ marginRight: 1 }} />
                <ListItemText
                  secondary={
                    updatedBox && updatedBox.ignore_alerts_active
                      ? `Alerts Ignored until ${moment(updatedBox.ignore_alerts_until).format("YYYY-MM-DD")}`
                      : props.device.ignore_alerts_active
                      ? `Alerts Ignored until ${moment(props.device.ignore_alerts_until).format("YYYY-MM-DD")}`
                      : null
                  }></ListItemText>
              </ListItem>
            </>
          ) : null}

          <DeviceSetName
            box_id={props.device.box}
            name={!updatedBox ? props.device.name : updatedBox.name}
            closeCallback={_nameClose}
            open={nameDialogOpen}
          />
          <DeviceSetIgnoreAlertsUntil
            device={!updatedBox ? props.device : updatedBox}
            box_id={props.device.box}
            closeCallback={_handleIgnoreAlertsClose}
            open={ignoreAlertsOpen}
          />
          <DeviceLatchDisplay
            open={latchesOpen}
            closeCallback={_latchesClose}
            box_id={props.device.box}
            boxName={props.device.name}
          />
          {historyOpen ? (
            <DeviceConnectionHistory
              open={historyOpen}
              close={() => closeHistory()}
              box={props.device.box}
              device={props.device}
            />
          ) : null}
          {accountGroupDialogOpen && (
            <AdminSelectAccountGroupDialog
              open={accountGroupDialogOpen}
              dispatch={props.dispatch}
              box_id={props.device.box}
              onClose={() => {
                setAccountGroupDialogOpen(false);
              }}
            />
          )}
          {vendorsDialogOpen && (
            <AdminSelectVendorDialog
              open={vendorsDialogOpen}
              dispatch={props.dispatch}
              box_id={props.device.box}
              onClose={() => {
                setVendorsDialogOpen(false);
              }}
            />
          )}
        </CardContent>
        {/*<CardActions disableSpacing>*/}
        {/*  <IconButton aria-label="add to favorites" size="large">*/}
        {/*    <MoreVertIcon />*/}
        {/*  </IconButton>*/}
        {/*  <IconButton aria-label="share" size="large">*/}
        {/*    <MoreVertIcon />*/}
        {/*  </IconButton>*/}
        {/*  <IconButton*/}
        {/*    sx={styles(theme).expand}*/}
        {/*    aria-label="show more" size="large"*/}
        {/*  >*/}
        {/*    <MoreVertIcon />*/}
        {/*  </IconButton>*/}
        {/*</CardActions>*/}
      </Card>
    </Grid>
  );
};

DeviceItem.propTypes = {
  device: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    box_update: state.deviceStatus.updates,
    is_admin: state.profileState.details.is_admin,
    latches: state.deviceStatus.device_latches,
  };
};

export default connect(mapStateToProps)(DeviceItem);
