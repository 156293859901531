import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

export const initialState = {
  loading: false,
  error: false,
  result: null,
  loaded: false,
};

export const addVendorAPIKey = createAsyncAction("ADMIN_ADD_VENDOR_API_KEY", (vendor_uuid) => {
  return call(nimbioServer.admin.addVendorAPIKey, [vendor_uuid], "admin-add-vendor-api-key");
});

export const resetVendorAPIKey = createAction("ADMIN_ADD_VENDOR_API_KEY_RESET");

export default handleActions(
  {
    ADMIN_ADD_VENDOR_API_KEY_RESET: () => {
      return initialState;
    },
    ADMIN_ADD_VENDOR_API_KEY_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: null,
      };
    },
    ADMIN_ADD_VENDOR_API_KEY_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        result: payload,
        loaded: true,
      };
    },
    ADMIN_ADD_VENDOR_API_KEY_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
