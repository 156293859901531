import React from "react";
import AdminAppBar from "./AdminAppBar";
import LatchUSPSMetadata from "./LatchUSPSMetadata";

const AdminLatchViewContainer = () => {
  return (
    <AdminAppBar>
      <LatchUSPSMetadata />
    </AdminAppBar>
  );
};

export default AdminLatchViewContainer;
