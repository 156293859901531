import "react-phone-number-input/style.css";
import React, { useState, useEffect } from "react";
import { Button, Card, CardActions, CardContent, Grid, Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { addInstallerCall, clearInstallerState } from "../../../reducers/add-installer-reducer";
import PhoneNumberCustom from "../../common/Components/PhoneNumberCustom";

const styles = () => ({
  baseCard: {
    padding: 4,
  },
  root: {
    flexGrow: 1,
    padding: 1,
  },
  resultBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 2,
  },
  errorBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
  },
});

const AddInstaller = () => {
  const { result, error: serverError, errorMessage, loading } = useSelector((state) => state.addInstaller);
  const dispatch = useDispatch();
  const [phoneInput, setPhoneInput] = useState("");
  const [error, setError] = useState("");

  const _send_phone = () => {
    if (!isPossiblePhoneNumber(phoneInput)) {
      setError("Please enter a valid phone number.");
      return;
    }
    dispatch(addInstallerCall(phoneInput));
  };

  const handleClearInstallerState = () => {
    setPhoneInput("");
    dispatch(clearInstallerState());
  };

  const handlePhoneInputChange = (phone_input) => {
    setPhoneInput(phone_input);
    setError("");
  };

  useEffect(() => {
    return () => {
      handleClearInstallerState();
    };
  }, []);

  return (
    <Box>
      <Grid container sx={styles().root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-start" spacing={1}>
            <Card sx={styles().baseCard}>
              <CardContent>
                <Typography gutterBottom variant="h6" component="h5">
                  Add Installer by Phone
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Enter the phone number of the installer you would like to add. Account will be created if it does not
                  already exist.
                </Typography>
              </CardContent>
              <CardContent>
                {error ? (
                  <Box sx={styles().errorBox}>
                    <ErrorOutlineIcon sx={{ marginRight: 1 }} color={"error"} />
                    <Typography>{error}</Typography>
                  </Box>
                ) : null}
                {result && (
                  <Box sx={styles().resultBox}>
                    <CheckCircleOutlineIcon sx={{ marginRight: 1 }} color={"success"} />
                    {result.first === null && result.last === null ? (
                      <Typography>Added user as installer. First and last names are not set for account.</Typography>
                    ) : (
                      <Typography>
                        Added {result.first} {result.last} as an Installer
                      </Typography>
                    )}
                  </Box>
                )}
                {serverError && (
                  <Box sx={styles().resultBox}>
                    <ErrorOutlineIcon />
                    <Typography>{errorMessage ? errorMessage : "Internal Server Error"}</Typography>
                  </Box>
                )}
                {result || serverError ? null : (
                  <PhoneInput
                    value={phoneInput}
                    onChange={(phone_input) => handlePhoneInputChange(phone_input)}
                    defaultCountry="US"
                    label="Phone Number"
                    placeholder="Phone Number"
                    numberInputProps={{ autoFocus: true, sx: styles().phoneInput }}
                    inputComponent={PhoneNumberCustom}
                  />
                )}
              </CardContent>
              <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                {result || serverError ? (
                  <Button variant="contained" onClick={handleClearInstallerState}>
                    Ok
                  </Button>
                ) : (
                  <Button variant="contained" onClick={_send_phone} disabled={loading || error ? true : false}>
                    Add Installer
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddInstaller;
