import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const HomeSelect = (props) => {
  const [homesList, setHomesList] = useState([]);
  const homes = useSelector((state) => {
    return state.homesState.homes;
  });

  useEffect(() => {
    if (!Array.isArray(homes)) {
      setHomesList([]);
      return;
    }

    // Sort the homes.
    const sortedHomes = homes.sort((a, b) => {
      if (a.address < b.address) {
        return -1;
      }
      if (a.address > b.address) {
        return 1;
      }
      return 0;
    });

    // Set the homes list.
    setHomesList(sortedHomes);
  }, [homes]);

  return (
    <FormControl variant="standard" sx={styles().selectBox}>
      <Select
        labelId="home-label-placeholder-label"
        id="home-label-placeholder"
        value={props.homeID ? props.homeID : ""}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event.target.value);
          }
        }}
        label="Home">
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {homesList.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.address}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const styles = () => ({
  selectBox: {
    width: "100%",
  },
});

export default HomeSelect;
