import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";

class ReconnectingDialog extends React.Component {
  render() {
    return (
      <Dialog open={this.props.open} disableEscapeKeyDown={true}>
        <div
          style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: 32, marginBottom: 16 }}>
          <CircularProgress />
          {/*<span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>*/}
        </div>
        <DialogTitle>Reconnecting</DialogTitle>
        <DialogContent>
          We are trying to reconnect
          <br />
          {/*Try {this.props.retryCount}*/}
        </DialogContent>
      </Dialog>
    );
  }
}

export default ReconnectingDialog;
