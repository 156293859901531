export function generalSubscription(message) {
  message = message.argsList[0];
  if ("a" in message) {
    switch (message.a) {
      case "READ_ONLY_MODE_OFF":
        window.location.reload();
        break;
      default:
        console.log("<B>Message received and not handled", message);
    }
  }
}
