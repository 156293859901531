import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { adminCommunitySetKeyName } from "../../../reducers/admin-community-list";

const ChangeKeyName = (props) => {
  const [keyName, setKeyName] = useState(props.key_name ? props.key_name : "");

  const _setKeyName = () => {
    props.dispatch(adminCommunitySetKeyName(props.key_id, keyName, props.community_id));
    props.closeCallback();
  };

  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Key Name</DialogTitle>
      <DialogContent>
        <DialogContentText>Change the key name below</DialogContentText>
        <TextField
          data-testid="change-key-name-input"
          autoFocus
          margin="dense"
          id="key_name"
          label="Key Name"
          type="text"
          fullWidth
          value={keyName}
          onChange={(event) => setKeyName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeCallback}>Cancel</Button>
        <Button onClick={_setKeyName}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeKeyName.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  key_name: PropTypes.string.isRequired,
  key_id: PropTypes.string.isRequired,
};

export default connect()(ChangeKeyName);
