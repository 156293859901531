import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AdminCreateCommunityKey from "./AdminCreateCommunityKey";
import AdminCommunityKey from "./AdminCommunityKey";

const AdminCommunityKeys = (props) => {
  const [addKeyDialogOpen, setAddKeyDialogOpen] = useState(false);

  const openAddKeyDialog = () => {
    setAddKeyDialogOpen(true);
  };

  const closeAddKeyDialog = () => {
    setAddKeyDialogOpen(false);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary" component="p">
          Community Keys
        </Typography>
        <IconButton onClick={openAddKeyDialog}>
          <AddIcon />
        </IconButton>
      </Grid>

      {props.community_keys?.length > 0 ? (
        props.community_keys.map((key) => <AdminCommunityKey type={props.type} key={key.id} community_key={key} />)
      ) : (
        <Typography style={{ marginTop: 8 }} variant="body2" color="textSecondary" component="p">
          No keys for this community
        </Typography>
      )}
      <AdminCreateCommunityKey open={addKeyDialogOpen} onClose={closeAddKeyDialog} />
    </>
  );
};

AdminCommunityKeys.propTypes = {
  community_keys: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
};

export default AdminCommunityKeys;
