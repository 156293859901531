import React from "react";
import PropTypes from "prop-types";
import { Box, Switch, FormControlLabel, Typography } from "@mui/material";
import { connect } from "react-redux";
import { adminCommunitySetAllowHoldOpens } from "../../../reducers/admin-community-list";

const CommunityAllowHoldOpens = (props) => {
  return (
    <Box sx={{ m: 1 }}>
      <Box direction="row" alignItems="center">
        <Typography sx={{ fontFamily: "Filson" }} variant="body2" color="textSecondary" component="p">
          Hold Opens
        </Typography>
      </Box>
      <Box direction="row" alignItems="center">
        <FormControlLabel
          control={<Switch checked={Boolean(props.allow_hold_opens)} />}
          label={<Typography sx={{ fontFamily: "Filson" }}>Allow Hold Opens</Typography>}
          labelPlacement="start"
          onClick={() => props.dispatch(adminCommunitySetAllowHoldOpens(props.community_id, !props.allow_hold_opens))}
        />
      </Box>
    </Box>
  );
};

CommunityAllowHoldOpens.propTypes = {
  allow_hold_opens: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunityAllowHoldOpens);
