import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";
import { useParams } from "react-router";
import { getCommunities, setCurrentCommunity } from "../../reducers/communities-reducer";

const ManagerCheck = () => {
  const { communityId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const communityState = useSelector((state) => state.communityState);

  useEffect(() => {
    if (!communityState?.communitiesLoaded) {
      dispatch(getCommunities());
    }
  }, [dispatch, communityState?.communitiesLoaded]);

  useEffect(() => {
    dispatch(setCurrentCommunity(parseInt(communityId)));
  }, [communityId, dispatch, communityState?.communitiesLoaded]);

  useEffect(() => {
    if (communityState.communitiesLoaded) {
      if (parseInt(communityState.currentCommunityID) === parseInt(communityId)) {
        if (!communityState.loading && communityState.currentCommunityIDLoaded && !communityState.currentCommunity) {
          navigate("/pick-community");
        }
      }
    }
  }, [communityState, communityId, navigate]);

  return <Outlet />;
};

export default ManagerCheck;
