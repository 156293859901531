import React from "react";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog } from "@mui/material";

type ConfirmDialogProps = {
  open: boolean;
  heading?: string;
  message?: string;
  okButton?: string;
  cancelButton?: string;
  closeCallback?: () => void;
  confirmCallback?: () => void;
};
const ConfirmDialog = ({
  open,
  heading = "Are you sure?",
  message = "Are you sure you want to do this?",
  okButton = "Yes",
  cancelButton = "No",
  closeCallback,
  confirmCallback,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={closeCallback} aria-labelledby="form-dialog-title" data-testid={"confirm-dialog"}>
      <DialogTitle id="form-dialog-title">{heading}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeCallback} color="primary">
          {cancelButton}
        </Button>
        <Button variant="contained" onClick={confirmCallback} color="primary" data-testid={"confirm-dialog-ok"}>
          {okButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type OpenDialogProps = {
  heading: string;
  message: string;
  okButton: string;
  cancelButton: string;
  closeCallback: () => void;
  confirmCallback: () => void;
};

const useConfirm = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [heading, setHeading] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [okButton, setOkButton] = React.useState<string>("");
  const [cancelButton, setCancelButton] = React.useState<string>("");
  const [closeCallback, setCloseCallback] = React.useState<() => void>(() => {});
  const [confirmCallback, setConfirmCallback] = React.useState<() => void>(() => {});

  const openDialog = React.useCallback((params: OpenDialogProps) => {
    const { heading, message, okButton, cancelButton, closeCallback, confirmCallback } = params;
    setHeading(heading);
    setMessage(message);
    setOpen(true);
    setOkButton(okButton);
    setCancelButton(cancelButton);
    setCloseCallback(() => closeCallback);
    setConfirmCallback(() => confirmCallback);
  }, []);

  return {
    openDialog,
    ConfirmDialog: () => {
      return (
        <ConfirmDialog
          open={open}
          heading={heading}
          message={message}
          okButton={okButton}
          cancelButton={cancelButton}
          closeCallback={() => {
            setOpen(false);
            if (closeCallback) {
              closeCallback();
            }
          }}
          confirmCallback={() => {
            setOpen(false);
            if (confirmCallback) {
              confirmCallback();
            }
          }}
          data-testid={"confirm-dialog"}
        />
      );
    },
  };
};

export default useConfirm;
