import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { connect, useDispatch, useSelector } from "react-redux";
import { removeMember } from "../../../reducers/memberdetail-reducer";
import { closeRemovePendingMember } from "../../../reducers/pending-member-detail-reducer";

const RemovePending = (props) => {
  const dispatch = useDispatch();
  const pendingMemberDetailState = useSelector((state) => state.pendingMemberDetailState);
  const phoneNumbers = pendingMemberDetailState.details["phone_numbers"];
  const firstName = pendingMemberDetailState.details["first_name"];
  const lastName = pendingMemberDetailState.details["last_name"];
  const created_datetime = pendingMemberDetailState.details["created_datetime"];
  const open = pendingMemberDetailState.removePendingMemberOpen;
  const account_community_id = pendingMemberDetailState.details["account_community_id"];

  const handleRemove = () => {
    dispatch(removeMember(account_community_id, [], props.community_id));
    handleClose();
  };

  const handleClose = () => {
    dispatch(closeRemovePendingMember());
  };

  return (
    <Box>
      <Dialog onClose={handleClose} open={open} data-testid={"remove-pending-dialog"}>
        <DialogTitle>Remove Pending Member</DialogTitle>
        <DialogContent>
          {phoneNumbers && phoneNumbers.length > 0 ? (
            <DialogContentText>
              Remove{" "}
              <b>
                {firstName} {lastName}
              </b>{" "}
              with a confirmed phone number of<b> {phoneNumbers[0]}</b> from your community?
            </DialogContentText>
          ) : (
            <DialogContentText>
              Warning: This person has no confirmed phone number. Remove {firstName} {lastName} to your community?
            </DialogContentText>
          )}
          <DialogContentText sx={{ mt: 2, mb: 2 }}>Requested on {created_datetime}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleClose}>
            Cancel
          </Button>
          <Button variant={"contained"} onClick={handleRemove} autoFocus data-testid={"remove-pending-confirm"}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(RemovePending);
