import React from "react";
import PropTypes from "prop-types";
import { Avatar, Badge, Button, CardHeader, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import EventTime from "./EventTime";
import { getColor } from "../../../utilities/materialAvatar";
import { removeHoldOpenEvent } from "../../../reducers/hold-open-reducers";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
  },
  eventNote: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(2),
  },
});

const OneTimeEvent = (props) => {
  const theme = useTheme();

  const _remove_event = () => {
    props.dispatch(removeHoldOpenEvent(props.community_id, props.latch_id, props.event_datetime_id));
  };

  if (props.end.split("-")[0] === "9990") {
    return;
  }

  return (
    <Grid data-testid={"one-time-event"} key={props.key} item xs={12} style={{ marginTop: 15 }}>
      <CardHeader
        avatar={
          <Badge
            data-testid={"one-time-event-active-badge"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            color="secondary"
            badgeContent="Active"
            invisible={!props.active}>
            <Avatar aria-label="recipe" style={{ backgroundColor: getColor(props.event_datetime_id) }}>
              E
            </Avatar>
          </Badge>
        }
        title={"One Time Hold Open"} // Id: xxx"+props.latch_id.slice(-4)}
        subheader={
          props.disabled_active ? "Disabled" : props.active ? "Active" : props.expired ? "Expired" : "Inactive"
        }
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 7 }}>
        <>
          <EventTime date={props.start} label="Start" />
          <EventTime date={props.end} label="End" />
        </>
      </Grid>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
        <Grid item xs={3}>
          <Button size="small" variant="outlined" onClick={_remove_event}>
            Remove
          </Button>
          {false && !props.expired && (
            <Button size="small" variant="outlined">
              Modify
            </Button>
          )}
        </Grid>
        <Grid item xs={9}>
          <Typography sx={styles(theme).eventNote}>This will be automatically removed after 7 days.</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

OneTimeEvent.propTypes = {
  event_datetime_id: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  expired: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
  disabled_active: PropTypes.bool.isRequired,
};

export default connect()(OneTimeEvent);
