import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const labelValues = ["include_in_tech_report", "include_in_delivery_report", "full_exclude"];

const getLabelValue = (value) => {
  if (value === "include_in_tech_report") return "Include In Tech Report";
  if (value === "include_in_delivery_report") return "Include In Delivery Report";
  if (value === "full_exclude") return "Full Exclude";
  return "";
};

export const LatchViewFormControlButtons = ({
  userModifiedMetaData,
  initialMetadata,
  changedMetadataOnServerSinceLoad,
  handleOnChange,
}) => {
  const [techReportChecked, setTechReportChecked] = useState(false);
  const [deliveryReportChecked, setDeliveryReportChecked] = useState(false);
  const [fullExcludeChecked, setFullExcludeChecked] = useState(false);

  useEffect(() => {
    // include_in_tech_report
    if (typeof userModifiedMetaData?.[labelValues[0]] === "boolean")
      setTechReportChecked(userModifiedMetaData?.[labelValues[0]]);
    else if (typeof changedMetadataOnServerSinceLoad?.[labelValues[0]] === "boolean")
      setTechReportChecked(changedMetadataOnServerSinceLoad?.[labelValues[0]]);
    else if (typeof initialMetadata?.[labelValues[0]] === "boolean")
      setTechReportChecked(initialMetadata?.[labelValues[0]]);
    // include_in_delivery_report
    if (typeof userModifiedMetaData?.[labelValues[1]] === "boolean")
      setDeliveryReportChecked(userModifiedMetaData?.[labelValues[1]]);
    else if (typeof changedMetadataOnServerSinceLoad?.[labelValues[1]] === "boolean")
      setDeliveryReportChecked(changedMetadataOnServerSinceLoad?.[labelValues[1]]);
    else if (typeof initialMetadata?.[labelValues[1]] === "boolean")
      setDeliveryReportChecked(initialMetadata?.[labelValues[1]]);
    // full_exclude
    if (typeof userModifiedMetaData?.[labelValues[2]] === "boolean")
      setFullExcludeChecked(userModifiedMetaData?.[labelValues[2]]);
    else if (typeof changedMetadataOnServerSinceLoad?.[labelValues[2]] === "boolean")
      setFullExcludeChecked(changedMetadataOnServerSinceLoad?.[labelValues[2]]);
    else if (typeof initialMetadata?.[labelValues[2]] === "boolean")
      setFullExcludeChecked(initialMetadata?.[labelValues[2]]);
  }, [userModifiedMetaData, changedMetadataOnServerSinceLoad, initialMetadata]);

  return (
    <Box style={{ display: "flex", marginTop: 5, justifyContent: "space-evenly" }}>
      <FormGroup sx={{ flexDirection: "row", flex: 1, justifyContent: "space-around" }}>
        <FormControlLabel
          control={<Checkbox />}
          checked={techReportChecked}
          value={!techReportChecked}
          label={getLabelValue(labelValues[0])}
          name={labelValues[0]}
          onChange={handleOnChange}
        />
        <FormControlLabel
          control={<Checkbox />}
          checked={deliveryReportChecked}
          value={!deliveryReportChecked}
          label={getLabelValue(labelValues[1])}
          name={labelValues[1]}
          onChange={handleOnChange}
        />
        <FormControlLabel
          control={<Checkbox />}
          checked={fullExcludeChecked}
          value={!fullExcludeChecked}
          label={getLabelValue(labelValues[2])}
          name={labelValues[2]}
          onChange={handleOnChange}
        />
      </FormGroup>
    </Box>
  );
};
