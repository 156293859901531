import React, { useEffect } from "react";
import { connect } from "react-redux";
import AdminAppBar from "./AdminAppBar";
import useSubscription from "../../../utilities/hooks/useSubscription";
import nimbioServer from "../../../server/endpoints";
import { adminGetDeviceStatusPaginated } from "../../../reducers/device-status-reducer";
import DeviceList from "../../common/Components/DeviceList";

import { deviceStatusSubscription } from "../../../subscriptions/deviceStatusSubscription";

const AdminRecentDeviceMonitor = (props) => {
  useSubscription(nimbioServer.internal.device.newBox, deviceStatusSubscription);

  useEffect(() => {
    props.dispatch(adminGetDeviceStatusPaginated(1, 24, "recent"));
  }, []);

  return (
    <AdminAppBar selected="Recent Devices" {...props}>
      <DeviceList type="recent" />
    </AdminAppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    deviceMonitor: state.deviceStatus,
  };
};

export default connect(mapStateToProps)(AdminRecentDeviceMonitor);
