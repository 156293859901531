import React, { useEffect } from "react";
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getVendors, sendAccountToVendorData, sendVendorToBoxData } from "../../../reducers/admin-get-vendors";
import { sendVendorToLatchData } from "../../../reducers/admin-get-latches-vendors";

const AdminSelectVendorDialog = ({ account_id, box_id, latch_id, open, onClose }) => {
  const dispatch = useDispatch();

  const vendors = useSelector((state) => {
    return state.getVendorsReducer?.results?.result || [];
  });

  function handleSave(vendor_uuid) {
    if (box_id) {
      dispatch(sendVendorToBoxData(box_id, vendor_uuid));
      return;
    }
    if (latch_id) {
      dispatch(sendVendorToLatchData(latch_id, vendor_uuid));
      return;
    }
    if (account_id) {
      dispatch(sendAccountToVendorData(account_id, vendor_uuid));
    }
  }

  useEffect(() => {
    dispatch(getVendors());
  }, [dispatch, open]);

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="get-vendors-dialog-title"
      aria-describedby="get-vendors-dialog-description"
      data-testid={"add-vendor"}>
      <DialogTitle>Select a Vendor</DialogTitle>
      <DialogContent>
        <Grid container>
          {vendors.map((vendor) => {
            return (
              <Grid item xs={12} key={vendor.vendor_uuid}>
                <Button
                  onClick={() => {
                    handleSave(vendor.vendor_uuid);
                    onClose();
                  }}>
                  {vendor.vendor_name}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminSelectVendorDialog;
