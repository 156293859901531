import React from "react";
import DeviceItem from "./DeviceItem";

const Devices = ({ error, loading, devices, type, accountGroups, vendors }) => {
  return (
    <>
      {!error && !loading && devices && Array.isArray(devices) && devices.length > 0
        ? devices.map((device) => {
            return (
              <DeviceItem
                device={device}
                key={device.box}
                type={type}
                accountGroups={accountGroups}
                vendors={vendors.result}
              />
            );
          })
        : null}
    </>
  );
};

export default Devices;
