import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import useConfirm from "./useConfirm";
import { getVendorsByAccount, deleteAccountFromVendors } from "../../../reducers/admin-get-vendors-by-account";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";

function Vendors({ account_id }) {
  const dispatch = useDispatch();
  const { ConfirmDialog, openDialog } = useConfirm();

  const vendors = useSelector((state) => {
    return state.getVendorsByAccountReducer.results;
  });

  useSubscription(nimbioServer.vendor.vendorToAccount, () => dispatch(getVendorsByAccount(account_id)));

  useEffect(() => {
    dispatch(getVendorsByAccount(account_id));
  }, [dispatch, account_id]);

  const columns = [
    { field: "vendor_name", flex: 1, headerName: "Vendor", sortable: false, filterable: false },
    {
      field: "actions",
      width: 100,
      headerName: "",
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              openDialog({
                heading: "Remove Vendor",
                message: "Are you sure you want to remove this vendor from this account?",
                okButton: "Remove",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteAccountFromVendors(params.row.vendor_uuid, params.row.account_id));
                },
              });
            }}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: red[500],
          },
          ".MuiDataGrid-filterIcon": {
            color: red[500],
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-menuIconButton": {
            color: red[500],
          },
        }}
        getRowId={(row) => {
          return `${row.vendor_uuid}-${row.account_id}`;
        }}
        rows={vendors}
        columns={columns}
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        disableColumnFilter={true}
        disableColumnReorder={true}
        disableColumnResize={true}
        disableDensitySelector={true}
        disableExtendRowFullWidth={true}
        disableMultipleColumnsFiltering={true}
        disableMultipleSelection={true}
        disableSelectionOnRowClick={true}
        disableSelectionOnRowDrag={true}
        disableSortBy={true}
        onRowClick={() => {
          //
        }}
      />
      <ConfirmDialog />
    </>
  );
}

export default Vendors;
