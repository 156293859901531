import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/SaveAlt";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import moment from "moment";
import { nimbioPalette } from "../../../theme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headers = [
  { label: "Key Name", key: "key_name" },
  { label: "Latch Name", key: "latch_name" },
  { label: "Date & Time", key: "datetime" },
  { label: "Description", key: "open_desc" },
  { label: "Result", key: "open_result" },
  { label: "User", key: "user" },
];

export function NimbioAsyncCSVExporter({ fetchData }) {
  const [csvData, setCsvData] = useState(null);
  const [open, setOpen] = React.useState(false);
  const now = moment().format("MM-DD-YYYY");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setOpen(false);
      setCsvData(null);
    }
  };

  const getData = async () => {
    const data = await fetchData();
    setCsvData(data);
  };

  useEffect(() => {
    if (open) getData();
  }, [open]);

  return (
    <>
      <Button
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ":hover": { backgroundColor: "rgba(114, 112, 252, 0.04)" },
          padding: "4px 5px",
          boxShadow: "border-box",
          borderRadius: "4px",
          userSelect: "none",
        }}
        onClick={handleClickOpen}>
        <DownloadIcon sx={{ p: "1px", mr: "3px" }} />
        EXPORT CSV
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ color: "primary" }}>{"Generating CSV Export"}</DialogTitle>
        <>
          {!csvData ? (
            <>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}></Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </>
          ) : (
            <DialogContent>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <DialogContentText id="alert-dialog-slide-description" sx={{ mb: 1 }}>
                  Your Export Is Ready
                </DialogContentText>
                <Button
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ":hover": { backgroundColor: "rgba(114, 112, 252, 0.04)" },
                    boxShadow: "border-box",
                    borderRadius: "4px",
                    userSelect: "none",
                  }}>
                  <CSVLink
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      userSelect: "none",
                      color: nimbioPalette.purple[4],
                    }}
                    headers={headers}
                    onClick={handleClose}
                    data={csvData ? csvData : []}
                    filename={`Nimbio-Access-Logs-${now}.csv`}>
                    <DownloadIcon sx={{ p: "1px", mr: "3px" }} />
                    DOWNLOAD CSV
                  </CSVLink>
                </Button>
                <Button sx={{ mt: 5 }} onClick={handleClose}>
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          )}
        </>
      </Dialog>
    </>
  );
}
