import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TextField } from "@mui/material";
import { addVendorContact } from "../../../reducers/admin-get-vendor-contacts";

const AdminVendorContactDialog = ({ vendorId, contact, open, onClose }) => {
  const dispatch = useDispatch();

  const [name, setName] = React.useState(contact?.name);
  const [address, setAddress] = React.useState(contact?.address);
  const [phone, setPhone] = React.useState(contact?.phone);

  const [nameError, setNameError] = React.useState(false);
  const [addressError, setAddressError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);

  function handleSave() {
    // Make sure we have a vendorId AND there is a name, address, or phone at least
    // trim the values
    if (!vendorId || (!name && !address && !phone)) {
      setNameError(true);
      setAddressError(true);
      setPhoneError(true);
      return false;
    }

    if (name?.trim().length === 0 && address?.trim().length === 0 && phone?.trim().length === 0) {
      setName("");
      setAddress("");
      setPhone("");

      setNameError(true);
      setAddressError(true);
      setPhoneError(true);

      return false;
    }

    dispatch(addVendorContact(contact?.vendor_contact_uuid, vendorId, name?.trim(), address?.trim(), phone?.trim()));
    return true;
  }

  return (
    <Dialog
      id="vendor-contact-dialog"
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{
        onClose,
      }}
      data-testid={"admin-vendor-contact-dialog"}>
      <DialogTitle>Edit Vendor Contact</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>Name</Typography>
          <TextField
            id="vendor-contact-dialog-name"
            variant="outlined"
            fullWidth
            value={name}
            error={nameError}
            defaultValue={name}
            onChange={(event) => {
              setName(event.target.value);
            }}
            data-testid={"admin-vendor-contact-dialog-name"}
          />
        </Box>
        <Box>
          <Typography>Address</Typography>
          <TextField
            id="vendor-contact-dialog-address"
            variant="outlined"
            fullWidth
            value={address}
            error={addressError}
            defaultValue={address}
            onChange={(event) => {
              setAddress(event.target.value);
            }}
            data-testid={"admin-vendor-contact-dialog-address"}
          />
        </Box>
        <Box>
          <Typography>Phone</Typography>
          <TextField
            id="vendor-contact-dialog-phone"
            variant="outlined"
            fullWidth
            value={phone}
            error={phoneError}
            defaultValue={phone}
            onChange={(event) => {
              setPhone(event.target.value);
            }}
            data-testid={"admin-vendor-contact-dialog-phone"}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          id="vendor-contact-dialog-cancel-button"
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          id="vendor-contact-dialog-save-button"
          onClick={() => {
            if (handleSave()) {
              onClose();
            }
          }}
          data-testid={"admin-vendor-contact-dialog-save"}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminVendorContactDialog;
