import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { red } from "@mui/material/colors";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import useConfirm from "./useConfirm";
import {
  getAccountGroupsByAccount,
  deleteAccountFromAccountGroup,
} from "../../../reducers/admin-get-account-account-groups";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";

function AccountGroups({ account_id }) {
  const dispatch = useDispatch();
  const { ConfirmDialog, openDialog } = useConfirm();

  const accountGroups = useSelector((state) => {
    return state.getAccountToAccountGroupsReducer.results;
  });

  useSubscription(nimbioServer.internal.accountToAccountGroups, () => dispatch(getAccountGroupsByAccount(account_id)));

  useEffect(() => {
    dispatch(getAccountGroupsByAccount(account_id));
  }, [dispatch, account_id]);

  const columns = [
    { field: "name", flex: 1, headerName: "Account Group", sortable: false, filterable: false },
    {
      field: "actions",
      width: 100,
      headerName: "",
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              openDialog({
                heading: "Remove Account Group",
                message: "Are you sure you want to remove this account group from this account?",
                okButton: "Remove",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteAccountFromAccountGroup(params.row.account_group_id, params.row.account_id));
                },
              });
            }}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: red[500],
          },
          ".MuiDataGrid-filterIcon": {
            color: red[500],
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-menuIconButton": {
            color: red[500],
          },
        }}
        getRowId={(row) => {
          return `${row.account_group_id}-${row.account_id}`;
        }}
        rows={accountGroups}
        columns={columns}
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        hideFooter={true}
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        disableColumnFilter={true}
        disableColumnReorder={true}
        disableColumnResize={true}
        disableDensitySelector={true}
        disableExtendRowFullWidth={true}
        disableMultipleColumnsFiltering={true}
        disableMultipleSelection={true}
        disableSelectionOnRowClick={true}
        disableSelectionOnRowDrag={true}
        disableSortBy={true}
        onRowClick={() => {
          //
        }}
      />
      <ConfirmDialog />
    </>
  );
}

export default AccountGroups;
