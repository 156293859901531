import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  latches: false,
  error: false,
  loading: false,
  total_length_latch_results: null,
};

export const searchForIntegratedLatchesByAccountId = createAsyncAction(
  "ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID",
  (account_id, page, size) => {
    const prefix = "owner:";
    const query = prefix + account_id;
    return call(nimbioServer.admin.searchIntegratedLatches, [query, page, size, 1], "admin-integrated-latches-reducer");
  }
);

export const searchForIntegratedLatchesByLatchName = createAsyncAction(
  "ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME",
  (latch_name, page, size) => {
    return call(
      nimbioServer.admin.searchIntegratedLatches,
      [latch_name, page, size, 1],
      "admin-integrated-latches-reducer"
    );
  }
);

export default handleActions(
  {
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID_LOADING: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID_SUCCESS: (state, { payload }) => {
      if (payload["result"] === false) {
        return {
          ...state,
          error: true,
          loading: false,
        };
      }

      return {
        ...state,
        latches: payload["data"]["results"],
        total_length_latch_results: payload["data"]["total_length"],
        error: payload["result"] === false,
        loading: false,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_ACCOUNT_ID_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME_LOADING: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME_SUCCESS: (state, { payload }) => {
      if (payload["result"] === false) {
        return {
          ...state,
          error: true,
          loading: false,
        };
      }
      return {
        ...state,
        latches: payload["data"]["results"],
        total_length_latch_results: payload["data"]["total_length"],
        error: payload["result"] === false,
        loading: false,
      };
    },
    ADMIN_SEARCH_INTEGRATED_LATCHES_BY_LATCH_NAME_ERROR: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
  },
  initialState
);
