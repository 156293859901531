import React from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { NimbioAsyncCSVExporter } from "./NimbioAsyncCsvExporter";

const styles = {
  font: {
    fontWeight: "300",
  },
};

/*
Full list of useGridApiContext functions:
https://mui.com/x/api/data-grid/grid-api/
*/
function CustomToolbar(props) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton style={styles.font} />
      <GridToolbarFilterButton style={styles.font} />
      <GridToolbarDensitySelector style={styles.font} />
      <NimbioAsyncCSVExporter fetchData={props.fetchAllData} />
    </GridToolbarContainer>
  );
}

function NoData() {
  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography>No Logs For This Community</Typography>
    </Box>
  );
}

const NimbioDataGrid = (props) => {
  const data = {
    columns: props.columns,
    rows: props.rows,
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false, //Hides the column ${columnName}
        },
      },
    },
  };
  return (
    <Box sx={[{ display: "flex" }]}>
      {props.loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          {props.rows.length == 0 ? (
            <NoData loading={props.loading} />
          ) : (
            <DataGrid
              {...data}
              slotProps={{ toolbar: { csvOptions: { allColumns: true } } }}
              useGridApiContext={{}}
              slots={{
                toolbar: () => <CustomToolbar fetchAllData={props.fetchAllData} />,
                noRowsOverlay: () => <NoData loading={props.loading} />,
              }}
              autoHeight
              getRowHeight={() => "auto"}
              sx={{
                p: 2,
                "& .MuiDataGrid-toolbarContainer": {
                  gap: 2,
                },
                "& .MuiDataGrid-cell": {
                  padding: 2,
                },
                "& .MuiDataGrid-cell:hover": {
                  // color: "#000eee",
                },
                "& .MuiDataGrid-cellContent": styles.font,
                "& .MuiButtonBase-root": styles.font,
                "& .MuiDataGrid-footerContainer": {
                  display: "none",
                },
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default NimbioDataGrid;
