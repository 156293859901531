import React, { useState, useRef, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { connect, useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import NoMeetingRoomIcon from "@mui/icons-material/NoMeetingRoom";
import FormControl from "@mui/material/FormControl";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import { Fab, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import KeysForAcceptPendingMember from "./KeysForAcceptPendingMember";
import { formatDatetime } from "../../../utilities/formatDatetime";
import EventDateTimePicker from "../../common/Components/EventDateTimePicker";
import { setMemberHome } from "../../../reducers/memberdetail-reducer";
import { closeAcceptPendingMember, openRemovePendingMember } from "../../../reducers/pending-member-detail-reducer";
import AddHome from "../../common/Components/AddHome";
import HomeSelect from "../../common/Components/HomeSelect";
import { confirmMember } from "../../../reducers/members-reducer";

const styles = (theme) => ({
  dialog_divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

const PendingMemberDetail = (props) => {
  const [moveOutDate, setMoveOutDate] = useState(null);
  const [moveOutDateOpen, setMoveOutDateOpen] = useState(false);
  const checkedKeys = useRef(new Set());
  const dispatch = useDispatch();
  const pendingMemberDetailState = useSelector((state) => state.pendingMemberDetailState);
  const removedMemberDetailState = useSelector((state) => state.removedMemberDetailState);

  const { community_id } = props;
  const phoneNumbers = pendingMemberDetailState.details["phone_numbers"];
  const firstName = pendingMemberDetailState.details["first_name"];
  const lastName = pendingMemberDetailState.details["last_name"];
  const created_datetime = pendingMemberDetailState.details["created_datetime"];
  const open = pendingMemberDetailState.acceptPendingMemberOpen;
  const account_community_id = pendingMemberDetailState.details["account_community_id"];
  const requested_home_id = pendingMemberDetailState.details["requested_home_id"];

  const [homeID, setHomeID] = useState("");

  useEffect(() => {
    if (requested_home_id) {
      setHomeID(requested_home_id);
    }
  }, [requested_home_id]);

  useEffect(() => {
    if (open) {
      setHomeID(requested_home_id);
    } else {
      setMoveOutDate(null);
      setHomeID("");
    }
  }, [open]);

  const theme = useTheme();

  const handleCheck = (key_id, checked) => {
    if (checked) {
      checkedKeys.current.add(key_id);
    } else {
      checkedKeys.current.delete(key_id);
    }
  };

  const deselectAll = () => {
    checkedKeys.current = new Set();
  };

  const handleAccept = () => {
    handleClose();
    const formattedMoveOutDate = moveOutDate ? formatDatetime(moveOutDate) : null;
    dispatch(confirmMember(account_community_id, [...checkedKeys.current], community_id, formattedMoveOutDate));

    if (homeID !== "") {
      dispatch(setMemberHome(community_id, account_community_id, homeID));
    }
  };

  const handleDateChange = (date) => {
    setMoveOutDate(date);
  };

  const setHome = (home_id) => {
    if (home_id === "") {
      home_id = null;
    }
    setHomeID(home_id);
  };

  const handleClose = () => {
    dispatch(closeAcceptPendingMember());
  };

  const handleOpenMoveOutDate = () => {
    setMoveOutDateOpen(true);
    if (!moveOutDate) {
      setMoveOutDate(moment().add(12, "months"));
    }
  };
  const removeMoveOutDate = () => {
    setMoveOutDate(null);
  };

  const handleEventDateTimePickerClose = (isCancel) => {
    if (isCancel) {
      setMoveOutDate(null);
    }
    setMoveOutDateOpen(false);
  };

  const handleRemovePending = () => {
    dispatch(closeAcceptPendingMember());
    dispatch(openRemovePendingMember(pendingMemberDetailState.details));
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <Dialog onClose={handleClose} open={open} data-testid={"pending-member-detail"}>
        <DialogTitle>
          {!pendingMemberDetailState.isMemberRemoved ? "Accept Pending Member" : "Accept Removed Member"}
        </DialogTitle>
        <DialogContent>
          {phoneNumbers && phoneNumbers.length > 0 ? (
            <DialogContentText>
              Accept{" "}
              <b>
                {firstName} {lastName}
              </b>{" "}
              with a confirmed phone number of<b> {phoneNumbers[0]}</b> to your community?
            </DialogContentText>
          ) : (
            <DialogContentText>
              Warning: This person has no confirmed phone number. Accept {firstName} {lastName} to your community?
            </DialogContentText>
          )}
          <DialogContentText sx={{ mt: 2, mb: 2 }}>
            {!pendingMemberDetailState.isMemberRemoved
              ? `Requested on ${created_datetime}`
              : `Removal Date - ${moment
                  .utc(removedMemberDetailState.details["removal_date"])
                  .local()
                  .format("l, h:mm a")}`}
          </DialogContentText>

          <Divider sx={styles(theme).dialog_divider} />
          <KeysForAcceptPendingMember community_id={community_id} check_func={handleCheck} deselectAll={deselectAll} />
          <Divider sx={styles(theme).dialog_divider} />
          <FormControl fullWidth={true}>
            <Typography color={"text.secondary"} sx={{ fontSize: 22 }}>
              Select Home
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box sx={styles(theme).root}>
                  <HomeSelect homeID={homeID} onChange={(home_id) => setHome(home_id)} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <AddHome />
              </Grid>
              <Grid item xs={6}>
                <Fab
                  sx={{ marginLeft: "8px" }}
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Add Move Out Date"
                  onClick={handleOpenMoveOutDate}>
                  <NoMeetingRoomIcon sx={{ mr: 2 }} />
                  <h5>{windowWidth < 740 ? "Add" : "Add Move Out Date"}</h5>
                </Fab>
              </Grid>

              <Grid padding={0} item xs={6}>
                <DialogContentText sx={{ marginTop: "8px" }}>
                  Move Out Date: {moveOutDate ? formatDatetime(moveOutDate) : "None"}
                  {moveOutDate ? (
                    <IconButton aria-label="settings" size="small" onClick={removeMoveOutDate}>
                      <DeleteIcon color="primary" />
                    </IconButton>
                  ) : null}
                </DialogContentText>
              </Grid>
            </Grid>
          </FormControl>

          <EventDateTimePicker
            open={moveOutDateOpen}
            setDateTimeCallback={(moveOutDate) => handleDateChange(moveOutDate)}
            firstPageTitle="Set Move Out Date"
            datetime={moveOutDate}
            dateOnly={true}
            closeCallback={handleEventDateTimePickerClose}
            startingPage="date"
          />
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "space-between", margin: "8px" }}>
          {pendingMemberDetailState.isMemberRemoved ? (
            <Box></Box>
          ) : (
            <Button data-testid={"remove-pending-button"} onClick={() => handleRemovePending()} variant={"outlined"}>
              Remove Pending
            </Button>
          )}
          <Box sx={{ display: "flex" }}>
            <Button sx={{ marginRight: "4px" }} variant={"outlined"} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: "4px" }}
              variant={"contained"}
              onClick={handleAccept}
              autoFocus
              data-testid={"accept-pending-confirm"}>
              Accept
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(PendingMemberDetail);
