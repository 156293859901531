import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { grey } from "@mui/material/colors";
import { Box, Card, CardContent, IconButton, Typography, Menu, MenuItem, Divider, Tooltip } from "@mui/material";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import KeyIcon from "@mui/icons-material/Key";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import AdminRemoveCommunityKeyDialog from "./AdminRemoveCommunityKeyDialog";
import CommunityLatch from "../../common/Components/CommunityLatch";
import ChangeKeyName from "../../common/Components/ChangeKeyName";
import CommunityKeyShareDepth from "../../common/Components/CommunityKeyShareDepth";
import BoxLatchSelectDialog from "../../common/Components/BoxLatchSelectDialog";
import CommunityKeySwitchDialog from "../../common/Components/CommunityKeySwitchDialog";
import { adminHideKey } from "../../../reducers/admin-hide-key-reducer";
import useSubscription from "../../../utilities/hooks/useSubscription";
import nimbioServer from "../../../server/endpoints";
import { adminGetCommunity } from "../../../reducers/admin-community-list";

const styles = () => ({
  accordian: {
    boxShadow: "none",
  },
  communityName: {
    fontWeight: "500",
    marginRight: "16px",
  },
  hidden: {
    backgroundColor: grey[200],
    padding: 3,
    width: "100%",
    borderRadius: 4,
    marginTop: 4,
    marginRight: 2,
    marginLeft: 2,
  },
  nonHidden: {
    backgroundColor: "white",
    marginTop: 4,
    marginRight: 2,
    marginLeft: 2,
  },
});

const AdminCommunityKey = (props) => {
  const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [boxLatchSelectOpen, setBoxLatchSelectOpen] = useState(false);
  const [shareDepthOpen, setShareDepthOpen] = useState(false);
  const [communityKeySwitchDialogOpen, setCommunityKeySwitchDialogOpen] = useState(false);
  const [removeCommunityKeyDialogOpen, setRemoveCommunityKeyDialogOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const community_id = useSelector((state) => state.adminCommunityList.community.id);

  useSubscription(nimbioServer.community.community_key + props.community_key.id, () => {
    dispatch(adminGetCommunity(community_id));
  });
  const _handle_edit_open = () => {
    setChangeNameDialogOpen(true);
  };

  const _handle_edit_close = () => {
    setChangeNameDialogOpen(false);
  };

  const _handle_share_depth_close = () => {
    setShareDepthOpen(false);
  };

  const _handleMenuPress = (event) => {
    if (!menuAnchor) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const _handleMenuClose = () => {
    setMenuAnchor(false);
  };

  const _showEnableDisableKeyDialog = () => {
    setCommunityKeySwitchDialogOpen(true);
  };

  const handleCloseEnableDisableDialog = () => {
    setCommunityKeySwitchDialogOpen(false);
    setMenuAnchor(null);
  };

  const handleRemoveKey = () => {
    setRemoveCommunityKeyDialogOpen(true);
  };

  return (
    <Card sx={props.community_key.hidden ? styles(theme).hidden : styles(theme).nonHidden}>
      <CardContent>
        <Box>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                {props.community_key.disabled === 1 ? (
                  <Box mt={1} ml={2} mr={2}>
                    <Tooltip title={"Key Disabled"}>
                      <KeyOffIcon color="error" />
                    </Tooltip>
                  </Box>
                ) : (
                  <Box mt={1} ml={2} mr={!props.community_key.hidden ? 2 : 1}>
                    <Tooltip title={"Key Enabled"}>
                      <KeyIcon color="success" />
                    </Tooltip>
                  </Box>
                )}
                {props.community_key.hidden === true && (
                  <Box mt={1} ml={1} mr={2}>
                    <Tooltip title={"Hidden"}>
                      <VisibilityOffIcon color={"secondary"} />
                    </Tooltip>
                  </Box>
                )}
                <Typography sx={styles(theme).communityName}>{props.community_key.name}</Typography>
              </Box>
              <>
                {props.community_key.parent_name && (
                  <Typography variant="body2" color="textSecondary" component="p">
                    {props.community_key.parent_name}
                  </Typography>
                )}
              </>
            </Grid>
            <Grid item>
              <IconButton
                data-testid={"community-keys-edit-key-name-button"}
                aria-label="settings"
                size="large"
                onClick={_handle_edit_open}>
                <EditIcon color="primary" sx={{ fontSize: 18 }} />
              </IconButton>
              <IconButton
                data-testid={"community-key-kebab-menu-button"}
                aria-label="settings"
                size="large"
                onClick={_handleMenuPress}>
                <MoreVertIcon />
                <Menu
                  id="settings-menu"
                  anchorEl={menuAnchor}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={_handleMenuClose}>
                  <MenuItem
                    onClick={() => {
                      setBoxLatchSelectOpen(true);
                      setMenuAnchor(null);
                    }}>
                    Add Latches
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      setShareDepthOpen(true);
                      setMenuAnchor(null);
                    }}>
                    Set Share Depth
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      props.dispatch(
                        adminHideKey(props.community_key.id, props.community_key.hidden ? false : true, community_id)
                      );
                      setMenuAnchor(null);
                    }}>
                    {props.community_key.hidden ? "Unhide" : "Hide"}
                  </MenuItem>
                  <MenuItem>
                    <Box
                      onClick={() => {
                        _showEnableDisableKeyDialog();
                        setMenuAnchor(null);
                      }}>
                      Enable/Disable Key
                    </Box>
                  </MenuItem>
                  <MenuItem>
                    <Box
                      onClick={() => {
                        handleRemoveKey();
                        setMenuAnchor(null);
                      }}>
                      Remove Key
                    </Box>
                  </MenuItem>
                </Menu>
              </IconButton>

              <CommunityKeyShareDepth
                open={shareDepthOpen}
                closeCallback={_handle_share_depth_close}
                key_name={props.community_key.name}
                key_id={props.community_key.id}
                community_key={props.community_key}
              />

              <ChangeKeyName
                open={changeNameDialogOpen}
                closeCallback={_handle_edit_close}
                key_name={props.community_key.name}
                key_id={props.community_key.id}
                community_id={community_id}
              />
              <CommunityKeySwitchDialog
                communityId={community_id}
                keyId={props.community_key.id}
                disabled={props.community_key.disabled}
                open={communityKeySwitchDialogOpen}
                closeCallback={handleCloseEnableDisableDialog}
              />

              <AdminRemoveCommunityKeyDialog
                open={removeCommunityKeyDialogOpen}
                closeCallback={() => setRemoveCommunityKeyDialogOpen(false)}
                keyId={props.community_key.id}
                communityId={community_id}
              />

              {boxLatchSelectOpen && (
                <BoxLatchSelectDialog key_id={props.community_key.id} onClose={() => setBoxLatchSelectOpen(false)} />
              )}
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Grid container>
            {props.community_key.latches.map((latch, index) => (
              <CommunityLatch
                key={`${props.community_key.id}_${index}`}
                latch={latch}
                key_id={props.community_key.id}
                type={props.type}
              />
            ))}
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

AdminCommunityKey.propTypes = {
  community_key: PropTypes.object.isRequired,
};

export default connect()(AdminCommunityKey);
