import { handleActions } from "redux-actions";
import { createAsyncAction, createAction } from "redux-promise-middleware-actions";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";
import { parseKeys } from "../models/KeysModel";

// Action Creators
export const getKeys = createAsyncAction("GET_KEYS", (community_id) =>
  call(nimbioServer.community.manager.getKeys, [community_id], "keys-reducer")
);

export const renameKey = createAsyncAction("CHANGE_NAME", (key_id, key_name, community_id) =>
  call(nimbioServer.community.manager.changeKeyName, [key_id, key_name, community_id], "keys-reducer")
);

export const disableCommunityKey = createAsyncAction("DISABLE_COMMUNITY_KEY", (key_id, community_id) =>
  call(nimbioServer.community.manager.disableCommunityKey, [key_id, community_id], "keys-reducer")
);

export const enableCommunityKey = createAsyncAction("ENABLE_COMMUNITY_KEY", (key_id, community_id) => {
  return call(nimbioServer.community.manager.enableCommunityKey, [key_id, community_id], "keys-reducer");
});

export const updateKeyStateLatchStatus = createAction("UPDATE_KEY_STATE_LATCH_STATUS", (message) => {
  return message;
});

export const updateKeyStateLatchOpened = createAction("UPDATE_KEY_STATE_LATCH_OPENED", (message) => {
  return message;
});

export const updateKeyStateLatchLoading = createAction("UPDATE_KEY_STATE_LATCH_LOADING", (message) => {
  return message;
});

export const updateKeyStateLatchFailed = createAction("UPDATE_KEY_STATE_LATCH_FAILED", (message) => {
  return message;
});

export const updateKeyStateLatchReset = createAction("UPDATE_KEY_STATE_RESET", (message) => {
  return message;
});

export const clearKeys = createAction("CLEAR_KEYS");

// Reducer
export const initialState = {
  keys: [],
  loading: false,
  loadingChangeName: false,
  error: false,
  errorMessage: "",
  errorChangeName: false,
  keysModel: {},
};

export default handleActions(
  {
    GET_KEYS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_KEYS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        error: false,
        loading: false,
        keysModel: parseKeys(payload),
        keys: payload,
      };
    },
    GET_KEYS_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    CHANGE_NAME_LOADING: (state) => {
      return {
        ...state,
        loadingChangeName: true,
      };
    },
    CHANGE_NAME_SUCCESS: (state) => {
      return {
        ...state,
        loadingChangeName: false,
      };
    },
    CHANGE_NAME_ERROR: (state, { payload }) => {
      return {
        ...state,
        errorChangeName: payload,
        loadingChangeName: false,
      };
    },
    UPDATE_KEY_STATE_LATCH_STATUS: (state, { payload }) => {
      let updatedKeys = state.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.latch_status_current_message = payload;
            return latch;
          } else {
            return latch;
          }
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_LATCH_OPENED: (state, { payload }) => {
      let updatedKeys = state.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = true;
            latch.loading = false;
            latch.failed = false;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_LATCH_LOADING: (state, { payload }) => {
      let updatedKeys = state.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = false;
            latch.loading = true;
            latch.failed = false;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_LATCH_FAILED: (state, { payload }) => {
      let updatedKeys = state.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = false;
            latch.loading = false;
            latch.failed = true;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    UPDATE_KEY_STATE_RESET: (state, { payload }) => {
      let updatedKeys = state.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = false;
            latch.loading = false;
            latch.failed = false;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        keys: updatedKeys,
        keysModel: parseKeys(updatedKeys),
      };
    },
    CLEAR_KEYS: () => {
      return {
        keys: [],
        loading: false,
        loadingChangeName: false,
        error: false,
        errorChangeName: false,
        keysModel: {},
      };
    },
    ENABLE_COMMUNITY_KEY_SUCCESS: (state, { payload }) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          errorMessage: payload["message"],
          loading: false,
        };
      }

      return {
        ...state,
        loading: false,
      };
    },
    ENABLE_COMMUNITY_KEY_ERROR: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    ENABLE_COMMUNITY_KEY_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    DISABLE_COMMUNITY_KEY_SUCCESS: (state, { payload }) => {
      if (!payload["result"]) {
        return {
          ...state,
          error: true,
          errorMessage: payload["message"],
          loading: false,
        };
      }

      return {
        ...state,
        loading: false,
      };
    },
    DISABLE_COMMUNITY_KEY_ERROR: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
      };
    },
    DISABLE_COMMUNITY_KEY_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
  },
  initialState
);

// Selectors
