import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  Box,
  DialogTitle,
  DialogContentText,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import KeyIcon from "@mui/icons-material/Key";
import { connect, useDispatch } from "react-redux";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";
import { disableCommunityKey, enableCommunityKey } from "../../../reducers/keys-reducer";

type CommunityKeySwitchDialogProps = {
  open: boolean;
  closeCallback: () => void;
  communityId: number;
  keyId: string;
  disabled: number | null;
};

const CommunityKeySwitchDialog = (props: CommunityKeySwitchDialogProps) => {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = React.useState(props.disabled === 0 || props.disabled === null);
  const { communityId, closeCallback, keyId } = props;
  const label = { inputProps: { "aria-label": "Enable/Disable Key" } };

  const handleCancel = () => {
    closeCallback();
  };
  const handleConfirm = () => {
    if (!enabled) {
      dispatch(disableCommunityKey(keyId, communityId));
    } else {
      dispatch(enableCommunityKey(keyId, communityId));
    }
    closeCallback();
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked);
  };

  return (
    <Dialog open={props.open} onClose={closeCallback} aria-labelledby="form-dialog-title">
      <Box sx={{ padding: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
        <DialogTitle p={1}>Enable/Disable Community Key</DialogTitle>
        <DialogContentText sx={{ alignItems: "center", paddingLeft: 3 }}>
          Disabling a key will cause all child keys will become disabled.
        </DialogContentText>
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <FormControlLabel
            control={
              <Switch
                data-testid={"community-key-switch"}
                checked={enabled}
                onChange={handleChange}
                size="medium"
                {...label}
              />
            }
            label={
              !enabled ? (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ paddingRight: 1 }}>{"Disabled"}</Typography>
                  <KeyOffIcon color="error" />{" "}
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={{ paddingRight: 1 }}>{"Enabled"}</Typography>
                  <KeyIcon color="success" />
                </Box>
              )
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant={"contained"} onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

CommunityKeySwitchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  communityId: PropTypes.number.isRequired,
  keyId: PropTypes.string.isRequired,
  disabled: PropTypes.number,
};

export default connect()(CommunityKeySwitchDialog);
