import React, { useState, useEffect } from "react";
import { Container, CircularProgress, Grid, Box, Snackbar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Alert } from "@mui/material";
import { nimbioPalette } from "../../../theme";
import { getColor } from "../../../utilities/materialAvatar";
import store from "../../../store";
import { updateKeyStateLatchLoading } from "../../../reducers/admin-community-list";

const LoadingLatchButton = (props) => {
  // "DEFAULT", "LOADING", "CHECKED"
  const [iconState, setIconState] = useState("DEFAULT");
  const [toast, setToast] = useState({
    open: false,
    msg: "",
  });
  const theme = useTheme();

  useEffect(() => {
    if (props.latch.loading) {
      setIconState("LOADING");
    } else if (props.latch.opened) {
      setIconState("CHECKED");
      handleSucess("Latch Opened Successfully");
    } else if (props.latch.failed) {
      setIconState("DEFAULT");
      handleError("Latch Failed To Open");
    } else {
      setIconState("DEFAULT");
    }
  }, [props.latch.opened, props.latch.loading, props.latch.failed]);

  const handleSucess = (msg) => {
    setToast((oldToast) => {
      return { ...oldToast, msg: msg, severity: "success", open: true };
    });
  };

  const handleError = (msg) => {
    setToast((oldToast) => {
      return { ...oldToast, msg: msg, severity: "error", open: true };
    });
  };

  const handleCloseToast = () =>
    setToast((oldToast) => {
      return { ...oldToast, msg: "", open: false };
    });

  const handleOnClick = () => {
    if (props.clickable) {
      store.dispatch(updateKeyStateLatchLoading({ latch_id: props.latch.id }));
      props.onClick();
    }
  };

  const buttonContainer = {
    width: 72,
    height: 72,
    background: getColor(props.latch.id),
    borderRadius: "50%",
    borderColor: getColor(props.latch.id) + "66",
    cursor: props.clickable ? "pointer" : "default",
  };

  return (
    <Container style={buttonContainer}>
      <Snackbar
        open={toast.open}
        autoHideDuration={4000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
        <Alert onClose={handleCloseToast} severity={toast.severity} sx={{ width: "100%", cursor: "default" }}>
          {toast.msg}
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={styles(theme).centerText}
        onClick={handleOnClick}>
        {iconState == "DEFAULT" && (
          <Grid item sx={styles(theme).gridCenter}>
            {props.latch.offline
              ? "Offline"
              : props.latch.latch_status_current_message &&
                  props.latch.latch_status_current_message["button_message_label"] !== null
                ? props.latch.latch_status_current_message["button_message_label"]
                : "Open"}
          </Grid>
        )}
        {iconState == "LOADING" && (
          <Box sx={{ display: "flex" }}>
            <CircularProgress sx={{ color: nimbioPalette.white }} />
          </Box>
        )}
        {iconState == "CHECKED" && (
          <Box sx={{ display: "flex" }}>
            <CheckCircleIcon sx={{ color: nimbioPalette.white, fontSize: "3rem" }} />
          </Box>
        )}
      </Grid>
    </Container>
  );
};

const styles = () => ({
  centerText: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  gridCenter: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    textShadow: "rgba(0, 0, 0, 0.4) 1px 1px 3px",
    userSelect: "none",
  },
});

LoadingLatchButton.propTypes = {
  latch: PropTypes.object.isRequired,
  latchMsgID: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  clickable: PropTypes.bool,
};

export default LoadingLatchButton;
