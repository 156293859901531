import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Card,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import MemberHomeDetail from "./MemberHomeDetail";
import MemberDetail from "./MemberDetail";
import MemberListItem from "./MemberListItem";
import RemovedItem from "./RemovedItem";
import PendingItem from "./PendingItem";
import RemovePending from "./RemovePending";
import RemovedMemberDetail from "./RemovedMemberDetail";
import PendingMemberDetail from "./PendingMemberDetail";
import {
  clearMembers,
  getAcceptedMembersPaginated,
  getAcceptedTotalLength,
  getRemovedMembersPaginated,
  getRemovedTotalLength,
  getUnacceptedMembersPaginated,
  getUnacceptedTotalLength,
  paginateAcceptedMembers,
  paginateRemovedMembers,
  paginateUnacceptedMembers,
  setMemberSortByType,
} from "../../../reducers/members-reducer";
import { openAcceptPendingMember } from "../../../reducers/pending-member-detail-reducer";
import { getHomes } from "../../../reducers/homes-reducer";
import PaginationComponent from "../../common/Components/PaginationComponent";
import LoadingScreen from "../../common/Components/LoadingScreen";
import { memberListPageLength } from "../../../utilities/constants";
import { getColor, getShortName } from "../../../utilities/materialAvatar";
import { getMemberDetail } from "../../../reducers/memberdetail-reducer";
import { getHomeDetail, setShowHomeMembersInList } from "../../../reducers/homedetail-reducer";
import { openRemovedMemberDetails } from "../../../reducers/removed-member-detail-reducer";
import ExportMembersDialog from "../../common/Components/ExportMembersDialog";

const styles = (theme) => ({
  root: {
    width: "100%",
    padding: 2,
    borderRadius: 1,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  isSortedByRemovalDateButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "2%",
  },
  listItem: {
    "&:hover": {
      background: "#00000011",
      cursor: "pointer",
    },
  },
});

const StateChip = ({ option }) => {
  return (
    <>
      {option.is_accepted && <Chip component={"span"} size="small" label="Approved" color="primary" />}
      {option.is_unaccepted && <Chip component={"span"} size="small" label="Pending" color="secondary" />}
      {option.is_removed && <Chip component={"span"} size="small" label="Removed" color="error" />}
    </>
  );
};

const SearchOption = ({ communityId, option }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const getAction = (option) => {
    if (option.is_accepted && option.is_home === false) {
      return () => {
        dispatch(getMemberDetail(option.account_community_id, communityId));
      };
    }
    if (option.is_accepted && option.is_home) {
      return () => {
        dispatch(getHomeDetail(option.id, communityId));
      };
    }
    if (option.is_unaccepted) {
      return () => dispatch(openAcceptPendingMember(option, false));
    }
    if (option.is_removed) {
      return () => dispatch(openRemovedMemberDetails(option));
    }

    return () => true;
  };

  return (
    <>
      {option.is_home === false ? (
        <ListItem onClick={getAction(option)} sx={styles(theme).listItem} data-testid={`search-option-${option.id}`}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: getColor(option.id), padding: 3 }}>
              {getShortName(option.first_name, option.last_name)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={option.searchTerm} secondary={<StateChip option={option} />} />
          <ChevronRight color="action" sx={styles(theme).chevron} />
        </ListItem>
      ) : (
        <ListItem onClick={getAction(option)} sx={styles(theme).listItem}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: getColor(option.id), padding: 3 }}>
              <HomeIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={option.address}
            secondary={<React.Fragment>{option.members.map((item) => item.searchTerm).join(" | ")}</React.Fragment>}
          />
          <ChevronRight color="action" sx={styles(theme).chevron} />
        </ListItem>
      )}
    </>
  );
};

const MemberList = ({ community, type }) => {
  const theme = useTheme();
  const membersState = useSelector((state) => state.membersState);
  const homesState = useSelector((state) => state.homesState);
  const [exportMembersDialogOpen, setExportMembersDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { communityId } = useParams();

  const pendingMembersLength = membersState.members?.unaccepted?.members
    ? membersState.members.unaccepted.members.filter((member) => member.is_home === false).length
    : 0;

  const currentAcceptedMembersPage = membersState.members.accepted.page;
  const currentUnacceptedMembersPage = membersState.members.unaccepted.page;
  const currentRemovedMembersPage = membersState.members.removed.page;

  const paginateAccepted = (pageNum) => {
    dispatch(paginateAcceptedMembers(pageNum));
  };

  const paginateUnaccepted = (pageNum) => {
    dispatch(paginateUnacceptedMembers(pageNum));
  };

  const paginateRemoved = (pageNum) => {
    dispatch(paginateRemovedMembers(pageNum));
  };

  const handleChangeSortBy = (event) => {
    dispatch(setMemberSortByType(event.target.value));
  };

  useEffect(() => {
    loadData();
    return () => {
      dispatch(clearMembers());
      dispatch(setShowHomeMembersInList(false));
    };
  }, []);

  useEffect(() => {
    if (membersState.error) return;
    if (membersState.members.accepted.total_length === membersState.members.accepted.members.length) return;

    const numberOfPages = Math.ceil(membersState.members.accepted.total_length / memberListPageLength);
    for (let i = 1; i <= numberOfPages; i++) {
      dispatch(getAcceptedMembersPaginated(communityId, i, memberListPageLength));
    }
  }, [membersState.members.accepted.total_length]);

  useEffect(() => {
    if (membersState.error) return;
    if (membersState.members.unaccepted.total_length === membersState.members.unaccepted.members.length) return;
    setTabValue(1);
    const numberOfPages = Math.ceil(membersState.members.unaccepted.total_length / memberListPageLength);
    for (let i = 1; i <= numberOfPages; i++) {
      dispatch(getUnacceptedMembersPaginated(communityId, i, memberListPageLength));
    }
  }, [membersState.members.unaccepted.total_length]);

  useEffect(() => {
    if (membersState.error) return;
    if (membersState.members.removed.total_length === membersState.members.removed.members.length) return;

    const numberOfPages = Math.ceil(membersState.members.removed.total_length / memberListPageLength);
    for (let i = 1; i <= numberOfPages; i++) {
      dispatch(getRemovedMembersPaginated(communityId, i, memberListPageLength));
    }
  }, [membersState.members.removed.total_length]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadData = () => {
    dispatch(getAcceptedTotalLength(communityId));
    dispatch(getUnacceptedTotalLength(communityId));
    dispatch(getRemovedTotalLength(communityId));
    dispatch(getHomes(communityId));
  };

  const returnMemberList = () => {
    const { accepted } = membersState.members;
    const memberObjects = accepted.render.map((item, index) => (
      <MemberListItem key={item.id + index} details={item} community_id={communityId} />
    ));

    return memberObjects;
  };

  const handleShowHomeMembers = (e) => {
    dispatch(setShowHomeMembersInList(e.target.checked));
  };

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (e, newValue) => {
    setTabValue(newValue);
  };

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const setExportMembersOpen = (isOpen) => {
    setExportMembersDialogOpen(isOpen);
  };

  return (
    <Card sx={styles(theme).root} data-testid={"member-list"}>
      <Box mb={2} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 1 }}>
          Community Members
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            sx={{ marginRight: theme.spacing(2), color: "text.secondary" }}
            control={<Switch checked={homesState.show_home_members_in_list} onChange={handleShowHomeMembers} />}
            label="Show Home Members"
            labelPlacement={"end"}
          />
          <Button
            data-testid={"export-members-button"}
            variant={"outlined"}
            endIcon={windowWidth > 450 ? <DownloadIcon /> : null}
            onClick={() => setExportMembersDialogOpen(true)}>
            {windowWidth > 450 ? "Export" : <DownloadIcon sx={{ fontSize: "24px" }} />}
          </Button>
        </Box>
      </Box>
      <ExportMembersDialog
        community={community}
        membersState={membersState}
        open={exportMembersDialogOpen}
        setOpen={setExportMembersOpen}
      />

      <Autocomplete
        id="searchmembers"
        disablePortal
        renderInput={(params) => (
          <TextField {...params} label="Search..." variant="outlined" fullWidth data-testid={"search-members"} />
        )}
        options={membersState.searchData}
        getOptionLabel={(obj) => obj.searchTerm}
        renderOption={(props, option, config) => {
          return <SearchOption key={option.id + config.index} communityId={communityId} option={option} />;
        }}
      />
      <Box sx={{ width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent={windowWidth < 500 ? "flex-end" : "space-between"}
          alignItems="center"
          pt={2}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            // display: "flex",
            // flexDirection: "row",
            flexWrap: windowWidth < 500 ? "wrap-reverse" : "nowrap",
          }}>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab data-testid={"member-list-accepted"} label="Accepted" {...tabProps(0)} />
            <Tab
              data-testid={"member-list-pending"}
              label={
                <Badge badgeContent={pendingMembersLength} color="secondary">
                  Pending&nbsp;&nbsp;
                </Badge>
              }
              {...tabProps(1)}
            />
            <Tab data-testid={"member-list-removed"} label="Removed" {...tabProps(2)} />
          </Tabs>
          <FormControl sx={{ paddingBottom: 1 }} size={"small"}>
            <InputLabel id="member-sort-by-label">Sort By</InputLabel>
            <Select
              onChange={handleChangeSortBy}
              labelId="member-sort-by-label"
              id="member-sort-by"
              value={membersState.memberSortByType}
              label="Sort By">
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"date"}>{tabValue === 2 ? "Removal Date" : "Date Added"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <CustomTabPanel value={tabValue} index={0}>
          {membersState.loading ? (
            <LoadingScreen />
          ) : (
            <List component="div" disablePadding>
              {membersState.members?.accepted?.members && membersState.members?.accepted?.members.length > 0 ? (
                <Box>{returnMemberList()}</Box>
              ) : (
                <Box>
                  <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 1 }}>
                    No Accepted Members
                  </Typography>
                </Box>
              )}
              <PaginationComponent
                totalItems={membersState.members?.accepted?.total_length}
                itemsPerPage={memberListPageLength}
                paginate={paginateAccepted}
                currentPage={currentAcceptedMembersPage}
              />
            </List>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          {membersState.loading ? (
            <LoadingScreen />
          ) : (
            <List component="div" disablePadding>
              {membersState.members?.unaccepted?.render && membersState.members?.unaccepted?.render.length > 0 ? (
                membersState.members.unaccepted.render.map((item, index) => (
                  <PendingItem key={item.id + index} details={item} community_id={communityId} />
                ))
              ) : (
                <Box>
                  <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 1 }}>
                    No Pending Members
                  </Typography>
                </Box>
              )}
              <PaginationComponent
                totalItems={membersState.members?.unaccepted?.total_length}
                itemsPerPage={memberListPageLength}
                paginate={paginateUnaccepted}
                currentPage={currentUnacceptedMembersPage}
              />
            </List>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          {membersState.loading ? (
            <LoadingScreen />
          ) : (
            <List component="div" disablePadding>
              {membersState.members.removed?.render && membersState.members.removed?.render?.length > 0 ? (
                membersState.members.removed.render.map((item, index) => (
                  <RemovedItem key={item.id + index} details={item} community_id={communityId} />
                ))
              ) : (
                <Box>
                  <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 1 }}>
                    No Removed Members
                  </Typography>
                </Box>
              )}
              <PaginationComponent
                totalItems={membersState.members?.removed?.total_length}
                itemsPerPage={memberListPageLength}
                paginate={paginateRemoved}
                currentPage={currentRemovedMembersPage}
              />
            </List>
          )}
        </CustomTabPanel>
      </Box>

      <PendingMemberDetail community_id={communityId} />
      <RemovePending community_id={communityId} />
      <RemovedMemberDetail />
      <MemberDetail type={type} community_id={communityId} />
      <MemberHomeDetail community_id={communityId} />
    </Card>
  );
};

export default MemberList;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
