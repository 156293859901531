import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TextField } from "@mui/material";
import { sendVendorData } from "../../../reducers/admin-get-vendors";

const AdminVendorDialog = ({ vendor, open, onClose }) => {
  const dispatch = useDispatch();

  const [name, setName] = React.useState(vendor ? vendor.vendor_name : "");

  function handleSave() {
    // Handle the save of the account.
    if (name.length > 0) {
      dispatch(sendVendorData(vendor ? vendor.vendor_uuid : null, name));
      onClose();
    }
  }

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      id="nimbio-testing-admin-vendor-dialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid={"admin-vendor-dialog"}>
      <DialogTitle>{vendor ? "Edit Vendor" : "Add Vendor"}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>Name</Typography>
          <TextField
            id="nimbio-testing-admin-vendor-dialog-name"
            variant="outlined"
            fullWidth
            defaultValue={name}
            autoFocus
            onChange={(event) => {
              setName(event.target.value);
            }}
            data-testid={"admin-vendor-dialog-name"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          id="nimbio-testing-admin-vendor-dialog-cancel-button"
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          id="nimbio-testing-admin-vendor-dialog-save-button"
          onClick={() => {
            handleSave();
          }}
          data-testid={"admin-vendor-dialog-save"}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminVendorDialog;
