import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { nimbioPalette } from "../../../theme";

const styles = () => ({
  nested: {
    paddingLeft: 2,
  },
});

type KeyForAcceptItemProps = {
  check_func: (key_id: string, checked: boolean) => void;
  key_id: string;
  key_name: string;
  cleared: boolean;
  is_highlighted: boolean;
};

const KeyForAcceptItem = (props: KeyForAcceptItemProps) => {
  const { check_func, key_id, key_name, cleared, is_highlighted } = props;
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (cleared && checked) {
      setChecked(false);
    }
  }, [cleared, checked]);

  const handleChange = (key_id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    check_func(key_id, event.target.checked);
  };

  useEffect(() => {
    setChecked(true);
    check_func(key_id, true);
  }, []);

  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid={"key-for-accept-checkbox+" + key_id}
          checked={checked}
          sx={styles().nested}
          onChange={handleChange(key_id)}
          value={key_id}
        />
      }
      label={<Typography sx={{ color: is_highlighted ? nimbioPalette.purple[2] : "" }}>{key_name}</Typography>}
    />
  );
};

export default KeyForAcceptItem;
