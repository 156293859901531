import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Card,
  Box,
  CardMedia,
  CardContent,
  CircularProgress,
  TextField,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { setCurrentCommunity } from "../../../reducers/communities-reducer";
import endpoints from "../../../routes/endpoints";
import NimbioLogo100 from "../../../assets/image/nimbio-logo-100x100.png";
import NotCommManager from "../../common/Components/NotCommManager";

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  input: {
    display: "none",
  },
  outerDiv: {
    padding: theme.spacing(4),
  },
  card: {
    maxWidth: 500,
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: 4,
    paddingLeft: 2,
    paddingRight: 2,
    minWidth: 300,
  },
  media: {
    minHeight: 100,
    objectFit: "contain",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  cardHeader: {
    textAlign: "center",
  },
  signInSubtitle: {
    textAlign: "center",
    paddingBottom: 22,
  },
  progress: {},
  progressOuter: {
    display: "flex",
    justifyContent: "center",
  },
});

const CommunityList = (props) => {
  const navigate = useNavigate();
  const searchDebounceTimer = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const debounceTimeout = 500;
  const [searchValueDebounce, setSearchValueDebounce] = useState("");

  const [filteredCommunities, setFilteredCommunities] = useState([]);

  const handleChangeSearch = (event) => {
    clearTimeout(searchDebounceTimer.current);
    searchDebounceTimer.current = setTimeout(() => {
      setSearchValue(event.target.value);
    }, debounceTimeout);

    setSearchValueDebounce(event.target.value);
  };

  useEffect(() => {
    if (searchValue !== "") {
      const filteredCommunities = props.communities.details.filter((comm) =>
        comm.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredCommunities(filteredCommunities);
    }

    if (searchValue === "") {
      setFilteredCommunities(props.communities.details);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredCommunities(props.communities.details);
    }
  }, [props.communities.details]);

  return (
    <Box>
      {props.communities.details.length > 4 ? (
        <TextField
          size="small"
          data-testid="list-communities-find-communities"
          onChange={handleChangeSearch}
          label="Find Community"
          value={searchValueDebounce}
          variant="outlined"
          fullWidth
          sx={{ marginBottom: 2, marginTop: 2 }}
        />
      ) : null}
      <List>
        {filteredCommunities.map((comm) => (
          <ListItemButton
            data-testid={"list-communities-item"}
            key={comm.id}
            onClick={() => {
              props.dispatch(setCurrentCommunity(comm.id));
              navigate("/community-manager/" + comm.id + "/community-detail");
            }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              id={comm.id}
              className={"nimbio-testing" + endpoints.PICK_COMMUNITY + "-list-item"}
              primary={comm.name}
            />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
};

const ListCommunities = (props) => {
  const theme = useTheme();

  if (props.communities.details === false) {
    return <NotCommManager />;
  }
  if (props.profile.error) {
    return <NotCommManager />;
  }
  const communitiesReady = !props.communities.loading && props.communities.communityModel !== false;

  return (
    <Box sx={styles(theme).outerDiv}>
      <Card sx={styles(theme).card}>
        <CardMedia component="img" sx={styles(theme).media} image={NimbioLogo100} title="Nimbio" />
        <CardContent>
          <Typography variant="h6" gutterBottom sx={styles(theme).cardHeader}>
            {communitiesReady ? "Select Community" : "Loading Communities"}
          </Typography>
          {communitiesReady ? (
            <CommunityList communities={props.communities} dispatch={props.dispatch} />
          ) : (
            <Box sx={styles(theme).progressOuter}>
              <CircularProgress sx={styles(theme).progress} />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    communities: state.communityState,
    profile: state.profileState,
  };
};

export default connect(mapStateToProps)(ListCommunities);
