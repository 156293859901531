import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import { parseHomes } from "../models/HomesModel";
import nimbioServer from "../server/endpoints";

// Action Creators
export const getHomes = createAsyncAction("GET_HOMES", (community_id) =>
  call(nimbioServer.community.manager.getHomes, [community_id], "homes-reducer")
);

export const addHome = createAsyncAction("ADD_HOME", async (community_id, home_address) => {
  return await call(nimbioServer.community.manager.addHome, [community_id, home_address], "homes-reducer");
});

export const removeHome = createAsyncAction("REMOVE_HOME", (community_id, home_id) => {
  return call(nimbioServer.community.manager.removeHome, [home_id, community_id], "homes-reducer");
});

export const setHomeDetails = createAsyncAction("SET_HOME_DETAILS", (community_id, home_id, details) => {
  return call(nimbioServer.community.manager.setHomeDetails, [community_id, home_id, details], "homes-reducer");
});

export const setAllowNewMemberRequestHome = createAsyncAction(
  "SET_ALLOW_NEW_MEMBER_REQUEST_HOME",
  (community_id, is_new_member_request_home_enabled) => {
    return call(
      nimbioServer.community.manager.setIsNewMemberRequestHomeEnabled,
      [community_id, is_new_member_request_home_enabled],
      "homes-reducer"
    );
  }
);

export const showDetails = createAction(
  "SHOW_HOME",
  (home_id, home_name, home_address, home_phone, home_email, home_owner_name, owner_occupied) => {
    return { home_id, home_name, home_address, home_phone, home_email, home_owner_name, owner_occupied };
  }
);

export const closeDetails = createAction("CLOSE_HOME", () => {
  return true;
});

// Reducer
export const initialState = {
  homes: [],
  loading: false,
  error: false,
  homesModel: {},
  add_home_loading: false,
  add_home_error: false,
  details_open: false,
  details_home_id: "",
  details_home_address: "",
  details_home_phone: "",
  details_home_owner_name: "",
  details_owner_occupied: false,
  details_home_name: "",
  details_home_email: "",
  home_added_id: "",
};

export default handleActions(
  {
    GET_HOMES_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_HOMES_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        error: false,
        loading: false,
        homesModel: parseHomes(payload),
        homes: payload,
      };
    },
    GET_HOMES_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    ADD_HOME_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    ADD_HOME_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        home_added_id: payload,
        loading: false,
      };
    },
    ADD_HOME_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    CHANGE_HOME_NAME_LOADING: (state) => {
      return {
        ...state,
        add_home_loading: true,
      };
    },
    CHANGE_HOME_NAME_SUCCESS: (state) => {
      return {
        ...state,
        add_home_loading: false,
      };
    },
    CHANGE_HOME_NAME_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    SHOW_HOME: (state, { payload }) => {
      return {
        ...state,
        details_open: true,
        details_home_id: payload.home_id,
        details_home_address: payload.home_address,
        details_home_phone: payload.home_phone,
        details_home_email: payload.home_email,
        details_home_owner_name: payload.home_owner_name,
        details_home_name: payload.home_name,
        details_owner_occupied: payload.owner_occupied,
      };
    },
    CLOSE_HOME: (state) => {
      return {
        ...state,
        details_open: false,
        details_home_id: "",
        details_home_address: "",
        details_home_phone: "",
        details_home_email: "",
        details_home_owner_name: "",
        details_owner_occupied: false,
      };
    },
    SET_DETAILS_HOME_ADDRESS: (state, { payload }) => {
      return {
        ...state,
        details_home_address: payload.home_address,
      };
    },
    REMOVE_HOME_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    REMOVE_HOME_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: !payload["result"],
      };
    },
    REMOVE_HOME_ERROR: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    SET_HOME_DETAILS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    SET_HOME_DETAILS_SUCCESS: (state, { payload }) => {
      if (!payload["result"]) {
        return {
          ...state,
          loading: false,
          error: true,
        };
      }

      return {
        ...state,
        loading: false,
      };
    },
    SET_HOME_DETAILS_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
      };
    },
  },
  initialState
);

// Selectors
