import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogContent, Typography, Grid, Button, Box, Divider } from "@mui/material";
import moment from "moment";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { openAcceptPendingMember } from "../../../reducers/pending-member-detail-reducer";
import { closeRemovedMemberDetails } from "../../../reducers/removed-member-detail-reducer";

const RemovedMemberDetail = () => {
  const dispatch = useDispatch();
  const removedMemberDetailState = useSelector((state: any) => state.removedMemberDetailState);

  const handleAddRemovedMember = () => {
    dispatch(openAcceptPendingMember(removedMemberDetailState.details, true));
    dispatch(closeRemovedMemberDetails());
  };

  const handleCloseRemovedMemberDetails = () => {
    dispatch(closeRemovedMemberDetails());
  };

  return (
    <Dialog
      data-testid="removed-member-detail"
      onClose={handleCloseRemovedMemberDetails}
      open={removedMemberDetailState.removedMemberDetailsOpen}>
      <DialogTitle>Removed Member Details</DialogTitle>
      <DialogContent>
        <Grid sx={{ flexDirection: "row" }}>
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 4 }} />
            <Typography>
              Removal Date:{" "}
              {removedMemberDetailState.details["removal_date"] &&
              removedMemberDetailState.details["removal_date"] !== "None"
                ? moment.utc(removedMemberDetailState.details["removal_date"]).local().format("l, h:mm a")
                : ""}
            </Typography>
          </Grid>
          <Grid mt={2} item xs={12}>
            <Typography>
              Reason for Removal:{" "}
              {!!removedMemberDetailState.details["reason_for_removal"]
                ? removedMemberDetailState.details["reason_for_removal"]
                : "None"}
            </Typography>
          </Grid>
          <Grid item mt={6} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button startIcon={<AddCircleOutline />} onClick={handleAddRemovedMember} variant={"outlined"}>
                Accept Removed
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default RemovedMemberDetail;
