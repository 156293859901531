import React from "react";
import PlaceHolderPage from "./PlaceHolderPage";

const NotCommManager = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   localStorage.removeItem("auth_id");
  //   localStorage.removeItem("secret");
  //
  //   return () => {
  //     resetState();
  //     dispatch(logout());
  //   };
  // }, []);

  return <PlaceHolderPage msg="We're sorry. You do not have access to manage any communities." withLogout={true} />;
};

export default NotCommManager;
