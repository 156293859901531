import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import DeviceListSearchBox from "./DeviceListSearchBox";
import Devices from "./Devices";
import PaginationComponent from "./PaginationComponent";
import {
  adminGetDeviceStatusPaginated,
  adminGetDeviceStatusPaginatedHidden,
  deviceListSetSearchTerm,
} from "../../../reducers/device-status-reducer";
import { getBoxesAccountGroups } from "../../../reducers/admin-get-boxes-account-groups";
import { getBoxesVendors } from "../../../reducers/admin-get-boxes-vendors";
import endpoints from "../../../routes/endpoints";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";

const DEVICES_PER_PAGE = 24;

// type DeviceListType = {
//   type:"revealed"|"hidden"|"recent";
//   error: any,
//   loading: any,
//   devices: any,
//   totalDeviceLength: any,
//   currentSearch: any,
//   isSearch: boolean,
//   search_term: string,
//   accountGroups: any,
//   vendors: any,
// };

const DeviceList = (props: any) => {
  const dispatch = useDispatch();

  function _getBoxesAccountGroups() {
    dispatch(getBoxesAccountGroups());
  }

  function _getBoxesVendors() {
    dispatch(getBoxesVendors());
  }

  useSubscription(nimbioServer.internal.boxToAccountGroups, () => _getBoxesAccountGroups());
  useSubscription(nimbioServer.vendor.vendorToBox, () => _getBoxesVendors());

  useEffect(() => {
    dispatch(getBoxesAccountGroups());
    dispatch(getBoxesVendors());
  }, [dispatch]);

  const paginate = (pageNum: number): void => {
    if (props.isSearch) {
      dispatch(deviceListSetSearchTerm(props.search_term, pageNum, DEVICES_PER_PAGE, props.type));
      return;
    }
    switch (props.type) {
      case "recent":
        // @ts-expect-error: adminGetDeviceStatusPaginated not converted to ts yet
        dispatch(adminGetDeviceStatusPaginated(pageNum, DEVICES_PER_PAGE, "recent"));
        break;
      case "hidden":
        // @ts-expect-error: eslint being weird
        dispatch(adminGetDeviceStatusPaginatedHidden(pageNum, DEVICES_PER_PAGE));
        break;
      case "revealed":
        // @ts-expect-error: adminGetDeviceStatusPaginated not converted to ts yet
        dispatch(adminGetDeviceStatusPaginated(pageNum, DEVICES_PER_PAGE));
        break;
      default:
        console.error("No type specified for DeviceListSearchBox");
        break;
    }
  };

  return (
    <>
      <DeviceListSearchBox type={props.type} paginate={paginate} devicesPerPage={DEVICES_PER_PAGE} />
      <Grid
        id={"nimbio-testing" + endpoints.ADMIN_DEVICE_MONITOR + "-list"}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={0}>
        <Devices
          error={props.error}
          loading={props.loading}
          devices={props.devices}
          type={props.type}
          accountGroups={props.accountGroups}
          vendors={props.vendors}
        />
      </Grid>
      <PaginationComponent
        totalItems={props?.totalDeviceLength}
        itemsPerPage={DEVICES_PER_PAGE}
        paginate={paginate}
        currentPage={1}
      />
    </>
  );
};

DeviceList.propTypes = {
  type: PropTypes.string.isRequired,
  error: PropTypes.any,
  loading: PropTypes.any,
  devices: PropTypes.any,
  totalDeviceLength: PropTypes.any,
  currentSearch: PropTypes.any,
  isSearch: PropTypes.bool,
  search_term: PropTypes.string,
  accountGroups: PropTypes.any,
  vendors: PropTypes.any,
};

const mapStateToProps = (state: any) => {
  return {
    error: state.deviceStatus.error,
    loading: state.deviceStatus.loading,
    devices: state.deviceStatus.result,
    totalDeviceLength: state.deviceStatus.total_length,
    currentSearch: state.deviceStatus.search_term_lower,
    isSearch: state.deviceStatus.isSearch,
    search_term: state.deviceStatus.search_term,
    accountGroups: state.getBoxesAccountGroupsReducer.results,
    vendors: state.getBoxesVendorsReducer.results,
  };
};

export default connect(mapStateToProps)(DeviceList);
