import store from "../store";
import { senseLineUpdateNewRecord } from "../reducers/sense-line-reducer";
import { getKeys, updateKeyStateLatchStatus } from "../reducers/keys-reducer";
import { adminGetCommunity, updateCommunityKeyStateLatchStatus } from "../reducers/admin-community-list";

export function latchStatusSubscription(message) {
  message = message.argsList[0];
  if ("a" in message) {
    switch (message.a) {
      case "sense_line_status":
        store.dispatch(senseLineUpdateNewRecord(message));
        store.dispatch(updateCommunityKeyStateLatchStatus(message));
        store.dispatch(updateKeyStateLatchStatus(message));
        break;
      case "no_previous_state":
        break;

      case "latch_offline":
        if (store.getState().adminCommunityList.community.id !== null) {
          console.log("sub updating admin state latch offline");
          store.dispatch(adminGetCommunity(store.getState().communityState.currentCommunityID));
        }
        if (store.getState().adminCommunityList.community.id === null) {
          console.log("sub updating cm state latch offline");
          store.dispatch(getKeys(store.getState().communityState.currentCommunityID));
        }
        break;
      case "latch_online":
        if (store.getState().adminCommunityList.community.id !== null) {
          console.log("sub updating admin state latch online");
          store.dispatch(adminGetCommunity(store.getState().communityState.currentCommunityID));
        }
        if (store.getState().adminCommunityList.community.id === null) {
          console.log("sub updating cm state latch online");
          store.dispatch(getKeys(store.getState().communityState.currentCommunityID));
        }
        break;
      case "offline_update":
        // console.log("sub", message);
        break;

      default:
        // eslint-disable-next-line no-console
        console.log("<B>Message received and not handled", message);
    }
  }
}
