import store from "../store";
import { refreshHoldOpens } from "../reducers/hold-open-reducers";

export function holdOpenSubscription(message) {
  message = message.argsList[0];
  if ("a" in message) {
    switch (message.a) {
      case "refresh_hold_opens":
        store.dispatch(refreshHoldOpens(store.getState().communityState.currentCommunityID));
        break;
      default:
        console.log("<B>Message received and not handled", message);
    }
  }
}
