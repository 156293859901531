import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingScreen = () => {
  return (
    <Box sx={{ p: 5, textAlign: "center", height: "100vh", backgroundColor: "#ffffff00" }}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
