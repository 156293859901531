import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { connect, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { sendFirstName, sendLastName } from "../../reducers/login-reducer";
import { getLoginDetails } from "../../reducers/profile-reducer";
import { logout } from "../../reducers/connection-reducer";
import NimbioLogo100 from "../../assets/image/nimbio-logo-100x100.png";

const Name = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const submitName = () => {
    if (firstName === "") {
      setFirstNameError("First name is required");
      return;
    }
    if (lastName === "") {
      setLastNameError("Last name is required");
      return;
    }
    dispatch(sendFirstName(firstName));
    dispatch(sendLastName(lastName));
    navigate("/");
  };

  const signOut = () => {
    dispatch(logout());
    navigate("/login");
  };

  if (props.loginState.sendFirstNameSuccess && props.loginState.sendLastNameSuccess) {
    dispatch(getLoginDetails());
    navigate("/");
    return;
  }

  if (props.profileState.loaded && !props.profileState.loading) {
    if (props.profileState.details.first_name && props.profileState.details.last_name) {
      navigate("/");
      return;
    }
  }

  return (
    <Box sx={classes(theme).outerdiv}>
      <Box>
        <Card sx={classes(theme).card}>
          <CardMedia component="img" sx={classes(theme).media} image={NimbioLogo100} title="Nimbio" />
          <CardContent>
            <Typography variant="h6" gutterBottom sx={classes(theme).signinText}>
              Enter your name
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={classes(theme).signinSubtitle}>
              to continue to nimbio
            </Typography>
            <TextField
              value={firstName}
              autoFocus
              placeholder="First name"
              label={firstNameError}
              margin="normal"
              inputProps={{ sx: classes(theme).otuInput }}
              error={firstNameError !== ""}
              onChange={(event) => {
                setFirstNameError("");
                setFirstName(event.target.value);
              }}
              data-testid={"first-name"}
            />
            <TextField
              value={lastName}
              placeholder="Last name"
              label={lastNameError}
              margin="normal"
              inputProps={{ sx: classes(theme).otuInput }}
              error={lastNameError !== ""}
              onChange={(event) => {
                setLastNameError("");
                setLastName(event.target.value);
              }}
              data-testid={"last-name"}
            />
          </CardContent>
          <CardActions>
            <Button variant="contained" sx={classes(theme).button} onClick={() => submitName()} data-testid={"submit"}>
              Submit
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Button variant="contained" sx={[classes(theme).button, { mt: 3 }]} onClick={signOut} data-testid={"sign-out"}>
        Sign Out
      </Button>
    </Box>
  );
};

const classes = (theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  input: {
    display: "none",
  },
  outerdiv: {
    padding: 30,
    textAlign: "center",
  },
  card: {
    maxWidth: 345,
    marginLeft: "auto",
    marginRight: "auto",
  },
  media: {
    minHeight: 100,
    objectFit: "contain",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signinText: {
    textAlign: "center",
  },
  signinSubtitle: {
    textAlign: "center",
    paddingBottom: 22,
  },
  otuInput: {
    fontSize: 24,
    letterSpacing: 3,
  },
});

const mapStateToProps = (state) => {
  return {
    loginState: state.loginState,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(Name);
