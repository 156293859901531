import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const getAccountVendorOpenLogs = createAsyncAction("ADMIN_GET_ACCOUNT_VENDOR_OPEN_LOGS", (vendorId) => {
  return call(nimbioServer.admin.getAccountVendorOpenLogs, [vendorId], "admin-get-account-vendor-open-logs");
});

export default handleActions(
  {
    ADMIN_GET_ACCOUNT_VENDOR_OPEN_LOGS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_ACCOUNT_VENDOR_OPEN_LOGS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        results: payload.result,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_ACCOUNT_VENDOR_OPEN_LOGS_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState,
);
