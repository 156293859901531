import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Box, Card, CardContent, FormControlLabel, Switch, Button } from "@mui/material";
import {
  adminDeviceGetLatches,
  adminDeviceSetGrata,
  adminDeviceSetTruckbays,
  adminDeviceSetUPS,
  adminDeviceSetUSPS,
} from "../../../reducers/device-status-reducer";
import DeviceLatch from "../../common/Components/DeviceLatch";

type AdminDeviceLatchesProps = {
  box_id: string;
};

const AdminDeviceLatches = ({ box_id }: AdminDeviceLatchesProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const latches = useSelector((state: any) => {
    return state.deviceStatus.device_latches;
  });

  React.useEffect(() => {
    dispatch(adminDeviceGetLatches(box_id));
  }, [dispatch, box_id]);

  function _handleUSPSChange(event: any) {
    dispatch(adminDeviceSetUSPS(event.target.name, event.target.checked));
  }

  function _handleTruckbaysChange(event: any) {
    dispatch(adminDeviceSetTruckbays(event.target.name, event.target.checked));
  }

  function _handleUPSChange(event: any) {
    dispatch(adminDeviceSetUPS(event.target.name, event.target.checked));
  }

  function _handleGrataChange(event: any) {
    dispatch(adminDeviceSetGrata(event.target.name, event.target.checked));
  }

  return (
    <Box>
      {latches.map((latch: any) => (
        <Card key={latch.id} style={{ margin: 8, padding: 8 }}>
          <CardContent>
            <Box>
              <DeviceLatch latch={latch} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", paddingTop: 2 }}>
              <FormControlLabel
                control={<Switch checked={latch.allow_usps} onChange={_handleUSPSChange} name={latch.id} />}
                label="USPS"
              />
              <FormControlLabel
                control={<Switch checked={latch.allow_truckbays} onChange={_handleTruckbaysChange} name={latch.id} />}
                label="Truckbays"
              />
              <FormControlLabel
                control={<Switch checked={latch.allow_ups} onChange={_handleUPSChange} name={latch.id} />}
                label="UPS"
              />
              <FormControlLabel
                control={<Switch checked={latch.allow_grata} onChange={_handleGrataChange} name={latch.id} />}
                label="Grata"
              />
            </Box>
            {latch.allow_usps && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", paddingTop: 2 }}>
                <Button variant="contained" onClick={() => navigate(`/admin/usps-edit/${latch.id}/${box_id}`)}>
                  USPS DATA
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default AdminDeviceLatches;
