import React, { useEffect } from "react";
import { connect } from "react-redux";

import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import AdminAppBar from "./AdminAppBar";
import AdminCommunityItem from "./AdminCommunityItem";
import { adminGetCommunity, clearAdminCommunity } from "../../../reducers/admin-community-list";

import { setCurrentCommunity } from "../../../reducers/communities-reducer";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { accountSubscription } from "../../../subscriptions/accountSubscription";
import LoadingScreen from "../../common/Components/LoadingScreen";
import nimbioServer from "../../../server/endpoints";
import {
  clearMembers,
  getAcceptedTotalLength,
  getRemovedTotalLength,
  getUnacceptedTotalLength,
} from "../../../reducers/members-reducer";
import { clearKeys } from "../../../reducers/keys-reducer";

const NoCommunityError = () => {
  return <div>A community with that ID was not found.</div>;
};

const AdminCommunityView = (props) => {
  const { communityId } = useParams();
  const dispatch = useDispatch();

  const communityListSubscription = () => {
    dispatch(adminGetCommunity(communityId));
    dispatch(clearMembers());
    dispatch(getAcceptedTotalLength(communityId));
    dispatch(getUnacceptedTotalLength(communityId));
    dispatch(getRemovedTotalLength(communityId));
  };

  useSubscription(nimbioServer.communityManager.community + "." + communityId, communityListSubscription);
  useSubscription(nimbioServer.admin.subscriptions.community + communityId, communityListSubscription);
  useSubscription(props.accountSubscription, accountSubscription);

  useEffect(() => {
    dispatch(adminGetCommunity(communityId));
    dispatch(setCurrentCommunity(communityId));
    return () => {
      dispatch(clearKeys());
      dispatch(clearAdminCommunity());
    };
  }, [communityId, dispatch]);

  if (props.communityLoading || !props.communityLoaded || !props.community) {
    return (
      <AdminAppBar selected="Community" {...props}>
        <Box>
          <LoadingScreen />
        </Box>
      </AdminAppBar>
    );
  }

  let type = props.community.active ? "active" : "inactive";
  if (props.community.hidden) {
    type = "hidden";
  }

  return (
    <AdminAppBar selected="Community" {...props}>
      {props.community && !props.communityLoading && props.communityLoaded && !props.communityError && (
        <AdminCommunityItem community={props.community} type={type} />
      )}
      {props.communityError && <NoCommunityError />}
    </AdminAppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    community: state.adminCommunityList.community,
    communityLoaded: state.adminCommunityList.communityLoaded,
    communityError: state.adminCommunityList.communityError,
    accountSubscription: state.profileState.details.subscriptions[0],
  };
};

export default connect(mapStateToProps)(AdminCommunityView);
