import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextField from "@mui/material/TextField/TextField";
import useConfirm from "../../common/Components/useConfirm";
import BoxLatchSelect from "../../common/Components/BoxLatchSelect";
import SelectMembers from "../../common/Components/SelectMembers";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";
import { Home, ServerResponseType } from "../../../utilities/nimbioTypes";

type AdminAddCommunityKeyProps = {
  open: boolean;
  onClose: any;
};

const statusBoxStyle = { display: "flex", flexDirection: "row", paddingTop: 1 };
const statusTypeStyle = { paddingLeft: 2 };

const initialStatusState: any = {
  keyCreated: false,
  latchesAdded: false,
  keyAddedToCommunity: false,
  membersAddedToKey: false,
};

const AdminCreateCommunityKey = (props: AdminAddCommunityKeyProps) => {
  const { open, onClose } = props;
  const [keyName, setKeyName] = useState("");
  const [error, setError] = useState("");
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([]);
  const [checkedUsers, setCheckedUsers] = useState<Record<string, boolean>>({});
  const [userLookUp, setUserLookUp] = useState<Record<string, any>>({});
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [statusState, setStatusState] = useState<any>(initialStatusState);
  const [selectType, setSelectType] = useState<string>("select-all");
  const [selectedLatches, setSelectedLatches] = useState<any>([]);
  const { ConfirmDialog, openDialog } = useConfirm();
  const community_id = useSelector((state: any) => state.adminCommunityList.community.id);
  const acceptedMembers = useSelector((state: any) => state.membersState.members.accepted.members);

  const steps = ["Enter Key Name", "Add Latches", "Select Members"];
  const handleResetState = () => {
    setKeyName("");
    setError("");
    setSelectedMemberIds([]);
    setCheckedUsers({});
    setUserLookUp({});
    setLoading(false);
    setStatusState(initialStatusState);
    setSelectedLatches([]);
    setActiveStep(0);
    setSelectType("select-all");
  };

  const handleKeyNameChange = (e: any) => {
    setKeyName(e.target.value);
    setError("");
  };

  const handleClose = () => {
    onClose();
  };

  const handleServerError = (response: ServerResponseType) => {
    setActiveStep(3);
    setError(response["message"]);
    setLoading(false);
  };

  const handleCreate = async () => {
    setLoading(true);

    const status: any = {
      keyCreated: false,
      latchesAdded: false,
      keyAddedToCommunity: false,
      membersAddedToKey: false,
    };

    const response1 = await call(nimbioServer.admin.key.createKey, [keyName], "AdminCreateCommunityKey");
    if (!response1["result"]) {
      handleServerError(response1);
      return;
    }
    status["keyCreated"] = true;

    const key_id = response1["data"];
    let addLatchError = false;
    const addLatchesResponses = await handleAddLatches(key_id);
    addLatchesResponses.forEach((response) => {
      if (!response["result"]) {
        handleServerError(response);
        addLatchError = true;
      }
    });

    if (addLatchError) {
      setStatusState(status);
      return;
    }
    status["latchesAdded"] = true;

    const response2 = await call(
      nimbioServer.admin.key.addKeyToCommunity,
      [community_id, key_id],
      "AdminCreateCommunityKey"
    );
    if (!response2["result"]) {
      setStatusState(status);
      handleServerError(response2);
      return;
    }
    status["keyAddedToCommunity"] = true;

    const addKeyToMembersResponses = await handleAddKeyToSelectedMembers(key_id);
    const parsed = await Promise.all(addKeyToMembersResponses.flat());

    let errorInAddKeyToMembers = false;
    parsed.length > 0 &&
      parsed[0].forEach((response: any) => {
        if (!response["result"]) {
          handleServerError(response);
          errorInAddKeyToMembers = true;
        }
      });

    if (errorInAddKeyToMembers) {
      setStatusState(status);
      return;
    }

    handleClose();
  };

  const handleAddLatches = async (key_id: string) => {
    const promises = Object.keys(selectedLatches).map((latch_id) => {
      return call(nimbioServer.admin.key.addLatch, [key_id, latch_id, true], "AdminCreateCommunityKey");
    });
    return await Promise.all(promises);
  };

  const handleAddKeyToSelectedMembers = async (key_id: string) => {
    const responses = selectedMemberIds.map(async (member_id) => {
      const data = userLookUp[member_id];
      if (data["is_home"]) {
        return await handleAddKeyToHomeMembers(data as Home, key_id);
      } else {
        const memberKeyIds = data["keys"].map((key: any) => key.parent_key_id);

        const memberSetKeysResponse = await call(
          nimbioServer.community.manager.setMemberKeys,
          [community_id, data.account_community_id, [...memberKeyIds, key_id]],
          "AdminCreateCommunityKey"
        );
        return [memberSetKeysResponse];
      }
    });
    return responses.flat();
  };

  const handleAddKeyToHomeMembers = async (home_data: Home, key_id: string) => {
    const home_members = home_data.members.map((acid: number) => {
      return acid;
    });

    const homeMemberKeysPromises = home_members.map(async (account_community_id: number) => {
      const memberDetailResponse = await call(
        nimbioServer.community.manager.getMember,
        [account_community_id, community_id],
        "AdminCreateCommunityKey"
      );

      if ("result" in memberDetailResponse && !memberDetailResponse["result"]) {
        return memberDetailResponse;
      }
      const memberKeyIds = memberDetailResponse["keys"].map((key: any) => key.parent_key_id);

      const setMemberKeysResponse = call(
        nimbioServer.community.manager.setMemberKeys,
        [community_id, account_community_id, [...memberKeyIds, key_id]],
        "AdminCreateCommunityKey"
      );

      return setMemberKeysResponse;
    });
    return await Promise.all(homeMemberKeysPromises.flat());
  };

  useEffect(() => {
    const initialCheckedState = acceptedMembers.reduce((acc: any, user: any) => {
      acc[user.id] = false;
      return acc;
    }, {} as Record<string, boolean>);
    setCheckedUsers(initialCheckedState);

    const userLookUp = acceptedMembers.reduce((acc: any, user: any) => {
      acc[user.id] = user;
      return acc;
    }, {} as Record<string, any>);
    setUserLookUp(userLookUp);
  }, [acceptedMembers, open]);

  useEffect(() => {
    const selectedUsers = Object.keys(checkedUsers).filter((accountId) => checkedUsers[accountId]);
    if (selectedUsers.length === acceptedMembers.length) {
      setSelectType("deselect-all");
    }
    if (selectedUsers.length === 0) {
      setSelectType("select-all");
    }
    if (selectedUsers.length > 0 && selectedUsers.length !== acceptedMembers.length) {
      setSelectType("partially-selected");
    }
  }, [checkedUsers]);

  useEffect(() => {
    if (!open) {
      setTimeout(handleResetState, 100);
    }
  }, [open]);

  const handleCheckboxChange = (accountId: string, value: boolean) => {
    const checkedUsersData = {
      ...checkedUsers,
      [accountId]: value,
    };

    const selectedUsers = Object.keys(checkedUsersData).filter((accountId) => checkedUsersData[accountId]);
    setSelectedMemberIds(selectedUsers);
    setCheckedUsers(checkedUsersData);
  };

  const handleSetSelectedLatches = (latches: any) => {
    setSelectedLatches(latches);
  };

  const selectAllMembers = () => {
    const checkedUsersData = acceptedMembers.reduce((acc: any, user: any) => {
      acc[user.id] = true;
      return acc;
    }, {} as Record<string, boolean>);
    setCheckedUsers(checkedUsersData);
    setSelectedMemberIds(acceptedMembers.map((user: any) => user.id));
  };

  const deselectAllMembers = () => {
    const checkedUsersData = acceptedMembers.reduce((acc: any, user: any) => {
      acc[user.id] = false;
      return acc;
    }, {} as Record<string, boolean>);
    setCheckedUsers(checkedUsersData);
    setSelectedMemberIds([]);
  };

  const handleClickNext = async () => {
    if (activeStep === 0 && keyName === "") {
      setError("Key name cannot be empty");
      return;
    }

    if (activeStep === 1 && Object.keys(selectedLatches).length === 0) {
      openDialog({
        heading: "No Latches Selected",
        message: "Are you sure you want to create this key with no latches?",
        confirmCallback: async () => await handleNext(),
        okButton: "Confirm",
        closeCallback: () => {
          console.log("closing");
        },
        cancelButton: "Cancel",
      });
      return;
    }

    if (activeStep === 2 && selectedMemberIds.length === 0) {
      openDialog({
        heading: "No Members Selected",
        message: "Are you sure you want to create this key with no assigned members?",
        confirmCallback: async () => await handleCreate(),
        okButton: "Confirm",
        closeCallback: () => {
          console.log("closing");
        },
        cancelButton: "Cancel",
      });
      return;
    }

    if (activeStep === 2) {
      openDialog({
        heading: "Create Community Key",
        message: "Are you sure you want to create this community key?",
        confirmCallback: async () => await handleCreate(),
        okButton: "Confirm",
        closeCallback: () => {
          console.log("closing");
        },
        cancelButton: "Cancel",
      });
      return;
    }
    await handleNext();
  };

  const handleNext = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>Create Community Key</DialogTitle>

      <DialogContent dividers>
        {!loading ? (
          <Box sx={{ width: "100%", marginTop: 2 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => {
                const stepProps: { completed?: boolean } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === 3 && error ? (
              <React.Fragment>
                <Box sx={{ padding: 2 }}>
                  {error !== "" ? <Typography style={{ color: "red" }}>Error: {error}</Typography> : null}
                  <Box>
                    <Box data-testid={`admin-create-community-key-key-created`} sx={statusBoxStyle}>
                      {statusState["keyCreated"] ? (
                        <CheckCircleOutlineIcon color={"success"} />
                      ) : (
                        <HighlightOffIcon data-testid={"error-creating-key"} color={"error"} />
                      )}
                      <Typography sx={statusTypeStyle}>Key Created</Typography>{" "}
                    </Box>
                    <Box data-testid={`admin-create-community-key-latches-added`} sx={statusBoxStyle}>
                      {statusState["latchesAdded"] ? (
                        <CheckCircleOutlineIcon color={"success"} />
                      ) : (
                        <HighlightOffIcon data-testid={"error-adding-latches"} color={"error"} />
                      )}
                      <Typography sx={statusTypeStyle}>Latches Added</Typography>{" "}
                    </Box>
                    <Box data-testid={`admin-create-community-key-key-added-to-community`} sx={statusBoxStyle}>
                      {statusState["keyAddedToCommunity"] ? (
                        <CheckCircleOutlineIcon color={"success"} />
                      ) : (
                        <HighlightOffIcon data-testid="error-adding-key-to-community" color={"error"} />
                      )}
                      <Typography sx={statusTypeStyle}>Key Added to Community</Typography>{" "}
                    </Box>
                    <Box data-testid={`admin-create-community-key-members-added`} sx={statusBoxStyle}>
                      {statusState["membersAddedToKey"] ? (
                        <CheckCircleOutlineIcon color={"success"} />
                      ) : (
                        <HighlightOffIcon data-testid={"error-adding-members"} color={"error"} />
                      )}
                      <Typography sx={statusTypeStyle}>Members Added to Key</Typography>{" "}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button variant="contained" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {activeStep === 0 ? (
                  <Box sx={{ margin: 2, paddingTop: 2, paddingBottom: 4 }}>
                    <Typography sx={{ marginBottom: 2 }}>Enter the name of the community key. </Typography>
                    {error !== "" ? <Typography style={{ color: "red", paddingBottom: 8 }}>{error}</Typography> : null}
                    <TextField
                      data-testid={"admin-create-community-key-key-name-input"}
                      value={keyName}
                      onChange={handleKeyNameChange}
                      id="standard-basic"
                      label="Key Name"
                    />
                  </Box>
                ) : null}
                {activeStep === 1 ? (
                  <Box sx={{ margin: 2, paddingTop: 2, paddingBottom: 4 }}>
                    <BoxLatchSelect selectedLatches={selectedLatches} setSelectedLatches={handleSetSelectedLatches} />
                    {error !== "" ? <Typography style={{ color: "red" }}>{error}</Typography> : null}
                  </Box>
                ) : null}
                {activeStep === 2 ? (
                  <Box sx={{ margin: 2, paddingTop: 2, paddingBottom: 4 }}>
                    {error !== "" ? <Typography style={{ color: "red" }}>{error}</Typography> : null}
                    <SelectMembers
                      selectAll={selectAllMembers}
                      deselectAll={deselectAllMembers}
                      users={acceptedMembers}
                      checkedUsers={checkedUsers}
                      handleCheckboxChange={handleCheckboxChange}
                      selectType={selectType}
                    />
                  </Box>
                ) : null}

                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button
                    data-testid={"admin-create-community-key-next-button"}
                    variant="contained"
                    disabled={error !== ""}
                    onClick={handleClickNext}>
                    {activeStep === 2 ? "Create Key" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
            <ConfirmDialog />
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AdminCreateCommunityKey;
