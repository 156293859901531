import React from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import AdminAppBar from "./AdminAppBar";
import { columns, rowData } from "../../../utilities/variables";

const UspsMetaData = () => {
  return (
    <div>
      <AdminAppBar selected="USPS Meta Data" />
      <div className="ag-theme-alpine" style={{ height: 500, marginLeft: "300px" }}>
        <AgGridReact rowData={rowData} columnDefs={columns} animateRows={true} rowSelection="multiple" />
      </div>
    </div>
  );
};

export default UspsMetaData;
