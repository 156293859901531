import { applyMiddleware, createStore } from "redux";
import { createPromise } from "redux-promise-middleware";
import { createLogger } from "redux-logger";
import LogRocket from "logrocket";
import { composeWithDevTools } from "@redux-devtools/extension";
import reducers from "./reducers/reducers";

// TODO(rick) i don't like this
if (process.env.TEST !== "true") {
  LogRocket.init("h9ft4w/communitymanager");
}

const middleware = [];

const readOnlyErrorMiddleware = (store) => (next) => (action) => {
  if (action?.payload?.error === "wamp.error.not_authorized") {
    store.dispatch({ type: "SHOW_READ_ONLY_ERROR_MODAL" });
  }
  return next(action);
};
middleware.push(readOnlyErrorMiddleware);

middleware.push(
  createPromise({
    promiseTypeSuffixes: ["LOADING", "SUCCESS", "ERROR"],
  }),
);

const forceDisable = true;
console.log("process.env.REACT_APP_SHOW_LOGS", process.env.REACT_APP_SHOW_LOGS);
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
const enableLogger =
  process.env.REACT_APP_SHOW_LOGS === "TRUE" || (process.env.NODE_ENV === "development" && !forceDisable);
if (enableLogger) {
  console.log("Enabling logger");

  const logger = createLogger();
  middleware.push(logger);
}

middleware.push(
  LogRocket.reduxMiddleware({
    actionSanitizer: function (action) {
      // if (process.env.NODE_ENV === "development" || !action.type.includes("ERROR")) {
      //   return null;
      // }
      if (process.env.NODE_ENV === "development") {
        return null;
      }
      return action;
    },
  }),
);

export function configureStore() {
  return createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));
}

export default configureStore();
