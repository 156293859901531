import React, { useState } from "react";
import { DialogActions, DialogContent, DialogTitle, Typography, TextField, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { cancelHoldOpensUntil } from "../../../reducers/hold-open-reducers";
import EventDateTimePicker from "../../common/Components/EventDateTimePicker";

const CancelFutureHoldOpens = (props) => {
  const { community_id, latch_id, cancelHoldOpensOpen } = props;
  const [open, setOpen] = useState(false);
  const [end_date, setEndDate] = useState(null);
  const [error, setError] = useState(false);

  const handleEndChange = (end_date) => {
    setEndDate(end_date);
    setError(false);
  };

  const resetState = () => {
    setEndDate(null);
    setError(false);
  };

  const formatDatetime = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  const onOK = () => {
    props.dispatch(cancelHoldOpensUntil(community_id, latch_id, formatDatetime(end_date)));
    props.closeCallback();
    resetState();
  };

  const onCancel = () => {
    resetState();
    props.closeCallback();
  };

  const handleOpenDatePicker = () => {
    setOpen(true);
    if (!end_date) {
      setEndDate(moment().add(1, "days"));
    }
  };

  const handleCloseEventDateTimePicker = (isCancel) => {
    if (isCancel) {
      setEndDate(null);
    }
    setOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={cancelHoldOpensOpen} sx={{ textAlign: "center" }}>
      <DialogTitle id="max-width-dialog-title">Postpone All Hold Opens</DialogTitle>
      <DialogContent>
        <Grid
          style={{
            paddingTop: 2,
            paddingBottom: 2,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <Grid sx={{ mb: 2 }} item xs={12}>
            <Typography>All hold opens will be disabled until this date.</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onClick={handleOpenDatePicker}
              value={end_date ? moment(end_date).format("MMMM Do YYYY, h:mm a") : ""}
              sx={{ minWidth: "275px" }}
              placeholder="End Date and Time"
              data-testid="cancel-hold-opens-end-date-time"
            />
          </Grid>
          <EventDateTimePicker
            open={open}
            setDateTimeCallback={(endDate) => handleEndChange(endDate)}
            firstPageTitle="Set End Date"
            secondPageTitle="Set End Time"
            datetime={end_date}
            closeCallback={handleCloseEventDateTimePicker}
            startingPage="date"
          />
          {error ? <Typography style={{ textAlign: "center", paddingTop: 1, color: "red" }}>{error}</Typography> : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          data-testid={"confirm-cancel-future-hold-opens"}
          onClick={onOK}
          disabled={!end_date || error}
          variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelFutureHoldOpens.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  cancelHoldOpensOpen: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
};

export default connect()(CancelFutureHoldOpens);
