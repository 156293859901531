import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { PaginationComponentType } from "../../../utilities/nimbioTypes";

const PaginationComponent = ({ itemsPerPage, totalItems, paginate, currentPage }: PaginationComponentType) => {
  const [curPage, setCurPage] = useState<number>(currentPage);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    setCurPage(currentPage);
  }, [currentPage]);

  const handlePagination = (event: any, page: number) => {
    setCurPage(page);
    paginate(page);
  };

  return (
    <div className="pagination">
      <Pagination count={pageNumbers.length} onChange={handlePagination} page={curPage} color="primary" />
    </div>
  );
};

export default PaginationComponent;
