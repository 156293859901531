import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteVendorFromLatch = createAsyncAction("REMOVE_VENDOR_FROM_LATCH_DATA", (latch_id, vendor_uuid) => {
  return call("com.nimbio.admin.remove_vendor_from_latch", [latch_id, vendor_uuid], "admin-get-latches-vendors");
});

export const getLatchesVendors = createAsyncAction("ADMIN_GET_LATCHES_VENDORS", (box_id, latch_id) => {
  return call("com.nimbio.admin.get_vendor_by_latch", [box_id, latch_id], "admin-get-latches-vendors");
});

export const sendVendorToLatchData = createAsyncAction("SEND_VENDOR_TO_LATCH_DATA", (latch_id, vendor_uuid) => {
  return call("com.nimbio.admin.add_vendor_to_latch", [latch_id, vendor_uuid], "admin-get-latches-vendors");
});

export default handleActions(
  {
    ADMIN_GET_LATCHES_VENDORS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_LATCHES_VENDORS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_LATCHES_VENDORS_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
