import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  ListItemSecondaryAction,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { adminSetCommunityContactInfo } from "../../../reducers/admin-community-list";

const styles = {
  contactKey: {
    textTransform: "capitalize",
  },
  editContactInfo: {
    color: "#24a0ed",
  },
};

const CommunityContactInfo = (props) => {
  const [editOpen, setEditOpen] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const [addRowOpen, setAddRowOpen] = useState(false);
  const [addRowName, setAddRowName] = useState("");

  const _handle_edit_open = () => {
    setEditOpen(true);
  };

  const _handle_edit_save = () => {
    props.dispatch(adminSetCommunityContactInfo(props.community_id, contactInfo));
    setEditOpen(false);
  };

  const _handle_edit_cancel = () => {
    setContactInfo(props.contact_info);
    setEditOpen(false);
  };

  const _handle_add_open = () => {
    setAddRowOpen(true);
  };

  const _handle_add_save = () => {
    props.dispatch(adminSetCommunityContactInfo(props.community_id, { ...props.contact_info, [addRowName]: "" }));
    setAddRowOpen(false);
    setAddRowName("");
  };

  const _handle_add_cancel = () => {
    setAddRowOpen(false);
    setAddRowName("");
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body2" color="textSecondary" component="p">
          Contact Info
        </Typography>
        <IconButton aria-label="settings" size="large" onClick={_handle_edit_open}>
          <EditIcon color="primary" />
        </IconButton>
      </Grid>

      <List>
        {props.contact_info
          ? Object.keys(props.contact_info).map((key) => (
              <ListItem key={key}>
                <ListItemText sx={styles.contactKey}>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {key}
                  </Typography>
                </ListItemText>
                <ListItemSecondaryAction>{props.contact_info[key]}</ListItemSecondaryAction>
              </ListItem>
            ))
          : null}
      </List>

      <Dialog open={editOpen} onClose={_handle_edit_cancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Contact Info</DialogTitle>
        <DialogContent>
          <DialogContentText>Update Contact Information Below</DialogContentText>
          {props.contact_info
            ? Object.keys(props.contact_info).map((key) => (
                <TextField
                  key={key}
                  margin="dense"
                  id={key}
                  label={key}
                  fullWidth
                  value={!contactInfo ? props.contact_info[key] : contactInfo[key]}
                  onChange={(event) => {
                    setContactInfo(() => {
                      return { ...props.contact_info, ...contactInfo, [key]: event.target.value };
                    });
                  }}
                />
              ))
            : null}
          <Button variant="contained" onClick={_handle_add_open}>
            Add Information
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={_handle_edit_cancel}>Cancel</Button>
          <Button onClick={_handle_edit_save}>Save</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addRowOpen} onClose={_handle_add_cancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Row</DialogTitle>
        <DialogContent>
          <DialogContentText>Please label the contact data</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="add_row"
            label="Data Name"
            fullWidth
            value={addRowName}
            onChange={(event) => setAddRowName(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={_handle_add_cancel}>Cancel</Button>
          <Button onClick={_handle_add_save}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CommunityContactInfo.propTypes = {
  contact_info: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.number.isRequired]),
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunityContactInfo);
