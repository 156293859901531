// TODO(rick) is there a reason we're stripping data from the payload here?
//   currently it's droppping allow_subkeys, community_type, and id from keys
export function parseCommunities(payload) {
  if (!payload) {
    return payload;
  }
  let communityReturn = {};
  payload.forEach((item) => {
    communityReturn[item.id] = {
      name: item.name,
      permissions: item.permissions,
      allow_hold_opens: item.allow_hold_opens,
      is_new_member_request_home_enabled: item.is_new_member_request_home_enabled,
      community_type: item.community_type,
    };
  });
  return communityReturn;
}
