export function parseHomes(payload) {
  let homesReturn = {};
  if (payload && payload.result) {
    payload.forEach((item) => {
      homesReturn[item.id] = {
        id: item.id,
        address: item.address,
        name: item.name,
      };
    });
  }
  return homesReturn;
}
