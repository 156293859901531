import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import { connect } from "react-redux";
import moment from "moment-timezone";
import DeleteIcon from "@mui/icons-material/Delete";
import EventDateTimePicker from "./EventDateTimePicker";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";
import { formatDatetime } from "../../../utilities/formatDatetime";

const DeviceSetIgnoreAlertsUntil = (props) => {
  const [ignoreReason, setIgnoreReason] = useState("");
  const [ignoreUntilDate, setIgnoreUtilDate] = useState(null);
  const [ignoreUntilOpen, setIgnoreUntilOpen] = useState(false);
  const [error, setError] = useState(false);

  const setReason = (value) => {
    setIgnoreReason(value);
  };
  const handleDateChange = (date) => {
    setIgnoreUtilDate(date);
    setError(false);
  };

  const handleCancel = () => {
    setIgnoreUntilOpen(false);
    setIgnoreReason("");
    setIgnoreUtilDate(null);
    props.closeCallback();
  };

  const handleOpenDatePicker = () => {
    setIgnoreUntilOpen(true);
    if (!ignoreUntilDate) {
      setIgnoreUtilDate(moment().add(1, "days"));
    }
  };

  const setIgnoreAlertsUntilDateAndReason = async (box_id, ignore_until, ignore_reason) => {
    await call(
      nimbioServer.admin.setBoxIgnoreUntilDateAndReason,
      [box_id, ignore_until, ignore_reason],
      "DeviceSetIgnoreAlertsUntil",
    );
  };

  const saveIgnoreUntilDateAndReason = (is_clear) => {
    if (is_clear) {
      setIgnoreAlertsUntilDateAndReason(props.box_id, null, null);
      return;
    } else {
      var currentDate = moment();
      if (ignoreUntilDate === null) {
        setError("Please select a date");
        return;
      }
      if (ignoreUntilDate.isBefore(currentDate)) {
        setError("Invalid Date");
        return;
      }
      const formattedDate = formatDatetime(ignoreUntilDate);
      setIgnoreAlertsUntilDateAndReason(props.box_id, formattedDate, ignoreReason);
      setIgnoreReason("");
      setIgnoreUtilDate(null);
      props.closeCallback();
    }
  };

  const handleCloseEventDateTimePicker = (isCancel) => {
    if (isCancel) {
      setIgnoreUtilDate(null);
    }
    setIgnoreUntilOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeCallback}
      aria-labelledby="form-dialog-title"
      data-testid={"ignore-alerts"}>
      <DialogTitle id="form-dialog-title">Ignore Device Alerts Until</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Box>
            {props.device.ignore_alerts_active ? (
              <Box>
                <DialogContentText sx={{ color: "red" }}>
                  {`Alerts Ignored Until: ${formatDatetime(props.device.ignore_alerts_until)}`}
                </DialogContentText>
                <DialogContentText>
                  {props.device.ignore_alerts_reason ? `Reason: ${props.device.ignore_alerts_reason}` : ""}
                </DialogContentText>
              </Box>
            ) : props.device.ignore_alerts_until ? (
              <>
                <DialogContentText sx={{ color: "green" }}>{"Alerts: Active"}</DialogContentText>
                <DialogContentText>{`Ignore Alerts Expired on ${formatDatetime(
                  props.device.ignore_alerts_until,
                )}`}</DialogContentText>
                <DialogContentText>Reason: {props.device.ignore_alerts_reason}</DialogContentText>
              </>
            ) : (
              <DialogContentText sx={{ color: "green" }}>{"Alerts: Active"}</DialogContentText>
            )}
          </Box>
          {props.device.ignore_alerts_until ? (
            <Button onClick={() => saveIgnoreUntilDateAndReason(true)}>Clear</Button>
          ) : null}
        </Box>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <Button sx={{ marginTop: 1, marginBottom: 1 }} onClick={handleOpenDatePicker}>
          Select Date that Alerts will be Disabled until.
        </Button>
        <Box>
          {error ? <DialogContentText sx={{ color: "red" }}>{error}</DialogContentText> : null}
          {ignoreUntilDate ? (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <DialogContentText sx={{ marginTop: 1, marginBottom: 1 }}>
                Selected Date: {formatDatetime(ignoreUntilDate)}
              </DialogContentText>
              <IconButton
                style={{ padding: 4 }}
                onClick={() => {
                  setIgnoreUtilDate(null);
                }}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : null}
        </Box>
        <TextField
          sx={{ marginTop: 2 }}
          autoFocus
          margin="dense"
          id="device_name"
          label="Provide a reason for ignoring alerts"
          type="text"
          fullWidth
          value={ignoreReason}
          onChange={(event) => setReason(event.target.value)}
        />
        <EventDateTimePicker
          open={ignoreUntilOpen}
          setDateTimeCallback={(ignoreUntilDate) => handleDateChange(ignoreUntilDate)}
          firstPageTitle="Set Date to Ignore Alerts Until"
          datetime={ignoreUntilDate ? ignoreUntilDate : moment()}
          dateOnly={true}
          closeCallback={handleCloseEventDateTimePicker}
          startingPage="date"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button variant="outlined" color="primary" onClick={() => saveIgnoreUntilDateAndReason(false)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeviceSetIgnoreAlertsUntil.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  box_id: PropTypes.string.isRequired,
  device: PropTypes.object.isRequired,
};

export default connect()(DeviceSetIgnoreAlertsUntil);
