import React, { useState } from "react";
import { DialogActions, DialogContent, DialogTitle, Grid, Typography, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { addHoldOpenTemporalTime } from "../../../reducers/hold-open-reducers";
import EventDateTimePicker from "../../common/Components/EventDateTimePicker";

const AddTime = (props) => {
  const initialState = {
    start_time: null,
    end_time: null,
    error: false,
  };

  const [timeState, setTimeState] = useState(initialState);
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);

  const holdOpens = props.holdOpens.hold_opens[props.latch_id];
  const timezone = holdOpens.timezone;

  const _check_dates = (start_time, end_time) => {
    if (start_time && end_time) {
      if (parseInt(start_time.format("HH")) > parseInt(end_time.format("HH"))) {
        return "End time must be after start time";
      }
    }
    return false;
  };

  const _handleStartChange = (start_time) => {
    setTimeState({ ...timeState, start_time, error: _check_dates(start_time, timeState.end_time) });
  };

  const _handleEndChange = (end_time) => {
    setTimeState({ ...timeState, end_time, error: _check_dates(timeState.start_time, end_time) });
  };

  const _formatTime = (time) => {
    return moment(time).format("HH:mm");
  };

  const handleCloseStartTime = (isCancel) => {
    if (isCancel) {
      setTimeState({ ...timeState, start_time: null });
    }
    setStartTimeOpen(false);
  };

  const handleCloseEndTime = (isCancel) => {
    if (isCancel) {
      setTimeState({ ...timeState, end_time: null });
    }
    setEndTimeOpen(false);
  };

  const _onOK = () => {
    props.dispatch(
      addHoldOpenTemporalTime(
        props.community_id,
        props.latch_id,
        props.temporal_date_id,
        _formatTime(timeState.start_time),
        _formatTime(timeState.end_time),
      ),
    );
    setTimeState(initialState);
    props.closeCallback();
  };
  const _onCancel = () => {
    setTimeState(initialState);
    props.closeCallback();
  };
  const _handleOpenStartTime = () => {
    setStartTimeOpen(true);
    if (!timeState.start_time) {
      const start_time = moment.tz(moment(), timezone);
      const error = _check_dates(start_time, timeState.end_time);
      setTimeState({ ...timeState, start_time, error });
    }
  };
  const _handleOpenEndTime = () => {
    setEndTimeOpen(true);
    if (!timeState.end_time) {
      const end_time = moment.tz(moment().add(1, "hour"), timezone);
      const error = _check_dates(timeState.start_time, end_time);
      setTimeState({ ...timeState, end_time, error });
    }
  };

  return (
    <Dialog data-testid="add-time-dialog" fullWidth maxWidth="lg" open={props.open}>
      <DialogTitle id="max-width-dialog-title">Add Time</DialogTitle>
      <DialogContent>
        <Box style={{ paddingTop: 2, paddingBottom: 2 }}>
          <Typography sx={{ paddingBottom: 2 }}>
            Set the start and end times below for when the gate/door should be held open.
          </Typography>

          <Grid container style={{ justifyContent: "center" }} alignItems="center" direction="row">
            <Grid item>
              <Grid container sx={{ paddingTop: 1, justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Grid item sx={{ margin: 2 }}>
                  <TextField
                    variant="outlined"
                    onClick={_handleOpenStartTime}
                    value={timeState.start_time ? moment(timeState.start_time).format("h:mm a") : ""}
                    sx={{ minWidth: "275px" }}
                    placeholder="Start Time"
                    data-testid="add-recurring-start-time"
                  />
                  <EventDateTimePicker
                    open={startTimeOpen}
                    setDateTimeCallback={(start_date) => _handleStartChange(start_date)}
                    secondPageTitle="Set Event Start Time"
                    datetime={timeState.start_time}
                    closeCallback={handleCloseStartTime}
                    startingPage="time"
                  />
                </Grid>
                <Grid item sx={{ margin: 2 }}>
                  <TextField
                    variant="outlined"
                    onClick={_handleOpenEndTime}
                    value={timeState.end_time ? moment(timeState.end_time).format("h:mm a") : ""}
                    sx={{ minWidth: "275px" }}
                    placeholder="End Time"
                    data-testid="add-recurring-end-time"
                  />
                  <EventDateTimePicker
                    open={endTimeOpen}
                    setDateTimeCallback={(start_date) => _handleEndChange(start_date)}
                    secondPageTitle="Set Event End Time"
                    datetime={timeState.end_time}
                    closeCallback={handleCloseEndTime}
                    startingPage="time"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {timeState.error && (
            <Typography style={{ textAlign: "center", paddingTop: 1, color: "red" }}>{timeState.error}</Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancel} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={_onOK}
          disabled={!timeState.start_time || !timeState.end_time || timeState.error}
          variant="contained"
          data-testid="add-time-ok">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddTime.propTypes = {
  temporal_date_id: PropTypes.string.isRequired,
  closeCallback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    holdOpens: state.holdOpens,
  };
};

export default connect(mapStateToProps)(AddTime);
