import React from "react";
import "../../App.css";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { Card } from "@mui/material";
import HomeListItemDetails from "./Components/HomeListItemDetail";
import HomeList from "./Components/HomesList";
import { APP_BAR_ITEMS } from "../common/Components/CommunityManagerAppBar";
import CommunityManagerAppBar from "../common/Components/CommunityManagerAppBar";

function Homes(props) {
  const { communityId } = useParams();
  const communityIdValue = parseInt(communityId);
  const navigate = useNavigate();

  if (!communityIdValue) {
    navigate("/pick-community");
    return;
  }

  return (
    <div>
      <CommunityManagerAppBar selected={APP_BAR_ITEMS.HOMES} {...props}>
        <Card sx={{ padding: 5 }}>
          <HomeList community_id={communityIdValue} />
          <HomeListItemDetails />
        </Card>
      </CommunityManagerAppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    communityState: state.communityState,
  };
};

export default connect(mapStateToProps)(Homes);
