import React, { useEffect, useState } from "react";
import "../../App.css";
import { IconButton, Menu, MenuItem, Card, Typography } from "@mui/material";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import KeyIcon from "@mui/icons-material/Key";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import CommunityManagerAppBar from "../common/Components/CommunityManagerAppBar";
import { clearKeys, getKeys } from "../../reducers/keys-reducer";
import CommunityLatch from "../common/Components/CommunityLatch";
import endpoints from "../../routes/endpoints";
import { APP_BAR_ITEMS } from "../common/Components/CommunityManagerAppBar";
import CommunityKeySwitchDialog from "../common/Components/CommunityKeySwitchDialog";

export function CommunityManagerKeysList(props) {
  const { communityId } = useParams();
  const [communityKeySwitchDialogOpen, setCommunityKeySwitchDialogOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);

  const handleMenuPress = (event) => {
    if (!menuAnchor) {
      setMenuAnchor(event.currentTarget);
    }
  };

  const handleOpenEnableDisableDialog = () => {
    setCommunityKeySwitchDialogOpen(true);
  };

  const handleCloseEnableDisableDialog = () => {
    setCommunityKeySwitchDialogOpen(false);
    setMenuAnchor(null);
  };

  const handleMenuClose = () => {
    setMenuAnchor(false);
  };

  // TODO(rick) this looks weird given that keys is an array
  if (props.keyState.keys.result == false) {
    return null;
  }

  return props.keyState.keys.map((key) => {
    return (
      <Card sx={{ margin: "12px", padding: "6px" }} key={key.id} data-testid={"keys-list-item"}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {key.disabled === 1 ? (
              <Box mt={2} ml={2}>
                <KeyOffIcon color="error" />
              </Box>
            ) : (
              <Box mt={2} ml={2}>
                <KeyIcon color="success" />
              </Box>
            )}
            <Typography mt={2} ml={2} variant="body" color="textSecondary">
              {key.name}
            </Typography>
          </Box>
          <IconButton aria-label="settings" size="large" onClick={handleMenuPress}>
            <MoreVertIcon />
            <Menu
              id="settings-menu"
              anchorEl={menuAnchor}
              keepMounted
              open={Boolean(menuAnchor)}
              onClose={handleMenuClose}>
              <MenuItem onClick={handleOpenEnableDisableDialog}>Enable/Disable Key</MenuItem>
            </Menu>
          </IconButton>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", wrap: "flex-wrap" }}>
          {key.latches &&
            key.latches.map((latch) => (
              <CommunityLatch key={latch.id} type="cm_configure_latches" latch={latch} key_id={key.id} />
            ))}
        </Box>

        <CommunityKeySwitchDialog
          communityId={parseInt(communityId)}
          keyId={key.id}
          disabled={key.disabled}
          open={communityKeySwitchDialogOpen}
          closeCallback={handleCloseEnableDisableDialog}
        />
      </Card>
    );
  });
}

function Keys(props) {
  const { communityId } = useParams();
  const { dispatch } = props;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getKeys(communityId));
  }, [dispatch, communityId]);

  useEffect(() => {
    return () => {
      dispatch(clearKeys());
    };
  }, []);

  // TODO(rick) this looks weird given that keys is an array
  if (props.keyState.keys.result == false) {
    navigate(endpoints.PICK_COMMUNITY);
    return;
  }

  if (communityId === null) {
    navigate(endpoints.PICK_COMMUNITY);
    return;
  }

  return (
    <div>
      <CommunityManagerAppBar selected={APP_BAR_ITEMS.KEYS} {...props}>
        <Box id={"nimbio-testing" + endpoints.COMMUNITY_KEYS + "-container"} data-testid={"keys-list"}>
          <CommunityManagerKeysList keyState={props.keyState} />
        </Box>
      </CommunityManagerAppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    keyState: state.keysState,
  };
};

export default connect(mapStateToProps)(Keys);
