import React from "react";
import { useDispatch } from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Avatar } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { getColor, getShortName } from "../../../utilities/materialAvatar";
import { openRemovedMemberDetails } from "../../../reducers/removed-member-detail-reducer";

const styles = (theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  removal_date: {
    textAlign: "left",
  },
  name_phone_container: {
    textAlign: "left",
    maxWidth: "144px",
    paddingRight: "16px",
  },
});

const RemovedItem = (props) => {
  const { details } = props;
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <Box sx={styles(theme).root} data-testid={"removed-item"}>
      <ListItemButton
        data-testid={"removed-item-button"}
        onClick={() => dispatch(openRemovedMemberDetails(details))}
        sx={{ display: "flex" }}>
        <ListItemIcon>
          <Avatar style={{ backgroundColor: getColor(details["id"]) }}>
            {getShortName(details.first_name, details.last_name)}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          sx={styles(theme).name_phone_container}
          secondary={
            details["phone_numbers"] && details["phone_numbers"].length > 0 ? details["phone_numbers"][0] : "Unknown"
          }>
          {details["first_name"]} {details["last_name"]}
        </ListItemText>
      </ListItemButton>
    </Box>
  );
};

export default RemovedItem;
