import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  keys: null,
  loaded: false,
};

export const accountGetKeys = createAsyncAction("ACCOUNT_GET_KEYS", (account_id) => {
  return call(nimbioServer.account.getKeysByAccountID, [account_id], "account-get-keys");
});

export default handleActions(
  {
    ACCOUNT_GET_KEYS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        keys: null,
      };
    },
    ACCOUNT_GET_KEYS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        keys: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ACCOUNT_GET_KEYS_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        keys: null,
        loaded: true,
      };
    },
  },
  initialState,
);
