import store from "../store";
import { getHomes } from "../reducers/homes-reducer";
import { getKeys } from "../reducers/keys-reducer";
import {
  clearMembers,
  getAcceptedTotalLength,
  getRemovedTotalLength,
  getUnacceptedTotalLength,
} from "../reducers/members-reducer";
import { getHomeDetail } from "../reducers/homedetail-reducer";
import { getCommunities } from "../reducers/communities-reducer";

export function communitySubscription(message) {
  message = message.argsList[0];
  if ("a" in message) {
    switch (message.a) {
      case "refresh_homes":
        store.dispatch(getHomes(store.getState().communityState.currentCommunityID));
        break;
      case "refresh_community_list":
        store.dispatch(clearMembers());
        store.dispatch(getAcceptedTotalLength(store.getState().communityState.currentCommunityID));
        store.dispatch(getUnacceptedTotalLength(store.getState().communityState.currentCommunityID));
        store.dispatch(getRemovedTotalLength(store.getState().communityState.currentCommunityID));

        if (store.getState().homeDetailState.open && store.getState().homeDetailState.loading === false) {
          store.dispatch(
            getHomeDetail(store.getState().homeDetailState.home_id, store.getState().homeDetailState.community_id)
          );
        }
        break;
      case "refresh_community_keys":
        store.dispatch(getKeys(store.getState().communityState.currentCommunityID));
        break;
      case "refresh_community_meta_data":
        store.dispatch(getCommunities());
        break;
      default:
        console.log("<B>Message received and not handled", message);
    }
  }
}
