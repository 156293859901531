import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Drawer from "@mui/material/Drawer";
import { Hidden, List, Box, Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import { connect, useDispatch } from "react-redux";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../reducers/connection-reducer";
import { nimbioPalette } from "../../../theme";
import endpoints from "../../../routes/endpoints";
import NimbioAppBar from "../../common/Components/NimbioAppBar";

const drawerWidth = 265;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
  },
  content: {
    flex: 1,
    p: 2,
    pt: 3,
    gap: theme.spacing(1),
    backgroundColor: nimbioPalette.white,
  },
  commHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.mixins.toolbar.minHeight,
    mt: 1,
  },
  versionContainer: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    marginBottom: theme.spacing(8),
  },
  versionText: {
    cursor: "pointer",
  },
});

const APP_BAR_ITEMS = {
  DEVICE_MONITOR: "Device Monitor",
  HIDDEN_DEVICES: "Hidden Devices",
  RECENT_DEVICES: "Recent Devices",
  INTEGRATED_LATCHES: "Integrated Devices",
  ACCOUNTS: "Accounts",
  ACCOUNT_GROUPS: "Account Groups",
  VENDORS: "Vendors",
  COMMUNITIES: "Communities",
  SIGN_OUT: "Logout",
  UNIVERSAL_SEARCH: "Universal Search",
  ADD_INSTALLER: "Add Installer",
  ADD_DISTRIBUTOR: "Add Distributor",
};

const TESTING_PREFIX = "nimbio-testing-appbar-";

export const AdminAppBarDrawer = (props) => {
  const [buildNumberClickedCount, setBuildNumberClickedCount] = useState(0);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBuildVersionClick = () => {
    if (buildNumberClickedCount === 10) {
      navigate(endpoints.ADMIN_MAINTENANCE_MODE);
      setBuildNumberClickedCount(0);
    } else {
      setBuildNumberClickedCount((oldValue) => oldValue + 1);
    }
  };

  return (
    <Box
      id={"nimbio-testing-appbar-Admin"}
      sx={{ display: "flex", flexDirection: "column", flex: 1 }}
      data-testid={"drawer"}>
      <Box sx={styles(theme).toolbar}>
        <Box sx={styles(theme).commHeader} data-testid={"title"}>
          {props.selected}
        </Box>
      </Box>
      <Divider />
      <List>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.DEVICE_MONITOR.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.DEVICE_MONITOR}
          key={APP_BAR_ITEMS.DEVICE_MONITOR}
          onClick={() => navigate(endpoints.ADMIN_DEVICE_MONITOR)}
          data-testid={"device-monitor"}>
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.DEVICE_MONITOR} />
        </ListItemButton>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.HIDDEN_DEVICES.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.HIDDEN_DEVICES}
          key={APP_BAR_ITEMS.HIDDEN_DEVICES}
          onClick={() => navigate(endpoints.ADMIN_HIDDEN_DEVICE_MONITOR)}
          data-testid={"hidden-devices"}>
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.HIDDEN_DEVICES} />
        </ListItemButton>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.RECENT_DEVICES.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.RECENT_DEVICES}
          key={APP_BAR_ITEMS.RECENT_DEVICES}
          onClick={() => navigate(endpoints.ADMIN_RECENT_DEVICE_MONITOR)}
          data-testid={"recent-devices"}>
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.RECENT_DEVICES} />
        </ListItemButton>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.INTEGRATED_LATCHES.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.INTEGRATED_LATCHES}
          key={APP_BAR_ITEMS.INTEGRATED_LATCHES}
          onClick={() => navigate(endpoints.ADMIN_INTEGRATED_LATCHES)}
          data-testid={"integrated-latches"}>
          <ListItemIcon>
            <DeviceHubIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.INTEGRATED_LATCHES} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.ACCOUNTS.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.ACCOUNTS}
          key={APP_BAR_ITEMS.ACCOUNTS}
          onClick={() => navigate(endpoints.ADMIN_ACCOUNT_LIST)}
          data-testid={"accounts"}>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.ACCOUNTS} />
        </ListItemButton>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.ACCOUNT_GROUPS.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.ACCOUNT_GROUPS}
          key={APP_BAR_ITEMS.ACCOUNT_GROUPS}
          onClick={() => navigate(endpoints.ADMIN_ACCOUNT_GROUPS)}
          data-testid={"account-groups"}>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.ACCOUNT_GROUPS} />
        </ListItemButton>

        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.VENDORS.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.VENDORS}
          key={APP_BAR_ITEMS.VENDORS}
          onClick={() => navigate(endpoints.ADMIN_VENDORS)}
          data-testid={"vendors"}>
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.VENDORS} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.COMMUNITIES.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.COMMUNITIES}
          key={APP_BAR_ITEMS.COMMUNITIES}
          onClick={() => navigate(endpoints.ADMIN_COMMUNITIES)}
          data-testid={"communities"}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.COMMUNITIES} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        <ListItemButton
          selected={props.selected === APP_BAR_ITEMS.ADD_INSTALLER}
          key={APP_BAR_ITEMS.ADD_INSTALLER}
          onClick={() => navigate("/admin/add-installer")}>
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.ADD_INSTALLER} />
        </ListItemButton>
        <ListItemButton
          selected={props.selected === APP_BAR_ITEMS.ADD_DISTRIBUTOR}
          key={APP_BAR_ITEMS.ADD_DISTRIBUTOR}
          onClick={() => navigate("/admin/add-distributor")}>
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.ADD_DISTRIBUTOR} />
        </ListItemButton>
      </List>
      {props.is_community_manager && (
        <>
          <Divider />
          <List>
            <ListItemButton
              selected={props.selected === "Community Manager"}
              key="Community Manager"
              onClick={() => navigate(endpoints.PICK_COMMUNITY)}
              data-testid={"community-manager"}>
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Comm Manager" />
            </ListItemButton>
          </List>
        </>
      )}
      <>
        <Divider />
        <List>
          <ListItemButton
            selected={props.selected === APP_BAR_ITEMS.UNIVERSAL_SEARCH}
            key="Universal Search"
            onClick={() => navigate(endpoints.ADMIN_UNIVERSAL_SEARCH)}
            data-testid={"admin-app-bar-universal-search"}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary={APP_BAR_ITEMS.UNIVERSAL_SEARCH} />
          </ListItemButton>
        </List>
      </>
      <Divider />
      <List>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.SIGN_OUT.replace(/\s/g, "-")}
          key="Logout"
          onClick={() => {
            dispatch(logout());
          }}
          data-testid={"logout"}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.SIGN_OUT} />
        </ListItemButton>
      </List>
      <List style={styles(theme).versionContainer}>
        <ListItem>
          <Typography variant="caption" style={styles(theme).versionText} onClick={() => handleBuildVersionClick()}>
            Build Version: {process.env.REACT_APP_BUILD_VERSION ? process.env.REACT_APP_BUILD_VERSION : "Dev"}
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

const AdminAppBar = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen((prevVal) => !prevVal);
  };

  return (
    <Box sx={styles(theme).root} data-testid={"admin-app-bar"}>
      <NimbioAppBar title="Admin Dashboard" handleDrawerToggle={handleDrawerToggle} />
      <Box sx={styles(theme).drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        {/* Mobile View */}
        <Hidden>
          <Drawer
            container={props.container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            PaperProps={{
              sx: styles(theme).drawerPaper,
            }}
            sx={{ display: { xs: "block", sm: "none" } }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            <AdminAppBarDrawer
              selected={props.selected}
              history={props.history}
              dispatch={props.dispatch}
              is_community_manager={props.is_community_manager}
            />
          </Drawer>
        </Hidden>
        {/* Desktop View */}
        <Hidden xsDown>
          <Drawer
            PaperProps={{ sx: styles(theme).drawerPaper }}
            sx={{ display: { xs: "none", sm: "block" } }}
            variant="permanent"
            open>
            <AdminAppBarDrawer
              selected={props.selected}
              history={props.history}
              dispatch={props.dispatch}
              is_community_manager={props.is_community_manager}
            />
          </Drawer>
        </Hidden>
      </Box>

      <Box sx={styles(theme).content}>
        <Box sx={styles(theme).toolbar} />
        {props.children}
      </Box>
    </Box>
  );
};

AdminAppBar.propTypes = {
  container: PropTypes.object,
  selected: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    is_community_manager: state.profileState.details.is_community_manager,
  };
};

export default connect(mapStateToProps)(AdminAppBar);
