import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, TextField, Typography } from "@mui/material";
import KeyForAcceptItem from "./KeyForAcceptItem";
import { clearKeys, getKeys } from "../../../reducers/keys-reducer";

const KeysForAcceptPendingMember = (props) => {
  const { community_id, deselectAll } = props;
  const dispatch = useDispatch();
  const keysState = useSelector((state) => state.keysState);
  // const [keys, setKeys] = useState(null);
  const [cleared, setCleared] = useState(false);

  useEffect(() => {
    dispatch(getKeys(community_id));

    return () => {
      dispatch(clearKeys());
    };
  }, []);

  const handleDeselectAll = () => {
    deselectAll();
    setCleared(true);
  };

  const check_func = (key_id, checked) => {
    props.check_func(key_id, checked);
    setCleared(false);
  };

  const searchDebounceTimer = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const debounceTimeout = 500;
  const [searchValueDebounce, setSearchValueDebounce] = useState("");

  const [highLightedKeys, setHighLightedKeys] = useState([]);

  const handleChangeSearch = (event) => {
    clearTimeout(searchDebounceTimer.current);
    searchDebounceTimer.current = setTimeout(() => {
      setSearchValue(event.target.value);
    }, debounceTimeout);

    setSearchValueDebounce(event.target.value);
  };

  useEffect(() => {
    if (searchValue !== "") {
      const highLightedKeys = keysState.keys
        .filter((key) => key.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map((key) => key.id);
      setHighLightedKeys(highLightedKeys);
    }

    if (searchValue === "") {
      setHighLightedKeys([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setHighLightedKeys([]);
    }
  }, [keysState.keys]);

  return (
    <div>
      {keysState.keys === null && <div>Loading...</div>}
      {keysState.keys && keysState.keys.length > 0 && (
        <div>
          <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <Typography color={"text.secondary"} sx={{ fontSize: 22 }}>
              Keys to assign
            </Typography>
            {keysState.keys.length > 1 ? (
              <Button variant={"outlined"} onClick={handleDeselectAll}>
                Deselect All Keys
              </Button>
            ) : null}
          </Box>
          <Box sx={{ marginBottom: 2, marginTop: 2 }}>
            {keysState.keys.filter((key) => !key.hidden).length > 4 ? (
              <TextField
                size="small"
                data-testid="find-keys-keys-for-accept"
                onChange={handleChangeSearch}
                label="Find Keys"
                value={searchValueDebounce}
                variant="outlined"
                fullWidth
              />
            ) : null}
          </Box>
          {keysState.keys.map((item) => {
            if (item.hidden) return;
            return (
              <KeyForAcceptItem
                home={item.home}
                key_id={item.id}
                key_name={item.name}
                latches={item.latches}
                key={item.id}
                check_func={check_func}
                cleared={cleared}
                is_highlighted={highLightedKeys.includes(item.id)}
              />
            );
          })}
        </div>
      )}
      {keysState.keys && keysState.keys.length === 0 && <div>No Keys</div>}
    </div>
  );
};

export default KeysForAcceptPendingMember;
