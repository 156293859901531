import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { addHoldOpenEvent } from "../../../reducers/hold-open-reducers";
import EventDateTimePicker from "../../common/Components/EventDateTimePicker";

const styles = () => ({
  text: {
    fontFamily: "Filson",
  },
});

const AddEvent = (props) => {
  const [startDate, setStartDate] = useState(null);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [endDateOpen, setEndDateOpen] = useState(false);
  const [error, setError] = useState(false);

  const holdOpens = props.holdOpens.hold_opens[props.latch_id];
  const timezone = holdOpens.timezone;

  const _check_dates = (start_date, end_date) => {
    if (start_date && end_date) {
      if (start_date > end_date) {
        return "End date and time must be after start date and time";
      }
    }
    return false;
  };

  const resetState = () => {
    setStartDate(null);
    setStartDateOpen(false);
    setEndDate(null);
    setEndDateOpen(false);
    setError(false);
  };

  const _handleStartChange = (startDate) => {
    setStartDate(startDate);
    setError(_check_dates(startDate, endDate));
  };

  const _handleEndChange = (endDate) => {
    setEndDate(endDate);
    setError(_check_dates(startDate, endDate));
  };

  const _formatDatetime = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm");
  };

  const _onOK = () => {
    props.dispatch(
      addHoldOpenEvent(props.community_id, props.latch_id, _formatDatetime(startDate), _formatDatetime(endDate)),
    );
    resetState();
    props.closeCallback();
  };

  const _onCancel = () => {
    resetState();
    props.closeCallback();
  };

  const handleOpenStartDate = () => {
    setStartDateOpen(true);
    if (!startDate) {
      setStartDate(moment.tz(moment(), timezone));
    }
  };

  const handleOpenEndDate = () => {
    setEndDateOpen(true);
    if (!endDate) {
      setEndDate(moment.tz(moment().add(1, "hour"), timezone));
    }
  };

  const handleCloseStartDate = (isCancel) => {
    if (isCancel) {
      setStartDate(null);
    }
    setStartDateOpen(false);
  };

  const handleCloseEndDate = (isCancel) => {
    if (isCancel) {
      setEndDate(null);
    }
    setEndDateOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open={props.open}>
      <DialogTitle sx={styles().text} id="max-width-dialog-title">
        Add Event
      </DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 4, paddingBottom: 4 }}>
          Set the start and end dates and times below for when the gate/door should be held open.
          <Grid container sx={{ justifyContent: "center" }} alignItems="center" direction="row">
            <Grid item>
              <Grid container sx={{ paddingTop: 1, justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Grid item sx={{ margin: 2 }}>
                  <TextField
                    variant="outlined"
                    onClick={handleOpenStartDate}
                    value={startDate ? moment(startDate).format("MMMM Do YYYY, h:mm a") : ""}
                    sx={{ minWidth: "275px" }}
                    placeholder="Start Date and Time"
                    data-testid={"one-time-event-set-start-date"}
                  />
                  <EventDateTimePicker
                    open={startDateOpen}
                    setDateTimeCallback={(start_date) => _handleStartChange(start_date)}
                    firstPageTitle="Set Event Start Date"
                    secondPageTitle="Set Event Start Time"
                    datetime={startDate}
                    closeCallback={handleCloseStartDate}
                    startingPage="date"
                    dateOnly={false}
                  />
                </Grid>
                <Grid item sx={{ margin: 2 }}>
                  <TextField
                    variant="outlined"
                    onClick={handleOpenEndDate}
                    value={endDate ? moment(endDate).format("MMMM Do YYYY, h:mm a") : ""}
                    sx={{ minWidth: "275px" }}
                    placeholder="End Date and Time"
                    data-testid={"one-time-event-set-end-date"}
                  />
                  <EventDateTimePicker
                    open={endDateOpen}
                    setDateTimeCallback={(start_date) => _handleEndChange(start_date)}
                    firstPageTitle="Set Event End Date"
                    secondPageTitle="Set Event End Time"
                    datetime={endDate}
                    closeCallback={handleCloseEndDate}
                    startingPage="date"
                    dateOnly={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {error ? (
            <Typography sx={{ textAlign: "center", paddingTop: 8, color: "red", fontWeight: "bold" }}>
              {error}
            </Typography>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancel} variant="outlined" color="primary" sx={styles().text}>
          Cancel
        </Button>
        <Button
          onClick={_onOK}
          disabled={!startDate || !endDate || moment(endDate?._d).isBefore(startDate?._d) || error}
          variant="contained"
          color="primary"
          sx={styles().text}
          data-testid={"one-time-event-add"}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddEvent.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    keysModel: state.keysState.keysModel,
    holdOpens: state.holdOpens,
  };
};

export default connect(mapStateToProps)(AddEvent);
