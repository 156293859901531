import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  result: false,
  rawResults: [],
  searchTerm: "",
  searchTermLower: "",
  loaded: false,
  list_type: false,
  communityLoading: false,
  communityError: false,
  communityLoaded: false,
  community: {
    id: null,
    uuid: null,
    display_name: null,
    allow_subkeys: null,
    contact_info: null,
    active: null,
    location: { x: null, y: null },
    is_open_log_history_enabled: null,
    community_managers: [],
    created_by: null,
    hidden: null,
    number_of_units: null,
    payment_amount: null,
    next_payment: null,
    keys: null,
    allow_hold_opens: null,
    door_bell_key_sharing_only: null,
    community_type: null,
  },
};

const DEBUG_ID = "admin-community-list.js";

export const communityListSetSearchTerm = createAction("SET_COMMUNITY_SEARCH_TERM", (searchTerm) => {
  return {
    searchTerm,
  };
});

export const clearAdminCommunity = createAction("CLEAR_ADMIN_COMMUNITY");

export const adminGetCommunity = createAsyncAction(
  "ADMIN_GET_COMMUNITY",
  (community_id) => {
    return call(nimbioServer.admin.getCommunity, [community_id], DEBUG_ID);
  },
  () => ""
);

export const adminGetInactiveCommunities = createAsyncAction(
  "ADMIN_GET_COMMUNITIES",
  () => {
    return call(nimbioServer.admin.getInactiveCommunities, [], DEBUG_ID);
  },
  () => "inactive"
);

export const adminGetActiveCommunities = createAsyncAction(
  "ADMIN_GET_COMMUNITIES",
  () => {
    return call(nimbioServer.admin.getActiveCommunities, [], DEBUG_ID);
  },
  () => "active"
);

export const adminGetHiddenCommunities = createAsyncAction(
  "ADMIN_GET_COMMUNITIES",
  () => {
    return call(nimbioServer.admin.getHiddenCommunities, [], DEBUG_ID);
  },
  () => "hidden"
);

export const adminSetCommunityContactInfo = createAsyncAction(
  "ADMIN_SET_COMMUNITY_CONTACT_INFO",
  (community_id, contact_info) => {
    return call(nimbioServer.admin.community.setContactInfo, [community_id, contact_info], DEBUG_ID);
  }
);

export const adminCommunityManagerSetAccessLogs = createAsyncAction(
  "ADMIN_SET_COMMUNITY_MANAGER_ACCESS_LOGS",
  (enabled, community_manager_id) => {
    return call(nimbioServer.admin.setCommunityManagerOpenLogHistory, [enabled, community_manager_id], DEBUG_ID);
  }
);

export const adminSetCommunityLocation = createAsyncAction("ADMIN_SET_COMMUNITY_LOCATION", (community_id, lng, lat) => {
  return call(nimbioServer.admin.community.setLocation, [community_id, lng, lat], DEBUG_ID);
});

export const adminSetCommunityPayment = createAsyncAction("ADMIN_SET_COMMUNITY_PAYMENT", (community_id, payment) => {
  return call(nimbioServer.admin.community.setPayment, [community_id, payment], DEBUG_ID);
});

export const adminSetCommunityNextPaymentDate = createAsyncAction(
  "ADMIN_SET_COMMUNITY_PAYMENT_DATE",
  (community_id, payment_date) => {
    return call(nimbioServer.admin.community.setNextPaymentDate, [community_id, payment_date], DEBUG_ID);
  }
);

export const adminAddCommunityManager = createAsyncAction("ADMIN_ADD_COMMUNITY_MANAGER", (community_id, account_id) => {
  return call(nimbioServer.admin.community.addCommunityManagerByAccountID, [community_id, account_id], DEBUG_ID);
});

export const adminCommunityManagerSetActive = createAsyncAction(
  "ADMIN_SET_COMMUNITY_MANAGER_ACTIVE",
  (community_manager_id) => {
    return call(nimbioServer.admin.community.manager.setActive, [community_manager_id, 1], DEBUG_ID);
  }
);

export const adminCommunityManagerSetInactive = createAsyncAction(
  "ADMIN_SET_COMMUNITY_MANAGER_INACTIVE",
  (community_manager_id) => {
    return call(nimbioServer.admin.community.manager.setActive, [community_manager_id, 0], DEBUG_ID);
  }
);

export const adminCommunitySetUnits = createAsyncAction("ADMIN_SET_COMMUNITY_UNITS", (commuity_id, units) => {
  return call(nimbioServer.admin.community.setUnits, [commuity_id, units], DEBUG_ID);
});

export const adminCommunitySetName = createAsyncAction("ADMIN_SET_COMMUNITY_NAME", (commuity_id, name) => {
  return call(nimbioServer.admin.community.setName, [commuity_id, name], DEBUG_ID);
});

export const adminCommunitySetActive = createAsyncAction("ADMIN_SET_COMMUNITY_ACTIVE", (commuity_id) => {
  return call(nimbioServer.admin.community.setActive, [commuity_id], DEBUG_ID);
});

export const adminCommunitySetInactive = createAsyncAction("ADMIN_SET_COMMUNITY_INACTIVE", (commuity_id) => {
  return call(nimbioServer.admin.community.setInactive, [commuity_id], DEBUG_ID);
});

export const adminCommunitySetHidden = createAsyncAction("ADMIN_SET_COMMUNITY_HIDDEN", (commuity_id) => {
  return call(nimbioServer.admin.community.setHidden, [commuity_id], DEBUG_ID);
});

export const adminCommunitySetVisible = createAsyncAction("ADMIN_SET_COMMUNITY_VISIBLE", (commuity_id) => {
  return call(nimbioServer.admin.community.setVisible, [commuity_id], DEBUG_ID);
});

export const adminCommunitySetKeyName = createAsyncAction(
  "ADMIN_SET_COMMUNITY_KEY_NAME",
  (key_id, name, community_id) => {
    return call(nimbioServer.admin.community.setKeyName, [key_id, name, community_id], DEBUG_ID);
  }
);

export const adminCommunitySetLatchName = createAsyncAction(
  "ADMIN_SET_COMMUNITY_LATCH_NAME",
  (latch_id, name, community_id) => {
    return call(nimbioServer.admin.community.setLatchName, [latch_id, name, community_id], DEBUG_ID);
  }
);

export const adminCommunitySetAllowHoldOpens = createAsyncAction(
  "ADMIN_SET_ALLOW_HOLD_OPENS",
  (community_id, allow_hold_opens) => {
    return call(nimbioServer.admin.community.allowHoldOpens, [community_id, allow_hold_opens], DEBUG_ID);
  }
);

export const adminCommunitySetAllowSubkeys = createAsyncAction(
  "ADMIN_SET_ALLOW_SUBKEYS",
  (community_id, allow_subkeys) => {
    return call(nimbioServer.admin.community.allowSubKeys, [community_id, allow_subkeys], DEBUG_ID);
  }
);

export const adminCommunitySetAllowAccessLogs = createAsyncAction(
  "ADMIN_SET_ALLOW_ACCESS_LOGS",
  async (community_id, allow_access_logs) => {
    return await call(nimbioServer.admin.setOpenLogHistory, [allow_access_logs, community_id], DEBUG_ID);
  }
);

export const adminCommunitySetCommunityType = createAsyncAction(
  "ADMIN_SET_COMMUNITY_TYPE",
  async (community_id, community_type) => {
    const response = await call(nimbioServer.admin.account.setCommunityType, [community_id, community_type], DEBUG_ID);
    return response;
  }
);

export const adminCommunityKeyRemoveLatch = createAsyncAction(
  "ADMIN_COMMUNITY_KEY_REMOVE_LATCH",
  (key_id, latch_id, recursive) => {
    return call(nimbioServer.admin.key.removeLatch, [key_id, latch_id, recursive], DEBUG_ID);
  }
);

export const addLatchToKey = createAsyncAction("ADMIN_ADD_LATCH", (key_id, latch_id) =>
  call(nimbioServer.admin.key.addLatch, [key_id, latch_id, true], DEBUG_ID)
);

export const updateCommunityKeyStateLatchStatus = createAction("UPDATE_COMMUNITY_KEY_STATE_LATCH_STATUS", (message) => {
  return message;
});

export const updateKeyStateLatchOpened = createAction("UPDATE_KEY_STATE_LATCH_OPENED", (message) => {
  return message;
});

export const updateKeyStateLatchLoading = createAction("UPDATE_KEY_STATE_LATCH_LOADING", (message) => {
  return message;
});

export const updateKeyStateLatchFailed = createAction("UPDATE_KEY_STATE_LATCH_FAILED", (message) => {
  return message;
});

export const updateKeyStateLatchReset = createAction("UPDATE_KEY_STATE_LATCH_RESET", (message) => {
  return message;
});

export const setShareDepth = createAsyncAction("SET_SHARE_DEPTH", async (max_depth, key_id) => {
  const response = await call(nimbioServer.admin.setKeyMaxShareDepth, [max_depth, key_id], DEBUG_ID);
  return { ...response, key_id, max_depth };
});

export const setShareDepthEnabled = createAsyncAction("SET_SHARE_DEPTH_ENABLED", async (enabled, key_id) => {
  const response = await call(nimbioServer.admin.setKeyMaxShareDepthEnabled, [enabled, key_id], DEBUG_ID);
  return { ...response, key_id, enabled };
});

export default handleActions(
  {
    SET_COMMUNITY_SEARCH_TERM: (state, { payload }) => {
      const searchTermLower = payload.searchTerm?.toLowerCase();
      return {
        ...state,
        searchTerm: payload.searchTerm,
        searchTermLower,
        result: state.rawResults.filter((community) => searchCommunity(community, searchTermLower)),
      };
    },
    // Get a single community by id
    ADMIN_GET_COMMUNITY_LOADING: (state) => {
      return {
        ...state,
        communityLoading: true,
        communityError: false,
      };
    },
    ADMIN_GET_COMMUNITY_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        communityLoading: false,
        communityError: payload === false,
        communityLoaded: true,
        community: payload,
      };
    },
    ADMIN_GET_COMMUNITY_ERROR: (state) => {
      return {
        ...state,
        communityLoading: false,
        communityError: true,
        communityLoaded: true,
      };
    },
    ADMIN_GET_COMMUNITIES_LOADING: (state, { meta }) => {
      return {
        ...state,
        loading: true,
        error: false,
        list_type: meta,
        searchTermLower: meta === state.list_type ? state.searchTermLower : "",
      };
    },
    ADMIN_GET_COMMUNITIES_SUCCESS: (state, { payload, meta }) => {
      const rawResults = payload.map((community) => ({
        lowerCaseCommunityName: community.display_name?.toLowerCase(),
        ...community,
      }));
      return {
        ...state,
        loading: false,
        result:
          state.searchTerm.length > 0
            ? rawResults.filter((community) => searchCommunity(community, state.searchTermLower))
            : rawResults,
        error: payload === false,
        loaded: true,
        list_type: meta,
        rawResults,
      };
    },
    ADMIN_GET_COMMUNITIES_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
    ADMIN_SET_ALLOW_ACCESS_LOGS_SUCCESS: (state) => {
      return {
        ...state,
        community: {
          ...state.community,
          is_open_log_history_enabled: !state.community.is_open_log_history_enabled,
        },
      };
    },
    UPDATE_COMMUNITY_KEY_STATE_LATCH_STATUS: (state, { payload }) => {
      if (!state.community?.keys) {
        return { ...state };
      }

      let updatedKeys = state.community.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.latch_status_current_message = payload;
            return latch;
          } else {
            return latch;
          }
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    UPDATE_KEY_STATE_LATCH_OPENED: (state, { payload }) => {
      let updatedKeys = state.community.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = true;
            latch.loading = false;
            latch.failed = false;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    UPDATE_KEY_STATE_LATCH_LOADING: (state, { payload }) => {
      let updatedKeys = state.community.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = false;
            latch.loading = true;
            latch.failed = false;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    UPDATE_KEY_STATE_LATCH_FAILED: (state, { payload }) => {
      let updatedKeys = state.community.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = false;
            latch.loading = false;
            latch.failed = true;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    UPDATE_KEY_STATE_LATCH_RESET: (state, { payload }) => {
      let updatedKeys = state.community.keys.map((key) => {
        let updatedLatches = key.latches.map((latch) => {
          if (latch.id === payload.latch_id) {
            latch.opened = false;
            latch.failed = false;
            latch.loading = false;
          }
          return latch;
        });
        key.latches = updatedLatches;
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    SET_SHARE_DEPTH_SUCCESS: (state, { payload }) => {
      let updatedKeys = state.community.keys.map((key) => {
        if (key.id === payload.key_id) {
          return { ...key, max_share_depth: payload.max_depth };
        }
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    SET_SHARE_DEPTH_ENABLED_SUCCESS: (state, { payload }) => {
      let updatedKeys = state.community.keys.map((key) => {
        if (key.id === payload.key_id) {
          return { ...key, max_share_depth_enabled: payload.enabled };
        }
        return key;
      });
      return {
        ...state,
        community: {
          ...state.community,
          keys: updatedKeys,
        },
      };
    },
    ADMIN_COMMUNITY_KEY_REMOVE_LATCH_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload["result"] === false,
      };
    },
    ADMIN_COMMUNITY_KEY_REMOVE_LATCH_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_COMMUNITY_KEY_REMOVE_LATCH_ERROR: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    ADMIN_ADD_LATCH_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
      };
    },
    ADMIN_ADD_LATCH_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload["result"] === false,
      };
    },
    ADMIN_ADD_LATCH_ERROR: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    },
    CLEAR_ADMIN_COMMUNITY: (state) => {
      return {
        ...state,
        community: initialState.community,
        communityLoading: false,
        communityError: false,
        communityLoaded: false,
      };
    },
  },
  initialState
);

const searchByCommunityManager = (search, community) => {
  search = search.substring(4);

  let communityAccounts = [];
  community.community_managers.forEach((manager) => {
    communityAccounts.push(manager.account);
  });

  const returnedManager = communityAccounts
    .map(
      (account) =>
        account.first_name?.toLowerCase().includes(search) ||
        account.last_name?.toLowerCase().includes(search) ||
        account.phone_numbers[0]?.includes(search)
    )
    .reduce((x, y) => x || y, false);

  return returnedManager;
};

export const searchCommunity = (community, searchTermLower) => {
  if (searchTermLower === "" || searchTermLower == null) {
    return true;
  }

  if (searchTermLower.startsWith("cm:")) {
    return searchByCommunityManager(searchTermLower, community) !== false;
  } else {
    return community.lowerCaseCommunityName.includes(searchTermLower);
  }
};
