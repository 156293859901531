import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, Box, DialogTitle, DialogContentText } from "@mui/material";
import { connect } from "react-redux";
import CommunityCreateAccountWithPreApprovedKey from "./CreateAccountPreApprovedKey";

const CommunityCreateAccountWithPreApprovedKeyDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="form-dialog-title">
      <Box sx={{ padding: 2, display: "flex", justifyContent: "flex-start", flexDirection: "column" }}>
        <DialogTitle p={1}>Add Member</DialogTitle>
        <DialogContentText sx={{ alignItems: "center", paddingLeft: 3 }}>
          Enter a phone number below and the user will automatically be approved for keys selected.
        </DialogContentText>
        <DialogContent>
          <CommunityCreateAccountWithPreApprovedKey
            community_id={props.community_id}
            closeCallback={props.closeCallback}
            is_admin={props.is_admin}
            is_dialog={true}
            open={props.open}
          />
        </DialogContent>
      </Box>
    </Dialog>
  );
};

CommunityCreateAccountWithPreApprovedKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  community_id: PropTypes.number.isRequired,
  is_admin: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    is_admin: state.profileState.details.is_admin,
  };
};

export default connect(mapStateToProps)(CommunityCreateAccountWithPreApprovedKeyDialog);
