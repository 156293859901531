import React from "react";
import { connect, useDispatch } from "react-redux";
import { Grid, Button, Box, Card, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { red } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AdminVendorDialog from "./AdminVendorDialog";
import { getVendors } from "../../../reducers/admin-get-vendors";
import { getAccountVendors } from "../../../reducers/admin-get-account-vendors";
import useConfirm from "../../common/Components/useConfirm";
import SelectedAppBar from "../../common/Components/SelectedAppBar";
import nimbioServer from "../../../server/endpoints";
import endpoints from "../../../routes/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import LoadingScreen from "../../common/Components/LoadingScreen";

const VendorsTable = ({ vendors }) => {
  const { ConfirmDialog } = useConfirm();
  const navigate = useNavigate();

  const columns = [
    {
      field: "vendor_name",
      flex: 1,
      headerName: "Name",
      sortable: false,
      filterable: false,
      renderHeader: (params) => {
        return <Typography sx={{ fontWeight: "bold" }}>{params.colDef.headerName}</Typography>;
      },
      renderCell: (params) => {
        return <Typography sx={{ color: params.row.is_disabled ? "gray" : "black" }}>{params.value}</Typography>;
      },
    },
  ];

  if (!vendors) {
    return null;
  }

  return (
    <Box id={"nimbio-testing" + endpoints.ADMIN_VENDORS + "-vendor-list"}>
      <DataGrid
        sx={{
          ".MuiDataGrid-sortIcon": {
            color: red[500],
          },
          ".MuiDataGrid-filterIcon": {
            color: red[500],
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
          ".MuiDataGrid-menuIconButton": {
            color: red[500],
          },
        }}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        getRowId={(row) => row.vendor_uuid}
        rows={vendors}
        columns={columns}
        disableColumnSelector={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        autoHeight={true}
        // hideFooter={vendors.length <= 5}
        // hideFooterPagination={vendors.length <= 5}
        hideFooterSelectedRowCount={true}
        hideFooterRowCount={true}
        disableColumnFilter={true}
        disableColumnReorder={true}
        disableColumnResize={true}
        disableDensitySelector={true}
        disableExtendRowFullWidth={true}
        disableMultipleColumnsFiltering={true}
        disableMultipleSelection={true}
        disableSelectionOnRowClick={true}
        disableSelectionOnRowDrag={true}
        disableSortBy={true}
        onRowClick={(params) => {
          navigate(`/admin/vendor/${params.row.vendor_uuid}`);
        }}
      />
      <Box id={"nimbio-testing" + endpoints.ADMIN_VENDORS + "-vendor-list-footer"} sx={{ height: "1px" }} />
      <ConfirmDialog />
    </Box>
  );
};

function AdminVendors({ vendors, accountVendors, profileState, vendorsLoading, accountVendorsLoading }) {
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const savedDisabled = localStorage.getItem("showDisabledVendors");

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [vendor, setVendor] = React.useState(null);
  const [showDisabled, setShowDisabled] = React.useState(savedDisabled || false);

  React.useEffect(() => {
    dispatch(getAccountVendors(showDisabled));
    dispatch(getVendors(showDisabled));
  }, [dispatch, showDisabled]);

  React.useEffect(() => {
    // TODO(rick) why are we delaying for 350ms?
    const loadDelay = setTimeout(() => {
      setLoading(vendorsLoading && accountVendorsLoading);
    }, 350);
    return () => {
      clearTimeout(loadDelay);
    };
  }, [vendorsLoading, accountVendorsLoading]);

  useSubscription(nimbioServer.vendor.vendors, () => {
    dispatch(getVendors(showDisabled));
    dispatch(getAccountVendors(showDisabled));
  });

  const usedVendors = React.useMemo(() => {
    if (!vendors.result && !accountVendors.result) {
      return [];
    }

    const { details } = profileState;
    if (details.is_admin) {
      return vendors.result;
    }
    return accountVendors.result;
  }, [vendors, accountVendors, profileState]);

  if (communityId === null && !profileState.details.is_admin) {
    navigate("/pick-community");
    return;
  }

  const handleEditModalOpen = (vendor) => {
    setVendor(vendor);
    setOpen(true);
  };

  return (
    <SelectedAppBar selected="Vendors">
      {loading ? (
        <LoadingScreen />
      ) : (
        <Card sx={{ padding: 5 }} data-testid={"vendors-list"}>
          <Grid
            sx={{ paddingTop: 0, width: "100%" }}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center">
            {profileState?.details.is_admin && (
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showDisabled}
                      label="Show disabled"
                      onChange={(event) => {
                        setShowDisabled(event.target.checked);
                        localStorage.setItem("showDisabledVendors", event.target.checked);
                      }}
                      data-testid={"vendors-list-toggle-show-disabled"}
                    />
                  }
                  label="Show disabled"
                />
              </Grid>
            )}
            {profileState?.details.is_admin && (
              <Grid item>
                <Button
                  id="nimbio-testing-admin-vendors-add-vendor-button"
                  onClick={() => {
                    setOpen(true);
                    setVendor(null);
                  }}
                  data-testid={"vendors-list-add-vendor"}>
                  Add Vendor
                </Button>
              </Grid>
            )}
          </Grid>

          <VendorsTable profileState={profileState} vendors={usedVendors} handleEditModalOpen={handleEditModalOpen} />
        </Card>
      )}

      {open && (
        <AdminVendorDialog
          open={open}
          vendor={vendor}
          onClose={() => {
            setOpen(false);
            setVendor(null);
          }}
        />
      )}
    </SelectedAppBar>
  );
}

AdminVendors.propTypes = {};

const mapStateToProps = (state) => {
  return {
    vendors: state.getVendorsReducer.results,
    vendorsLoading: state.getVendorsReducer.loading,
    accountVendors: state.getAccountVendorsReducer.results,
    accountVendorsLoading: state.getAccountVendorsReducer.loading,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(AdminVendors);
