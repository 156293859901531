import React, { useState } from "react";
import { connect } from "react-redux";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AdminAppBar from "./AdminAppBar";
import NimbioDataGrid from "../../common/Components/NimbioDataGrid";
import DateTwoWeekMaxPicker from "../../common/Components/DateTwoWeekMaxPicker";
import { adminGetCommunityKeyUsageHistory } from "../../../server/server";
import PickerPagination from "../../common/Components/PickerPagination";
import endpoints from "../../../routes/endpoints";

const DATE_FORMAT = "YYYY-MM-DD";
const MAX_LOG_LENGTH = 1000;

const MIN_COLUMN_WIDTH = 100;
const COLUMNS = [
  { field: "id", headerName: "ID", width: 0, hide: true },
  {
    field: "datetime",
    headerName: "Date",
    flex: 1,
    minWidth: MIN_COLUMN_WIDTH * 2,
    renderCell: (params) => {
      return (
        <Typography sx={{ fontSize: "inherit" }}>
          {moment(params.row.datetime).calendar(null, { sameElse: "MM/DD/YYYY hh:mm:ss A" })}
        </Typography>
      );
    },
  },
  { field: "user", headerName: "User", flex: 1, minWidth: MIN_COLUMN_WIDTH * 1.5, height: "auto" },
  { field: "key_name", headerName: "Key", flex: 1, minWidth: MIN_COLUMN_WIDTH * 1.75 },
  { field: "latch_name", headerName: "Latch", flex: 1, minWidth: MIN_COLUMN_WIDTH * 1.25 },
  { field: "open_desc", headerName: "Description", flex: 1, minWidth: MIN_COLUMN_WIDTH },
  { field: "open_result", headerName: "Result", flex: 1, minWidth: MIN_COLUMN_WIDTH },
];

const AdminAccessLogs = () => {
  const [redirect, setRedirect] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [eDate, setEDate] = useState([]);
  const [lDate, setLDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const { communityId } = useParams();
  const navigate = useNavigate();

  const fetchAllAdminData = async (page = 0) => {
    const result = await adminGetCommunityKeyUsageHistory(
      communityId,
      lDate.format(DATE_FORMAT),
      eDate.format(DATE_FORMAT),
      page,
    );
    if (result.logs) {
      let appendedData = [];
      if (result.logs.length === MAX_LOG_LENGTH) {
        appendedData = await fetchAllAdminData(page + 1);
      }
      return result.logs.concat(appendedData);
    }
    return null;
  };

  const fetchAdminData = async (earlierDate, laterDate, page) => {
    const result = await adminGetCommunityKeyUsageHistory(
      communityId,
      laterDate.format(DATE_FORMAT),
      earlierDate.format(DATE_FORMAT),
      page,
    );
    if (result.logs) {
      return result.logs;
    }
    return null;
  };

  const fetchData = async (earlierDate, laterDate, page = 0) => {
    setEDate(earlierDate);
    setLDate(laterDate);
    const data = await fetchAdminData(earlierDate, laterDate, page);
    if (!data) setRedirect(true);
    setData(data);
  };

  const setData = (logs) => {
    try {
      if (!logs) throw new Error("failed to get key usage history");

      const newRows = [];
      for (const [index, row] of logs.entries()) {
        newRows.push({
          id: index,
          key_name: row.key_name,
          latch_name: row.latch_name,
          datetime: row.datetime,
          open_desc: row.open_desc,
          open_result: row.open_result,
          user: row.user,
        });
      }

      const orderedRows = newRows.sort((a, b) => Date.parse(b.datetime) - Date.parse(a.datetime));

      setRowData(orderedRows);
    } catch (err) {
      setRedirect(true);
      console.error("err: " + JSON.stringify(err));
    } finally {
      setLoading(false);
    }
  };

  if (redirect) {
    navigate("/");
    return;
  }

  return (
    <AdminAppBar selected="Access Logs">
      <Box>
        <DateTwoWeekMaxPicker
          startLabel="Logs Starting On"
          endLabel="Logs Ending On"
          handlePickerChanged={fetchData}
          loading={loading}
        />
        <NimbioDataGrid
          rows={rows}
          columns={COLUMNS}
          rowData={rowData}
          loading={loading}
          fetchAllData={fetchAllAdminData}
        />
        {rows && (
          <PickerPagination
            rowData={rowData}
            setRows={setRows}
            fetchData={fetchData}
            maxLogLength={MAX_LOG_LENGTH}
            earlierDate={eDate}
            laterDate={lDate}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            mb: 1,
          }}>
          <Typography sx={{ fontSize: ".8em" }}>
            If you need Access Logs prior to August 27, 2023, please contact support.
          </Typography>
          <Button
            sx={{ fontSize: ".9em" }}
            button
            key="Logout"
            onClick={() => {
              window.open(
                endpoints.EXTERNAL.nimbioHelpPage,
                "_blank", // <- This is what makes it open in a new window.
              );
            }}>
            Support
          </Button>
        </Box>
      </Box>
    </AdminAppBar>
  );
};

AdminAccessLogs.propTypes = {};

const mapStateToProps = (state) => {
  return {
    comm_state: state.communityState,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(AdminAccessLogs);
