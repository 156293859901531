import moment from "moment/moment";

export const parseSenseLineRecords = (state, payload) => {
  const records_with_applied_current_status_config = payload.map((sense_line_record) => {
    const status = state.box_sense_line_statuses.filter(
      (box_sense_line_status) =>
        box_sense_line_status.sense_line_id === sense_line_record.sense_line &&
        box_sense_line_status.sense_line_state === sense_line_record.state
    );
    const sense_line_online = state.box_sense_lines.filter(
      (sense_line) => sense_line.sense_line_id === sense_line_record.sense_line
    )[0].sense_line_online;
    const latch_message_id = status[0].latch_message_id;
    const transient = status[0].transient;
    const latch_message_label = state.latch_messages.filter(
      (latch_message) => latch_message.latch_message_id === latch_message_id
    )[0].latch_label;

    // if(transient > 0 && sense_line_online === true) {
    //     sense_line_record.log_datetime = sense_line_record.log_datetime + "---- transient"
    // }
    return { ...sense_line_record, latch_message_label, sense_line_online, transient };
  });

  const filtered_online_records = records_with_applied_current_status_config.filter(
    (record) => record.sense_line_online === true
  );

  const findPreviousOnlineRecordAndUpdate = (index, record) => {
    const split_date = record.log_datetime.split(" ");
    const date = split_date[0];
    const time = split_date[1];
    const timezone = split_date[2];
    var hour = time.split(":")[0];
    var minute = time.split(":")[1];
    const second = time.split(":")[2];

    const date_obj = new Date(date);
    date_obj.setHours(hour);
    date_obj.setMinutes(minute);
    date_obj.setSeconds(second);
    const date_result = moment(date_obj);
    const appliedTransient = date_result.add(parseInt(record.transient) / 1000, "second");

    const formattedDate = appliedTransient.format(`YYYY-MM-DD HH:mm:ss [${timezone}]`);
    return { ...filtered_online_records[index + 1], log_datetime: formattedDate };
  };

  const records_with_applied_transients = [...filtered_online_records];
  const inserts = [];

  filtered_online_records.forEach((sense_line_record, index) => {
    if (sense_line_record.transient > 0) {
      if (index === filtered_online_records.length - 1) {
        return;
      }
      const insert = findPreviousOnlineRecordAndUpdate(index, sense_line_record);
      inserts.push([index, insert]);
    }
  });

  let number_of_inserts = 0;
  inserts.forEach((insert) => {
    records_with_applied_transients.splice(insert[0] + number_of_inserts, 0, insert[1]);
    number_of_inserts = number_of_inserts + 1;
  });

  return records_with_applied_transients;
};
