import { createAction, handleActions } from "redux-actions";
import { nimbioConnection } from "../utilities/connection";
import { wampCreateConnection } from "../utilities/connector";
import endpoints from "../routes/endpoints";

// Action Creators
export const setCredentials = createAction("SET_CREDENTIALS", (auth_id, secret) => {
  wampCreateConnection(auth_id, secret, process.env.REACT_APP_SERVER_URL);
  return { auth_id: auth_id, secret: secret };
});

export const startAuthConnection = createAction("INIT_AUTH_CONNECTION", () => {
  wampCreateConnection("no", "user", process.env.REACT_APP_SERVER_URL, false);
  return { authRealm: true };
});

export const logout = createAction("LOGOUT", () => {
  localStorage.removeItem("auth_id");
  localStorage.removeItem("secret");
  nimbioConnection.disconnect();
  window.location.href = endpoints.ROOT;
});

export const loginSuccess = createAction("LOGIN_OK");

// TODO(rick) this looks unused
export const invalidLogin = createAction("UNKNOWN_USER", () => nimbioConnection.disconnect());

export const connectionClosed = createAction("CONN_CLOSED");

export const connectionReconnecting = createAction("CONN_RECONNECTING");

export const connectionReconnected = createAction("CONN_RECONNECTED");

// Reducer
export const initialState = {
  auth_id: null,
  secret: null,
  connected: false,
  reconnecting: false,
  reconnectCount: 0,
  connecting: false,
  should_connect: false,
  authRealm: false,
};

export default handleActions(
  {
    SET_CREDENTIALS: (state, { payload }) => {
      return {
        ...state,
        auth_id: payload.auth_id,
        secret: payload.secret,
        connecting: true,
        should_connect: true,
        authRealm: false,
      };
    },
    // TODO(rick) this also looks unused
    LOGIN_DETAILS_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    },
    LOGIN_OK: (state) => {
      return {
        ...state,
        connected: true,
        reconnecting: false,
        connecting: false,
      };
    },
    UNKNOWN_USER: (state) => {
      return {
        ...state,
        reconnecting: false,
        should_connect: false,
      };
    },
    CONN_CLOSED: (state) => {
      return {
        ...state,
        connected: false,
        reconnecting: false,
      };
    },
    CONN_RECONNECTING: (state) => {
      return {
        ...state,
        reconnecting: true,
        reconnectCount: state.reconnectCount + 1,
      };
    },
    CONN_RECONNECTED: (state) => {
      return {
        ...state,
        connected: true,
        reconnecting: false,
        connecting: false,
        reconnectCount: 0,
      };
    },
    LOGOUT: (state) => {
      return {
        ...state,
        connected: false,
        reconnecting: false,
        reconnectCount: 0,
        should_connect: false,
      };
    },
    INIT_AUTH_CONNECTION: (state) => {
      return {
        ...state,
        connecting: true,
        should_connect: true,
        authRealm: true,
      };
    },
  },
  initialState,
);
