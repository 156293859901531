import React, { useEffect } from "react";
import "../../App.css";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import MemberList from "./Components/MemberList";
import CommunityManagerAppBar from "../common/Components/CommunityManagerAppBar";
import { getCommunities } from "../../reducers/communities-reducer";
import { APP_BAR_ITEMS } from "../common/Components/CommunityManagerAppBar";
import LoadingScreen from "../common/Components/LoadingScreen";

function Members(props) {
  const { communityId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.communities.communitiesLoaded) {
      dispatch(getCommunities());
    }
  }, [dispatch, props.communities.communitiesLoaded]);

  // if (props.communities.communitiesLoaded && communityId === null) {
  //   return <Redirect to={"/pick-community"} />;
  // }
  if (!props.communities.communitiesLoaded || !communityId) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <CommunityManagerAppBar {...props} selected={APP_BAR_ITEMS.MEMBERS}>
        <MemberList
          type={"communityManager"}
          community_id={communityId}
          community={props.communities.currentCommunity}
        />
      </CommunityManagerAppBar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    communities: state.communityState,
  };
};

export default connect(mapStateToProps)(Members);
