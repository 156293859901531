import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

export const initialState = {
  phone_number: "",
  loading: false,
  error: false,
  result: false,
};

export const addAdminCall = createAsyncAction("ADD_ADMIN", (phone) => {
  return call(nimbioServer.admin.account.setAdmin, [phone, 1], "add-admin-reducer");
});

export default handleActions(
  {
    ADD_ADMIN_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: false,
      };
    },
    ADD_ADMIN_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        result: payload,
        error: payload === false,
      };
    },
    ADD_ADMIN_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
      };
    },
  },
  initialState
);
