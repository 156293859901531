import React, { useEffect } from "react";
import { Grid, Typography, Card, CardContent, Checkbox, TextField, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { deviceListSetSearchTerm } from "../../../reducers/device-status-reducer";
import { adminDeviceGetLatches } from "../../../reducers/device-status-reducer";

const BoxLatches = ({ latches, selected, onValueChanged }: any) => {
  return (
    <>
      <Grid container spacing={1}>
        {latches &&
          latches.map((latch: any) => {
            return (
              <Grid item xs={12} key={latch.id}>
                <Grid container alignItems="center" justifyContent="space-between">
                  <Grid item>
                    <Typography>{latch.name}</Typography>
                  </Grid>
                  <Grid item>
                    <Checkbox
                      data-testid={"box-latches-checkbox"}
                      checked={Object.keys(selected).includes(latch.id)}
                      onChange={(event) => {
                        const newLatches: any = { ...selected };
                        for (let i = 0; i < selected.length; i++) {
                          newLatches[selected[i]] = true;
                        }
                        if (event.target.checked) {
                          newLatches[latch.id] = latch;
                        } else {
                          delete newLatches[latch.id];
                        }

                        if (onValueChanged) {
                          onValueChanged(newLatches);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

const BoxLatchSelect = ({ selectedLatches, setSelectedLatches }: any) => {
  const dispatch = useDispatch();

  const [boxLatches, setBoxLatches] = React.useState<any>({});
  const [boxes, setBoxes] = React.useState<any>([]);

  const deviceList = useSelector((state: any) => {
    return state.deviceStatus.result;
  });

  useEffect(() => {
    dispatch(deviceListSetSearchTerm("", 1, 50, "revealed"));
  }, [dispatch]);

  useEffect(() => {
    async function getLatches() {
      const promises = deviceList.map((device: any) => {
        return dispatch(adminDeviceGetLatches(device.box));
      });
      const result = await Promise.all(promises);

      // build a list of latches by box id
      const latches: any = {};
      result.forEach((r: any) => {
        r.value.forEach((latch: any) => {
          if (!latches[latch.box_id]) {
            latches[latch.box_id] = [];
          }
          latches[latch.box_id].push(latch);
        });
      });

      // filter deviceList by those boxes that have latches
      const boxes: any = [];
      deviceList.forEach((device: any) => {
        if (latches[device.box]) {
          boxes.push(device);
        }
      });
      // console.log("setting boxes", boxes);
      setBoxes(boxes);
      // console.log("setting box latches", latches);
      setBoxLatches(latches);
    }
    if (!deviceList || deviceList.length === 0) return;

    getLatches();
  }, [dispatch, deviceList]);

  return (
    <Box style={{ height: "85%", padding: 1 }}>
      <Typography>Search</Typography>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Type a box name to find it."
        onChange={(event) => {
          dispatch(deviceListSetSearchTerm(event.target.value, 1, 50, "revealed"));
        }}
      />
      <Divider style={{ marginTop: 12, marginBottom: 12 }} />
      <Typography sx={{ padding: 2 }}>Check the latches to add to the key.</Typography>
      <Grid container spacing={3}>
        {boxes &&
          boxes.map((device: any) => {
            return (
              <Grid item xs={12} key={device.box}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{device.name}</Typography>

                    <BoxLatches
                      latches={boxLatches[device.box]}
                      selected={selectedLatches}
                      onValueChanged={(selected: any) => {
                        setSelectedLatches(selected);
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default BoxLatchSelect;
