import store from "../store";
import { adminUpdateDeviceListWithNewBox } from "../reducers/device-status-reducer";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

export function deviceStatusSubscription(message) {
  message = message.argsList[0];
  if ("a" in message) {
    switch (message.a) {
      case "new_box_created":
        call(nimbioServer.distributor.box.getStatus, message.box_id, "deviceStatusSubscription").then((status) => {
          store.dispatch(adminUpdateDeviceListWithNewBox(status));
        });
        break;
      default:
        console.log("<B>Message received and not handled", message);
    }
  }
}
