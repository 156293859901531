import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { connect } from "react-redux";
import CommunityManagerDetail from "./CommunityManagerDetail";
import CommunityManagerAdd from "./CommunityManagerAdd";

const styles = () => ({
  text: {
    fontFamily: "Filson",
  },
});

const CommunityManagersList = (props) => {
  const isHistoryEnabled = props.currentCommunity.community.is_open_log_history_enabled;
  const [historyEnabled, setHistoryEnabled] = useState(isHistoryEnabled);
  const [addOpen, setAddOpen] = useState(false);

  const _openAddDialog = () => {
    setAddOpen(true);
  };

  const _closeAddDialog = () => {
    setAddOpen(false);
  };

  useEffect(() => {
    setHistoryEnabled(isHistoryEnabled);
  }, [isHistoryEnabled]);

  return (
    <Box>
      <Typography variant="body2" color="textSecondary" component="p" sx={styles().text}>
        {props.managers.length === 0 && "No Property Managers"}
        {props.managers.length > 0 && "Property Managers"}
      </Typography>
      <IconButton aria-label="settings" onClick={_openAddDialog} sx={{ ml: 1, mt: 1, p: 1, borderRadius: 5 }}>
        <AddCircleIcon color="primary" sx={{ fontSize: 30 }} />
        <Typography sx={{ ml: 1 }}>Add Property Manager</Typography>
      </IconButton>
      {props.managers.length > 0 &&
        props.managers.map((cm) => <CommunityManagerDetail manager={cm} key={cm.id} historyEnabled={historyEnabled} />)}
      <CommunityManagerAdd open={addOpen} closeCallback={_closeAddDialog} community_id={props.community_id} />
    </Box>
  );
};

CommunityManagersList.propTypes = {
  managers: PropTypes.array.isRequired,
  community_id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentCommunity: state.adminCommunityList,
  };
};

export default connect(mapStateToProps)(CommunityManagersList);
