import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import { useTheme } from "@mui/material/styles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  weekday: {
    color: theme.palette.text.secondary,
    fontSize: 20,
  },
  label: {
    color: theme.palette.text.secondary,
    textAlign: "left",
  },
});

const EventTime = (props) => {
  const theme = useTheme();

  if (!props.isRecurring) {
    const js_date = moment(props.date, "YYYY-MM-DD HH:mm:ss").toDate();
    return (
      <Grid item xs={12} sm={6}>
        <Paper sx={styles(theme).paper}>
          <Typography sx={styles(theme).label}>{props.label}</Typography>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>{moment(js_date).format("MMM Do")}</Grid>
            <Grid item sx={styles(theme).weekday}>
              {moment(js_date).format("ddd")}
            </Grid>
            <Grid item>{moment(js_date).format("h:mm a")}</Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  } else {
    const js_date = moment(props.date, "HH:mm:ss").toDate();
    return (
      <Grid item xs={12} sm={6}>
        <Paper sx={styles(theme).paper}>
          <Typography sx={styles(theme).label}>{props.label}</Typography>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
            <Grid item>{moment(js_date).format("h:mm a")}</Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
};

EventTime.propTypes = {
  date: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isRecurring: PropTypes.bool,
};

export default EventTime;
