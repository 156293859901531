import React, { Component } from "react";
import { connect } from "react-redux";
import AdminAppBar from "./AdminAppBar";
import AdminCommunityList from "./AdminCommunityList";

class AdminHiddenCommunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: "",
    };
  }

  render() {
    return (
      <AdminAppBar selected="Hidden Communities" {...this.props}>
        <AdminCommunityList type="hidden" />
      </AdminAppBar>
    );
  }
}

AdminHiddenCommunities.propTypes = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(AdminHiddenCommunities);
