import "react-phone-number-input/style.css";
import React, { useState } from "react";

import { Button, Card, CardActions, CardContent, Grid, Paper, Typography } from "@mui/material";
import { connect } from "react-redux";
import PhoneInput from "react-phone-number-input";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/material/styles";
import AdminAppBar from "./AdminAppBar";
import { addAdminCall } from "../../../reducers/add-admin-reducer";
import PhoneNumberCustom from "../../common/Components/PhoneNumberCustom";

const styles = () => ({
  baseCard: {
    maxWidth: "345px",
    padding: 2,
  },
  root: {
    flexGrow: 1,
  },
  resultPaper: {
    margin: 1,
    flex: 1,
    padding: 2,
  },
});

const AdminAddAdmin = (props) => {
  const [phoneInput, setPhoneInput] = useState("");
  const theme = useTheme();

  const _send_phone = () => {
    props.dispatch(addAdminCall(phoneInput));
  };

  return (
    <AdminAppBar selected="Add Admin" {...props}>
      <Grid container sx={styles(theme).root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={1}>
            <Card sx={styles(theme).baseCard}>
              <CardContent>
                <Typography gutterBottom variant="h6" component="h5">
                  Add Admin by Phone
                </Typography>
              </CardContent>
              <CardContent>
                <PhoneInput
                  // country="US"
                  value={phoneInput}
                  onChange={(phone_input) => setPhoneInput(phone_input)}
                  defaultCountry="US"
                  label="Phone Number"
                  placeholder="Phone Number"
                  // onKeyPress={() => true}
                  numberInputProps={{ autoFocus: true, className: styles(theme).phoneInput }}
                  inputComponent={PhoneNumberCustom}
                />
                {/*<TextField id="outlined-basic" label="Installer Phone Number" variant="outlined" onChange />*/}
                {props.addAdmin.result && (
                  <Paper sx={styles(theme).resultPaper}>
                    <Typography>
                      <CheckCircleOutlineIcon />
                      Added {this.props.addAdmin.result.first} {props.addAdmin.result.last} as a Admin
                    </Typography>
                  </Paper>
                )}
                {props.addAdmin.error && (
                  <Paper sx={styles(theme).resultPaper}>
                    <Typography>
                      <ErrorOutlineIcon /> Phone number not found
                    </Typography>
                  </Paper>
                )}
              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={_send_phone} disabled={props.addAdmin.loading}>
                  Add Admin
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </AdminAppBar>
  );
};

AdminAddAdmin.propTypes = {};

const mapStateToProps = (state) => {
  return {
    addAdmin: state.addAdmin,
  };
};

export default connect(mapStateToProps)(AdminAddAdmin);
