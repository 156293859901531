import React, { useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PaginationComponent from "../../common/Components/PaginationComponent";

type DataTableProps = {
  getDataCallBack: any;
  paginateCallback: any;
  pageSize: number;
  dataTableTitle: string;
  parentDataRefresh?: boolean;
};

type TableData = {
  total_length: number;
  results: any[];
  page?: number;
};

const DataTable = (props: DataTableProps) => {
  const { getDataCallBack, paginateCallback, pageSize, dataTableTitle, parentDataRefresh } = props;
  const [page, setPage] = React.useState(1);
  const [tableData, setTableData] = React.useState<TableData>({
    total_length: 0,
    results: [],
  });

  const getData = async () => {
    const result: any = await getDataCallBack();
    if (result["result"]) setTableData(result["data"]);
  };

  const handlePaginate = async (page: number) => {
    const result = await paginateCallback(page - 1);
    if (result["result"]) setTableData(result["data"]);
    setPage(page);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (parentDataRefresh === undefined) return;
    handlePaginate(page);
  }, [parentDataRefresh]);

  return (
    <Box style={{ margin: 4 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={styles.table_container} data-testid={"sense-linse-records-list"}>
          <Box sx={styles.data_table_title} mt={2}>
            <Typography variant="h5">{dataTableTitle}</Typography>
          </Box>
          <TableContainer sx={{ height: 700, overflow: "auto", width: "100%" }} component={Paper}>
            <Table sx={{ minHeight: 124 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  {tableData.results && tableData.results.length > 0 ? (
                    Object.keys(tableData.results[0]).map((key) => {
                      return (
                        <TableCell key={key}>
                          <b>{key}</b>
                        </TableCell>
                      );
                    })
                  ) : (
                    <TableCell>No results</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.results && tableData.results.length > 0
                  ? tableData.results.map((record: any, index: number) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          data-testid={"sense-linse-records-item"}>
                          {Object.keys(record).map((key: string) => {
                            return (
                              <TableCell key={key} align="left">
                                {String(record[key])}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationComponent
            itemsPerPage={pageSize}
            totalItems={tableData.total_length}
            paginate={handlePaginate}
            currentPage={page}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  table_container: {
    width: "100%",
  },
  data_table_title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "16px",
  },
};

export default DataTable;
