import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { connect } from "react-redux";
import { adminDeviceSetName } from "../../../reducers/device-status-reducer";

const DeviceSetName = (props) => {
  const [name, setName] = useState(props.name ? props.name : "");

  const _setName = () => {
    props.dispatch(adminDeviceSetName(props.box_id, name));
    props.closeCallback();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeCallback}
      aria-labelledby="form-dialog-title"
      data-testid={"set-name"}>
      <DialogTitle id="form-dialog-title">Device Name</DialogTitle>
      <DialogContent>
        <DialogContentText>Box name is the name of the device itself.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="device_name"
          label="Device Name"
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeCallback}>Cancel</Button>
        <Button onClick={_setName}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

DeviceSetName.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  box_id: PropTypes.string.isRequired,
};

export default connect()(DeviceSetName);
