import React, { useState } from "react";
import "../../App.css";
import { connect } from "react-redux";
import { Typography, Box, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import CreateAccountWithPreApprovedKey from "../common/Components/CreateAccountPreApprovedKey";
import { APP_BAR_ITEMS } from "../common/Components/CommunityManagerAppBar";
import CommunityManagerAppBar from "../common/Components/CommunityManagerAppBar";
const CreateAccountMain = (props) => {
  const { communityId } = useParams();
  const communityIdValue = parseInt(communityId);
  const navigate = useNavigate();
  const [createAccountOpen, setCreateAccountOpen] = useState(true);

  const closeCreateAccount = () => {
    setCreateAccountOpen(false);
  };

  if (!communityIdValue) {
    navigate("/pick-community");
    return;
  }

  return (
    <div>
      <CommunityManagerAppBar selected={APP_BAR_ITEMS.ADD_MEMBER} {...props}>
        <Card sx={{ padding: 4 }}>
          <Box sx={{ marginBottom: 4 }}>
            <Typography color="primary" variant="h6" sx={{ marginBottom: 1 }}>
              Add Member
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Enter a phone number below and the user will automatically be approved for keys selected.
            </Typography>
          </Box>
          <CreateAccountWithPreApprovedKey
            community_id={communityIdValue}
            closeCallback={closeCreateAccount}
            open={createAccountOpen}
            is_admin={props.is_admin}
            is_dialog={false}
          />
        </Card>
      </CommunityManagerAppBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    is_admin: state.profileState.details.is_admin,
  };
};

export default connect(mapStateToProps)(CreateAccountMain);
