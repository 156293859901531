import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import AdminAppBar from "./AdminAppBar";
import { getReadOnlyStatusOfServer, setReadOnlyStatusOfServer } from "../../../reducers/admin-maintenance-mode-reducer";
import store from "../../../store";

const AdminMaintenanceMode = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    props.dispatch(getReadOnlyStatusOfServer());
  }, []);

  if (!props.is_admin) {
    navigate("/");
    return;
  }

  const handleDialogClick = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setReadOnlyMode(null);
  };

  const handleSwitchChange = () => {
    setReadOnlyMode((oldValue) => (oldValue != null ? !oldValue : props.READ_ONLY_MODE));
  };

  const handleConfirm = () => {
    store.dispatch(setReadOnlyStatusOfServer(readOnlyMode));
    handleDialogClose();
  };

  return (
    <>
      {
        <AdminAppBar selected="Admin Maintenance Mode" {...props}>
          <div>
            <Button color="primary" variant="contained" onClick={() => handleDialogClick()}>
              Maintenance Mode
            </Button>
            <Dialog onClose={handleDialogClose} open={isDialogOpen}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  padding: "8px",
                }}>
                <DialogTitle>SET SERVER MAINTENANCE MODE</DialogTitle>
                <Typography variant="subtitle1" component="div">
                  {readOnlyMode !== null
                    ? readOnlyMode
                      ? "READ ONLY MODE ACTIVE"
                      : "READ ONLY MODE INACTIVE"
                    : props.READ_ONLY_MODE
                    ? "READ ONLY MODE ACTIVE"
                    : "READ ONLY MODE INACTIVE"}
                </Typography>
                <Switch
                  onChange={handleSwitchChange}
                  checked={readOnlyMode !== null ? readOnlyMode : props.READ_ONLY_MODE}
                />
                <Button color="secondary" variant="contained" onClick={() => handleConfirm()}>
                  Confirm
                </Button>
              </Box>
            </Dialog>
          </div>
        </AdminAppBar>
      }
    </>
  );
};

AdminMaintenanceMode.propTypes = {};

const mapStateToProps = (state) => {
  return {
    READ_ONLY_MODE: state.adminMaintenanceModeState.READ_ONLY_MODE,
    is_admin: state.profileState.details.is_admin,
  };
};

export default connect(mapStateToProps)(AdminMaintenanceMode);
