import "react-phone-number-input/style.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import DistributorAppBar from "./DistributorAppBar";
import AddInstaller from "../../common/Components/AddInstaller";

class DistributorAddInstaller extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <DistributorAppBar selected="Add Installer" {...this.props}>
        <AddInstaller />
      </DistributorAppBar>
    );
  }
}

DistributorAddInstaller.propTypes = {};

const mapStateToProps = (state) => {
  return {
    addInstaller: state.addInstaller,
  };
};

export default connect(mapStateToProps)(DistributorAddInstaller);
