import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIconOutline from "@mui/icons-material/HomeOutlined";
import { Avatar } from "@mui/material";
import { connect } from "react-redux";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { showDetails } from "../../../reducers/homes-reducer";
import { getColor } from "../../../utilities/materialAvatar";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  owner_occupied: {},
});

const HomesListItem = (props) => {
  const theme = useTheme();

  return (
    <Box sx={styles(theme).container}>
      <ListItem
        button
        sx={styles(theme).nested}
        onClick={() =>
          props.dispatch(
            showDetails(
              props.id,
              props.name,
              props.address,
              props.phone,
              props.email,
              props.owner_name,
              props.owner_occupied
            )
          )
        }
        data-testid={"homes-list-item"}>
        <ListItemIcon>
          <Avatar sx={{ backgroundColor: getColor(props.id) }}>
            <HomeIconOutline />
          </Avatar>
        </ListItemIcon>
        <ListItemText inset>{props.address}</ListItemText>
      </ListItem>
      {props.owner_occupied ? (
        <ListItemText sx={styles(theme).ownerOccupied} inset>
          <Tooltip enterTouchDelay={0} title="Owner Occupied">
            <IconButton size="large">
              <PermIdentityIcon />
            </IconButton>
          </Tooltip>
        </ListItemText>
      ) : null}
    </Box>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(HomesListItem);
