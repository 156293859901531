import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import CategoryIcon from "@mui/icons-material/Category";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { Box, Divider, Drawer, Hidden, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import SendIcon from "@mui/icons-material/Send";
import HomeIcon from "@mui/icons-material/Home";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import SwapIcon from "@mui/icons-material/SwapHoriz";
import HistoryIcon from "@mui/icons-material/History";
import Typography from "@mui/material/Typography";
import HelpIcon from "@mui/icons-material/Help";
import { connect, useDispatch } from "react-redux";
import { MeetingRoom } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import NimbioAppBar from "./NimbioAppBar";
import ReadOnlyErrorModal from "./ReadOnlyErrorModal";
import { nimbioPalette } from "../../../theme";
import { setCurrentCommunity } from "../../../reducers/communities-reducer";
import endpoints from "../../../routes/endpoints";
import { logout } from "../../../reducers/connection-reducer";
import nimbioServer from "../../../server/endpoints";
import { communitySubscription } from "../../../subscriptions/communitySubscription";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { clearKeys } from "../../../reducers/keys-reducer";

const drawerWidth = 265;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    marginTop: theme.spacing(8),
  },
  content: {
    flex: 1,
    p: 2,
    pt: 3,
    gap: theme.spacing(1),
    backgroundColor: nimbioPalette.white,
  },
  commHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.mixins.toolbar.minHeight,
  },
  nimbioFont: {
    font: "Filson",
  },
  versionContainer: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    marginBottom: theme.spacing(8),
  },
  versionText: {
    cursor: "cursor",
  },
});

export const APP_BAR_ITEMS = {
  MEMBERS: "Members",
  KEYS: "Keys",
  VENDORS: "Vendors",
  HOMES: "Homes",
  ACCESS_LOGS: "Access Logs",
  ADMIN: "Admin",
  HOLD_OPENS: "Hold Opens",
  ADD_MEMBER: "Add Member",
  HELP: "Help",
  SIGN_OUT: "Logout",
  SEND_MESSAGE: "Community Messenger",
};

const TESTING_PREFIX = "nimbio-testing-appbar-";

export const CommunityAppBarDrawer = ({ props }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { currentCommunityId } = props;
  const { communityId } = useParams();

  useEffect(() => {
    return () => {
      dispatch(clearKeys());
    };
  }, []);

  // This seems odd. Why not just use the communityId from the URL?
  const communityIdToUse = communityId || currentCommunityId;
  useSubscription(nimbioServer.communityManager.community + "." + communityIdToUse, communitySubscription);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }} data-testid={"community-drawer"}>
      <Box sx={styles(theme).toolbar}>
        <Box sx={styles(theme).commHeader}>{props?.communities?.currentCommunity?.name || ""}</Box>
      </Box>
      <Divider />
      <List>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.MEMBERS.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.MEMBERS}
          key={APP_BAR_ITEMS.MEMBERS}
          onClick={() => navigate("/community-manager/" + communityIdToUse + "/community-detail")}
          data-testid={"members"}>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.MEMBERS} />
        </ListItemButton>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.KEYS.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.KEYS}
          key={APP_BAR_ITEMS.KEYS}
          onClick={() => navigate("/community-manager/" + communityIdToUse + "/community-keys")}
          data-testid={"keys"}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.KEYS} />
        </ListItemButton>
        {/*<ListItemButton*/}
        {/*  id={TESTING_PREFIX + APP_BAR_ITEMS.VENDORS.replace(/\s/g, "-")}*/}
        {/*  selected={props.selected === APP_BAR_ITEMS.VENDORS}*/}
        {/*  key={APP_BAR_ITEMS.VENDORS}*/}
        {/*  onClick={() => {*/}
        {/*    if (props.profileState.details.is_admin && !communityIdToUse) {*/}
        {/*      navigate(endpoints.ADMIN_VENDORS);*/}
        {/*    } else {*/}
        {/*      navigate("/community-manager/" + communityIdToUse + "/vendors");*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  data-testid={"vendors"}>*/}
        {/*  <ListItemIcon>*/}
        {/*    <SupervisorAccountIcon />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText primary={APP_BAR_ITEMS.VENDORS} />*/}
        {/*</ListItemButton>*/}
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.HOMES.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.HOMES}
          key={APP_BAR_ITEMS.HOMES}
          onClick={() => navigate("/community-manager/" + communityIdToUse + "/community-homes")}
          data-testid={"homes"}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.HOMES} />
        </ListItemButton>
        {props.communities.currentCommunity?.allow_hold_opens && (
          <ListItemButton
            id={TESTING_PREFIX + APP_BAR_ITEMS.HOLD_OPENS.replace(/\s/g, "-")}
            selected={props.selected === APP_BAR_ITEMS.HOLD_OPENS}
            key={APP_BAR_ITEMS.HOLD_OPENS}
            onClick={() => navigate("/community-manager/" + communityIdToUse + "/community-hold-opens")}
            data-testid={"hold-opens"}>
            <ListItemIcon>
              <MeetingRoom />
            </ListItemIcon>
            <ListItemText primary={APP_BAR_ITEMS.HOLD_OPENS} />
          </ListItemButton>
        )}
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.ADD_MEMBER.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.ADD_MEMBER}
          key={APP_BAR_ITEMS.ADD_MEMBER}
          onClick={() => navigate("/community-manager/" + communityIdToUse + "/add-member")}
          data-testid={"add-member"}>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.ADD_MEMBER} />
        </ListItemButton>
        {props?.profileState?.details.is_community_manager &&
          props?.communities?.currentCommunity?.permissions?.is_open_log_history_enabled &&
          props?.communities?.currentCommunity?.permissions?.CM_permissions.is_open_log_history_enabled && (
            <ListItemButton
              id={TESTING_PREFIX + APP_BAR_ITEMS.ACCESS_LOGS.replace(/\s/g, "-")}
              selected={props.selected === APP_BAR_ITEMS.ACCESS_LOGS}
              key={APP_BAR_ITEMS.ACCESS_LOGS}
              onClick={() => navigate("/community-manager/" + communityIdToUse + endpoints.ACCESS_LOGS)}
              data-testid={"access-logs"}>
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary={APP_BAR_ITEMS.ACCESS_LOGS} />
            </ListItemButton>
          )}
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.ACCESS_LOGS.replace(/\s/g, "-")}
          selected={props.selected === APP_BAR_ITEMS.SEND_MESSAGE}
          key={APP_BAR_ITEMS.SEND_MESSAGE}
          onClick={() => navigate("/community-manager/" + communityIdToUse + endpoints.COMMUNITY_SEND_MESSAGE)}
          data-testid={"cm-app-bar-send-community-message"}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.SEND_MESSAGE} />
        </ListItemButton>
        {props.profileState.details.is_admin && (
          <>
            <Divider />
            <ListItemButton
              id={TESTING_PREFIX + APP_BAR_ITEMS.ADMIN.replace(/\s/g, "-")}
              selected={props.selected === APP_BAR_ITEMS.ADMIN}
              key={APP_BAR_ITEMS.ADMIN}
              onClick={() => {
                dispatch(setCurrentCommunity(null));
                navigate(endpoints.ADMIN_DEVICE_MONITOR);
              }}
              data-testid={"admin"}>
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
              <ListItemText primary={APP_BAR_ITEMS.ADMIN} />
            </ListItemButton>
          </>
        )}
      </List>

      <Divider />
      <List>
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.HELP.replace(/\s/g, "-")}
          key={APP_BAR_ITEMS.HELP}
          onClick={() => {
            window.open(
              endpoints.EXTERNAL.nimbioHelpPage,
              "_blank" // <- This is what makes it open in a new window.
            );
          }}
          data-testid={"help"}>
          <ListItemIcon>
            <HelpIcon />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.HELP} />
        </ListItemButton>
      </List>
      <Divider />
      <List>
        {props.communities.details.length > 1 ? (
          <ListItemButton
            key="Keys"
            onClick={() => navigate(endpoints.PICK_COMMUNITY)}
            data-testid={"switch-community"}>
            <ListItemIcon>
              <SwapIcon />
            </ListItemIcon>
            <ListItemText primary="Switch Community" />
          </ListItemButton>
        ) : (
          <></>
        )}
        <ListItemButton
          id={TESTING_PREFIX + APP_BAR_ITEMS.SIGN_OUT.replace(/\s/g, "-")}
          key={APP_BAR_ITEMS.SIGN_OUT}
          onClick={() => {
            dispatch(logout());
          }}
          data-testid={"logout"}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary={APP_BAR_ITEMS.SIGN_OUT} />
        </ListItemButton>
      </List>
      <List style={styles(theme).versionContainer}>
        <ListItem>
          <Typography variant="caption" style={styles(theme).versionText}>
            Build Version: {process.env.REACT_APP_BUILD_VERSION ? process.env.REACT_APP_BUILD_VERSION : "Dev"}
          </Typography>
        </ListItem>
      </List>
    </Box>
  );
};

const CommunityManagerAppBar = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();

  const handleDrawerToggle = () => {
    setMobileOpen((oldValue) => !oldValue);
  };

  return (
    <Box sx={styles(theme).root} data-testid={"community-manager-app-bar"}>
      <NimbioAppBar title="Community Manager" handleDrawerToggle={handleDrawerToggle} />
      <Box sx={styles(theme).drawer}>
        {/* The implementation can be swap with js to avoid SEO duplication of links. */}
        {/* Mobile View */}
        <Hidden>
          <Drawer
            container={props.container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            PaperProps={{
              sx: styles(theme).drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}>
            <CommunityAppBarDrawer props={{ ...props }} />
          </Drawer>
        </Hidden>
        {/* Desktop View */}
        <Hidden xsDown>
          <Drawer
            PaperProps={{ sx: styles(theme).drawerPaper }}
            sx={{ display: { xs: "none", sm: "block" } }}
            variant="permanent"
            open>
            <CommunityAppBarDrawer props={{ ...props }} />
          </Drawer>
        </Hidden>
      </Box>
      <Box sx={styles(theme).content}>
        <Box sx={styles(theme).toolbar} />
        <ReadOnlyErrorModal />
        {props.children}
      </Box>
    </Box>
  );
};

CommunityManagerAppBar.propTypes = {
  container: PropTypes.object,
  selected: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentCommunityId: state.communityState.currentCommunityID,
    communities: state.communityState,
    membersState: state.membersState,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(CommunityManagerAppBar);
