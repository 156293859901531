import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { connect } from "react-redux";
import { DialogActions, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../reducers/connection-reducer";

function NoAccountDialog(props) {
  const navigate = useNavigate();

  return (
    <Dialog open={props.open} disableEscapeKeyDown={true}>
      <DialogTitle>Your account is not setup</DialogTitle>
      <DialogContent>
        Your account is not currently setup. Please contact your community manager or admin to setup your account.
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.removeItem("auth_id");
            localStorage.removeItem("secret");
            window.location.href = "/";
            props.dispatch(logout);
            navigate("/login");
          }}>
          Back to Login
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connect()(NoAccountDialog);
