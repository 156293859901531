import React, { Component } from "react";
import { connect } from "react-redux";
import AdminAppBar from "./AdminAppBar";
import AdminCommunityList from "./AdminCommunityList";

class AdminActiveCommunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_term: "",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <AdminAppBar selected="Communities" {...this.props}>
        <AdminCommunityList type="active" />
      </AdminAppBar>
    );
  }
}

AdminActiveCommunities.propTypes = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(AdminActiveCommunities);
