import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogTitle, TextField } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { nimbioPalette } from "../../../theme";
import nimbioLocationIcon from "../../../assets/image/nimbio_location_icon.png";

const Marker = ({ lat, lng, size = 75 }) => {
  return (
    <Box lat={lat} lng={lng} sx={{ position: "relative" }}>
      <img
        src={nimbioLocationIcon}
        width={size}
        height={size}
        style={{ left: -size / 2, top: -size, position: "absolute" }}
      />
    </Box>
  );
};

// Location of Nimbio
const defaultPinLocation = { lat: 36.08285797874148, lng: -115.18461924851171 };

export const OverrideLocationField = ({
  handleLocationFieldClick,
  initialMetadata,
  userModifiedMetaData,
  changedMetadataOnServerSinceLoad,
  mapsModalOpen,
  handleCloseModal,
  updateOverrideLocation,
}) => {
  const [location, setLocation] = useState("");
  const [textValue, setTextValue] = useState("");

  useEffect(() => {
    const newLocation = getFieldValue();
    setLocation(newLocation);
  }, [userModifiedMetaData, changedMetadataOnServerSinceLoad, initialMetadata]);

  useEffect(() => {
    setTextValue(location === "" ? "Unknown" : `(${location?.lat},${location?.lng})`);
  }, [location]);

  const getFieldValue = () => {
    if (userModifiedMetaData?.["override_location"]) return userModifiedMetaData?.["override_location"];
    if (changedMetadataOnServerSinceLoad?.["override_location"])
      return changedMetadataOnServerSinceLoad?.["override_location"];
    return initialMetadata?.["override_location"].lat && initialMetadata?.["override_location"].lng
      ? initialMetadata?.["override_location"]
      : "";
  };

  const renderMarker = (obj) => {
    setLocation({ lat: obj.lat, lng: obj.lng });
  };

  return (
    <Box sx={{ p: 1 }}>
      <TextField
        label="Override Location"
        variant="outlined"
        name="override_location"
        shrink="true"
        fullWidth={true}
        onClick={handleLocationFieldClick}
        value={textValue}
      />
      <Dialog
        open={mapsModalOpen}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        sx={{ backgroundColor: nimbioPalette.white }}>
        <DialogTitle>Choose Override Location</DialogTitle>
        <Box style={{ height: "80vh", width: "100%" }}>
          <GoogleMapReact
            key={JSON.stringify(location)}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_USPS_MAP_KEY,
              libraries: ["places", "geometry", "drawing", "visualization"],
            }}
            defaultZoom={location !== "" ? 18 : 5}
            onClick={renderMarker}
            defaultCenter={location !== "" ? location : defaultPinLocation}>
            {location !== "" && <Marker lat={location.lat} lng={location.lng} />}
          </GoogleMapReact>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "50%",
            }}>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={() => {
                handleCloseModal();
              }}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              sx={{ m: 1 }}
              onClick={() => {
                updateOverrideLocation({ lat: location?.lat, lng: location?.lng });
                handleCloseModal();
              }}>
              SET LOCATION
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
