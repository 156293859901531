import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import WeekdayButton from "./WeekdayButton";
import { addHoldOpenRecurringEvent } from "../../../reducers/hold-open-reducers";

const styles = () => ({
  text: {
    fontFamily: "Filson",
  },
});

const AddRecurring = (props) => {
  const [recurringWeek, setRecurringWeek] = useState("1");
  const [daysOfTheWeek, setDaysOfTheWeek] = useState({
    U: false,
    M: false,
    T: false,
    W: false,
    H: false,
    F: false,
    S: false,
  });

  const setInitialState = () => {
    setRecurringWeek("1");
    setDaysOfTheWeek({
      U: false,
      M: false,
      T: false,
      W: false,
      H: false,
      F: false,
      S: false,
    });
  };

  const _dotwHandler = (serverKey) => {
    setDaysOfTheWeek((oldDaysOfTheWeek) => {
      return { ...oldDaysOfTheWeek, [serverKey]: !oldDaysOfTheWeek[serverKey] };
    });
  };

  const _onOK = () => {
    const dotw = Object.keys(daysOfTheWeek)
      .filter((key) => daysOfTheWeek[key])
      .reduce((accum, key) => accum + key, "");
    props.dispatch(addHoldOpenRecurringEvent(props.community_id, props.latch_id, dotw, parseInt(recurringWeek)));
    setInitialState();
    props.closeCallback();
  };

  const _onCancel = () => {
    setInitialState();
    props.closeCallback();
  };

  const _checkDisableOK = () => {
    return !Object.values(daysOfTheWeek).reduce((accum, value) => accum | value, false);
  };

  return (
    <Dialog data-testid="dotw-recurring-event-dialog" maxWidth="lg" open={props.open}>
      <DialogTitle id="max-width-dialog-title" sx={styles().text}>
        Add Recurring Hold Open
      </DialogTitle>
      <DialogContent>
        <Box style={{ paddingTop: 1, paddingBottom: 3 }}>
          <Typography sx={styles().text}>
            Set the parameters for a recurring event. Times will be added later.
          </Typography>
        </Box>
        {/*<FormControl component="fieldset">*/}
        {/*  <FormLabel component="legend">Repeating Week</FormLabel>*/}
        {/*  <RadioGroup row aria-label="position" name="position" defaultValue="1">*/}
        {/*    <FormControlLabel*/}
        {/*      value="1"*/}
        {/*      control={<Radio color="primary" />}*/}
        {/*      label="Every Week"*/}
        {/*      labelPlacement="bottom"*/}
        {/*    />*/}
        {/*    <FormControlLabel*/}
        {/*      value="start"*/}
        {/*      control={<Radio color="primary" />}*/}
        {/*      label="Every Other Week"*/}
        {/*      labelPlacement="bottom"*/}
        {/*    />*/}
        {/*  </RadioGroup>*/}
        {/*</FormControl>*/}
        <Typography sx={[{ paddingTop: 2, paddingBottom: 2 }, styles().text]}>Days of the Week</Typography>
        <Grid container spacing={1}>
          <WeekdayButton dayLabel="Su" serverKey="U" handleClick={_dotwHandler} isActive={daysOfTheWeek["U"]} />
          <WeekdayButton dayLabel="M" serverKey="M" handleClick={_dotwHandler} isActive={daysOfTheWeek["M"]} />
          <WeekdayButton dayLabel="Tu" serverKey="T" handleClick={_dotwHandler} isActive={daysOfTheWeek["T"]} />
          <WeekdayButton dayLabel="W" serverKey="W" handleClick={_dotwHandler} isActive={daysOfTheWeek["W"]} />
          <WeekdayButton dayLabel="Th" serverKey="H" handleClick={_dotwHandler} isActive={daysOfTheWeek["H"]} />
          <WeekdayButton dayLabel="F" serverKey="F" handleClick={_dotwHandler} isActive={daysOfTheWeek["F"]} />
          <WeekdayButton dayLabel="Sa" serverKey="S" handleClick={_dotwHandler} isActive={daysOfTheWeek["S"]} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={_onCancel} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={_onOK} disabled={_checkDisableOK()} variant="contained" color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddRecurring.propTypes = {
  closeCallback: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
};

export default connect()(AddRecurring);
