import React from "react";
import { connect } from "react-redux";
import {
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  Chip,
  Divider,
} from "@mui/material";

import { getColor, getShortName } from "../../../utilities/materialAvatar";
import { closeMemberChildren } from "../../../reducers/member-children-reducer";
import { clearKeys, getKeys } from "../../../reducers/keys-reducer";

class ChildrenDialog extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      keys: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(getKeys(this.props.communityID));
  }

  componentWillUnmount() {
    this.props.dispatch(clearKeys());
  }

  dialogContent = () => {
    if (this.props.children.loading) {
      return <div>Loading children</div>;
    }
    if (this.props.children.error) {
      return <div>Error loading member children keys.</div>;
    }

    return this.displayKeys();
  };

  handleClose = () => {
    this.props.dispatch(closeMemberChildren());
  };

  render() {
    return (
      <Dialog open={this.props.children?.open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle>Member Children</DialogTitle>
        <DialogContent>{this.dialogContent()}</DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} variant="contained" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  displayKeys = () => {
    return <List>{this.props.children.details.map(this.displayKey)}</List>;
  };

  displayKey = (parent) => {
    return (
      <Box key={parent.key_id}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: getColor(parent.key_id) }}>{getShortName(parent.key_name, "")}</Avatar>
          </ListItemAvatar>
          <ListItemText>{parent.key_name}</ListItemText>
        </ListItem>
        {parent.subs.length === 0 && <ListItem sx={{ paddingLeft: 9 }}>No Children</ListItem>}
        {parent.subs.length > 0 && this.displayChildren(parent.subs)}
        <Divider sx={{ marginBottom: 2, marginTop: 2 }} />
      </Box>
    );
  };

  displayChildren = (subs) => {
    if (subs.length === 0) {
      return;
    }
    return <List style={{ paddingLeft: 24 }}>{subs.map((sub, index) => this.displayChild(sub, index))}</List>;
  };

  displayChild = (child, index) => {
    return (
      <Box key={child.account_name + index}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: getColor(child.account_name) }}>
              {getShortName(child.account_name, "")}
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={child.account_name} />
          {this.keyTypes(child.key_types)}
        </ListItem>
        <Box sx={{ paddingLeft: 2 }}>{this.displayChildren(child.subs)}</Box>
      </Box>
    );
  };

  keyTypes = (keyTypes) => {
    return keyTypes.map((name) => <Chip key={name} size="small" label={name} />);
  };
}

const mapStateToProps = (state) => {
  return {
    children: state.memberChildren,
  };
};

export default connect(mapStateToProps)(ChildrenDialog);
