import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar, Badge, Button, CardHeader, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import WeekdayButton from "./WeekdayButton";
import RecurringTime from "./RecurringTime";
import AddTime from "./AddTime";
import { removeHoldOpenRecurringEvent } from "../../../reducers/hold-open-reducers";
import { getColor } from "../../../utilities/materialAvatar";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  addPaper: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  label: {
    color: theme.palette.text.secondary,
    textAlign: "left",
    paddingTop: 2,
    paddingBottom: 2,
  },
  noPointer: {
    cursor: "initial",
  },
});

const RecurringEvent = (props) => {
  const [addTimeOpen, setAddTimeOpen] = useState(false);
  const theme = useTheme();

  const _dotwHandler = () => {
    return true;
  };

  const _remove_recurring_event = () => {
    props.dispatch(removeHoldOpenRecurringEvent(props.community_id, props.latch_id, props.temporal_date_id));
  };

  const _close_add_time = () => {
    setAddTimeOpen(false);
  };

  const _open_add_time = () => {
    setAddTimeOpen(true);
  };

  return (
    <Grid data-testid={"recurring-event"} key={props.key} item xs={12}>
      <CardHeader
        avatar={
          <Badge
            data-testid={"recurring-event-active-badge"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            color="secondary"
            badgeContent="Active"
            invisible={!props.active}>
            <Avatar aria-label="recipe" sx={{ backgroundColor: getColor(props.temporal_date_id) }}>
              R
            </Avatar>
          </Badge>
        }
        title="Recurring Event"
        subheader={props.disabled_active ? "Disabled" : props.active ? "Active" : "Inactive"}
      />
      <Typography sx={styles(theme).label}>Active Days of the Week</Typography>

      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <WeekdayButton
          dayLabel="Su"
          serverKey="U"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("U")}
        />
        <WeekdayButton
          dayLabel="M"
          serverKey="M"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("M")}
        />
        <WeekdayButton
          dayLabel="Tu"
          serverKey="T"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("T")}
        />
        <WeekdayButton
          dayLabel="W"
          serverKey="W"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("W")}
        />
        <WeekdayButton
          dayLabel="Th"
          serverKey="H"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("H")}
        />
        <WeekdayButton
          dayLabel="F"
          serverKey="F"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("F")}
        />
        <WeekdayButton
          dayLabel="Sa"
          serverKey="S"
          handleClick={_dotwHandler}
          styles={styles(theme).noPointer}
          isActive={props.days_of_the_week.includes("S")}
        />
      </Grid>
      <Typography color="textSecondary" sx={{ textAlign: "center" }}>
        {props.recurring_week === 1 ? "Recurs Every Week" : "Recurs Every Other Week"}
      </Typography>
      <Typography sx={[styles(theme).label, { mt: 2.5 }]}>Active Time Windows</Typography>
      {props.temporal_times.length === 0 && (
        <Typography sx={{ color: "red", marginBottom: 2.5 }}>
          Latch will be open all day if you do not specify a time window
        </Typography>
      )}

      <Grid container direction="row" justifyContent="flex-start" alignItems="baseline" spacing={3}>
        {props.temporal_times.map((temporal_time) => (
          <RecurringTime
            key={temporal_time.temporal_time_id}
            temporal_time_id={temporal_time.temporal_time_id}
            start={temporal_time.start}
            end={temporal_time.end}
            community_id={props.community_id}
            temporal_date_id={props.temporal_date_id}
            latch_id={props.latch_id}
          />
        ))}

        {/*<Grid item xs={12} md={6}>*/}
        {/*  <Paper sx={styles(theme).addPaper}>*/}
        {/*    <Button variant="contained" onClick={_open_add_time}>*/}
        {/*      Add Time*/}
        {/*    </Button>*/}
        <AddTime
          open={addTimeOpen}
          closeCallback={_close_add_time}
          community_id={props.community_id}
          latch_id={props.latch_id}
          temporal_date_id={props.temporal_date_id}
        />
        {/*  </Paper>*/}
        {/*</Grid>*/}
      </Grid>

      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
        <Grid item sx={{ marginTop: 2.5, marginBottom: 2.5 }} xs={12}>
          <Button size="small" variant="contained" onClick={_open_add_time}>
            Add Time Window
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button sx={{ marginBottom: 2.5 }} variant="outlined" onClick={_remove_recurring_event}>
            Remove Recurring Event
          </Button>
          <Divider sx={{ marginBottom: 1.25 }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

RecurringEvent.propTypes = {
  temporal_date_id: PropTypes.string.isRequired,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
  active: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
  temporal_times: PropTypes.array.isRequired,
  days_of_the_week: PropTypes.string.isRequired,
  recurring_week: PropTypes.number.isRequired,
  disabled_active: PropTypes.bool.isRequired,
};

export default connect()(RecurringEvent);
