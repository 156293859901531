import React from "react";
import { FormControlLabel, FormGroup, Box, Checkbox, Typography, Divider, Card } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Home, User } from "../../../utilities/nimbioTypes";

type UserCheckboxesProps = {
  users: (User | Home)[];
  checkedUsers: Record<string, boolean>;
  handleCheckboxChange: (userId: string, value: boolean) => void;
  selectAll: () => void;
  deselectAll: () => void;
  selectType: string;
};

function SelectMembers({
  users,
  checkedUsers,
  handleCheckboxChange,
  selectAll,
  deselectAll,
  selectType,
}: UserCheckboxesProps) {
  const getSelectAllIcon = () => {
    switch (selectType) {
      case "select-all":
        return (
          <CheckBoxOutlineBlankIcon onClick={handleSelect} data-testid="select-members-select-all" color={"action"} />
        );
      case "deselect-all":
        return <CheckBoxIcon onClick={handleSelect} data-testid="select-members-deselect-all" color={"primary"} />;
      case "partially-selected":
        return (
          <IndeterminateCheckBoxIcon
            onClick={handleSelect}
            data-testid="select-members-partially-selected"
            color={"primary"}
          />
        );
      default:
        console.error(`Incorrect select type`);
    }
  };

  const handleSelect = () => {
    switch (selectType) {
      case "select-all":
        selectAll();
        break;
      case "deselect-all":
        deselectAll();
        break;
      case "partially-selected":
        deselectAll();
        break;
      default:
        console.error(`Incorrect select type`);
    }
  };

  return (
    <Card sx={{ padding: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <Box>
          <Typography data-testid={"select-members-title"}>
            <b>Select Members</b>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ paddingBottom: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "row", marginTop: 1, justifyContent: "flex-start" }}>
        {getSelectAllIcon()} <Typography sx={{ marginLeft: 1 }}>Select All</Typography>
      </Box>

      {users.map((user: any) => {
        return (
          <Box sx={{ paddingTop: 1, paddingLeft: 0.3 }} key={user.id}>
            {!user.is_home ? (
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid={`select-members-checkbox-${user.id}`}
                        onChange={() => handleCheckboxChange(user.id, !checkedUsers[user.id])}
                        checked={checkedUsers[user.id]}
                      />
                    }
                    label={user.first_name + " " + user.last_name}
                  />
                </FormGroup>
              </Box>
            ) : (
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => handleCheckboxChange(user.id, !checkedUsers[user.id])}
                        checked={checkedUsers[user.id]}
                        data-testid={`select-members-checkbox-${user.id}`}
                      />
                    }
                    label={user.home_address}
                  />
                </FormGroup>
              </Box>
            )}
          </Box>
        );
      })}
    </Card>
  );
}

export default SelectMembers;
