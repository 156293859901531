import { createAction, handleActions } from "redux-actions";

export const pageVisibleChange = createAction("PAGE_VISIBLE_CHANGE");

const initialState = {
  pageVisible: true,
};

export default handleActions(
  {
    PAGE_VISIBLE_CHANGE: (state) => {
      return {
        ...state,
        pageVisible: document.visibilityState === "visible",
      };
    },
  },
  initialState,
);
