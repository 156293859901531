import Wampy from "wampy";
import { CallType, SubscriptionType, UnsubscriptionType } from "./nimbioTypes";
import nimbioDebugger from "./debugger";

export let nimbioConnection = new Wampy();

export const setConnection = (connection: wampy.Wampy) => {
  nimbioConnection = connection;
};

const DEBUG_MODULE = "connection";

export const call: CallType = async (topicURI, params, origin?) => {
  // TODO(rick) uncomment these when routes are fixed
  // @/ts-expect-error reaching behind the interface
  // if (nimbioConnection._cache.sessionId === null || nimbioConnection._ws === null) {
  //     throw new Error("wamp is currently disconnected");
  // }

  const moduleIdentifier = origin ? DEBUG_MODULE + " -> " + origin : DEBUG_MODULE;
  nimbioDebugger.matchType(topicURI, "string", moduleIdentifier);
  nimbioDebugger.matchType(params, "object", DEBUG_MODULE);
  try {
    const result = await nimbioConnection.call(topicURI, params);
    // TODO(rick) clean this up
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return result.argsList[0];
  } catch (e) {
    console.error(topicURI, JSON.stringify(e));
    nimbioDebugger.debug("RPC Call error from endpoint: " + topicURI, moduleIdentifier, {
      extraMsg: JSON.stringify(e),
    });
  }
};

// TODO(rick) clean this up
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const subscribe: SubscriptionType = async (topicURI, callback) => {
  // TODO(rick) uncomment these when routes are fixed
  // @/ts-expect-error reaching behind the interface
  // if (nimbioConnection._cache.sessionId === null || nimbioConnection._ws === null) {
  //     throw new Error("wamp is currently disconnected");
  // }

  // Debug
  nimbioDebugger.matchType(topicURI, "string", DEBUG_MODULE);

  try {
    const result = await nimbioConnection.subscribe(topicURI, callback);
    return result;
  } catch (e) {
    console.error(" " + topicURI, JSON.stringify(e));
    nimbioDebugger.debug("Subscription error with topicURI " + topicURI, DEBUG_MODULE, { extraMsg: JSON.stringify(e) });
  }
};

// TODO(rick) clean this up
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const unsubscribe: UnsubscriptionType = async (subscriptionId, callback, topicURI?) => {
  // TODO(rick) uncomment these when routes are fixed
  // @/ts-expect-error reaching behind the interface
  // if (nimbioConnection._cache.sessionId === null || nimbioConnection._ws === null) {
  //     throw new Error("wamp is currently disconnected");
  // }

  // Debug
  nimbioDebugger.matchType(subscriptionId, "string", DEBUG_MODULE);

  try {
    if (typeof subscriptionId === "string") {
      const result = await nimbioConnection.unsubscribe(subscriptionId, callback);
      return result;
    }
  } catch (e) {
    let errMsg = "Unsubscription error with subscriptionId: " + subscriptionId;
    if (topicURI) errMsg += +" and topicURI: " + topicURI;
    console.error(errMsg, JSON.stringify(e));
    nimbioDebugger.debug(errMsg, DEBUG_MODULE, { extraMsg: JSON.stringify(e) });
  }
};
