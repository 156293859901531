import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import HomesListItem from "./HomesListItem";
import BulkAddHomes from "./BulkAddHomes";
import { getHomes } from "../../../reducers/homes-reducer";
import LoadingScreen from "../../common/Components/LoadingScreen";
import CommunityAllowNewMemberToRequestHome from "../../common/Components/CommunityAllowNewMemberToRequestHome";
import { setCurrentCommunity } from "../../../reducers/communities-reducer";

const HomeList = (props) => {
  const [homes, setHomes] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const homesState = useSelector((state) => state.homesState);
  const { details: communityDetails, currentCommunity } = useSelector((state) => state.communityState);

  useEffect(() => {
    dispatch(getHomes(props.community_id));
  }, []);

  useEffect(() => {
    dispatch(setCurrentCommunity(props.community_id));
  }, [communityDetails]);

  useEffect(() => {
    setHomes(returnHomeList());
  }, [homesState]);

  useEffect(() => {
    if (homesState.loading) setLoading(true);
    const loadDelay = setTimeout(() => {
      setLoading(false);
    }, 950);
    return () => {
      clearTimeout(loadDelay);
    };
  }, [homesState.loading]);

  const returnHomeList = () => {
    const sortedArr = homesState.homes.sort((a, b) => {
      const homeA = a.address.toLowerCase();
      const homeB = b.address.toLowerCase();
      return homeA < homeB ? -1 : homeA > homeB ? 1 : 0;
    });

    const sortedHomes = sortedArr.map((item) => {
      return (
        <HomesListItem
          key={item.id}
          id={item.id}
          name={item.name}
          address={item.address}
          community_id={props.community_id}
          owner_occupied={item.owner_occupied}
          email={item.home_email}
          phone={item.home_phone}
          owner_name={item.owner_name}
        />
      );
    });

    return sortedHomes;
  };

  return (
    <div data-testid={"homes-list"}>
      <Box sx={{ display: "flex", paddingBottom: "16px", alignItems: "flex-end", flexDirection: "column" }}>
        <BulkAddHomes />
        <CommunityAllowNewMemberToRequestHome
          community_id={props.community_id}
          is_new_member_request_home_enabled={currentCommunity?.is_new_member_request_home_enabled ? true : false}
          community_type={currentCommunity?.community_type ? currentCommunity?.community_type : ""}
        />
      </Box>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {homesState.error && <div>Error Loading Homes</div>}
          {!homesState.loading && !homesState.error && homesState.homes.length > 0 && homes}
          {homes && homes.length === 0 && <div>No Homes</div>}
        </>
      )}
    </div>
  );
};

export default HomeList;
