import React from "react";
import { Routes, Route } from "react-router-dom";
import PickCommunity from "../views/PickCommunity/PickCommunity";
import AccessLogs from "../views/AccessLogs/index";
import Login from "../views/Login/Login";
import Members from "../views/Members/Members";
import CreateAccountMain from "../views/CreateAccount/CreateAccountMain";
import BasePage from "../views/common/BasePage";
import Keys from "../views/Keys/Keys";
import Homes from "../views/Homes/Homes";
import Verify from "../views/Login/Verify";
import Name from "../views/Login/Name";
import Installer from "../views/Installer/Components/Installer";
import {
  DistributorAddInstaller,
  DistributorDeviceMonitor,
  DistributorBoard,
  Distributor,
} from "../views/Distributor/index";
import HoldOpenMain from "../views/HoldOpen/HoldOpenMain";
import CommunitySenseLine from "../views/CommunityDevices/CommunitySenseLine";
import {
  AdminAccountGroups,
  AdminAccountList,
  AdminAccessLogs,
  AdminAccountView,
  AdminActiveCommunities,
  AdminAddAdmin,
  AdminAddDistributor,
  AdminAddInstaller,
  AdminCommunityView,
  AdminDeviceMonitor,
  AdminDeviceView,
  AdminHiddenCommunities,
  AdminHiddenDeviceMonitor,
  AdminInactiveCommunities,
  AdminIntegratedLatches,
  AdminLatchViewContainer,
  AdminMaintenanceMode,
  AdminRecentDeviceMonitor,
  // AdminSenseLines,
  AdminVendors,
  AdminVendorView,
  // LatchView,
  UspsMetaData,
  AdminUniversalSearchView,
} from "../views/Admin/index.js";
import ManagerCheck from "../views/CommunityManager/ManagerCheck";
import endpoints from "../routes/endpoints";
import SendCommunityMessage from "../views/SendCommunityMessage/SendCommunityMessage";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path={endpoints.LOGIN} element={<Login />} />
        <Route path={endpoints.VERIFY} element={<Verify />} />
        <Route path={endpoints.NAME} element={<Name />} />
        <Route path={endpoints.ROOT} element={<BasePage />}>
          <Route path={endpoints.ADMIN_DEVICE_MONITOR} element={<AdminDeviceMonitor />} />
          <Route path={endpoints.ADMIN_RECENT_DEVICE_MONITOR} element={<AdminRecentDeviceMonitor />} />
          <Route path={endpoints.ADMIN_ADD_INSTALLER} element={<AdminAddInstaller />} />
          <Route path={endpoints.ADMIN_ADD_DISTRIBUTOR} element={<AdminAddDistributor />} />
          <Route path={endpoints.ADMIN_ADD_ADMIN} element={<AdminAddAdmin />} />
          <Route path={endpoints.ADMIN_ACCOUNT_LIST} element={<AdminAccountList />} />
          <Route path={endpoints.ADMIN_ACCOUNT_GROUPS} element={<AdminAccountGroups />} />
          <Route path={endpoints.ADMIN_VENDOR + "/:vendorId"} element={<AdminVendorView />} />
          <Route path={endpoints.ADMIN_COMMUNITIES_TO_ADD} element={<AdminInactiveCommunities />} />
          <Route path={endpoints.ADMIN_COMMUNITIES} element={<AdminActiveCommunities />} />
          <Route path={endpoints.ADMIN_COMMUNITY + "/:communityId"} element={<AdminCommunityView />} />
          <Route path={endpoints.ADMIN_DEVICE + "/:deviceId"} element={<AdminDeviceView />} />
          <Route path={endpoints.ADMIN_ACCOUNT + "/:accountId"} element={<AdminAccountView />} />
          <Route path={endpoints.ADMIN_HIDDEN_COMMUNITIES} element={<AdminHiddenCommunities />} />
          <Route path={endpoints.ADMIN_HIDDEN_DEVICE_MONITOR} element={<AdminHiddenDeviceMonitor />} />
          <Route path={endpoints.ADMIN_USPS_METADATA} element={<UspsMetaData />} />
          <Route path={endpoints.ADMIN_USPS_EDIT + "/:latchId/:boxName"} element={<AdminLatchViewContainer />} />
          <Route path={endpoints.ADMIN_MAINTENANCE_MODE} element={<AdminMaintenanceMode />} />
          <Route path={endpoints.ADMIN_INTEGRATED_LATCHES} element={<AdminIntegratedLatches />} />
          <Route path={endpoints.ADMIN_UNIVERSAL_SEARCH} element={<AdminUniversalSearchView />} />

          <Route path={endpoints.COMMUNITY_BASE} element={<ManagerCheck />}>
            <Route path={endpoints.COMMUNITY_BASE + endpoints.COMMUNITY_DETAIL} element={<Members />} />
            <Route path={endpoints.COMMUNITY_BASE + endpoints.COMMUNITY_KEYS} element={<Keys />} />
            <Route path={endpoints.COMMUNITY_BASE + endpoints.COMMUNITY_HOMES} element={<Homes />} />
            <Route path={endpoints.COMMUNITY_BASE + endpoints.COMMUNITY_HOLD_OPENS} element={<HoldOpenMain />} />
            <Route path={endpoints.COMMUNITY_BASE + "/vendors"} element={<AdminVendors />} />
            <Route path={endpoints.COMMUNITY_BASE + endpoints.ADD_MEMBER} element={<CreateAccountMain />} />
            <Route path={endpoints.COMMUNITY_BASE + endpoints.ACCESS_LOGS} element={<AccessLogs />} />
            <Route
              path={endpoints.COMMUNITY_BASE + endpoints.COMMUNITY_SEND_MESSAGE}
              element={<SendCommunityMessage />}
            />
            <Route
              path={endpoints.COMMUNITY_BASE + "/configure-sense-line/:boxId/:latchId"}
              element={<CommunitySenseLine />}
            />
          </Route>

          <Route path={endpoints.ADMIN_VENDORS} element={<AdminVendors />} />

          <Route path={endpoints.PICK_COMMUNITY} element={<PickCommunity />} />
          <Route path={endpoints.ADMIN_ACCESS_LOGS + "/:communityId"} element={<AdminAccessLogs />} />

          <Route path={endpoints.DISTRIBUTOR} element={<DistributorBoard />} exact />
          <Route path={endpoints.DISTRIBUTOR_ADD_INSTALLER} element={<DistributorAddInstaller />} />
          <Route path={endpoints.DISTRIBUTOR} element={<Distributor />} />
          <Route path={endpoints.DISTRIBUTOR_DEVICE_MONITOR} element={<DistributorDeviceMonitor />} />

          <Route path={endpoints.INSTALLER} element={<Installer />} />
          <Route
            path={endpoints.COMMUNITY_CONFIGURE_SENSE_LINE + "/:communityId/:boxId/:latchId"}
            element={<CommunitySenseLine />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
