import "react-phone-number-input/style.css";
import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { sendPhone, setPhone } from "../../reducers/login-reducer";
import { startAuthConnection } from "../../reducers/connection-reducer";
import PhoneNumberCustom from "../common/Components/PhoneNumberCustom";
import endpoints from "../../routes/endpoints";
import NimbioLogo100 from "../../assets/image/nimbio-logo-100x100.png";

const isDevelopment = process.env.NODE_ENV === "development" ? "DEV Build: " + process.env.REACT_APP_SERVER_URL : "";

const Login = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const onKeyPress = (e) => {
    if (e.which === 13) {
      submitPhoneNumber();
    }
  };

  const submitPhoneNumber = () => {
    if (!isPossiblePhoneNumber(props.loginState.phoneNumber)) {
      setError("Please enter a valid phone number.");
      return;
    }
    props.dispatch(sendPhone(props.loginState.phoneNumber));
  };

  const handleChangePhoneNumber = (phone) => {
    setError(null);
    props.dispatch(setPhone(phone));
  };

  useEffect(() => {
    props.dispatch(startAuthConnection());
  }, []);

  useEffect(() => {
    if (props.loginState.sendPhoneSuccess) {
      navigate("/verify");
    }
  }, [navigate, props.loginState]);

  if (!props.realmState.connected) {
    return <></>;
  }

  return (
    <div style={classes(theme).outerdiv}>
      <Card style={classes(theme).card} data-testid="login-card">
        <CardMedia component="img" style={classes(theme).media} image={NimbioLogo100} title="Nimbio" />
        <CardContent>
          <Typography variant="h6" gutterBottom style={classes(theme).signinText}>
            Sign in
          </Typography>
          <Typography variant="subtitle1" gutterBottom style={classes(theme).signinSubtitle}>
            Enter your phone number to continue to nimbio
          </Typography>
          <PhoneInput
            id={"nimbio-testing" + endpoints.LOGIN + "-input"}
            defaultCountry="US"
            error={props.loginState.phoneNumberError !== ""}
            label="Phone Number"
            value={props.loginState.phoneNumber}
            placeholder="Phone Number"
            onKeyPress={onKeyPress}
            onChange={handleChangePhoneNumber}
            numberInputProps={{ autoFocus: true, style: classes(theme).phoneInput }}
            inputComponent={PhoneNumberCustom}
          />
          {error ? (
            <Typography variant="subtitle1" style={classes(theme).error}>
              {error}
            </Typography>
          ) : null}
        </CardContent>
        <CardActions>
          <Button
            id={"nimbio-testing" + endpoints.LOGIN + "-button"}
            variant="contained"
            style={classes(theme).button}
            onClick={() => submitPhoneNumber()}>
            Next
          </Button>
        </CardActions>
        <Typography variant={"caption"}>{isDevelopment}</Typography>
      </Card>
    </div>
  );
};

const classes = (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  button: {
    margin: theme.spacing(1),
    marginLeft: "auto",
  },
  input: {
    display: "none",
  },
  outerdiv: {
    padding: "30px",
  },
  card: {
    maxWidth: "345px",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "10px",
    borderRadius: "10px",
  },
  media: {
    minHeight: "100px",
    objectFit: "contain",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  signinText: {
    textAlign: "center",
  },
  signinSubtitle: {
    textAlign: "center",
    paddingBottom: "22px",
  },
  onetimecodebody: {},
  phoneInput: {
    fontSize: 22,
  },
  error: {
    color: "red",
    marginTop: "16px",
  },
});

const mapStateToProps = (state) => {
  return {
    realmState: state.realmState,
    loginState: state.loginState,
  };
};

export default connect(mapStateToProps)(Login);
