import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import { red } from "@mui/material/colors";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Typography, Divider } from "@mui/material";
import moment from "moment";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AdminVendorContactDialog from "./AdminVendorContactDialog";
import AdminVendorKeyDialog from "./AdminVendorKeyDialog";
import AdminVendorDialog from "./AdminVendorDialog";
import SelectedAppBar from "../../common/Components/SelectedAppBar";
import { getKeysByVendor, deleteVendorAPIKeyData } from "../../../reducers/admin-get-vendor-keys";
import { addVendorAPIKey } from "../../../reducers/admin-add-vendor-api-key";
import { getAccountVendorOpenLogs } from "../../../reducers/admin-get-account-vendor-open-logs";
import useConfirm from "../../common/Components/useConfirm";
import { deleteVendorContactData, getContactsByVendor } from "../../../reducers/admin-get-vendor-contacts";
import { getVendorByUUID } from "../../../reducers/admin-get-vendor-by-uuid-reducer";
import { disableVendor, enableVendor } from "../../../reducers/admin-get-vendors";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { subscribe, unsubscribe } from "../../../utilities/connection";

const VendorHistoryTable = ({ logs }) => {
  const columns = [
    {
      field: "log_datetime",
      width: 250,
      headerName: "Date",
      sortable: true,
      filterable: true,
      renderCell: (params) => {
        const mDate = moment(params.row.log_datetime);
        return mDate.format("MM/DD/YYYY hh:mm:ss A");
      },
    },
    {
      field: "latch_name",
      flex: 1,
      headerName: "Latch Name",
      sortable: true,
      filterable: true,
    },
  ];

  if (!logs) {
    return null;
  }

  return (
    <>
      <div style={{ flex: 1, overflow: "hidden", minWidth: 0, width: "100%" }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: red[500],
            },
            ".MuiDataGrid-filterIcon": {
              color: red[500],
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-menuIconButton": {
              color: red[500],
            },
          }}
          getRowId={(row) => row.vendor_open_log_id}
          rows={logs}
          columns={columns}
          disableColumnSelector={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          autoHeight={true}
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          disableColumnFilter={true}
          disableColumnReorder={true}
          disableColumnResize={true}
          disableDensitySelector={true}
          disableExtendRowFullWidth={true}
          disableMultipleColumnsFiltering={true}
          disableMultipleSelection={true}
          disableSelectionOnRowClick={true}
          disableSelectionOnRowDrag={true}
          disableSortBy={true}
        />
      </div>
    </>
  );
};

const VendorContactTable = ({ profileState, contacts, onContactSelected }) => {
  const { ConfirmDialog, openDialog } = useConfirm();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "name",
      width: 200,
      headerName: "Name",
      sortable: true,
      filterable: true,
    },
    {
      field: "address",
      width: 200,
      headerName: "Address",
      sortable: true,
      filterable: true,
    },
    { field: "phone", flex: 1, headerName: "Phone", sortable: true, filterable: true },
  ];

  if (profileState?.details.is_admin) {
    columns.push({
      field: "delete_action",
      width: 150,
      headerName: "Delete?",
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(event) => {
              event.ignore = true;
              event.stopPropagation();
              openDialog({
                heading: "Delete the Contact?",
                message: "Are you sure you want to delete this contact?",
                okButton: "Yes",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteVendorContactData(params.row.vendor_contact_uuid));
                },
                closeCallback: () => {},
              });
            }}>
            <DeleteIcon />
          </IconButton>
        );
      },
    });
  }

  if (!contacts || !contacts.result) {
    return null;
  }

  return (
    <>
      <div style={{ flex: 1, overflow: "hidden", minWidth: 0, width: "100%" }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: red[500],
            },
            ".MuiDataGrid-filterIcon": {
              color: red[500],
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-menuIconButton": {
              color: red[500],
            },
          }}
          getRowId={(row) => row.vendor_contact_uuid}
          rows={contacts.result}
          columns={columns}
          disableColumnSelector={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          autoHeight={true}
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          disableColumnFilter={true}
          disableColumnReorder={true}
          disableColumnResize={true}
          disableDensitySelector={true}
          disableExtendRowFullWidth={true}
          disableMultipleColumnsFiltering={true}
          disableMultipleSelection={true}
          disableSelectionOnRowClick={true}
          disableSelectionOnRowDrag={true}
          disableSortBy={true}
          onRowClick={(params) => {
            if (onContactSelected) {
              onContactSelected(params.row);
            }
          }}
        />
      </div>
      <ConfirmDialog />
    </>
  );
};

const VendorKeyTable = ({ keys }) => {
  const { ConfirmDialog, openDialog } = useConfirm();
  const dispatch = useDispatch();

  const columns = [
    {
      field: "date_created",
      width: 200,
      headerName: "Created On",
      sortable: true,
      filterable: true,
      renderCell: (params) => {
        const mDate = moment(params.row.date_created);
        return mDate.format("MM/DD/YYYY hh:mm:ss A");
      },
    },
    {
      field: "date_deactivated",
      flex: 1,
      headerName: "Deactivated On",
      sortable: true,
      filterable: true,
      renderCell: (params) => {
        if (params.row.date_deactivated === "None") {
          return "Active";
        }
        const mDate = moment(params.row.date_deactivated);
        return mDate.format("MM/DD/YYYY hh:mm:ss A");
      },
    },
    // { field: "api_key", flex: 1, headerName: "API Key", sortable: true, filterable: true },
    {
      field: "delete_action",
      width: 150,
      headerName: "Deactivate?",
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(event) => {
              event.ignore = true;
              event.stopPropagation();
              openDialog({
                heading: "Deactivate API Key?",
                message: "Are you sure you want to deactivate this key?",
                okButton: "Yes",
                cancelButton: "Cancel",
                confirmCallback: () => {
                  dispatch(deleteVendorAPIKeyData(params.row.vendor_api_key_uuid));
                },
                closeCallback: () => {},
              });
            }}
            data-testid={"admin-vendor-view-item-deactivate"}>
            <BlockIcon />
          </IconButton>
        );
      },
    },
  ];

  if (!keys || !keys.result) {
    return null;
  }

  return (
    <>
      <div style={{ flex: 1, overflow: "hidden", minWidth: 0, width: "100%" }}>
        <DataGrid
          sx={{
            ".MuiDataGrid-sortIcon": {
              color: red[500],
            },
            ".MuiDataGrid-filterIcon": {
              color: red[500],
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-menuIconButton": {
              color: red[500],
            },
          }}
          getRowId={(row) => row.vendor_api_key_uuid}
          rows={keys.result}
          columns={columns}
          disableColumnSelector={true}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          autoHeight={true}
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
          hideFooterRowCount={true}
          disableColumnFilter={true}
          disableColumnReorder={true}
          disableColumnResize={true}
          disableDensitySelector={true}
          disableExtendRowFullWidth={true}
          disableMultipleColumnsFiltering={true}
          disableMultipleSelection={true}
          disableSelectionOnRowClick={true}
          disableSelectionOnRowDrag={true}
          disableSortBy={true}
        />
      </div>
      <ConfirmDialog />
    </>
  );
};

const MoreButton = ({ vendor, profileState, enabled, onEditAccount, onEditName, onAddContact, onDisable }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  if (!enabled) {
    return null;
  }

  return (
    <>
      <IconButton
        id="nimbio-testing-admin-vendor-more-button"
        size="large"
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-label="Account More"
        onClick={handleClick}
        data-testid={"admin-vendor-view-more"}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="nimbio-testing-settings-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        data-testid={"admin-vendor-view-more-menu"}>
        {profileState?.details.is_admin && (
          <MenuItem
            id="nimbio-testing-edit-name-menu-item"
            onClick={() => {
              setAnchorEl(null);
              if (onEditName) {
                onEditName();
              }
            }}
            data-testid={"admin-vendor-view-more-menu-edit-name"}>
            Edit Name
          </MenuItem>
        )}

        {profileState?.details.is_admin && (
          <MenuItem
            id="nimbio-testing-disable-menu-item"
            onClick={() => {
              setAnchorEl(null);
              if (onDisable) {
                onDisable(vendor?.is_disabled);
              }
            }}
            data-testid={"admin-vendor-view-more-menu-toggle-enabled"}>
            {vendor?.is_disabled ? "Enable" : "Disable"}
          </MenuItem>
        )}

        {profileState?.details.is_admin && <Divider />}

        {profileState?.details.is_admin && (
          <MenuItem
            id="nimbio-testing-add-contact-menu-item"
            onClick={() => {
              setAnchorEl(null);
              if (onAddContact) {
                onAddContact();
              }
            }}
            data-testid={"admin-vendor-view-more-menu-add-contact"}>
            Add Contact
          </MenuItem>
        )}

        <MenuItem
          id="nimbio-testing-add-api-key-menu-item"
          onClick={() => {
            setAnchorEl(null);
            if (onEditAccount) {
              onEditAccount();
            }
          }}
          data-testid={"admin-vendor-view-more-menu-add-api-key"}>
          Add API Key
        </MenuItem>
      </Menu>
    </>
  );
};

function AdminVendorView(props) {
  const { vendorId } = useParams();

  const [open, setOpen] = React.useState(false);
  const [vendorOpen, setVendorOpen] = React.useState(false);
  const [contact, setContact] = React.useState(null);
  const [key, setKey] = React.useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vendorState = useSelector((state) => state.getVendorByUUIDReducer);

  const vendorAPIKeyRef = React.useRef(null);
  const vendorContactRef = React.useRef(null);

  React.useEffect(() => {
    if (vendorId) {
      dispatch(getKeysByVendor(vendorId));
    }
  }, [dispatch, vendorId]);

  React.useEffect(() => {
    setKey(props.vendorKey);
  }, [props.vendorKey]);

  React.useEffect(() => {
    if (vendorId) {
      dispatch(getContactsByVendor(vendorId));
    }
  }, [dispatch, vendorId]);

  React.useEffect(() => {
    if (vendorId) {
      dispatch(getAccountVendorOpenLogs(vendorId));
    }
  }, [dispatch, vendorId]);

  React.useEffect(() => {
    if (vendorId) {
      dispatch(getVendorByUUID(vendorId));
    }
  }, [vendorId, dispatch]);

  const vendor = React.useMemo(() => {
    if (vendorState?.results?.result) {
      return vendorState.results?.result;
    }
    return null;
  }, [vendorState]);

  const vendorTitle = React.useMemo(() => {
    const title = `Vendor Details - ${vendor?.vendor_name || ""}`;
    if (vendor?.is_disabled) {
      return `${title} (Disabled)`;
    }
    return title;
  }, [vendor]);

  useSubscription(nimbioServer.vendor.vendors, () => {
    dispatch(getVendorByUUID(vendorId));
  });

  React.useEffect(() => {
    const initAsync = async () => {
      try {
        const vendorAPIKeyResponse = await subscribe(nimbioServer.vendor.vendorAPIKey, () => {
          dispatch(getKeysByVendor(vendorId));
        });
        if (vendorAPIKeyResponse.subscriptionId) vendorAPIKeyRef.current = vendorAPIKeyResponse.subscriptionId;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error subscribing to topic:" + nimbioServer.vendor.vendorAPIKey, err);
      }
      try {
        const vendorContactResponse = await subscribe(nimbioServer.vendor.vendorContact, () => {
          dispatch(getContactsByVendor(vendorId));
        });
        if (vendorContactResponse.subscriptionId) vendorContactRef.current = vendorContactResponse.subscriptionId;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error subscribing to topic:" + nimbioServer.vendor.vendorContact, err);
      }
    };
    initAsync();

    return () => {
      if (vendorAPIKeyRef.current) {
        unsubscribe(vendorAPIKeyRef.current, null, nimbioServer.vendor.vendorAPIKey);
        vendorAPIKeyRef.current = null;
      }
      if (vendorContactRef.current) {
        unsubscribe(vendorContactRef.current, null, nimbioServer.vendor.vendorContact);
        vendorContactRef.current = null;
      }
    };
  }, [dispatch, vendorId]);

  if (props.currentCommunityID === null && !props.profileState.details.is_admin) {
    navigate("/pick-community");
    return;
  }

  return (
    <SelectedAppBar selected="Vendors" {...props}>
      <>
        <Card data-testid={"admin-vendor-view"}>
          <CardHeader
            id={"nimbio-testing-admin-vendor-details-card-header"}
            title={vendorTitle}
            action={
              <MoreButton
                vendor={vendor}
                profileState={props.profileState}
                enabled={true}
                onEditAccount={() => {
                  dispatch(addVendorAPIKey(vendorId));
                }}
                onAddContact={() => {
                  setContact(null);
                  setOpen(true);
                }}
                onEditName={() => {
                  setVendorOpen(true);
                }}
                onDisable={(isDisabled) => {
                  if (isDisabled) {
                    dispatch(enableVendor(vendorId));
                  } else {
                    dispatch(disableVendor(vendorId));
                  }
                }}
              />
            }
          />
          <CardContent>
            <Typography variant="h6">{"API Keys"}</Typography>
          </CardContent>
          <CardContent>
            <VendorKeyTable keys={props.vendorKeys} profileState={props.profileState} />
          </CardContent>

          <CardContent>
            <Typography variant="h6">{"Contacts"}</Typography>
          </CardContent>
          <CardContent>
            <VendorContactTable
              profileState={props.profileState}
              contacts={props.vendorContacts}
              onContactSelected={(contact) => {
                setContact(contact);
                setOpen(true);
              }}
            />
          </CardContent>

          <CardContent>
            <Typography variant="h6">{"History"}</Typography>
          </CardContent>
          <CardContent>
            <VendorHistoryTable logs={props.vendorLogs} profileState={props.profileState} />
          </CardContent>
        </Card>
      </>

      {key && key.apiKey && (
        <AdminVendorKeyDialog
          apiKey={key.apiKey}
          open={true}
          onClose={() => {
            setKey(null);
          }}
        />
      )}

      {open && (
        <AdminVendorContactDialog
          open={open}
          contact={contact}
          vendorId={vendorId}
          onClose={() => {
            setOpen(false);
          }}
        />
      )}

      {vendorOpen && (
        <AdminVendorDialog
          open={vendorOpen}
          vendor={vendor}
          onClose={() => {
            setVendorOpen(false);
          }}
        />
      )}
    </SelectedAppBar>
  );
}

const mapStateToProps = (state) => {
  return {
    vendorKeys: state.getKeysByVendorReducer.results,
    vendorContacts: state.getContactsByVendorReducer.results,
    vendorLogs: state.getAccountVendorOpenLogsReducer.results,
    vendorKey: state.addVendorAPIKeyReducer.result,
    profileState: state.profileState,
    currentCommunityID: state.communityState.currentCommunityID,
  };
};

export default connect(mapStateToProps)(AdminVendorView);
