export function parseKeys(payload) {
  let keysReturn = {};
  if (payload && payload.result) {
    payload.forEach((item) => {
      keysReturn[item.id] = {
        home: item.home,
        keyName: item.name,
        latches: item.latches,
      };
    });
  }
  return keysReturn;
}
