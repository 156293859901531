import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";

const drawerWidth = 265;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    top: 0,
    left: 0,
    right: 0,
    width: "100%",
    boxShadow: "none",
    // marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  menuButton: {
    marginRight: 2,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  commHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.mixins.toolbar.minHeight,
  },
  nimbioFont: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14pt",
    },
  },
});

const NimbioAppBar = (props) => {
  const theme = useTheme();
  const { title } = props;

  return (
    <AppBar id={"nimbio-testing-toolbar"} position="fixed" sx={styles(theme).appBar}>
      <Toolbar>
        <IconButton
          sx={styles(theme).menuButton}
          color="inherit"
          size="large"
          aria-label="Menu"
          onClick={props.handleDrawerToggle}>
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" color="inherit" sx={styles(theme).nimbioFont}>
          Nimbio&nbsp;{title || "Dashboard"}
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default connect()(NimbioAppBar);
