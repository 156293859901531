import { useEffect, useRef } from "react";
import { SubscriptionResultType } from "../nimbioTypes";
import { subscribe, unsubscribe } from "../connection";

const useSubscription = (topicURI: string, subscriptionFunction: (...args: any[]) => void) => {
  const subscriptionIdRef = useRef<string | null>(null);

  useEffect(() => {
    const initAsync = async () => {
      try {
        const subscriptionResponse: SubscriptionResultType = await subscribe(topicURI, subscriptionFunction);
        if (subscriptionResponse.subscriptionId) subscriptionIdRef.current = subscriptionResponse.subscriptionId;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error subscribing to topic:" + topicURI, err);
      }
    };
    initAsync();
    return () => {
      if (subscriptionIdRef.current) {
        unsubscribe(subscriptionIdRef.current, subscriptionFunction, topicURI);
        subscriptionIdRef.current = null;
      }
    };
  }, []);
};

export default useSubscription;
