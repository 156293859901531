const columns = [
  { field: "metaDataId", sortable: true, filter: true, resizable: true },
  { field: "latchId", sortable: true, filter: true, resizable: true },
  {
    field: "address",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "address_2",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "address_3",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "address_4",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "city",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "state",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "zip",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "plus_four",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "foot_route",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "mon_sat_x_route",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "sun_x_route",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "sun_hub_zip",
    sortable: true,
    filter: true,
    resizable: true,
    editable: true,
  },
  {
    field: "include_in_tech_report",
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    field: "include_in_delivery_report",
    sortable: true,
    filter: true,
    resizable: true,
  },
  { field: "virtualized", sortable: true, filter: true, resizable: true },
  {
    field: "report_start_date",
    sortable: true,
    filter: true,
    resizable: true,
  },
  { field: "georadius", sortable: true, filter: true, resizable: true },
  { field: "full_exclude", sortable: true, filter: true, resizable: true },
  { field: "image_valid", sortable: true, filter: true, resizable: true },
  { field: "hub", sortable: true, filter: true, resizable: true },
  { field: "station", sortable: true, filter: true, resizable: true },
  {
    field: "override_location",
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    field: "last_update_datetime",
    sortable: true,
    filter: true,
    resizable: true,
  },
  { field: "sys_id", sortable: true, filter: true, resizable: true },
  { field: "sunday_sys_id", sortable: true, filter: true, resizable: true },
];

const rowData = [
  {
    metaDataId: 1,
    latchId: "96b71409-fe58-4864-924e-9acc48a98b6d",
    address: "435 Grand Avenue",
    address_2: "435 Grand Avenue",
    address_3: "Brooklyn",
    address_4: "NY",
    city: "Brooklyn",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 2,
    latchId: "e5dadbd9-442c-47bc-9fa8-81961a4f1f6c",
    address: "425 Grand Avenue",
    address_2: "425 Grand Avenue",
    address_3: "Brooklyn",
    address_4: "NY",
    city: "Brooklyn",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 3,
    latchId: "e5d5dc96-3fa1-4e38-b89f-1d69a931d45d",
    address: "29 Putnam Avenue",
    address_2: "29 Putnam Avenue",
    address_3: "Brooklyn",
    address_4: "NY",
    city: "Brooklyn",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 4,
    latchId: "d5bf75e3-759d-4b4b-9786-00dc643ffd58",
    address: "201 W 76th Street",
    address_2: "201 W 76th Street",
    address_3: "New York",
    address_4: "NY",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: true,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 5,
    latchId: "9ebfe35f-eb88-4278-8e30-b6dde950b93d",
    address: "187 E 116th Street",
    address_2: "187 E 116th Street",
    address_3: "New York",
    address_4: "NY",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: true,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 6,
    latchId: "67537819-6b99-4086-9910-a6aef192a97b",
    address: "31 Oliver Street",
    address_2: "31 Oliver Street",
    address_3: "New York",
    address_4: "NY",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: true,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 7,
    latchId: "0d43704e-9f24-4e93-ab06-d78efb7db09f",
    address: "247 Water Street",
    address_2: "247 Water Street",
    address_3: "New York",
    address_4: "NY",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 8,
    latchId: "cc175734-d170-4335-8dd1-aa1ac4eaf3c7",
    address: "1296 Sheridan Avenue ",
    address_2: "1296 Sheridan Avenue",
    address_3: "Bronx",
    address_4: "NY",
    city: "Bronx",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 9,
    latchId: "30d228b9-1caa-4a28-9b05-ae5e2bb89aee",
    address: "1189 Sheridan Avenue",
    address_2: "1189 Sheridan Avenue",
    address_3: "Bronx",
    address_4: "NY",
    city: "Bronx",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 10,
    latchId: "dc4f7cd1-c01a-4fb3-adb8-a815c6119b12",
    address: "301 W 107th Street",
    address_2: "301 W 107th Street",
    address_3: "New York",
    address_4: "NY",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "https://nimbio.com/door-images/shrunk/9add88a6-76b0-468f-b15a-5b358d1db23c.jpg",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 11,
    latchId: "a98508a5-03e5-4f8c-8cd9-0dfd801a6d13",
    address: "130 Fort Washington Avenue",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 12,
    latchId: "f7f2abb9-7291-4c5e-848a-d501d5bea1af",
    address: "100 Fort Washington Avenue",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "New York",
    state: "NY",
    zip: "11238",
    plus_four: "",
    foot_route: "C004",
    mon_sat_x_route: "X003",
    sun_x_route: "X003",
    sun_hub_zip: "11238",
    include_in_tech_report: true,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 13,
    latchId: "5319b69a-eeb9-11e8-9e3c-002590fd7eb1",
    address: "Rochelle",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    state: "",
    zip: "",
    plus_four: "",
    foot_route: "",
    mon_sat_x_route: "",
    sun_x_route: "",
    sun_hub_zip: "",
    include_in_tech_report: false,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 14,
    latchId: "5caae1c3-db03-409c-9ca0-b96be6dfe300",
    address: "Dev Box 1",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    state: "",
    zip: "",
    plus_four: "",
    foot_route: "",
    mon_sat_x_route: "",
    sun_x_route: "",
    sun_hub_zip: "",
    include_in_tech_report: false,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 15,
    latchId: "785f23fc-47f7-4dfb-80ae-94f8149779b4",
    address: "Dev Box 2",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    state: "",
    zip: "",
    plus_four: "",
    foot_route: "",
    mon_sat_x_route: "",
    sun_x_route: "",
    sun_hub_zip: "",
    include_in_tech_report: false,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
  {
    metaDataId: 16,
    latchId: "7263c874-8c43-4dce-af63-8cc21664c11f",
    address: "Dev Box 3",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    state: "",
    zip: "",
    plus_four: "",
    foot_route: "",
    mon_sat_x_route: "",
    sun_x_route: "",
    sun_hub_zip: "",
    include_in_tech_report: false,
    include_in_delivery_report: false,
    virtualized: false,
    report_start_date: "10/3/2022 12:00:00 AM",
    georadius: 100,
    full_exclude: false,
    image_valid: true,
    hub: "Central",
    station: "Adelphi",
    override_location: "(40.68293058645586,-73.96160203375817)",
    last_update_datetime: "2022-10-03 18:12:24.263216 +00:00",
    sunday_sys_id: "SYS00507",
  },
];

export { columns, rowData };
