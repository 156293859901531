import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const sendAccountGroupData = createAsyncAction("SEND_ACCOUNT_GROUP_DATA", (id, name) => {
  return call(nimbioServer.admin.addAccountGroup, [id, name], "admin-get-account-groups");
});

export const deleteAccountGroupData = createAsyncAction("DELETE_ACCOUNT_GROUP_DATA", (id) => {
  return call(nimbioServer.admin.deleteAccountGroup, [id], "admin-get-account-groups");
});

export const sendAccountToAccountGroupData = createAsyncAction(
  "SEND_ACCOUNT_TO_ACCOUNT_GROUP_DATA",
  (account_group_id, account_id) =>
    call(nimbioServer.admin.addAccountToAccountGroup, [account_group_id, account_id], "admin-get-account-groups"),
);

export const sendBoxToAccountGroupData = createAsyncAction(
  "SEND_BOX_TO_ACCOUNT_GROUP_DATA",
  (account_group_id, box_id) => {
    return call(nimbioServer.admin.addBoxToAccountGroup, [account_group_id, box_id], "admin-get-account-groups");
  },
);

export const getAccountGroups = createAsyncAction("ADMIN_GET_ACCOUNT_GROUPS", () => {
  return call(nimbioServer.admin.getAccountGroups, [], "admin-get-account-groups");
});

export default handleActions(
  {
    ADMIN_GET_ACCOUNT_GROUPS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_ACCOUNT_GROUPS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_ACCOUNT_GROUPS_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState,
);
