import React from "react";
import { connect } from "react-redux";
import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { CSVLink } from "react-csv";
import moment from "moment/moment";

const headers = [
  {
    label: "NAME",
    key: "searchTerm",
  },
  {
    label: "ID",
    key: "id",
  },
  {
    label: "PHONE",
    key: "phone_numbers",
  },
  {
    label: "ACCEPTED",
    key: "is_accepted",
  },
  { label: "PENDING", key: "is_unaccepted" },
  { label: "REMOVED", key: "is_removed" },
  { label: "LATCHES", key: "latches" },
];

const csvLink = (membersState: any, communityName: string) => {
  let membersArr: any = [];

  const checkForLatches = (item: any) => {
    if (item.is_accepted) {
      const latch_names: any = [];
      item.keys.forEach((key: any) => {
        if (key.disabled === 1) return;
        key.latches.forEach((latch: any) => {
          if (!latch_names.includes(latch.name)) {
            if (latch.disabled === 1) return;
            latch_names.push(latch.name);
          }
        });
      });
      item.latches = latch_names.join(", ");
    } else {
      item.latches = "";
    }
    return item;
  };

  membersState.searchData.forEach((item: any) => {
    if (item.is_home) {
      item.members.forEach((member: any) => {
        const parsedMember = checkForLatches({ ...member });
        membersArr.push(parsedMember);
      });
    } else {
      const parsedMember = checkForLatches({ ...item });
      membersArr.push(parsedMember);
    }
  });

  membersArr = membersArr.sort((first: any, second: any) =>
    (first.is_home ? first.home_address : first.first_name).localeCompare(
      second.is_home ? second.home_address : second.first_name
    )
  );
  const now = moment().format("MM-DD-YYYY");
  return {
    filename: `${communityName}-members-${now}.csv`,
    headers: headers,
    data: membersArr,
  };
};

type ExportMemberProps = {
  membersState: object;
  setOpen: (isOpen: boolean) => void;
  open: boolean;
  community: any;
};

const ExportMembersDialog = (props: ExportMemberProps) => {
  const { open, setOpen, community, membersState } = props;

  const closeDialogBox = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialogBox}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Download Members CSV File?</DialogTitle>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            padding: 2,
          }}>
          <Button variant={"outlined"} onClick={closeDialogBox}>
            Close
          </Button>
          {open ? (
            <CSVLink
              {...csvLink(membersState, community?.name ? community.name : community.display_name)}
              style={{ textDecoration: "none" }}
              data-testid={"export-members-download-button"}>
              <Button onClick={closeDialogBox} variant={"contained"}>
                Download
              </Button>
            </CSVLink>
          ) : null}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default connect()(ExportMembersDialog);
