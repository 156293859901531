import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Divider } from "@mui/material";
import { connect } from "react-redux";
import ShareDepthItem from "../../ShareDepth/ShareDepthItem";

const CommunityKeyShareDepth = (props) => {
  return (
    <Dialog
      data-testid={"community-key-share-depth"}
      open={props.open}
      onClose={props.closeCallback}
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <DialogTitle>Key Max Share Depth</DialogTitle>
        <Divider sx={{ marginBottom: 4 }} />
        <ShareDepthItem
          maxShareDepth={props.community_key.max_share_depth}
          maxShareDepthEnabled={props.community_key.max_share_depth_enabled}
          keyID={props.community_key.id}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeCallback}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

CommunityKeyShareDepth.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  key_name: PropTypes.string.isRequired,
  key_id: PropTypes.string.isRequired,
  community_key: PropTypes.object.isRequired,
};

export default connect()(CommunityKeyShareDepth);
