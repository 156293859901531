import React from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminAppBar from "./AdminAppBar";
import AdminUniversalSearch from "./AdminUniversalSearch";

const AdminUniversalSearchView = (props: any) => {
  const navigate = useNavigate();
  const { profileState } = useSelector((state: any) => state);

  if (!profileState.details.is_admin) {
    navigate("/pick-community");
    return;
  }

  return (
    <AdminAppBar selected="Universal Search" {...props}>
      <AdminUniversalSearch />
    </AdminAppBar>
  );
};

export default connect()(AdminUniversalSearchView);
