import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { connect } from "react-redux";
import { FormControl } from "@mui/base";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import PhoneNumberCustom from "./PhoneNumberCustom";
import { call } from "../../../utilities/connection";
import nimbioServer from "../../../server/endpoints";

const styles = {
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
  },
};

const CommunityManagerAdd = (props) => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(false);
  const confirm = async () => {
    if (!isPossiblePhoneNumber(phone)) {
      setError("Please enter a valid phone number.");
      return;
    }
    const data = await call(
      nimbioServer.admin.community.addCommunityManagerByPhoneNumber,
      [props.community_id, phone],
      "CommunityManagerAdd"
    );
    if (!data["result"]) {
      setError(data["message"]);
      return;
    }
    setPhone("");
    props.closeCallback();
  };

  const handlePhoneChange = (phone_input) => {
    setError(false);
    setPhone(phone_input);
  };

  const handleClose = () => {
    setPhone("");
    setError(false);
    props.closeCallback();
  };

  return (
    <Dialog open={props.open} maxWidth="md" fullWidth sx={{ paper: styles.dialogPaper }} onClose={props.closeCallback}>
      <DialogTitle id="form-dialog-title">Add Property Manager</DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: 4, marginTop: 2 }}>
          <FormControl>
            <PhoneInput
              value={phone}
              onChange={(phone_input) => handlePhoneChange(phone_input)}
              defaultCountry="US"
              numberInputProps={{ autoFocus: true, label: "Property Manager Phone Number" }}
              inputComponent={PhoneNumberCustom}
            />
          </FormControl>
        </Box>
        {error ? (
          <DialogContentText sx={{ marginBottom: 2 }} color="error">
            {error}
          </DialogContentText>
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button variant="contained" onClick={confirm}>
          Add Property Manager
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CommunityManagerAdd.propTypes = {
  community_id: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    findUser: state.findUser,
  };
};

export default connect(mapStateToProps)(CommunityManagerAdd);
