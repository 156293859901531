import { handleActions } from "redux-actions";
import { createAsyncAction, createAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

export const initialState = {
  phone_number: "",
  loading: false,
  error: false,
  errorMessage: null,
  result: false,
};

export const addInstallerCall = createAsyncAction("ADD_INSTALLER", (phone) => {
  return call(nimbioServer.distributor.account.setInstaller, [phone, 1], "add-installer-reducer");
});

export const clearInstallerState = createAction("CLEAR_INSTALLER_STATE", () => {
  return true;
});

export default handleActions(
  {
    ADD_INSTALLER_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        result: false,
      };
    },
    ADD_INSTALLER_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        result: payload,
        error: payload === false,
      };
    },
    ADD_INSTALLER_ERROR: (state, { payload }) => {
      if (payload === false) {
        return {
          ...state,
          loading: false,
          error: true,
          result: false,
        };
      }

      if (payload["result"] === false) {
        return {
          ...state,
          loading: false,
          error: true,
          errorMessage: payload["message"],
        };
      }

      return {
        ...state,
        loading: false,
        error: true,
        result: payload,
      };
    },
    CLEAR_INSTALLER_STATE: () => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);
