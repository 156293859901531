import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { adminCommunityKeyRemoveLatch } from "../../../reducers/admin-community-list";

class RemoveLatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recursive: true,
    };
  }

  _removeLatch = () => {
    this.props.dispatch(adminCommunityKeyRemoveLatch(this.props.key_id, this.props.latch_id, this.state.recursive));
    this.props.closeCallback();
  };

  _handleRecursiveChange = () => {
    this.setState({ recursive: !this.state.recursive });
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.closeCallback} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Remove Latch</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Remove a latch from the community. If you select recursive, it will remove the latch from all children in
            the community key chain too.
          </DialogContentText>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch checked={this.state.recursive} onChange={this._handleRecursiveChange} name="recursive" />
              }
              label="Recursive Remove"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeCallback}>Cancel</Button>
          <Button onClick={this._removeLatch}>Remove</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RemoveLatch.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  key_id: PropTypes.string.isRequired,
  latch_id: PropTypes.string.isRequired,
};

export default connect()(RemoveLatch);
