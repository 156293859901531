import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { sendAccountData } from "../../../reducers/login-reducer";
import { getLoginDetails } from "../../../reducers/profile-reducer";

const AdminAccountDialog = ({ account, open, onClose }) => {
  const [first_name, setFirstName] = React.useState(account.first_name);
  const [last_name, setLastName] = React.useState(account.last_name);
  const [admin, setAdmin] = React.useState(account.admin);
  const [distributor, setDistributor] = React.useState(account.distributor);
  const [installer, setInstaller] = React.useState(account.installer);

  const dispatch = useDispatch();

  function handleSave() {
    // Handle the save of the account.

    dispatch(sendAccountData(account.id, first_name, last_name, admin, distributor, installer));
    dispatch(getLoginDetails());
  }

  return (
    <Dialog
      fullWidth={true}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionProps={{
        onClose,
      }}>
      <DialogTitle>Edit Account</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>First Name</Typography>
          <TextField
            variant="outlined"
            fullWidth
            defaultValue={first_name}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
          />
        </Box>
        <Box>
          <Typography>Last Name</Typography>
          <TextField
            variant="outlined"
            fullWidth
            defaultValue={last_name}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
          />
        </Box>
        <Box style={{ marginTop: 12 }}>
          <Grid container>
            <Grid item xs={4}>
              <Box>
                <Typography>Admin</Typography>
                <Checkbox
                  checked={admin}
                  onChange={() => {
                    setAdmin(!admin);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box>
                <Typography>Installer</Typography>
                <Checkbox
                  checked={installer}
                  onChange={() => {
                    setInstaller(!installer);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box>
                <Typography>Distributor</Typography>
                <Checkbox
                  checked={distributor}
                  onChange={() => {
                    setDistributor(!distributor);
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSave();
            onClose();
          }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminAccountDialog;
