import store from "../store";
import { adminDeviceGetUSPSMetadata } from "../reducers/device-status-reducer";

export function uspsMetadataSubscription(message) {
  message = message.argsList[0];
  const latchId = message.latch_id;
  if ("a" in message) {
    switch (message.a) {
      case "refresh":
        store.dispatch(adminDeviceGetUSPSMetadata(latchId));
        break;
      default:
        console.log("<B>Message received and not handled", message);
    }
  }
}
