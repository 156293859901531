import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import {
  Typography,
  Box,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  Button,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  Input,
  List,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import Tooltip from "@mui/material/Tooltip";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MemberDetailKeys from "./MemberDetailKeys";
import MemberListItem from "./MemberListItem";
import { getColor, getShortName } from "../../../utilities/materialAvatar";
import { closeHomeDetail } from "../../../reducers/homedetail-reducer";
import { closeMemberDetail, removeMember, setMemberHome } from "../../../reducers/memberdetail-reducer";
import useConfirm from "../../common/Components/useConfirm";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  chevron: {
    paddingLeft: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
});

const DialogBody = (props) => {
  const { ConfirmDialog, openDialog } = useConfirm();
  const theme = useTheme();

  const handleConfirmRemoveMembers = () => {
    openDialog({
      heading: "Remove Members from Community",
      message:
        "Are you sure you want to remove all members associated with this home? " +
        "The member keys and shared keys will be revoked.",
      okButton: "Confirm",
      cancelButton: "Cancel",
      confirmCallback: () => {
        props.details.members.map((item) =>
          props.revokeKeys(
            item.account_community_id,
            item.keys.map((item_key) => item_key.key_id),
            props.community_id
          )
        );
      },
      closeCallback: () => {},
    });
  };

  if (props.detailState.is_home) {
    return (
      <div>
        <ListItem sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "95%" }}>
          <Box sx={{ display: "flex", flexDirection: "row", minWidth: "124px" }}>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: getColor(props.detailState.details.id) }}>
                <HomeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText sx={{ paddingTop: 0.5 }}>{props.detailState.details.address}</ListItemText>
          </Box>
          {props.ownerOccupied ? (
            <Tooltip enterTouchDelay={0} title="Owner Occupied">
              <PermIdentityIcon />
            </Tooltip>
          ) : null}
        </ListItem>
        <Divider sx={styles(theme).divider} />
        <Box sx={{ display: "flex", flexDirection: "column", paddingLeft: 2, paddingRight: 2 }}>
          <Typography paddingBottom={1} variant="body2" color="textSecondary" component="p">
            Home Name: <b>{props.detailState.details.name}</b>
          </Typography>
          <Typography paddingBottom={1} variant="body2" color="textSecondary" component="p">
            Home Address: <b>{props.detailState.details.address}</b>
          </Typography>
          <Typography paddingBottom={1} variant="body2" color="textSecondary" component="p">
            Owner Name: <b>{props.detailState.details.home_owner_name}</b>
          </Typography>
          <Typography paddingBottom={1} variant="body2" color="textSecondary" component="p">
            Phone: <b>{props.detailState.details.home_phone}</b>
          </Typography>
          <Typography paddingBottom={1} variant="body2" color="textSecondary" component="p">
            Email: <b>{props.detailState.details.home_email}</b>
          </Typography>
          <Typography paddingBottom={1} variant={"body2"} color={"textSecondary"} component={"p"}>
            Home Phone: <b>{props.detailState.details.home_phone}</b>
          </Typography>
        </Box>
        <Divider sx={styles(theme).divider} />
        <List component="div" disablePadding>
          {props.detailState.details.members &&
            props.detailState.details.members.length > 0 &&
            props.detailState.details.members
              .filter((e) => e.status === 1)
              .map((item) => <MemberListItem key={item.id} details={item} community_id={props.community_id} />)}
        </List>
        <Divider sx={styles(theme).divider} />
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Button variant="contained" color="secondary" onClick={handleConfirmRemoveMembers}>
            Remove Members
            <DeleteIcon sx={styles(theme).rightIcon} />
          </Button>
        </Grid>
        <ConfirmDialog />
      </div>
    );
  }
  if (!props.detailState.is_home) {
    return (
      <div>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ backgroundColor: getColor(props.detailState.details.id) }}>
              {getShortName(props.detailState.details.first_name, props.detailState.details.last_name)}
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            {props.detailState.details.first_name} {props.detailState.details.last_name}
          </ListItemText>
        </ListItem>
        <Divider sx={props.styles(theme).divider} />
        <MemberDetailKeys keys={props.detailState.details.keys} />
        <Divider sx={styles(theme).divider} />
        <FormControl sx={{ minWidth: 240 }}>
          <Typography variant="h6">Home</Typography>
          <Select
            value={""}
            // onChange={props.homeHandleChange}
            input={<Input name="home" id="home-label-placeholder" />}
            displayEmpty
            name="homeSelect">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {/* {props.homeMenuItems(props.homesState.homes)} */}
          </Select>
        </FormControl>
        {/* {props.showAcceptKeys(props.detailState.details)} */}
        <Divider sx={styles(theme).divider} />
        <Typography variant="h6">Revoke Keys</Typography>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Button variant="contained" color="secondary">
            Revoke
            <DeleteIcon sx={styles(theme).rightIcon} />
          </Button>
        </Grid>
      </div>
    );
  }
};

const MemberHomeDetail = (props) => {
  const handleClose = () => {
    props.dispatch(closeHomeDetail());
  };

  const revokeKeys = (account_community_id, keys_to_remove, community_id) => {
    props.dispatch(setMemberHome(community_id, account_community_id, null));
    props.dispatch(removeMember(account_community_id, keys_to_remove, community_id));
    props.dispatch(closeMemberDetail());
    props.dispatch(closeHomeDetail());
  };

  if (!props.detailState.open) {
    return <Dialog open={false} />;
  }
  if (props.detailState.open && props.detailState.error) {
    return (
      <Dialog open={props.detailState.open} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>Could not load member details.</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (props.detailState.open && props.detailState.loading) {
    return (
      <Dialog open={props.detailState.open} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Loading</DialogTitle>
        <DialogContent>Loading details.</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <Dialog open={props.detailState.open} fullWidth={true} maxWidth={"sm"} onClose={handleClose}>
      <DialogTitle>Home Details</DialogTitle>
      <DialogContent>
        <DialogBody
          detailState={props.detailState}
          homesState={props.homesState}
          community_id={props.community_id}
          revokeKeys={revokeKeys}
          details={props.detailState.details}
          ownerOccupied={props.detailState.details.owner_occupied}
          // homeHandleChange={homeHandleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    homesState: state.homesState,
    detailState: state.homeDetailState,
  };
};

export default connect(mapStateToProps)(MemberHomeDetail);
