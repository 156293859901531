import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Box,
  Divider,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  TextField,
  Typography,
  Button,
  Switch,
  FormControlLabel,
} from "@mui/material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import AdminAppBar from "./AdminAppBar";
import {
  searchForIntegratedLatchesByAccountId,
  searchForIntegratedLatchesByLatchName,
} from "../../../reducers/admin-integrated-latches-reducer";
import { findUser } from "../../../reducers/admin-find-user";
import PaginationComponent from "../../common/Components/PaginationComponent";
import CommunityLatch from "../../common/Components/CommunityLatch";
import endpoints from "../../../routes/endpoints";

const AccountRow = ({ account, onClick }) => {
  return (
    <TableBody>
      <TableRow>
        <TableCell>
          <Typography>{`${account.first_name} ${account.last_name}`}</Typography>
        </TableCell>
        <TableCell style={{ width: 40 }}>
          <Button aria-label="settings" size="large" onClick={onClick}>
            <LockOpenIcon color="primary" />
            <Typography data-testid={"view-account-" + account.id} sx={{ marginLeft: 1 }}>
              View
            </Typography>
          </Button>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

const SearchBar = ({ defaultValue, onValueChanged, onSearch, onClear }) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          if (onSearch) {
            onSearch();
          }
        }
      }}>
      <Grid item>
        <Typography>Search</Typography>
      </Grid>
      <Grid item>
        <TextField
          size="small"
          id="first_name"
          data-testid="first_name"
          onChange={(event) => {
            setValue({
              ...value,
              first_name: event.target.value,
            });

            if (onValueChanged) {
              onValueChanged({
                ...value,
                first_name: event.target.value,
              });
            }
          }}
          label="First Name"
          value={value.first_name}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          size="small"
          id="last_name"
          data-testid="last_name"
          onChange={(event) => {
            setValue({
              ...value,
              last_name: event.target.value,
            });

            if (onValueChanged) {
              onValueChanged({
                ...value,
                last_name: event.target.value,
              });
            }
          }}
          label="Last Name"
          value={value.last_name}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          size="small"
          id="phone"
          data-testid="phone"
          onChange={(event) => {
            setValue({
              ...value,
              phone: event.target.value,
            });

            if (onValueChanged) {
              onValueChanged({
                ...value,
                phone: event.target.value,
              });
            }
          }}
          label="Phone Number"
          value={value.phone}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item>
        <Button
          id="search-latches-by-owner-button"
          data-testid="search-latches-by-owner-button"
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (onSearch) {
                onSearch();
              }
            }
          }}
          variant="contained"
          onClick={() => {
            if (onSearch) {
              onSearch();
            }
          }}>
          Search
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-testid={"search-latches-by-owner-button-clear-search"}
          onClick={() => {
            setValue({
              first_name: "",
              last_name: "",
              phone: "",
            });
            onValueChanged({
              first_name: "",
              last_name: "",
              phone: "",
            });
            if (onClear) {
              onClear();
            }
          }}>
          clear
        </Button>
      </Grid>
    </Grid>
  );
};

const AdminIntegratedLatches = (props) => {
  const first_name = sessionStorage.getItem("admin_integrated_devices_search_first_name");
  const last_name = sessionStorage.getItem("admin_integrated_devices_search_last_name");
  const phone = sessionStorage.getItem("admin_integrated_devices_search_phone");
  const latch_name = sessionStorage.getItem("admin_integrated_devices_search_latch_name");
  const is_account_search = sessionStorage.getItem("admin_integrated_devices_is_account_search");
  const view_latches = sessionStorage.getItem("admin_integrated_devices_view_latches");

  const { dispatch, latches, total_length_latch_results } = props;
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      handleClear("unmount");
    };
  }, []);

  const [isAccountSearch, setIsAccountSearch] = useState(is_account_search === "true" ? true : false);
  const [viewAccountSearchResults, setViewAccountSearchResult] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewLatches, setViewLatches] = useState(view_latches === "true" ? true : false);
  const [cleared, setCleared] = useState(false);
  const [value, setValue] = useState({
    first_name: first_name || "",
    last_name: last_name || "",
    phone: phone || "",
    latch_name: latch_name || "",
  });
  const itemsPerPage = useRef(24);

  const handleChooseSearchType = (isAccountSearch) => {
    setIsAccountSearch(isAccountSearch);
    sessionStorage.setItem("admin_integrated_devices_is_account_search", isAccountSearch);
    setViewLatches(false);
    setViewAccountSearchResult(true);
  };

  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
    props.dispatch(searchForIntegratedLatchesByLatchName(value.latch_name, pageNum, itemsPerPage.current));
  };

  const handleOnSearchByAccount = () => {
    sessionStorage.setItem("admin_integrated_devices_search_first_name", value.first_name);
    sessionStorage.setItem("admin_integrated_devices_search_last_name", value.last_name);
    sessionStorage.setItem("admin_integrated_devices_search_phone", value.phone);

    if (value.first_name === "" && value.last_name === "" && value.phone === "") {
      setValue({ first_name: "", last_name: "", phone: "", cleared: true });
      return;
    }
    setCleared(false);
    props.dispatch(findUser(value.first_name, value.last_name, value.phone));
    setViewAccountSearchResult(true);
    setViewLatches(false);
  };

  const handleSearchByLatchName = () => {
    sessionStorage.setItem("admin_integrated_devices_search_latch_name", value.latch_name);
    if (value.latch_name === "") {
      setValue({ ...value, latch_name: "" });
      return;
    }
    setCleared(false);
    props.dispatch(searchForIntegratedLatchesByLatchName(value.latch_name, currentPage, itemsPerPage.current));
    handleViewLatches();
  };

  const handleClear = (clear_type) => {
    setCleared(true);
    switch (clear_type) {
      case "account":
        sessionStorage.removeItem("admin_integrated_devices_search_first_name");
        sessionStorage.removeItem("admin_integrated_devices_search_last_name");
        sessionStorage.removeItem("admin_integrated_devices_search_phone");
        setValue({ ...value, first_name: "", last_name: "", phone: "" });
        break;
      case "latch_name":
        sessionStorage.removeItem("admin_integrated_devices_search_latch_name");
        setValue({ ...value, latch_name: "" });
        break;
      case "umount":
        break;
    }

    setViewLatches(false);
    sessionStorage.setItem("admin_integrated_devices_view_latches", false);
  };

  const handleSelectUser = (account_id) => {
    sessionStorage.setItem("admin_integrated_devices_view_latches", true);
    setViewAccountSearchResult(false);
    dispatch(searchForIntegratedLatchesByAccountId(account_id, currentPage, itemsPerPage.current));
    handleViewLatches();
  };

  let results = [];
  const accountSearchFieldsExist = value.first_name.length > 0 || value.last_name.length > 0 || value.phone.length > 0;

  if (props.findUser.result && accountSearchFieldsExist && !cleared) {
    results = props.findUser.result;
  }

  const indexOfLastResult = currentPage * itemsPerPage.current;
  const indexOfFirstResult = indexOfLastResult - itemsPerPage.current;

  const currentAccounts = results ? results.slice(indexOfFirstResult, indexOfLastResult) : [];

  if (props.profileState.details.is_admin === false) {
    navigate("/");
    return;
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchByLatchName();
    }
  };

  const handleViewLatches = () => {
    setViewLatches(true);
    sessionStorage.setItem("admin_integrated_devices_view_latches", true);
  };

  const handleBackToAccountSearch = () => {
    setViewAccountSearchResult(true);
    setViewLatches(false);
    sessionStorage.setItem("admin_integrated_devices_view_latches", false);
  };

  return (
    <AdminAppBar selected="Integrated Devices" {...props}>
      <Box>
        {isAccountSearch ? (
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 1 }}>
              <Typography
                data-testid="search-latches-by-owner-title"
                sx={{ marginBottom: 1 }}
                variant="h6"
                gutterBottom>
                Search For Latches By Owner
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    id="search-latches-by-owner-switch"
                    checked={isAccountSearch}
                    onChange={(e) => handleChooseSearchType(e.target.checked)}
                  />
                }
                label="Search By Owner"
              />
            </Box>
            <Divider sx={{ marginBottom: 4 }} />
            <SearchBar
              defaultValue={value}
              onValueChanged={(data) => {
                setValue({ ...value, ...data });
              }}
              onSearch={handleOnSearchByAccount}
              onClear={() => handleClear("account")}
            />
            <Box>
              {viewAccountSearchResults ? (
                <Box data-testid="view-account-search-results-box">
                  <Table>
                    {currentAccounts.map((account) => {
                      return (
                        <AccountRow
                          key={account.id}
                          account={account}
                          onClick={() => {
                            handleSelectUser(account.id);
                          }}
                        />
                      );
                    })}
                  </Table>
                  <PaginationComponent
                    totalItems={results.length}
                    itemsPerPage={itemsPerPage.current}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        ) : (
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 1 }}>
              <Typography
                id="search-latches-by-name-title"
                data-testid="search-latches-by-name-title"
                sx={{ marginBottom: 1 }}
                variant="h6"
                gutterBottom>
                Search For Latches By Latch Name
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    id="search-latches-by-name-switch"
                    data-testid="search-latches-by-name-switch"
                    checked={isAccountSearch}
                    onChange={(e) => handleChooseSearchType(e.target.checked)}
                  />
                }
                label="Search By Latch Name"
              />
            </Box>
            <Divider sx={{ marginBottom: 4 }} />
            <TextField
              id="search-latches-by-name-input"
              data-testid="search-latches-by-name-input"
              size="small"
              onChange={(event) => {
                setValue({
                  ...value,
                  latch_name: event.target.value,
                });
              }}
              onKeyDown={(e) => keyPress(e)}
              label="Latch Name"
              value={value.latch_name}
              variant="outlined"
            />
            <Button
              id="search-latches-by-latch-name-button"
              data-testid="search-latches-by-latch-name-button"
              xs={{ marginLeft: 1 }}
              onClick={handleSearchByLatchName}>
              Search
            </Button>
            <Button
              id={"search-latches-by-latch-name-button-clear-search"}
              xs={{ marginLeft: 1 }}
              onClick={() => handleClear("latch_name")}>
              Clear
            </Button>
          </Box>
        )}
      </Box>
      {viewLatches && latches && latches.length === 0 ? (
        <Box sx={{ display: "flex", flexDirection: "row", marginTop: 4, justifyContent: "space-between" }}>
          <Typography ml={2}>No results</Typography>
          {isAccountSearch ? (
            <Button size="sm" variant={"outlined"} onClick={handleBackToAccountSearch}>
              Back to Search
            </Button>
          ) : null}
        </Box>
      ) : viewLatches && latches && latches.length > 0 ? (
        <Box id={"nimbio-testing" + endpoints.ADMIN_INTEGRATED_LATCHES + "-integrated-latches"}>
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 4 }}>
            {latches.map((latch) => {
              return <CommunityLatch key={latch.id} latch={latch} />;
            })}
          </Box>
          <PaginationComponent
            totalItems={total_length_latch_results}
            itemsPerPage={itemsPerPage.current}
            paginate={paginate}
            currentPage={currentPage}
          />
        </Box>
      ) : null}
    </AdminAppBar>
  );
};

AdminIntegratedLatches.propTypes = {};

const mapStateToProps = (state) => {
  return {
    findUser: state.findUser,
    profileState: state.profileState,
    latches: state.adminIntegratedLatches.latches,
    total_length_latch_results: state.adminIntegratedLatches.total_length_latch_results,
  };
};

export default connect(mapStateToProps)(AdminIntegratedLatches);
