import React, { useState, FC } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { StaticDatePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const styles = {
  text: {
    fontFamily: "Filson",
    "& .MuiTypography-root": {
      fontFamily: "Filson",
    },
    "& .MuiPickersCalendarHeader-label": {
      fontFamily: "Filson",
    },
    "& .MuiButtonBase-root": {
      fontFamily: "Filson",
    },
    "& .MuiDialogActions-root": {
      display: "none",
    },
  },
};

type RenderDateProps = {
  datetime?: moment.Moment;
  setDateTimeCallback: (datetime: any) => any;
  onCancel: () => any;
  onNext: () => any;
  dateOnly: boolean;
};

const RenderDate: FC<RenderDateProps> = ({ datetime, setDateTimeCallback, onCancel, onNext, dateOnly }) => {
  return (
    <>
      <DialogContent
        data-testid={"event-datetime-date-picker"}
        style={{ display: "flex", justifyContent: "center" }}
        sx={styles.text}>
        <StaticDatePicker defaultValue={datetime} onChange={setDateTimeCallback} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary" sx={styles.text}>
          Cancel
        </Button>
        <Button
          data-testid={"confirm-date-btn"}
          onClick={onNext}
          disabled={false}
          variant="contained"
          color="primary"
          sx={styles.text}>
          {dateOnly ? "Done" : "Next"}
        </Button>
      </DialogActions>
    </>
  );
};

type RenderTimeProps = {
  datetime?: moment.Moment;
  setDateTimeCallback: (datetime: any) => any;
  onCancel: () => any;
  onDone: () => any;
};
const RenderTime: React.FC<RenderTimeProps> = ({ datetime, setDateTimeCallback, onCancel, onDone }) => {
  return (
    <>
      <DialogContent
        data-testid={"event-datetime-time-picker"}
        style={{ display: "flex", justifyContent: "center" }}
        sx={styles.text}>
        <TimePicker openTo="hours" value={datetime} onChange={setDateTimeCallback} sx={styles.text} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary" sx={styles.text}>
          Cancel
        </Button>
        <Button
          data-testid={"confirm-time-btn"}
          onClick={onDone}
          disabled={false}
          variant="contained"
          color="primary"
          sx={styles.text}>
          Done
        </Button>
      </DialogActions>
    </>
  );
};

type EventDateTimePickerProps = {
  open: boolean;
  setDateTimeCallback: (datetime: any) => any;
  firstPageTitle: string;
  secondPageTitle?: string;
  datetime?: moment.Moment;
  closeCallback: (value?: any) => any;
  startingPage: string;
  dateOnly?: boolean;
};

const EventDateTimePicker: FC<EventDateTimePickerProps> = ({
  open,
  setDateTimeCallback,
  firstPageTitle,
  secondPageTitle,
  datetime,
  closeCallback,
  startingPage,
  dateOnly,
}) => {
  const [page, setPage] = useState(startingPage);

  const _onCancel = () => {
    const isCancel = true;
    closeCallback(isCancel);
    setPage(startingPage);
  };

  const _onDone = () => {
    const isCancel = false;
    closeCallback(isCancel);
    setPage(startingPage);
  };

  const _onNext = () => {
    setPage("time");
  };
  if (dateOnly) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Dialog data-testid={"event-datetime-dialog-date-only"} open={open}>
          <DialogTitle data-testid={"event-datetime-dialog-title-date-only"} sx={styles.text}>
            {firstPageTitle}
          </DialogTitle>
          <RenderDate
            datetime={datetime}
            setDateTimeCallback={setDateTimeCallback}
            onCancel={_onCancel}
            onNext={_onDone}
            dateOnly={dateOnly}
          />
        </Dialog>
      </LocalizationProvider>
    );
  }
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Dialog data-testid={"event-datetime-dialog"} open={open}>
        <DialogTitle data-testid={"event-datetime-dialog-title"} id="max-width-dialog-title" sx={styles.text}>
          {page === "date" ? firstPageTitle : secondPageTitle}
        </DialogTitle>
        {page === "date" ? (
          <RenderDate
            datetime={datetime}
            setDateTimeCallback={setDateTimeCallback}
            onCancel={_onCancel}
            onNext={_onNext}
            dateOnly={dateOnly ? true : false}
          />
        ) : null}
        {page === "time" ? (
          <RenderTime
            datetime={datetime}
            setDateTimeCallback={setDateTimeCallback}
            onCancel={_onCancel}
            onDone={_onDone}
          />
        ) : null}
      </Dialog>
    </LocalizationProvider>
  );
};

EventDateTimePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  datetime: PropTypes.any,
  setDateTimeCallback: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  startingPage: PropTypes.oneOf(["date", "time"]).isRequired,
  dateOnly: PropTypes.bool,
};

export default EventDateTimePicker;
