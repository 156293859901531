import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
  FormGroup,
  Switch,
} from "@mui/material";
import { Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { communitySenseLineUpdateBoxSenseLine } from "../../reducers/sense-line-reducer";

export function SenseLineRecordsTable(props) {
  return (
    <Box style={styles.table_container} data-testid={"sense-linse-records-list"}>
      <Box style={styles.sense_line_table_title} mt={2}>
        <Typography variant="h5">Sense Line History</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minHeight: 124 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Log Datetime</TableCell>
              <TableCell align="left">Sense Line #</TableCell>
              <TableCell align="left">Triggered</TableCell>
              <TableCell align="left">Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.sense_line_records.map((sense_line_record, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  data-testid={"sense-linse-records-item"}>
                  <TableCell component="th" scope="row">
                    {sense_line_record.log_datetime}
                  </TableCell>
                  <TableCell align="left">{sense_line_record.sense_line}</TableCell>
                  <TableCell align="left">{sense_line_record.state === 1 ? "Triggered" : "Untriggered"}</TableCell>
                  <TableCell align="left">{sense_line_record.latch_message_label}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export function SenseLineTable(props) {
  const dispatch = useDispatch();
  const { box_id, community_id } = props;
  const { box_sense_lines: sense_lines } = useSelector((state) => {
    return state.senseLine;
  });

  const handleOnChange = (sense_line_id, checked) => {
    // Single switch value represents both the sense_line_online and latch_data_online
    dispatch(communitySenseLineUpdateBoxSenseLine(community_id, box_id, sense_line_id, checked, checked));
  };

  return (
    <Box style={styles.table_container} data-testid={"sense-line-config"}>
      <Box style={styles.sense_line_table_title} mt={2}>
        <Typography variant="h5">Sense Line Config</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sense Line #</TableCell>
              <TableCell align="left">Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sense_lines &&
              sense_lines
                ?.sort((a, b) => a.sense_line_id - b.sense_line_id)
                .map((sense_line, index) => {
                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="center" component="th" scope="row">
                        {sense_line.sense_line_id}
                      </TableCell>
                      <TableCell align="left">
                        <FormGroup>
                          <Switch
                            onChange={() => handleOnChange(sense_line.sense_line_id, !sense_line.sense_line_online)}
                            checked={sense_line.sense_line_online}
                            data-testid={"sense-line-active-toggle"}
                          />
                        </FormGroup>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export function SenseLineStatusTable(props) {
  const { handleUpdateSenseLineStatus } = props;
  const { box_sense_lines: sense_lines, box_sense_line_statuses: sense_line_statuses } = useSelector((state) => {
    return state.senseLine;
  });

  return (
    <Box style={styles.table_container}>
      <Box style={styles.sense_line_table_title} mt={2}>
        <Typography variant="h5">Sense Line Status Config</Typography>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Edit</TableCell>
              <TableCell align="left">Sense Line #</TableCell>
              <TableCell align="left">Triggered</TableCell>
              <TableCell align="left">Result</TableCell>
              <TableCell align="left">Button Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sense_line_statuses &&
              sense_line_statuses
                ?.sort((a, b) => a.sense_line_id - b.sense_line_id)
                .map((sense_line_status, index) => {
                  const sense_line = sense_lines?.filter((sense_line_obj) => {
                    return sense_line_obj.sense_line_id === sense_line_status.sense_line_id;
                  })[0];
                  if (!sense_line || !sense_line.sense_line_online) {
                    return null;
                  }

                  const button_message = props.button_messages?.filter((button_obj) => {
                    return button_obj.button_message_id === sense_line_status.button_message_id;
                  });
                  const latch_message = props.latch_messages?.filter((latch_obj) => {
                    return latch_obj.latch_message_id === sense_line_status.latch_message_id;
                  });
                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell align="left">
                        <IconButton
                          onClick={() => handleUpdateSenseLineStatus(sense_line_status)}
                          size="large"
                          data-testid={"sense-line-config-edit"}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {sense_line_status.sense_line_id}
                      </TableCell>
                      <TableCell align="left">
                        {sense_line_status.sense_line_state === 1 ? "Triggered" : "Untriggered"}
                      </TableCell>
                      <TableCell align="left">{latch_message[0]?.latch_label}</TableCell>
                      <TableCell align="left">{button_message[0]?.button_label}</TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

const styles = {
  table_container: {
    width: "100%",
  },
  sense_line_table_title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "16px",
  },
};
