import React from "react";
import ListItem from "@mui/material/ListItem";

import ListItemText from "@mui/material/ListItemText";
import { grey, red } from "@mui/material/colors";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { getColor, getShortName } from "../../../utilities/materialAvatar";

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  disabledAvatar: {
    color: grey[500],
  },
  disabledText: {
    color: red[900],
    fontSize: "12px",
  },
});

const KeyItem = ({ item }) => {
  return (
    <Grid key={item.key_id} item xs={12} md={6}>
      <Paper>
        <ListItem>
          <ListItemAvatar>
            <Avatar sx={{ backgroundColor: item?.disabled === 1 ? styles().disabledAvatar : getColor(item.key_id) }}>
              {getShortName(item.key_name, "")}
            </Avatar>
          </ListItemAvatar>
          <Box>
            <ListItemText
              secondary={item.disabled === 1 ? <Typography sx={styles().disabledText}>Disabled</Typography> : null}>
              {item.key_name}
            </ListItemText>
          </Box>
        </ListItem>
      </Paper>
    </Grid>
  );
};

const MemberDetailKeys = ({ keys, handleOpenEditKeys = () => {}, showChildren }) => {
  return (
    <Box sx={styles().root}>
      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: 3, justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Keys</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {showChildren()}
          <Button sx={{ marginLeft: 2 }} variant="contained" onClick={handleOpenEditKeys}>
            Edit
            <EditIcon />
          </Button>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {keys.map((item) => (
          <KeyItem key={item.key_id} item={item} />
        ))}
      </Grid>
    </Box>
  );
};

export default MemberDetailKeys;
