import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { adminCommunitySetLatchName } from "../../../reducers/admin-community-list";

const ChangeLatchName = (props) => {
  const [latchName, setLatchName] = useState(props.latch_name ? props.latch_name : "");

  const _setKeyName = () => {
    props.dispatch(adminCommunitySetLatchName(props.latch_id, latchName, props.community_id));
    props.closeCallback();
  };

  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Latch Name</DialogTitle>
      <DialogContent>
        <DialogContentText>Change the latch name below</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="latch_name"
          label="Latch Name"
          type="text"
          fullWidth
          value={latchName}
          onChange={(event) => setLatchName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeCallback}>Cancel</Button>
        <Button onClick={_setKeyName}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

ChangeLatchName.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  latch_name: PropTypes.string.isRequired,
  latch_id: PropTypes.string.isRequired,
};

export default connect()(ChangeLatchName);
