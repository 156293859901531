import React from "react";
import { useDispatch } from "react-redux";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { getColor, getShortName } from "../../../utilities/materialAvatar";
import { openAcceptPendingMember } from "../../../reducers/pending-member-detail-reducer";

const PendingItem = (props) => {
  const dispatch = useDispatch();
  const { details } = props;
  return (
    <>
      {!details.is_home ? (
        <Box data-testid={"pending-item"}>
          <ListItemButton
            data-testid={"pending-item-button"}
            onClick={() => dispatch(openAcceptPendingMember(details))}
            sx={{ ml: 1 }}>
            <ListItemIcon>
              <Avatar sx={{ backgroundColor: getColor(details["id"]) }}>
                {getShortName(details.first_name, details.last_name)}
              </Avatar>
            </ListItemIcon>
            <ListItemText secondary={details.phone_numbers ? details.phone_numbers[0] : ""}>
              {details["first_name"]} {details["last_name"]}
            </ListItemText>
          </ListItemButton>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default PendingItem;
