import React from "react";
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, Typography, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetVendorAPIKey } from "../../../reducers/admin-add-vendor-api-key";

const AdminVendorKeyDialog = ({ apiKey, open, onClose }) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      id="vendor-api-key-dialog"
      fullWidth={true}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-testid={"admin-vendor-key-dialog"}>
      <DialogTitle>{"New API Key"}</DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            Below is your newly created API Key. Make sure you copy it somewhere secure. The API Key will not be
            displayed again after this dialog is closed. If you loose your key or it becomes compromised, generate a new
            key and deactive this key.
          </Typography>
        </Box>
      </DialogContent>
      <DialogContent>
        <Box>
          <Typography>API Key</Typography>
          <TextField
            variant="outlined"
            fullWidth
            defaultValue={apiKey}
            autoFocus
            disabled={true}
            data-testid={"admin-vendor-key-dialog-api-key"}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          id="vendor-api-key-dialog-close-button"
          onClick={() => {
            navigator.clipboard.writeText(apiKey);
            dispatch(resetVendorAPIKey());
            onClose();
          }}>
          Copy to Clipboard and Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminVendorKeyDialog;
