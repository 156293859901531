import { handleActions } from "redux-actions";
import { createAction } from "redux-promise-middleware-actions";

// Action Creators
export const openRemovedMemberDetails = createAction("OPEN_REMOVED_MEMBER_DETAILS", (details) => details);
export const closeRemovedMemberDetails = createAction("CLOSE_REMOVED_MEMBER_DETAILS", () => false);

// Reducer
export const initialState = {
  details: {},
  loading: false,
  error: false,
  removedMemberDetailsOpen: false,
};

export default handleActions(
  {
    OPEN_REMOVED_MEMBER_DETAILS: (state, { payload }) => {
      return {
        ...state,
        removedMemberDetailsOpen: true,
        details: payload,
      };
    },
    CLOSE_REMOVED_MEMBER_DETAILS: (state) => {
      return {
        ...state,
        removedMemberDetailsOpen: false,
      };
    },
  },
  initialState
);

// Selectors
