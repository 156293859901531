import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { adminCommunitySetName } from "../../../reducers/admin-community-list";

const CommunitySetName = (props) => {
  const [name, setName] = useState(props.name ? props.name : "");

  const _setName = () => {
    props.dispatch(adminCommunitySetName(props.community_id, name));
    props.closeCallback();
  };

  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Community Name</DialogTitle>
      <DialogContent>
        <DialogContentText>Community name is displayed to users when signing up.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="community_name"
          label="Community Name"
          type="text"
          fullWidth
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeCallback}>Cancel</Button>
        <Button onClick={_setName}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

CommunitySetName.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunitySetName);
