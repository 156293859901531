import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  results: [],
  loaded: false,
};

export const deleteBoxFromAccountGroup = createAsyncAction(
  "DELETE_BOX_FROM_ACCOUNT_GROUP_DATA",
  (account_group_id, box_id) =>
    call(nimbioServer.admin.deleteBoxFromAccountGroup, [account_group_id, box_id], "admin-get-boxes-account-groups")
);

export const getBoxesAccountGroups = createAsyncAction("ADMIN_GET_BOXES_ACCOUNT_GROUPS", () => {
  return call(nimbioServer.admin.getBoxesFromAccountGroup, [], "admin-get-boxes-account-groups");
});

export default handleActions(
  {
    ADMIN_GET_BOXES_ACCOUNT_GROUPS_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        results: [],
      };
    },
    ADMIN_GET_BOXES_ACCOUNT_GROUPS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        results: payload,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_GET_BOXES_ACCOUNT_GROUPS_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
