import React, { useEffect, useRef, useState } from "react";
import { Box, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const PickerPagination = (props) => {
  const [pageSelected, setPageSelected] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const rpcRef = useRef(0);

  // Reset the page when the date has changed
  useEffect(() => {
    rpcRef.current = 0;
    setPageCount(0);
    setPageSelected(1);
  }, [props.earlierDate, props.laterDate]);

  // Set the data every time the rpc is called
  useEffect(() => {
    if (props.rowData) {
      const rowsSize = props.rowData.length;
      // The amount of pages should round to the nearest 100 e.g. 881 = 9
      let pages = parseInt((rowsSize + 99) / 100);
      // Create an extra page if the log size is full
      if (rowsSize === props.maxLogLength) pages += 1;
      // Add the current pages in place
      pages += 10 * rpcRef.current;
      setPageCount(pages);
      populate(pageSelected);
    }
  }, [props.rowData]);

  const handleChange = async (e, value) => {
    setPageSelected(value);
    const rpcPage = parseInt((value - 1) / 10);
    if (rpcPage !== rpcRef.current) {
      rpcRef.current = rpcPage;
      props.fetchData(props.earlierDate, props.laterDate, rpcPage);
    } else {
      populate(value);
    }
  };

  const populate = async (value) => {
    let page = parseInt(value % 10);
    if (page === 0) page = 10;
    let max = page * 100;
    const min = max - 100;
    props.setRows(props.rowData.slice(min, max));
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", m: 1, mt: 3 }}>
      <Pagination
        color="primary"
        boundaryCount={2}
        sx={{
          "& .MuiButtonBase-root.MuiPaginationItem-root": {
            ":hover": { backgroundColor: "primary.main", opacity: 0.85, color: "#fff" },
          },
          "& .MuiButtonBase-root.MuiPaginationItem-root.Mui-disabled": { display: "none" },
        }}
        count={pageCount}
        page={pageSelected}
        onChange={handleChange}
        hideNextButton={props.rowData.length < 1000}
        hidePrevButton={false}
        renderItem={(item) => (
          <PaginationItem components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
        )}
      />
    </Box>
  );
};

export default PickerPagination;
