import "react-phone-number-input/style.css";
import React from "react";
import AdminAppBar from "./AdminAppBar";
import AddInstaller from "../../common/Components/AddInstaller";

const AdminAddInstaller = (props) => {
  return (
    <AdminAppBar selected="Add Installer" {...props}>
      <AddInstaller />
    </AdminAppBar>
  );
};

export default AdminAddInstaller;
