import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Fab, Dialog, TextField, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { addHome } from "../../../reducers/homes-reducer";
import endpoints from "../../../routes/endpoints";

const TITLE = "Add Home";

const AddHome = (props) => {
  const [open, setOpen] = useState(false);
  const [homeAddress, setHomeAddress] = useState("");

  const theme = useTheme();

  const _addHome = (home_address) => {
    props.dispatch(addHome(props.community.currentCommunityID, home_address));
    setHomeAddress("");
    setOpen(false);
  };

  const _handleChange = (e) => {
    setHomeAddress(e.target.value);
  };

  const handleCancel = () => {
    setOpen(false);
    setHomeAddress("");
  };

  return (
    <Box>
      <Fab
        id={"nimbio-testing" + endpoints.COMMUNITY_HOMES + "-button-add"}
        variant="extended"
        size="medium"
        color="primary"
        aria-label={TITLE}
        sx={styles(theme).margin}
        onClick={() => setOpen(true)}
        data-testid={"homes-list-add-home"}>
        <HomeIcon sx={styles(theme).marginRight} />
        <h5>{TITLE}</h5>
      </Fab>
      <Dialog
        open={open}
        onClose={handleCancel}
        maxWidth={"sm"}
        fullWidth={true}
        data-testid={"homes-list-add-home-dialog"}>
        <DialogTitle>{TITLE}</DialogTitle>
        <DialogContent sx={styles(theme).textContainer}>
          <TextField
            sx={styles(theme).textBox}
            id="new_address"
            type="text"
            label="New Home Address"
            value={homeAddress}
            onChange={_handleChange}
            fullWidth={true}
            autoFocus={true}
            data-testid={"homes-list-add-home-dialog-address"}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id={"nimbio-testing" + endpoints.COMMUNITY_HOMES + "-button-cancel"}
            onClick={handleCancel}
            color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => _addHome(homeAddress)}
            data-testid={"homes-list-add-home-dialog-save"}>
            {TITLE}
            <HomeIcon sx={styles(theme).marginLeft} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const styles = (theme) => ({
  addButtonContainer: {
    m: 2,
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: 1,
  },
  marginRight: {
    marginRight: 1,
  },
  textBox: {
    flex: 1,
  },
  textContainer: { minHeight: 100, display: "flex", alignItems: "center" },
  rightIcon: {},
});

const mapStateToProps = (state) => {
  return {
    community: state.communityState,
  };
};

export default connect(mapStateToProps)(AddHome);
