import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import { adminCommunitySetUnits } from "../../../reducers/admin-community-list";

const CommunitySetUnits = (props) => {
  const [numberOfUnits, setNumberOfUnits] = useState(props.number_of_units ? props.number_of_units : "");

  const _setUnits = () => {
    props.dispatch(adminCommunitySetUnits(props.community_id, numberOfUnits));
    props.closeCallback();
  };

  return (
    <Dialog open={props.open} onClose={props.closeCallback} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Number of Units</DialogTitle>
      <DialogContent>
        <DialogContentText>Set the number of units for the community.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="number_of_units"
          label="Number of Units"
          type="number"
          fullWidth
          value={numberOfUnits}
          onChange={(event) => setNumberOfUnits(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeCallback}>Cancel</Button>
        <Button onClick={_setUnits}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

CommunitySetUnits.propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  units: PropTypes.number.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default connect()(CommunitySetUnits);
