import { handleActions } from "redux-actions";
import { createAction, createAsyncAction } from "redux-promise-middleware-actions";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";

// Action Creators
export const getMemberDetail = createAsyncAction(
  "GET_MEMBER_DETAILS",
  (account_community_id, community_id) => {
    return call(nimbioServer.community.manager.getMember, [account_community_id, community_id], "memberdetail-reducer");
  },
  (account_community_id, community_id) => {
    return { account_community_id, community_id };
  }
);

export const refreshMemberDetail = createAsyncAction("REFRESH_MEMBER_DETAILS", (account_community_id, community_id) => {
  return call(nimbioServer.community.manager.getMember, [account_community_id, community_id], "memberdetail-reducer");
});

export const closeMemberDetail = createAction("CLOSE_MEMBER_DETAILS", () => false);

export const setMemberHome = createAsyncAction("SET_MEMBER_HOME", (community_id, account_community_id, home_id) => {
  return call(
    nimbioServer.community.manager.setHome,
    [community_id, account_community_id, home_id],
    "memberdetail-reducer"
  );
});

export const removeMember = createAsyncAction(
  "REMOVE_MEMBER",
  (account_community_id, keys_to_remove, community_id, reason) => {
    return call(
      nimbioServer.community.manager.removeMember,
      [account_community_id, keys_to_remove, community_id, reason],
      "memberdetail-reducer"
    );
  }
);

// Reducer
export const initialState = {
  details: {},
  loading: false,
  loadingSetHome: false,
  loadingRemove: false,
  error: false,
  errorSetHome: false,
  errorRemove: false,
  removed: false,
  open: false,
  is_home: false,
  home_id: "",
  account_community_id: false,
  community_id: false,
};

export default handleActions(
  {
    REFRESH_MEMBER_DETAILS_LOADING: (state) => {
      return {
        ...state,
        error: false,
        loading: true,
      };
    },
    REFRESH_MEMBER_DETAILS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        error: false,
        loading: false,
        details: payload,
        is_home: false,
        home_id: payload.home == null ? "" : payload.home.id,
      };
    },
    REFRESH_MEMBER_DETAILS_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        is_home: false,
        account_community_id: false,
        community_id: false,
      };
    },
    GET_MEMBER_DETAILS_LOADING: (state) => {
      return {
        ...state,
        open: true,
        loading: true,
        error: false,
      };
    },
    GET_MEMBER_DETAILS_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        error: false,
        loading: false,
        details: payload,
        is_home: false,
        home_id: payload.home == null ? "" : payload.home.id,
      };
    },
    GET_MEMBER_DETAILS_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload,
        loading: false,
        is_home: false,
        account_community_id: false,
        community_id: false,
      };
    },
    SET_MEMBER_HOME_LOADING: (state) => {
      return {
        ...state,
        loadingSetHome: true,
      };
    },
    SET_MEMBER_HOME_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        home_id: payload,
        loadingSetHome: false,
      };
    },
    SET_MEMBER_HOME_ERROR: (state, { payload }) => {
      return {
        ...state,
        errorSetHome: payload,
        loadingSetHome: false,
      };
    },
    CLOSE_MEMBER_DETAILS: (state) => {
      return {
        ...state,
        open: false,
        account_community_id: false,
        community_id: false,
      };
    },
    REMOVE_MEMBER_LOADING: (state) => {
      return {
        ...state,
        loadingRemove: true,
        removed: false,
      };
    },
    REMOVE_MEMBER_SUCCESS: (state) => {
      return {
        ...state,
        removed: true,
        loadingRemove: false,
      };
    },
    REMOVE_MEMBER_ERROR: (state, { payload }) => {
      return {
        ...state,
        removed: false,
        errorRemove: payload,
        loadingRemove: false,
      };
    },
  },
  initialState
);

// Selectors
