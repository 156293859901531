import { handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { call } from "../utilities/connection";
import nimbioServer from "../server/endpoints";

const initialState = {
  loading: false,
  error: false,
  loaded: false,
};

export const adminHideKey = createAsyncAction("ADMIN_HIDE_KEY", (key_id, hide, community_id) => {
  return call(nimbioServer.admin.key.hide, [key_id, hide, community_id], "admin-hide-keys-reducer");
});

export default handleActions(
  {
    ADMIN_HIDE_KEY_LOADING: (state) => {
      return {
        ...state,
        loading: true,
        error: false,
        account: null,
      };
    },
    ADMIN_HIDE_KEY_SUCCESS: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload === false,
        loaded: true,
      };
    },
    ADMIN_HIDE_KEY_ERROR: (state) => {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    },
  },
  initialState
);
