import store from "../store";
import {
  updateKeyStateLatchOpened,
  updateKeyStateLatchFailed,
  updateKeyStateLatchReset,
} from "../reducers/admin-community-list";

const MIN_LOAD_TIME = 1000;
const SUCCESS_MSG_TIME = 2000;

export function accountSubscription(result) {
  result = result.argsList[0];
  if ("a" in result) {
    switch (result.a) {
      case "open_update":
        // Take at least MIN_LOAD_TIME time to load to show the animation
        setTimeout(() => {
          if (result.message === "did_not_open") {
            store.dispatch(updateKeyStateLatchFailed(result));
          }
          if (result.message === "opened") {
            store.dispatch(updateKeyStateLatchOpened(result));
          }
          if (result.message === "Successful Alfred lock") {
            store.dispatch(updateKeyStateLatchOpened(result));
          }
          if (result.message === "Successful Alfred unlock") {
            store.dispatch(updateKeyStateLatchOpened(result));
          }
          if (result.message === "latch_busy") {
            store.dispatch(updateKeyStateLatchOpened(result));
          }
          if (result.message === "doorbell_failure") {
            store.dispatch(updateKeyStateLatchFailed(result));
          }
          if (result.message === "doorbell_success") {
            store.dispatch(updateKeyStateLatchOpened(result));
          }
          // Wait SUCCESS_MSG_TIME time to load to show the success checkmark
          setTimeout(() => {
            store.dispatch(updateKeyStateLatchReset(result));
          }, SUCCESS_MSG_TIME);
        }, MIN_LOAD_TIME);
        break;
      default:
        console.log("Unknown message from server:", JSON.stringify(result));
    }
  }
}
