import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PaginationComponent from "../../common/Components/PaginationComponent";
import { findCommunity } from "../../../reducers/admin-find-community";
import endpoints from "../../../routes/endpoints";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";

const styles = (theme) => ({
  link: {
    textDecoration: "none",
    color: theme.link.text,
    cursor: "pointer",
    FontWeight: "750",
    "&:hover": {
      backgroundColor: theme.link.background,
      borderRadius: "3px",
      color: theme.link.highlighted,
    },
  },
});

const CommunityRow = ({ community }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <TableRow className={"nimbio-testing" + endpoints.ADMIN_COMMUNITIES + "-result"} key={community.community_id}>
      <TableCell>
        <Box>
          <Link
            sx={styles(theme).link}
            onClick={() => navigate(`/admin/community/${community.community_id}`)}
            data-testid={"community-name"}>
            {community.community_name}
          </Link>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            {community.community_active === 0 && (
              <Grid item>
                <Typography variant="body2" color="textSecondary" component="p" data-testid={"community-inactive"}>
                  {community.community_active === 0 ? "Inactive" : ""}
                </Typography>
              </Grid>
            )}
            {community.community_hidden === 1 && (
              <Grid item>
                <Typography variant="body2" color="textSecondary" component="p" data-testid={"community-hidden"}>
                  {community.community_hidden === 1 ? "Hidden" : ""}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const CommunityTable = ({ currentCommunities, searchTerm, loaded, result, itemsPerPage, paginate, currentPage }) => {
  if (currentCommunities.length === 0 && loaded && searchTerm.length === 0) {
    return (
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="body2" color="textSecondary" component="p">
          Type in the search box above to find a community.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Community Name</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {loaded &&
            result &&
            currentCommunities &&
            currentCommunities.map((community) => <CommunityRow key={community.community_id} community={community} />)}
        </TableBody>
      </Table>
      <PaginationComponent
        totalItems={result.length}
        itemsPerPage={itemsPerPage}
        paginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
};

const AdminCommunityList = (props) => {
  const searchDefault = sessionStorage.getItem("AdminCommunityList_searchTerm");

  const [searchTerm, setSearchTerm] = useState(searchDefault || "");
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 15;

  useEffect(() => {
    sessionStorage.setItem("AdminCommunityList_searchTerm", searchTerm);
    setSearchTerm(searchTerm);
    const timer = setTimeout(() => {
      dispatch(findCommunity(searchTerm));
      setCurrentPage(1);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, searchTerm]);

  const communityListSubscription = (message) => {
    message = message.argsList[0];
    if ("a" in message) {
      switch (message.a) {
        case "refresh":
          dispatch(findCommunity(searchTerm));
          break;
        default:
          break;
      }
    }
  };

  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
  };

  useSubscription(nimbioServer.internal.communityList, communityListSubscription);

  useEffect(() => {
    const searchDefault = sessionStorage.getItem("AdminCommunityList_searchTerm");
    dispatch(findCommunity(searchDefault || searchTerm));
  }, []);

  if (!props.community_list.loaded) {
    return "Loading...";
  }

  let currentCommunities;

  if (searchTerm?.length === 0) {
    const results = props.community_list.result;
    const indexOfLastResult = currentPage * itemsPerPage;
    const indexOfFirstResult = indexOfLastResult - itemsPerPage;
    currentCommunities = results.slice(indexOfFirstResult, indexOfLastResult);
  }

  if (searchTerm?.length > 0) {
    const results = props.community_list.result;
    const indexOfLastResult = currentPage * itemsPerPage;
    const indexOfFirstResult = indexOfLastResult - itemsPerPage;
    currentCommunities = results.slice(indexOfFirstResult, indexOfLastResult);
  }

  return (
    <>
      <Grid
        sx={{ paddingTop: 0 }}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        data-testid={"community-list-grid"}>
        <Grid item xs={11}>
          <TextField
            id={"nimbio-testing" + endpoints.ADMIN_COMMUNITIES + "-input"}
            size="small"
            variant="outlined"
            sx={{ width: "100%" }}
            label="Search"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            data-testid={"community-search-input"}
          />
        </Grid>
        <Grid item xs={1}>
          <Button
            onClick={() => {
              sessionStorage.removeItem("AdminCommunityList_searchTerm");
              setSearchTerm("");
              dispatch(findCommunity(""));
            }}>
            clear
          </Button>
        </Grid>
      </Grid>

      <CommunityTable
        currentCommunities={currentCommunities}
        {...props.community_list}
        {...{ searchTerm, currentPage, itemsPerPage }}
        paginate={paginate}
      />
    </>
  );
};

AdminCommunityList.propTypes = {
  type: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    community_list: state.findCommunity,
  };
};

export default connect(mapStateToProps)(AdminCommunityList);
