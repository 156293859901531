import { createAction, handleActions } from "redux-actions";
import { createAsyncAction } from "redux-promise-middleware-actions";
import nimbioServer from "../server/endpoints";
import { call } from "../utilities/connection";
import {
  formatSearchData,
  parseAcceptedMembers,
  parseRemovedMembers,
  parseUnacceptedMembers,
} from "../models/MembersModel";

import { memberListPageLength } from "../utilities/constants";
// Action Creators

export const getAcceptedMembersPaginated = createAsyncAction(
  "GET_ACCEPTED_MEMBERS_PAGINATED",
  (community_id, page, size) => {
    return call(
      nimbioServer.community.manager.getAcceptedMembersPaginated,
      [community_id, page, size],
      "members-reducer"
    );
  }
);
export const getAcceptedTotalLength = createAsyncAction(
  "GET_ACCEPTED_TOTAL_LENGTH",

  (community_id) => {
    return call(nimbioServer.community.manager.getAcceptedMembersPaginated, [community_id, 1, 1], "members-reducer");
  }
);
export const paginateAcceptedMembers = createAction("PAGINATE_ACCEPTED_MEMBERS", (page) => {
  return page;
});

export const getUnacceptedMembersPaginated = createAsyncAction(
  "GET_UNACCEPTED_MEMBERS_PAGINATED",
  (community_id, page, size) => {
    return call(
      nimbioServer.community.manager.getUnacceptedMembersPaginated,
      [community_id, page, size],
      "members-reducer"
    );
  }
);
export const getUnacceptedTotalLength = createAsyncAction("GET_UNACCEPTED_TOTAL_LENGTH", (community_id) => {
  return call(nimbioServer.community.manager.getUnacceptedMembersPaginated, [community_id, 1, 1], "members-reducer");
});
export const paginateUnacceptedMembers = createAction("PAGINATE_UNACCEPTED_MEMBERS", (page) => {
  return page;
});

export const getRemovedMembersPaginated = createAsyncAction(
  "GET_REMOVED_MEMBERS_PAGINATED",
  (community_id, page, size) => {
    return call(
      nimbioServer.community.manager.getRemovedMembersPaginated,
      [community_id, page, size],
      "members-reducer"
    );
  }
);
export const getRemovedTotalLength = createAsyncAction("GET_REMOVED_TOTAL_LENGTH", (community_id) => {
  return call(nimbioServer.community.manager.getRemovedMembersPaginated, [community_id, 1, 1], "members-reducer");
});
export const paginateRemovedMembers = createAction("PAGINATE_REMOVED_MEMBERS", (page) => {
  return page;
});

export const clearMembers = createAction("CLEAR_MEMBERS", () => {
  return true;
});

export const setMemberSortByType = createAction("SET_MEMBER_SORT_BY_TYPE", (type) => {
  return type;
});

export const confirmMember = createAsyncAction(
  "CONFIRM_MEMBER",
  (account_community_id, checked_keys, community_id, move_out_date) => {
    return call(
      nimbioServer.community.manager.confirmMember,
      [account_community_id, checked_keys, community_id, move_out_date],
      "members-reducer"
    );
  }
);

export const getHomeDetailForCache = createAsyncAction(
  "GET_HOME_DETAILS_FOR_CACHE",
  (home_id, community_id) => {
    return call(nimbioServer.community.manager.getHomeDetails, [home_id, community_id], "members-reducer");
  },
  (home_id, community_id) => {
    return { home_id, community_id };
  }
);

const defaultMembersState = {
  accepted: {
    members: [],
    total_length: 0,
    page: 1,
  },
  unaccepted: {
    members: [],
    total_length: 0,
    page: 1,
  },
  removed: {
    members: [],
    total_length: 0,
    page: 1,
  },
};

// Reducer
export const initialState = {
  members: defaultMembersState,
  memberSortByType: "name", // date, name
  loading: false,
  loadingConfirmMember: false,
  loadingCache: false,
  error: false,
  errorMessage: null,
  errorConfirmMember: false,
  errorCache: false,
  notManager: false,
  searchData: [],
};

export default handleActions(
  {
    GET_ACCEPTED_TOTAL_LENGTH_SUCCESS: (state, { payload }) => {
      if (!payload.result) {
        return {
          ...state,
          error: true,
          errorMessage: payload.message,
        };
      }
      return {
        ...state,
        members: {
          ...state.members,
          accepted: {
            ...state.members.accepted,
            total_length: payload.data.total_length,
          },
        },
      };
    },
    GET_ACCEPTED_MEMBERS_PAGINATED_SUCCESS: (state, { payload }) => {
      if (payload.result === false) {
        return {
          ...state,
          error: true,
          errorMessage: payload.message,
        };
      }
      const searchData = parseAcceptedMembers(payload, getHomeDetailForCache);

      let allAcceptedMembers = [...state.members.accepted.members, ...payload.data.accepted];

      if (state.memberSortByType === "name") {
        allAcceptedMembers = allAcceptedMembers.sort((first, second) =>
          (first.is_home ? first.home_address : first.first_name).localeCompare(
            second.is_home ? second.home_address : second.first_name
          )
        );
      }

      if (state.memberSortByType === "date") {
        allAcceptedMembers = allAcceptedMembers.sort(
          (first, second) => new Date(second.created_datetime) - new Date(first.created_datetime)
        );
      }

      const currentPage = state.members.accepted.page;
      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const acceptedMembersToRender = allAcceptedMembers.slice(startIndex, endIndex);

      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        members: {
          ...state.members,
          accepted: {
            ...state.members.accepted,
            members: allAcceptedMembers,
            total_length: payload.data.total_length,
            render: acceptedMembersToRender,
          },
        },
        notManager: false,
        searchData: [...state.searchData, ...searchData],
      };
    },
    GET_ACCEPTED_MEMBERS_PAGINATED_ERROR: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        loaded: true,
      };
    },
    GET_ACCEPTED_MEMBERS_PAGINATED_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_UNACCEPTED_TOTAL_LENGTH_SUCCESS: (state, { payload }) => {
      if (!payload.result) {
        return {
          ...state,
          error: true,
          errorMessage: payload.message,
        };
      }
      return {
        ...state,
        members: {
          ...state.members,
          unaccepted: {
            ...state.members.unaccepted,
            total_length: payload.data.total_length,
          },
        },
      };
    },
    GET_UNACCEPTED_MEMBERS_PAGINATED_SUCCESS: (state, { payload }) => {
      if (payload.result === false) {
        return {
          ...state,
          error: true,
          errorMessage: payload.message,
        };
      }
      const searchData = parseUnacceptedMembers(payload, getHomeDetailForCache);

      let allUnacceptedMembers = [...state.members.unaccepted.members, ...payload.data.unaccepted];

      if (state.memberSortByType === "name") {
        allUnacceptedMembers = allUnacceptedMembers.sort((first, second) => {
          return (first.is_home ? first.home_address : first.first_name).localeCompare(
            second.is_home ? second.home_address : second.first_name
          );
        });
      }

      if (state.memberSortByType === "date") {
        allUnacceptedMembers = allUnacceptedMembers.sort((first, second) => {
          return new Date(second.created_datetime) - new Date(first.created_datetime);
        });
      }

      const currentPage = state.members.unaccepted.page;
      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const unacceptedMembersToRender = allUnacceptedMembers.slice(startIndex, endIndex);

      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        members: {
          ...state.members,
          unaccepted: {
            ...state.members.unaccepted,
            members: allUnacceptedMembers,
            total_length: payload.data.total_length,
            render: unacceptedMembersToRender,
          },
        },
        notManager: false,
        searchData: [...state.searchData, ...searchData],
      };
    },
    GET_UNACCEPTED_MEMBERS_PAGINATED_ERROR: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        loaded: true,
      };
    },
    GET_UNACCEPTED_MEMBERS_PAGINATED_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_REMOVED_MEMBERS_PAGINATED_SUCCESS: (state, { payload }) => {
      if (payload.result === false) {
        return {
          ...state,
          error: true,
          errorMessage: payload.message,
        };
      }
      const searchData = parseRemovedMembers(payload, getHomeDetailForCache);

      let allRemovedMembers = [...state.members.removed.members, ...payload.data.removed];

      if (state.memberSortByType === "name") {
        allRemovedMembers = allRemovedMembers.sort((first, second) =>
          first.first_name.localeCompare(second.first_name)
        );
      }

      if (state.memberSortByType === "date") {
        allRemovedMembers = allRemovedMembers.sort(
          (first, second) => new Date(second.removal_date) - new Date(first.removal_date)
        );
      }

      const currentPage = state.members.removed.page;
      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const removedMembersToRender = allRemovedMembers.slice(startIndex, endIndex);

      return {
        ...state,
        error: false,
        loading: false,
        loaded: true,
        members: {
          ...state.members,
          removed: {
            ...state.members.removed,
            members: allRemovedMembers,
            total_length: payload.data.total_length,
            render: removedMembersToRender,
          },
        },
        notManager: false,
        searchData: [...state.searchData, ...searchData],
      };
    },
    GET_REMOVED_MEMBERS_PAGINATED_ERROR: (state) => {
      return {
        ...state,
        error: true,
        loading: false,
        loaded: true,
      };
    },
    GET_REMOVED_MEMBERS_PAGINATED_LOADING: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    GET_REMOVED_TOTAL_LENGTH_SUCCESS: (state, { payload }) => {
      if (!payload.result) {
        return {
          ...state,
          error: true,
          errorMessage: payload.message,
        };
      }
      return {
        ...state,
        members: {
          ...state.members,
          removed: {
            ...state.members.removed,
            total_length: payload.data.total_length,
          },
        },
      };
    },
    GET_HOME_DETAILS_FOR_CACHE_LOADING: (state) => {
      return {
        ...state,
        loadingCache: true,
      };
    },
    GET_HOME_DETAILS_FOR_CACHE_ERROR: (state, { payload }) => {
      return {
        ...state,
        errorCache: payload,
        loadingCache: false,
      };
    },
    GET_HOME_DETAILS_FOR_CACHE_SUCCESS: (state, { payload }) => {
      state.searchData.push({
        searchTerm: (
          payload.members.map((item) => item.first_name + " " + item.last_name).join(" ") +
          " " +
          payload.address
        ).trim(),
        is_home: true,
        is_accepted: true,
        id: payload.id,
        address: payload.address,
        members: payload.members.map((item) => formatSearchData(true, false, false, item)),
      });
      return {
        ...state,
        loadingCache: false,
        searchData: state.searchData,
      };
    },
    CONFIRM_MEMBER_LOADING: (state) => {
      return {
        ...state,
        loadingConfirmMember: true,
      };
    },
    CONFIRM_MEMBER_SUCCESS: (state) => {
      return {
        ...state,
        loadingConfirmMember: false,
      };
    },
    CONFIRM_MEMBER_ERROR: (state, { payload }) => {
      return {
        ...state,
        errorConfirmMember: payload,
        loadingConfirmMember: false,
      };
    },
    SET_CURRENT_COMMUNITY: (state) => {
      return {
        ...state,
        members: defaultMembersState,
      };
    },
    CLEAR_MEMBERS: () => {
      return {
        ...initialState,
      };
    },
    PAGINATE_ACCEPTED_MEMBERS: (state, { payload }) => {
      const currentPage = payload;
      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const membersToRender = state.members.accepted.members.slice(startIndex, endIndex);

      return {
        ...state,
        members: {
          ...state.members,
          accepted: {
            ...state.members.accepted,
            page: payload,
            render: membersToRender,
          },
        },
      };
    },
    PAGINATE_UNACCEPTED_MEMBERS: (state, { payload }) => {
      const currentPage = payload;

      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const membersToRender = state.members.unaccepted.members.slice(startIndex, endIndex);

      return {
        ...state,
        members: {
          ...state.members,
          unaccepted: {
            ...state.members.unaccepted,
            page: payload,
            render: membersToRender,
          },
        },
      };
    },
    PAGINATE_REMOVED_MEMBERS: (state, { payload }) => {
      const currentPage = payload;
      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const membersToRender = state.members.removed.members.slice(startIndex, endIndex);

      return {
        ...state,
        members: {
          ...state.members,
          removed: {
            ...state.members.removed,
            page: payload,
            render: membersToRender,
          },
        },
      };
    },
    SET_MEMBER_SORT_BY_TYPE: (state, { payload }) => {
      let allAcceptedMembers = [...state.members.accepted.members];
      let allUnacceptedMembers = [...state.members.unaccepted.members];
      let allRemovedMembers = [...state.members.removed.members];

      if (payload === "name") {
        allAcceptedMembers = allAcceptedMembers.sort((first, second) =>
          (first.is_home ? first.home_address : first.first_name).localeCompare(
            second.is_home ? second.home_address : second.first_name
          )
        );

        allUnacceptedMembers = allUnacceptedMembers.sort((first, second) =>
          (first.is_home ? first.home_address : first.first_name).localeCompare(
            second.is_home ? second.home_address : second.first_name
          )
        );
        allRemovedMembers = allRemovedMembers.sort((first, second) =>
          (first.is_home ? first.home_address : first.first_name).localeCompare(
            second.is_home ? second.home_address : second.first_name
          )
        );
      }

      if (payload === "date") {
        allRemovedMembers = allRemovedMembers.sort(
          (first, second) => new Date(second.removal_date) - new Date(first.removal_date)
        );

        allUnacceptedMembers = allUnacceptedMembers.sort(
          (first, second) => new Date(second.created_datetime) - new Date(first.created_datetime)
        );

        allAcceptedMembers = allAcceptedMembers.sort(
          (first, second) => new Date(second.created_datetime) - new Date(first.created_datetime)
        );
      }

      const currentPage = 1;
      const pageSize = memberListPageLength;
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = currentPage * pageSize;
      const acceptedMembersToRender = allAcceptedMembers.slice(startIndex, endIndex);
      const unacceptedMembersToRender = allUnacceptedMembers.slice(startIndex, endIndex);
      const removedMembersToRender = allRemovedMembers.slice(startIndex, endIndex);

      return {
        ...state,
        memberSortByType: payload,
        members: {
          ...state.members,
          accepted: {
            ...state.members.accepted,
            page: 1,
            members: allAcceptedMembers,
            render: acceptedMembersToRender,
          },
          unaccepted: {
            ...state.members.unaccepted,
            page: 1,
            members: allUnacceptedMembers,
            render: unacceptedMembersToRender,
          },
          removed: {
            ...state.members.removed,
            page: 1,
            members: allRemovedMembers,
            render: removedMembersToRender,
          },
        },
      };
    },
  },
  initialState
);
