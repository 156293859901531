import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  Box,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { useTheme } from "@mui/material/styles";
import AdminAppBar from "./AdminAppBar";
import AdminAccountDialog from "./AdminAccountDialog";
import { findUser } from "../../../reducers/admin-find-user";
import PaginationComponent from "../../common/Components/PaginationComponent";
import endpoints from "../../../routes/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import nimbioServer from "../../../server/endpoints";
import PhoneNumberCustom from "../../common/Components/PhoneNumberCustom";

const debounceTimeout = 500;

const styles = () => ({
  phoneInput: {
    width: "100%",
  },
});

const SearchBar = ({
  userFirstName,
  userLastName,
  userPhone,
  setUserFirstName,
  setUserLastName,
  setUserPhone,
  onSearch,
  onClear,
}) => {
  const theme = useTheme();
  const [firstNameDebounce, setFirstNameDebounce] = useState(userFirstName);
  const [lastNameDebounce, setLastNameDebounce] = useState(userLastName);
  const [phoneDebounce, setPhoneDebounce] = useState(userPhone);

  const firstNameDebounceTimer = useRef(null);
  const lastNameDebounceTimer = useRef(null);
  const phoneDebounceTimer = useRef(null);

  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          if (onSearch) {
            onSearch();
          }
        }
      }}>
      <Grid item>
        <Typography>Search</Typography>
      </Grid>
      <Grid item>
        <TextField
          size="small"
          id="first_name"
          onChange={(event) => {
            clearTimeout(firstNameDebounceTimer.current);
            firstNameDebounceTimer.current = setTimeout(() => {
              setUserFirstName(event.target.value);
            }, debounceTimeout);

            setFirstNameDebounce(event.target.value);
          }}
          label="First Name"
          value={firstNameDebounce}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item>
        <TextField
          size="small"
          id="last_name"
          onChange={(event) => {
            clearTimeout(lastNameDebounceTimer.current);
            lastNameDebounceTimer.current = setTimeout(() => {
              setUserLastName(event.target.value);
            }, debounceTimeout);

            setLastNameDebounce(event.target.value);
          }}
          label="Last Name"
          value={lastNameDebounce}
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item>
        <PhoneInput
          // country="US"
          id={"nimbio-testing" + endpoints.ADMIN_ACCOUNT_LIST + "-input-phone"}
          value={phoneDebounce}
          onChange={(phone_input) => {
            if (phone_input === undefined) {
              phone_input = "";
            }
            clearTimeout(phoneDebounceTimer.current);
            phoneDebounceTimer.current = setTimeout(() => {
              setUserPhone(phone_input);
            }, debounceTimeout);

            setPhoneDebounce(phone_input);
          }}
          defaultCountry="US"
          label="Phone Number"
          placeholder="Phone Number"
          numberInputProps={{ autoFocus: true, style: styles(theme).phoneInput }}
          inputComponent={PhoneNumberCustom}
        />
      </Grid>

      {/* <Grid item>
        <Button
          id={"nimbio-testing" + endpoints.ADMIN_ACCOUNT_LIST + "-button-search"}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (onSearch) {
                onSearch();
              }
            }
          }}
          variant="contained"
          onClick={() => {
            if (onSearch) {
              onSearch();
            }
          }}>
          Search
        </Button>
      </Grid> */}
      <Grid item>
        <Button
          variant={"outlined"}
          onClick={() => {
            setUserFirstName("");
            setUserLastName("");
            setUserPhone("");
            setFirstNameDebounce("");
            setLastNameDebounce("");
            setPhoneDebounce("");
            if (onClear) {
              onClear();
            }
          }}>
          clear
        </Button>
      </Grid>
    </Grid>
  );
};

const AccountRow = ({ account, onClick }) => {
  return (
    <TableBody className={"nimbio-testing" + endpoints.ADMIN_ACCOUNT_LIST + "-result"}>
      <TableRow>
        <TableCell style={{ width: 40 }}>
          <IconButton aria-label="settings" size="large" onClick={onClick}>
            <EditIcon color="primary" />
          </IconButton>
        </TableCell>
        <TableCell>
          <Link to={`/admin/account/${account.id}`}>{`${account.first_name} ${account.last_name}`}</Link>
        </TableCell>
        <TableCell>{account.phone_numbers}</TableCell>
        <TableCell>{account.admin ? "Yes" : "No"}</TableCell>
        <TableCell>{account.installer ? "Yes" : "No"}</TableCell>
        <TableCell>{account.distributor ? "Yes" : "No"}</TableCell>
      </TableRow>
    </TableBody>
  );
};

const AccountTable = ({
  currentAccounts,
  userFirstName,
  userLastName,
  userPhone,
  results,
  itemsPerPage,
  paginate,
  currentPage,
  handleEditModalOpen,
}) => {
  if (
    currentAccounts.length === 0 &&
    userFirstName.length === 0 &&
    userLastName.length === 0 &&
    userPhone.length === 0
  ) {
    return (
      <Box style={{ marginTop: 20 }}>
        <Typography variant="body2" color="textSecondary" component="p">
          Type a first name, last name, or phone number to find an account.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone Numbers</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Installer</TableCell>
            <TableCell>Distributor</TableCell>
          </TableRow>
        </TableHead>

        {currentAccounts.map((account) => {
          return (
            <AccountRow
              key={account.id}
              account={account}
              onClick={() => {
                handleEditModalOpen(account);
              }}
            />
          );
        })}
      </Table>

      <PaginationComponent
        totalItems={results.length}
        itemsPerPage={itemsPerPage}
        paginate={paginate}
        currentPage={currentPage}
      />
    </>
  );
};

const AdminAccountList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [cleared, setCleared] = useState(false);
  const [searchFieldsExist, setSearchFieldsExist] = useState(false);
  const [account, setAccount] = useState(null);
  const [userFirstName, setUserFirstName] = useState(
    sessionStorage.getItem("admin_account_list_search_first_name") || ""
  );
  const [userLastName, setUserLastName] = useState(sessionStorage.getItem("admin_account_list_search_last_name") || "");
  const [userPhone, setUserPhone] = useState(sessionStorage.getItem("admin_account_list_search_phone") || "");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = 10;

  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
  };

  useEffect(() => {
    const fieldsExist = userFirstName.length > 0 || userLastName.length > 0 || userPhone.length > 0;
    setSearchFieldsExist(fieldsExist);

    handleOnSearch();
  }, [userFirstName, userLastName, userPhone]);

  const handleOnSearch = () => {
    // clear the results if no search criteria is entered

    sessionStorage.setItem("admin_account_list_search_first_name", userFirstName);
    sessionStorage.setItem("admin_account_list_search_last_name", userLastName);
    sessionStorage.setItem("admin_account_list_search_phone", userPhone);

    if (userFirstName === "" && userLastName === "" && userPhone === "") {
      setCleared(true);
      return;
    }
    setCleared(false);
    dispatch(
      findUser(
        userFirstName === "" ? null : userFirstName,
        userLastName === "" ? null : userLastName,
        userPhone === "" ? null : userPhone
      )
    );
  };

  useSubscription(nimbioServer.internal.accountList, () => {
    dispatch(
      findUser(
        sessionStorage.getItem("admin_account_list_search_first_name"),
        sessionStorage.getItem("admin_account_list_search_last_name"),
        sessionStorage.getItem("admin_account_list_search_phone")
      )
    );
  });

  const handleEditModalOpen = (account) => {
    setOpen(true);
    setAccount(account);
  };

  let results = [];
  if (props.account_list.result && searchFieldsExist && !cleared) {
    results = props.account_list.result;
  }

  const indexOfLastResult = currentPage * itemsPerPage;
  const indexOfFirstResult = indexOfLastResult - itemsPerPage;
  const currentAccounts = results ? results.slice(indexOfFirstResult, indexOfLastResult) : [];

  if (props.profileState.details.is_admin === false) {
    navigate("/");
    return;
  }

  return (
    <AdminAppBar selected="Accounts" {...props}>
      <>
        <SearchBar
          userFirstName={userFirstName}
          userLastName={userLastName}
          userPhone={userPhone}
          setUserFirstName={setUserFirstName}
          setUserLastName={setUserLastName}
          setUserPhone={setUserPhone}
          onSearch={() => handleOnSearch()}
          onClear={() => {
            setCleared(true);
            sessionStorage.removeItem("admin_account_list_search_first_name");
            sessionStorage.removeItem("admin_account_list_search_last_name");
            sessionStorage.removeItem("admin_account_list_search_phone");
          }}
        />

        <>
          <AccountTable
            currentAccounts={currentAccounts}
            userFirstName={userFirstName}
            userLastName={userLastName}
            userPhone={userPhone}
            results={results}
            itemsPerPage={itemsPerPage}
            paginate={paginate}
            currentPage={currentPage}
            handleEditModalOpen={handleEditModalOpen}
          />
        </>
      </>

      {open && (
        <AdminAccountDialog
          open={open}
          account={account}
          onClose={() => {
            setOpen(false);
            setAccount(null);
          }}
        />
      )}
    </AdminAppBar>
  );
};

AdminAccountList.propTypes = {};

const mapStateToProps = (state) => {
  return {
    account_list: state.findUser,
    profileState: state.profileState,
  };
};

export default connect(mapStateToProps)(AdminAccountList);
