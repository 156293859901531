import { forwardRef } from "react";
import TextField from "@mui/material/TextField";
import React from "react";

const styles = () => ({
  input: {
    backgroundColor: "#fff",
  },
});

const PhoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      sx={props.style ? props.style : styles().input}
      inputRef={ref}
      fullWidth
      size="small"
      label={props.label ? props.label : "Phone Number"}
      variant="outlined"
      name="phone"
      data-testid="phone-input"
    />
  );
};
export default forwardRef(PhoneInput);
