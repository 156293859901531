import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

export const LatchViewTextFields = ({
  onChange,
  icon,
  userModifiedMetaData,
  changedMetadataOnServerSinceLoad,
  initialMetadata,
  field,
}) => {
  const [fieldValue, setFieldValue] = useState("");

  useEffect(() => {
    if (field?.value in userModifiedMetaData) {
      if (userModifiedMetaData?.[field?.value] === null) {
        setFieldValue("");
        return;
      }
      setFieldValue(userModifiedMetaData?.[field?.value]);
      return;
    }
    if (changedMetadataOnServerSinceLoad?.[field?.value]) {
      if (changedMetadataOnServerSinceLoad?.[field?.value] === null) {
        setFieldValue("");
        return;
      }
      setFieldValue(changedMetadataOnServerSinceLoad?.[field?.value]);
      return;
    }
    if (initialMetadata?.[field?.value] === null) {
      setFieldValue("");
      return;
    }
    setFieldValue(initialMetadata?.[field?.value]);
    return;
  }, [field.value, userModifiedMetaData, changedMetadataOnServerSinceLoad, initialMetadata]);

  if (initialMetadata !== undefined) {
    return (
      <TextField
        label={
          changedMetadataOnServerSinceLoad?.[field?.value] && field?.value in userModifiedMetaData
            ? `${field.label} - Was Updated Remotely`
            : field?.label
        }
        shrink={field?.shrink}
        variant={field?.variant}
        name={field?.value}
        onChange={(event) => onChange(event)}
        style={field?.style}
        value={fieldValue}
        fullWidth={true}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor:
                changedMetadataOnServerSinceLoad?.[field?.value] && userModifiedMetaData?.[field?.value]
                  ? "red"
                  : "gray",
              borderWidth:
                changedMetadataOnServerSinceLoad?.[field?.value] && userModifiedMetaData?.[field?.value] ? "3px" : "",
            },
          },
        }}
        InputProps={
          changedMetadataOnServerSinceLoad?.[field?.value] && userModifiedMetaData?.[field?.value]
            ? {
                endAdornment: icon,
              }
            : {
                endAdornment: "",
              }
        }
        inputProps={{
          maxLength: field?.max,
        }}
      />
    );
  }
};
