import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Card, FormControl, Grid, Typography, Snackbar, Alert } from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import { connect } from "react-redux";

import { LatchViewFormControlButtons } from "./LatchViewFormControlButtons";
import { OverrideLocationField } from "./OverrideLocationField";

import { LatchViewTextFields } from "./LatchViewTextFields";
import AdminAppBar from "./AdminAppBar";
import {
  adminDeviceGetUSPSMetadata,
  adminDeviceResetUSPSMetadata,
  adminDeviceSetUSPSMetadata,
} from "../../../reducers/device-status-reducer";
import { call } from "../../../utilities/connection";

import "react-image-crop/dist/ReactCrop.css";
import nimbioServer from "../../../server/endpoints";
import useSubscription from "../../../utilities/hooks/useSubscription";
import { uspsMetadataSubscription } from "../../../subscriptions/uspsMetadataSubscriptions";
import LoadingScreen from "../../common/Components/LoadingScreen";

const LatchUSPSMetadata = (props) => {
  const { latchId } = useParams();
  const [userModifiedMetaData, setUserModifiedMetadata] = useState({});
  const [initialMetadata, setInitialMetadata] = useState(null);
  const [changedMetadataOnServerSinceLoad, setChangedMetadataOnServerSinceLoad] = useState();
  const [mapsModalOpen, setMapsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { dispatch } = props;

  useEffect(() => {
    const loadDelay = setTimeout(() => {
      setLoading(false);
    }, 350);
    return () => {
      clearTimeout(loadDelay);
    };
  }, [props.deviceStatusLoading]);

  const handleLocationFieldClick = () => {
    setMapsModalOpen(true);
  };

  const handleCloseModal = () => {
    setMapsModalOpen(false);
  };

  const fetchUSPSAddressValidation = async () => {
    const response = await call(
      nimbioServer.admin.usps.validateAddress,
      [
        {
          City: userModifiedMetaData.city ? userModifiedMetaData.city : initialMetadata?.city,
          State: userModifiedMetaData.state ? userModifiedMetaData.state : initialMetadata?.state,
          Address1: userModifiedMetaData.address ? userModifiedMetaData.address : initialMetadata?.address,
        },
      ],
      "LatchUSPSMetadata"
    );

    setUserModifiedMetadata({
      address: response.Address1 ? response.Address2 + " " + response.Address1 : response.Address2,
      city: response.City,
      state: response.State,
      zip: response.Zip5,
      /* There is an error in saving plus_four on server. */
      // plus_four: response.Zip4,
      foot_route: response.CarrierRoute,
      override_location: response.google_data
        ? {
            lat: response.google_data.result.geocode.location.latitude,
            lng: response.google_data.result.geocode.location.longitude,
          }
        : false,
    });
    setMapsModalOpen(true);
  };

  const submitForm = async () => {
    setLoading(true);
    const deletedKeys = [
      "usps_metadata_id",
      "latch_id",
      "virtualized",
      "image_valid",
      "last_update_datetime",
      "report_start_date",
    ];

    if (userModifiedMetaData === undefined) {
      setLoading(false);
      alert("No changes to submit.");
      return;
    }

    let changedValues = {};

    Object.keys(userModifiedMetaData).forEach((key) => {
      if (!deletedKeys.includes(key) && userModifiedMetaData[key] !== props.uspsMetadata[key]) {
        changedValues = { ...changedValues, [key]: userModifiedMetaData[key] };
      }
    });
    const response = await props.dispatch(adminDeviceSetUSPSMetadata(latchId, changedValues));
    if (typeof (response?.value?.result === "boolean") && !response.value.result) {
      setError(response.value.message);
      setTimeout(() => {
        setLoading(false);
      }, 350);
    }
    setUserModifiedMetadata({});
    setChangedMetadataOnServerSinceLoad({});
    setInitialMetadata(null);
  };

  const updateOverrideLocation = (pinLocation) => {
    setUserModifiedMetadata({
      ...userModifiedMetaData,
      override_location: {
        lat: pinLocation.lat,
        lng: pinLocation.lng,
      },
    });
  };

  const handleOnChange = (e) => {
    if (e.target.value === "true") {
      const value = true;
      setUserModifiedMetadata({ ...userModifiedMetaData, [e.target.name]: value });
    } else if (e.target.value === "false") {
      const value = false;
      setUserModifiedMetadata({ ...userModifiedMetaData, [e.target.name]: value });
    } else if (e.target.value === "") {
      setUserModifiedMetadata({ ...userModifiedMetaData, [e.target.name]: null });
    } else {
      setUserModifiedMetadata({ ...userModifiedMetaData, [e.target.name]: e.target.value });
    }
  };

  useSubscription(nimbioServer.admin.uspsMetadata + `.${latchId}`, uspsMetadataSubscription);

  useEffect(() => {
    if (latchId && latchId === props.uspsMetadata?.latch_id && initialMetadata === null) {
      setInitialMetadata(props.uspsMetadata);
    }
    if (latchId === props.uspsMetadata?.latch_id && initialMetadata?.latch_id) {
      for (const [key, value] of Object.entries(initialMetadata)) {
        if (key in props.uspsMetadata && value !== props.uspsMetadata[key]) {
          if (key in userModifiedMetaData) {
            setChangedMetadataOnServerSinceLoad((prevData) => ({ ...prevData, [key]: props.uspsMetadata }));
          } else {
            setInitialMetadata((prevData) => ({ ...prevData, [key]: props.uspsMetadata[key] }));
          }
        }
      }
    }
  }, [props.uspsMetadata, latchId, initialMetadata, userModifiedMetaData]);

  useEffect(() => {
    dispatch(adminDeviceGetUSPSMetadata(latchId));

    return () => {
      dispatch(adminDeviceResetUSPSMetadata(latchId));
    };
  }, [latchId, dispatch]);

  const icon = <RefreshIcon style={{ color: "red" }} />;
  const { boxName } = useParams();

  if (latchId !== props.uspsMetadata?.latch_id || loading) {
    return (
      <div style={{ padding: 5 }}>
        <AdminAppBar />
        <LoadingScreen />
      </div>
    );
  } else {
    return (
      <Box style={{ padding: 5 }}>
        <Snackbar
          open={typeof error === "string"}
          autoHideDuration={4000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: "center", horizontal: "center" }}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: "100%", cursor: "default" }}>
            {error}
          </Alert>
        </Snackbar>
        <AdminAppBar />
        <Box
          style={{
            marginTop: -100,
            minWidth: 700,
            backgroundColor: "red",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
          <Card sx={{ width: "100%" }}>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6">Device: {boxName} </Typography>
            </Box>
            <FormControl
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}>
              <Box style={{ padding: 8 }}>
                <LatchViewTextFields
                  field={{
                    label: "Address",
                    shrink: "true",
                    variant: "outlined",

                    value: "address",
                    type: "text",
                    max: 30,
                  }}
                  onChange={handleOnChange}
                  userModifiedMetaData={userModifiedMetaData}
                  changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                  initialMetadata={initialMetadata}
                  icon={icon}
                />
              </Box>
              {/* The server does not take in address_2 as a param. */}
              {/* <Box style={{ padding: 8 }}>
                <LatchViewTextFields
                  field={{
                    label: "Address 2",
                    shrink: "true",
                    variant: "outlined",

                    value: "address_2",
                    type: "text",
                    max: 30,
                  }}
                  onChange={handleOnChange}
                  userModifiedMetaData={userModifiedMetaData}
                  changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                  initialMetadata={initialMetadata}
                  icon={icon}
                />
              </Box> */}
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ margin: 8 }}>
                <Grid item xs={6} style={{ paddingLeft: 0, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "City",
                      shrink: "true",
                      variant: "outlined",
                      value: "city",

                      type: "text",
                      max: 20,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "State",
                      shrink: "true",
                      variant: "outlined",
                      value: "state",

                      type: "text",
                      max: 3,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Zip",
                      shrink: "true",
                      variant: "outlined",
                      value: "zip",
                      type: "text",
                      max: 5,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={2} style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Plus Four",
                      shrink: "true",
                      variant: "outlined",
                      value: "plus_four",

                      type: "text",
                      max: 4,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
              </Grid>

              <Button variant="contained" onClick={() => fetchUSPSAddressValidation()} style={{ margin: 8 }}>
                Validate Address
              </Button>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ margin: 8 }}>
                <Grid item xs={4} style={{ paddingLeft: 0, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Georadius",
                      shrink: "true",
                      variant: "outlined",
                      value: "georadius",

                      type: "text",
                      max: 4,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Foot Route",
                      shrink: "true",
                      variant: "outlined",
                      value: "foot_route",

                      type: "text",
                      max: 8,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Mon to Sat Route",
                      shrink: "true",
                      variant: "outlined",
                      value: "mon_sat_x_route",

                      type: "text",
                      max: 8,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ margin: 8 }}>
                <Grid item xs={4} style={{ paddingLeft: 0, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Sun X Route",
                      shrink: "true",
                      variant: "outlined",
                      value: "sun_x_route",

                      type: "text",
                      max: 8,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Sun Hub Zip",
                      shrink: "true",
                      variant: "outlined",
                      value: "sun_hub_zip",

                      type: "text",
                      max: 5,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Station",
                      shrink: "true",
                      variant: "outlined",
                      value: "station",

                      type: "text",
                      max: 25,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ margin: 8 }}>
                <Grid item xs={4} style={{ paddingLeft: 0, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Hub",
                      shrink: "true",
                      variant: "outlined",
                      value: "hub",

                      type: "text",
                      max: 25,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <LatchViewTextFields
                    field={{
                      label: "System ID",
                      shrink: "true",
                      variant: "outlined",
                      value: "sys_id",

                      type: "text",
                      max: 15,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
                <Grid item xs={4} style={{ paddingLeft: 8, paddingRight: 16 }}>
                  <LatchViewTextFields
                    field={{
                      label: "Sun Sys ID",
                      shrink: "true",
                      variant: "outlined",
                      value: "sunday_sys_id",

                      type: "text",
                      max: 15,
                    }}
                    onChange={handleOnChange}
                    userModifiedMetaData={userModifiedMetaData}
                    changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                    initialMetadata={initialMetadata}
                    icon={icon}
                  />
                </Grid>
              </Grid>

              <OverrideLocationField
                handleLocationFieldClick={handleLocationFieldClick}
                initialMetadata={initialMetadata}
                userModifiedMetaData={userModifiedMetaData}
                changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                icon={icon}
                handleCloseModal={handleCloseModal}
                mapsModalOpen={mapsModalOpen}
                updateOverrideLocation={updateOverrideLocation}
              />
              <LatchViewFormControlButtons
                userModifiedMetaData={userModifiedMetaData}
                changedMetadataOnServerSinceLoad={changedMetadataOnServerSinceLoad}
                initialMetadata={initialMetadata}
                handleOnChange={handleOnChange}
              />
            </FormControl>
            <Button
              variant="contained"
              disabled={Object.keys(userModifiedMetaData).length === 0}
              onClick={() => {
                submitForm();
              }}
              style={{
                width: "95%",
                paddingTop: 10,
                paddingBottom: 10,
                marginTop: 10,
                marginRight: "50%",
                marginLeft: "50%",
                transform: "translateX(-50%)",
              }}>
              SUBMIT METADATA
            </Button>
            <Box style={{ marginTop: 16 }}>
              <Typography variant="caption" style={{ marginTop: 16 }}>
                USPS METADATA ID: {initialMetadata?.usps_metadata_id}
              </Typography>
            </Box>
          </Card>
        </Box>
      </Box>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    uspsMetadata: state.deviceStatus.uspsMetadata,
    deviceStatusLoading: state.deviceStatus.loading,
  };
};

export default connect(mapStateToProps)(LatchUSPSMetadata);
