import React, { useState } from "react";
import PropTypes from "prop-types";
import { ListItemButton, ListItem, Box, Typography, Button, Snackbar, IconButton } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";

const styles = (theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: 28,
    right: 0,
    left: 0,
    zIndex: 100,
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    opacity: 1.0,
  },
});

const PhoneNumbers = (props) => {
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const theme = useTheme();

  const handleClick = () => {
    setOpen((oldOpen) => !oldOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const copyToClipBoard = () => {
    setSnackOpen(true);
    navigator.clipboard.writeText(props.phone_numbers[0]);
  };
  const handleClose = () => {
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="primary" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box sx={styles(theme).root}>
        <ListItemButton>
          <Typography onClick={handleClick}>
            {" "}
            {props.phone_numbers.length === 0 && "No Phone"}
            {props.phone_numbers.length > 0 && props.phone_numbers[0]}
          </Typography>

          {props.phone_numbers.length > 0 ? (
            <Button onClick={copyToClipBoard} sx={{ marginLeft: 3 }}>
              <ContentCopyIcon size={"sm"} sx={{ marginLeft: 1, fontSize: "16px" }} />
            </Button>
          ) : null}
          <Snackbar
            open={snackOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            message="Copied to Clipboard"
            action={action}
          />
        </ListItemButton>
        {open ? (
          <Box sx={styles(theme).dropdown}>
            {props.phone_numbers.length === 1 && <ListItem>No More Phone Numbers</ListItem>}
            {props.phone_numbers.slice(1).map((phone) => (
              <ListItem key={phone}>{phone}</ListItem>
            ))}
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

PhoneNumbers.propTypes = {
  phone_numbers: PropTypes.array.isRequired,
};

export default PhoneNumbers;
