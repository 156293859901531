import React from "react";
import PropTypes from "prop-types";
import { Box, Switch, FormControlLabel, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setAllowNewMemberRequestHome } from "../../../reducers/homes-reducer";

const styles = {
  text: {
    fontFamily: "Filson",
  },
};

type CommunityAllowNewMemberToRequestHomeProps = {
  is_new_member_request_home_enabled: boolean;
  community_id: number;
  community_type: number;
};
const CommunityAllowNewMemberToRequestHome = (props: CommunityAllowNewMemberToRequestHomeProps) => {
  const dispatch = useDispatch();
  const { community_type, community_id, is_new_member_request_home_enabled } = props;
  if (community_type == 2) return null;

  return (
    <Box sx={{ m: 1 }}>
      <Box alignItems="center">
        <FormControlLabel
          control={<Switch checked={is_new_member_request_home_enabled} />}
          label={<Typography sx={styles.text}>Allow New Members to Request Home</Typography>}
          labelPlacement="start"
          onClick={() => dispatch(setAllowNewMemberRequestHome(community_id, !is_new_member_request_home_enabled))}
        />
      </Box>
    </Box>
  );
};

CommunityAllowNewMemberToRequestHome.propTypes = {
  is_new_member_request_home_enabled: PropTypes.bool.isRequired,
  community_id: PropTypes.number.isRequired,
};

export default CommunityAllowNewMemberToRequestHome;
