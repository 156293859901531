import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import EventTime from "./EventTime";
import { removeHoldOpenTemporalTime } from "../../../reducers/hold-open-reducers";

class RecurringTime extends Component {
  _remove_recurring_time = () => {
    this.props.dispatch(
      removeHoldOpenTemporalTime(
        this.props.community_id,
        this.props.latch_id,
        this.props.temporal_date_id,
        this.props.temporal_time_id,
      ),
    );
  };

  render() {
    return (
      <>
        <EventTime isRecurring={true} date={this.props.start} label="Start" />
        <EventTime isRecurring={true} date={this.props.end} label="End" />
      </>
    );
  }
}

RecurringTime.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  temporal_time_id: PropTypes.string.isRequired,
  community_id: PropTypes.number.isRequired,
  latch_id: PropTypes.string.isRequired,
  temporal_date_id: PropTypes.string.isRequired,
};

export default connect()(RecurringTime);
